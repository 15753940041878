import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import './Dropdown.css';

export const Dropdown = ({ label, items, color, className, ...rest }) => {
  const getItems = useCallback(() => {
    return items && items.length > 0 && items.map((item, i) => {
      return (
        <DropdownItem key={i} {...item?.props}>
          { item?.label }
        </DropdownItem>
      )
    })
  }, [items])
  
  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle caret color={color}  {...rest}>
        {label}
        </DropdownToggle>
        <DropdownMenu>
        {getItems()}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
};

Dropdown.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
  items: PropTypes.array,
  label: PropTypes.string,
};

Dropdown.defaultProps = {
  color: "secondary",
  size: "normal",
};

/**
Example, NOTE! If you need Select component please use SelectMulti.js
This component is meant for Menu dropdown where user clicks the item
and does some action

const getProductAttributeOptions = useCallback(() => {
    return productAttributes && productAttributes.map(productAttribute => {
      return {
        value: productAttribute.id, 
        label: productAttribute.code,
        props: {
          onClick: () => myFunction(productAttribute.id)
        }
      }
    })
  }, [productAttributes])

<Dropdown
  label="Lisää uusi tuoteominaisuus"
  items={getProductAttributeOptions()}
/>
**/

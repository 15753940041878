import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { 
  httpGetStockItem, 
  httpCreateStockItem,
  httpUpdateStockItem, 
  httpDeleteStockItem, 
  httpExportStockItemToMCF 
} from "services/stock" 
//import {
//  httpExportProductToMCF,
//  httpExportProductVariationToMCF
//} from "services/products" 

const defaultValues = {
  id: null,
  product_fk: null,
  product_variation_fk: null,
  id_mcf: null,
  backorder_enabled: "false",
  backorder_estimate: "30",
  balance: 0,
  balance_alert: null,
  balance_limit: null,
  barcode: "",
  code: "",
  enabled: true,
  location: "",
  product_id: null,
  quantity: 0,
  reserved: null,
  // sku: "-",
  variation_id: null,
}


const ProductStockEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      t,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("Haluatko varmasti poistaa?"));
    if (result) {
      httpDeleteStockItem(values?.id).then(res => {
        props.handleResolve()
      })
    }
  }
  
  // Product Codes
  
  const getCodesOptions = useCallback(() => {
    return values?.codes && values?.codes.map(code => {
      return {value: code.id, label: code.name}
    })
  }, [values?.codes])
  
  useEffect(() => {
    if (values?.enabled === true) {
      // Ensure quantity is not empty value
      if (!values?.quantity > 0) {
        setFieldValue("quantity", 0);
      }
    }
  }, [values])
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Varastotuote")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("barcode")}
                label={t("Viivakoodi")}
              />
            </Col>
          </Row>
          
          {/*
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("sku")}
                label={t("SKU")}
              />
            </Col>
          </Row>
          */}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("location")}
                label={t("Varastopaikka")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              {t("Tuotetta on rajallinen määrä varastossa")}&nbsp;
              <Toggle
                {...getFormikInputProps("enabled")}
                defaultChecked={values?.enabled}
                labelOn={t("Kyllä")}
                labelOff={t("Ei")}
              />
            </Col>
          </Row>
          
          { values?.enabled && (
            <>
              <Row>
                <Col className="mb-3">
                  <Input
                    {...getFormikInputProps("quantity")}
                    label={t("Varastossa")}
                    type="number"
                  />
                </Col>
              </Row>
              
              <Row>
                <Col className="mb-3">
                  <Input
                    {...getFormikInputProps("balance_limit")}
                    label={t("Hälytysraja")}
                    type="number"
                  />
                </Col>
              </Row>
              
              <Row>
                <Col className="mb-3">
                  {t("Jos tuote on loppu")}
                  <Radio
                      {...getFormikInputProps("backorder_enabled")}
                      checkedChoice={values?.backorder_enabled ? values?.backorder_enabled.toString() : "false"}
                      choices={[
                        { id: 1, label: t("Lopeta tuotteen myynti"), value: "false"},
                        { id: 2, label: t("Jatka tuotteen myynistä, jolloin toimitusaika"), value: "true"},
                      ]}
                  />
                 
                </Col>
              </Row>
              
              { values?.backorder_enabled && values?.backorder_enabled != "false" && (
              <Row>
                <Col className="mb-3">
                  <Input
                    {...getFormikInputProps("backorder_estimate")}
                    label={t("Tilaustuotteen toimitusaika")}
                    type="number"
                  />
                </Col>
              </Row>
              )}
            </>
          )}
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        {values?.id && (
        <Col className="mb-3 text-center">
          <Button onClick={handleDelete} variant="danger">{t("Poista")}</Button>
        </Col>
        )}
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Tallenna")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const ProductStockEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      
      const exportToMcf = (productId, productVariationId) => {
        
        if (productVariationId && productVariationId > 0) {
          httpExportStockItemToMCF(props.preSetValues.id).then(McfResponse => {
            setSubmitting(false);
            props.notify({ 
              type: 'success', 
              title:t("Varasto"), 
              message:t("Muutokset viety MyCashFlowiin")
            })
            //props.history.push(`/dashboard/products/edit/${props.productId}/variations`);
          }, error => {
            
            if (error?.data?.errorCode == "UNABLE_TO_EXPORT_PRODUCT_VARIATIONS_MCF") {
              props.notify({ 
                type: "info", 
                title:t("Ei viety MyCashFlowiin"), 
                message: JSON.stringify(error?.data?.message)
              })
              //props.history.push(`/dashboard/products/edit/${props.productId}/variations`);
            }
            else if (error?.data?.detail) {
              props.notify({ 
                type: "danger", 
                title:t("Virhe MyCashFlow viennissä"), 
                message: JSON.stringify(error?.data?.detail)
              })
              setErrors({
                general: error?.data?.detail,
              })
            }
            else {
              props.notify({ 
                type: "danger", 
                title:t("MyCashFlow viennissä virhe"), 
              })
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          })
          .finally(() => {
            setSubmitting(false);
          })
        }
        else {
          httpExportStockItemToMCF(props.preSetValues.id).then(res => {
            setSubmitting(false);
            props.notify({ 
              type: 'success', 
              title:t("Varasto"), 
              message:t("Muutokset viety MyCashFlowiin")
            })
          }, error => {
            if (error?.data?.errorCode == "UNABLE_TO_EXPORT_ORDERS_MCF") {
              props.notify({ 
                type: "info", 
                title:t("Ei viety MyCashFlowiin"), 
                message: JSON.stringify(error?.data?.message)
              })
            }
            else if (error?.data?.detail) {
              props.notify({ 
                type: "danger", 
                title:t("Virhe MyCashFlow viennissä"), 
                message: JSON.stringify(error?.data?.detail)
              })
              setErrors({
                general: error?.data?.detail,
              })
            }
            else {
              props.notify({ 
                type: "danger", 
                title:t("MyCashFlow viennissä virhe"), 
              })
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }).finally(() => {
            setSubmitting(false);
          })
          
        }
        
        
      }
      
      const {t} = props;
      let data = {
        ...values,
        product_fk: props.productId,
        product_variation_fk: props.productVariationId,
      };
      
      if (values?.id) {
        httpUpdateStockItem(values.id, data).then(res => {
          props.notify({ title:t("Varasto"), message:t("Tallennettu onnistuneesti")});
          exportToMcf(props.productId, props.productVariationId);
          props.handleResolve();
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      else {
        httpCreateStockItem(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Varasto"), message:t("Tallennettu onnistuneesti")})
          props.handleResolve()
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
    },
    displayName: "BasicForm"
  
})(ProductStockEdit)
    
    
const ProductStockEditView = ({history, stockItemId, productId, productVariationId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  
  const getProductStockItem = (id) => {
    setLoading(true)
    httpGetStockItem(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    if (stockItemId && parseInt(stockItemId) > -1) {
      getProductStockItem(stockItemId)
    }
  }, [stockItemId, modalOpen])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <ProductStockEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} productId={productId} productVariationId={productVariationId} {...rest} />
  )
  

}
  

export default ProductStockEditView;

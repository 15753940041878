import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import {
  Card,
  Typography,
  Button,
  Link,
  IconButton,
  Breadcrumb,
  BreadcrumbItem,
  Modal,
  Pad,
} from "stories/components";
import { Input } from "stories/forms";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetComplaints } from "services/complaints"
import { httpGetSuppliers } from "services/suppliers"
import { useAppContext } from "contexts/AppContext"
import { getDate, getDateAndTime } from "services/datetime"
import ProductComplaintEdit from "views/dashboard/ProductComplaintEdit/ProductComplaintEdit"

const ProductComplaintsList = ({history, purchaseOrderId}) => {
  
  const { enums } = useAppContext()
  const { t } = useTranslation();
  
  const getStatusValue = (key) => {
    const value = enums && enums?.complaints?.product_complaint?.status.filter((item) => {
        return item.key === key
      })[0].value
    // i18next-extract-disable-next-line
    return t(value)
  }
  
  const getTypeValue = (key) => {
    const value = enums && enums?.complaints?.product_complaint?.type.filter((item) => {
        return item.key === key
      })[0].value
    // i18next-extract-disable-next-line
    return t(value)
  }
  
  // Modal: New Complaint
  
  const [modalComplaintNewOpen, setModalComplaintNewOpen] = useState(false)
  
  const toggleModalComplaintNew = () => {
    setModalComplaintNewOpen(s => !s);
  }
  
  const resolveModalComplaintNew = () => {
    setModalComplaintNewOpen(false)
    getComplaints()
  }
  const rejectModalComplaintNew = () => {
    setModalComplaintNewOpen(false)
  }
  
  const handleNew = () => {
    setModalComplaintNewOpen(true)
  }
  
  // Modal: Edit Complaint
  
  const [selectedComplaintId, setSelectedComplaintId] = useState()
  const [modalComplaintOpen, setModalComplaintOpen] = useState(false)
  
  const toggleModalComplaint = () => {
    setModalComplaintOpen(s => !s);
  }
  
  const resolveModalComplaint = () => {
    setSelectedComplaintId(null)
    getComplaints()
  }
  const rejectModalComplaint = () => {
    setSelectedComplaintId(null)
  }
  
  const handleOpenModalComplaint = (complaintId) => {
    setSelectedComplaintId(complaintId)
  }

  useEffect(() => {
    if (selectedComplaintId) {
      setModalComplaintOpen(true)
    }
    else {
      setModalComplaintOpen(false)
    }
  }, [selectedComplaintId])
  
  // Filter: Status
  
  const getStatusOptions = useCallback(() => {
    return enums?.complaints?.product_complaint?.status && enums?.complaints?.product_complaint?.status.map(obj => {
      return (
        <>
          {/* i18next-extract-disable-next-line */}
          <option value={obj.key}>{t(obj.value)}</option> 
        </>
      )
    })
  }, [enums])
  
  // Filter: Suppliers
  
  const [suppliers, setSuppliers] = useState([])
   
  useEffect(() => {
    getSuppliers()
  }, [])
   
  const getSuppliers = () => {
    const params = {
      active: true,
    }
    httpGetSuppliers(params).then(res => {
      setSuppliers(res.data)
      /*
      if (values?.supplier == null && res.data.length > 0) {
        setFieldValue("supplier", res.data[0]?.id)
      }
      */
    })
  }
   
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option value={supplier.id}>{supplier.name}</option>
      )
    })
  }, [suppliers])
  
  //
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 25,
    offset:0,
    serializer: "ProductComplaintListSerializer",
    purchase_order: purchaseOrderId ? purchaseOrderId : undefined,
    status: 1,
    ordering: "-id",
  })
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  const getComplaints = () => {
    setLoading(true)
    httpGetComplaints(filters).then(res => {
      setRows(res?.data)
      setLoading(false)
    }, errorResponse => {
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getComplaints();
  }, [filters])
  
  const headers = [
    { label: t("product_complaints_list_header_complaint_erp_id","Reklamaatio ERP-ID"), key: "id", sortable: true, sortingKey:"id"},
    // { label: t("MCF-id"), key: "id_mcf", sortable:false, visible: true},
    { label: t("MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: "", key: "actions"},
    { label: t("Nimi"), key: "name", sortable: true, sortingKey:"product_name"},
    { label: t("Tuotenumero"), key: "product_supplier_code",},
    { label: t("Luotu"), key: "created"},
    { label: t("Toimittaja"), key: "supplier"},
    { label: t("Ostotilaus (Reklamoitu)"), key: "purchase_order"},
    { label: t("Ostotilaus (Käsitelty)"), key: "purchase_order_resolved_id"},
    { label: t("Tyyppi"), key: "type"},
    { label: t("Määrä"), key: "amount"},
    { label: t("Tila"), key: "status"},
  ];
  
  const handleEdit = (id) => {
    history.push(`/dashboard/complaints/edit/${id}/basic`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        id: row?.id,
        // id_mcf: `${row?.product?.id_mcf}-${row?.product_variation?.id_mcf ? row?.product_variation?.id_mcf : "0"}`,
        mcf_product_id: row?.product?.id_mcf,
        mcf_variation_id: row?.product_variation?.id_mcf,
        name: (
          <>
            <Link onClick={() => handleOpenModalComplaint(row?.id)}>
              <Typography bold>{row?.product?.name}</Typography>
            </Link>
            { row?.product_variation && (
              <Typography>{row?.product_variation?.name}</Typography>
            )}
          </>
        ),
        product_supplier_code: (
          <Typography>
            {row?.product_supplier_code ? row?.product_supplier_code?.name : ""}
            {row?.product_variation_supplier_code ? row?.product_variation_supplier_code?.name : ""}
          </Typography>
        ),
        created: (
          <>
            <Typography>{getDate(row?.created)}</Typography>
          </>
        ),
        supplier: (
          <>
            <Typography>{row?.supplier?.name}</Typography>
          </>
        ),
        purchase_order: (
          <>
          { row?.purchase_order && (
            <Link target="_blank" to={`/dashboard/purchase_orders/review/${row?.purchase_order}/`}>
              <Typography bold>{row?.purchase_order}</Typography>
            </Link>
          )}
          </>
        ),
        purchase_order_resolved_id: (
          <>
          { row?.purchase_order_resolved_id && (
            <Link target="_blank" to={`/dashboard/purchase_orders/review/${row?.purchase_order_resolved_id}/`}>
              <Typography bold>{row?.purchase_order_resolved_id}</Typography>
            </Link>
          )}
          </>
        ),
        type: (
          <>
            <Typography>{getTypeValue(row?.type)}</Typography>
          </>
        ),
        amount: (
          <>
            <Typography>{row?.amount}</Typography>
          </>
        ),
        status: (
          <>
            <Typography>{getStatusValue(row?.status)}</Typography>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleOpenModalComplaint(row?.id)} iconName="pen" />
            {/* <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" /> */}
          </>
        ),
        
      }
    })
  }, [rows])
  
  return (
    <>
      { !purchaseOrderId && (
        <Row>
          <Col>
            <PageHeader title={t("Reklamaatiot")}></PageHeader>
          </Col>
          <Col className="text-right">
            <div className="d-flex flex-row-reverse">
              <div className="p-2">
                <Button color="success" onClick={handleNew}>{t("Lisää reklamaatio")}</Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
      
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
        
        </Col>
      </Row>
      
      <Row className="mb-3">
        <Col className="col-12 col-sm-12 col-md-auto">
          <Input label={t("Toimittaja")} type="select" name="supplier" onChange={handleFilterChange} value={filters?.supplier}>
            <option value="">{t("Kaikki","Kaikki")}</option>
            {getSupplierOptions()}
          </Input>
        </Col>
        
        <Col className="col-12 col-sm-12 col-md-auto">
          <Input label={t("Tila")} name="status" type="select" onChange={handleFilterChange} value={filters?.status}>
            <option value="">{t("Kaikki","Kaikki")}</option>
            {getStatusOptions()}
          </Input>
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        tableStickyHeader={true}
        filters={filters}
        setFilters={setFilters}
      />
      
      {/* Create new complaint */}

      <Modal
        title={t("Uusi reklamaatio")}
        isOpen={modalComplaintNewOpen}
        toggleModal={toggleModalComplaintNew}
      >
        <ProductComplaintEdit
          modalOpen={modalComplaintNewOpen}
          handleResolve={resolveModalComplaintNew}
          handleReject={rejectModalComplaintNew}
        />
      </Modal>
      
      {/* Edit existing complaint */}
      <Modal
        title={t("Muokkaa reklamaatiota")}
        isOpen={modalComplaintOpen}
        toggleModal={toggleModalComplaint}
        closeModal={rejectModalComplaint}
      >
        <ProductComplaintEdit
          productComplaintId={selectedComplaintId}
          modalOpen={modalComplaintOpen}
          handleResolve={resolveModalComplaint}
          handleReject={rejectModalComplaint}
        />
      </Modal>
      
    </>
  );
}

export default ProductComplaintsList;

import FrontpageSupplier from "views/dashboard/FrontpageSupplier/FrontpageSupplier";
import PurchaseOrders from "views/dashboard/purchaseOrders/PurchaseOrders";
import PurchaseOrderEdit from "views/dashboard/purchaseOrderEdit/PurchaseOrderEdit";
import ProductsList from "views/dashboard/ProductsList/ProductsList";
import ProductEditView from "views/dashboard/ProductEditView/ProductEditView";
import ProductEdit from "views/dashboard/ProductEdit/ProductEdit";
import ProductComplaintsList from "views/dashboard/ProductComplaintsList/ProductComplaintsList";
import CategoriesList from "views/dashboard/CategoriesList/CategoriesList";
import CategoryEdit from "views/dashboard/CategoryEdit/CategoryEdit";
import SupplierPurchaseOrders from "views/dashboard/SupplierPurchaseOrders/SupplierPurchaseOrders";
import SupplierPurchaseOrderEdit from "views/dashboard/SupplierPurchaseOrderEdit/SupplierPurchaseOrderEdit";
import MyAccount from "views/dashboard/MyAccount/MyAccount";
import MyAccountPasswordChange from "views/dashboard/MyAccountPasswordChange/MyAccountPasswordChange";

const Routes = (t, badges) => {
  return [
    {
      path: "/frontpage",
      name: t("nav_supplier_frontpage","Etusivu"),
      icon: "fas fa-home text-primary",
      component: FrontpageSupplier,
      layout: "/dashboard",
    },
    /* OLD, remove when fully tested with NEW routes */
    {
      icon: "fas fa-store text-primary",
      path: "/supplier_purchase_orders/list/received",
      name: t("nav_supplier_purchase_orders","Ostotilaukset"),
      miniName: "OT",
      layout: "/dashboard",
      badge: badges?.nav_supplier_purchase_orders,
      invisible: true,
    },
    {
      path: "/supplier_purchase_orders/list/:tab",
      name: t("nav_supplier_purchase_orders","Ostotilaukset"),
      miniName: "OT",
      component: SupplierPurchaseOrders,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/supplier_purchase_orders/edit/:purchaseOrderId?",
      name: t("nav_supplier_purchase_orders_edit","Muokkaa ostotilausta"),
      miniName: "A",
      component: SupplierPurchaseOrderEdit,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    /* OLD END */
    /* NEW START */
    {
      icon: "fas fa-store text-primary",
      path: "/supplier_sales_orders/list/received",
      name: t("nav_supplier_sales_orders","Myyntitilaukset"),
      miniName: "OT",
      layout: "/dashboard",
      badge: badges?.nav_supplier_purchase_orders,
    },
    {
      path: "/supplier_sales_orders/list/:tab",
      name: t("nav_supplier_sales_orders","Myyntitilaukset"),
      miniName: "OT",
      component: SupplierPurchaseOrders,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/supplier_sales_orders/edit/:purchaseOrderId?",
      name: t("nav_supplier_sales_orders_edit","Muokkaa myyntitilausta"),
      miniName: "A",
      component: SupplierPurchaseOrderEdit,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    /* NEW END */
    {
      path: "/complaints/list",
      name: t("nav_complaints","Reklamaatiot"),
      icon: "fas fa-expand text-primary",
      component: ProductComplaintsList,
      layout: "/dashboard",
    },
    {
      path: "/myaccount",
      name: t("nav_myaccount","Omat tiedot"),
      icon: "fas fa-user text-primary",
      component: MyAccount,
      layout: "/dashboard",
      exact: true,
    },
    {
      path: "/myaccount/password",
      name: t("nav_myaccount_password_change","Vaihda salasanaa"),
      icon: "fas fa-user text-primary",
      component: MyAccountPasswordChange,
      layout: "/dashboard",
      invisible: true,
    },
  ];
}

export default Routes;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from 'i18next-http-backend';
import appConfig from "services/config"

// const en = require('json-loader!./locales/en/translation.json');
// const fi = require('json-loader!./locales/fi/translation.json');
import en from "./locales/en/translation.json"
import fi from "./locales/fi/translation.json"

const resources = {
  en: {
    translation: en
  },
  fi: {
    translation: fi
  }
}

// the translations
// (tip move them in a JSON file and import them)
/*
const resources = {
  en: {
    translation: en
  },
  fi: {
    translation: {
      "Not arrive": "Ei saapunut",
      "Incomplete": "Puutteellinen",
    }
  }
};
*/

i18n
  // .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: appConfig.DEFAULT_LANGUAGE,
    fallbackLng: appConfig.DEFAULT_LANGUAGE,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    /*
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    */
  });

  export default i18n;
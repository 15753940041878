import http from './api'

const url = '/categories'

export const httpGetCategories = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

export const httpGetCategory = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'GET',
  })
}

export const httpCreateCategory = (data) => {
  return http({
    url: `${url}/` ,
    method: 'POST',
    data
  })
}

export const httpUpdateCategory = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data
  })
}

export const httpDeleteCategory = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'DELETE',
  })
}

export const httpImportCategoriesFromMCF = () => {
  return http({
    url: `${url}/import_from_mcf` ,
    method: 'GET',
  })
}
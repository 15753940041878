import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import moment from 'moment'
import 'moment/locale/fi'  // without this line it didn't work

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
// Font Awesome 5 icons
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
// Nucleo icons
import "assets/vendor/nucleo/css/nucleo.css"; // icons
import "react-notification-alert/dist/animate.css";

// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import "./services/i18n"

import { AppProvider } from "contexts/AppContext";
import { AuthProvider } from "contexts/AuthContext";
import { NotificationProvider } from "stories/components/Notification"
import history from "services/history"
import { StoreProvider } from 'easy-peasy';
import { store } from 'store/index';

import App from "./app";
import { Spinner } from "stories/components"

moment().locale('fi')

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Spinner />}>
      <StoreProvider store={store}>
        <AppProvider>
          <AuthProvider history={history}>
            <NotificationProvider>
              <App />
            </NotificationProvider>
          </AuthProvider>
        </AppProvider>
      </StoreProvider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);

import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetUsers } from "services/users"

const UsersList = ({match, history}) => {
  
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering:"last_name",
  })
  
  const getUsers = () => {
    setLoading(true)
    httpGetUsers(filters).then(res => {
      setRows({
        count: res?.data.length,
        results: res?.data,
      })
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getUsers()
  }, [filters])
  
  const headers = [
    { label: "", key: "actions"},
    { label: t("Name"), key: "name"},
    { label: t("Email"), key: "email"},
    { label: t("Userrole","Rooli"), key: "user_role"},
    { label: t("Usertype","Tyyppi"), key: "user_type"},
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/users/new/`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/users/edit/${id}`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            {/*
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
            */}
          </>
        ),
        email: (
          <>
            <Typography>{row?.email}</Typography>
          </>
        ),
        id: row?.id,
        name: (
          <>
            <Link to={`/dashboard/users/edit/${row?.id}`}>
              <Typography bold>{row?.last_name} {row?.first_name}</Typography>
            </Link>
          </>
        ),
        user_role: row?.is_superuser ? t('Pääkäyttäjä', 'Pääkäyttäjä') : t('Peruskäyttäjä', 'Peruskäyttäjä'),
        user_type: row?.is_supplier ? t('Supplier', 'Tavarantoimittaja') : t('Companyuser', 'Yrityskäyttäjä'),
      }
    })
  }, [rows])
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Käyttäjät")}></PageHeader>
        </Col>
        {/*
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2"><Typography variant="small"></Typography></div>
          </div>
        </Col>
        */}
      </Row>
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
          <Button color="success" onClick={handleNew}>{t("Lisää käyttäjä")}</Button>
          
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={true}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
    </>
  );
}

export default UsersList;

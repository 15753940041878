import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Checkbox, Input } from "stories/forms";
import { PageWrapper, PageHeader } from "components/Page"

import { useNotification } from "stories/components/Notification"
import { httpGetMyUser, httpUpdateUser } from "services/users";
import { useAuthContext }from "contexts/AuthContext"

require('yup-phone');

const MyAccount = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/users/list");
  }
  
  const handleDelete = () => {
    const result = window.confirm("Haluatko varmasti poistaa?");
    if (result) {
      /*
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
      */
    }
  }
  
  const getLanguageOptions = useCallback(() => {
    const languages = [
      {key: "en", value: t("English","English")},
      {key: "fi", value: t("Suomi","Suomi")},
    ]
    return languages && languages.map(obj => {
      return (
        <option value={obj.key}>{obj.value}</option>
      )
    })
  }, [])
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("first_name")}
                label={t("Etunimi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("last_name")}
                label={t("Sukunimi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("email")}
                label={t("Sähköposti")}
                type="email"
                disabled
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("username")}
                label={t("Käyttäjätunnus")}
                disabled
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("phone")}
                label={t("Puhelin")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Input 
                {...getFormikInputProps("language")}
                label={t("Kieli")}
                type="select">
                  {getLanguageOptions()}
              </Input>
            </Col>
          </Row>
          
          {/*
          <Row>
            <Col className="mb-3">
              <Checkbox
                {...getFormikInputProps("is_staff")}
                label="Admin"
                checked={values?.is_staff}
              />
            </Col>
          </Row>
          */}
          
          {/*
          <Row>
            <Col className="mb-3">
              <Checkbox
                {...getFormikInputProps("active")}
                label="Aktiivinen"
                checked={values?.active}
              />
            </Col>
          </Row>
          */}
          
          <Row className="mt-3">
            <Col className="mb-3">
              <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
            </Col>
            <Col className="mb-3 text-center">
            {/* <Button onClick={handleDelete} variant="danger">Poista</Button> */}
            </Col>
            <Col className="mb-3 text-right">
              <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
              { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
            </Col>
          </Row>
      
        </Col>
        
        <Col></Col>
        <Col></Col>
        
      </Row>
      
      
    </>
  );
}

const defaultValues = {
  id: null,
  name: '',
  // activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const MyAccountFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        first_name: Yup.string().required(required).nullable(),
        last_name: Yup.string().required(required).nullable(),
        username: Yup.string().required(required).nullable(),
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        // phone: Yup.string().phone("FI", false,t('Puhelinnumero tulee olla muodossa esim. +358501234567')).required(required).nullable(),
        // activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      // activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      let data = {
        ...values,
      };
      
      httpUpdateUser("me", data).then(res => {
        setSubmitting(false);
        props.notify({ title:t("Käyttäjä"), message:t("Päivitetty onnistuneesti")})
        props.getMyUser(); // update whole user context also
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
      
    },
    displayName: "BasicForm"
  
  })(MyAccount)
    
    
const MyAccountView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const { myUser, getMyUser } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  useEffect(() => {
    setLoading(true)
    httpGetMyUser().then(res => {
      setPreSetValues({
        ...defaultValues,
        ...res?.data,
      });
      setLoading(false)
    })
  }, [])
  
  const handleChangePassword = () => {
    history.push("/dashboard/myaccount/password");
  }
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <PageHeader title={t("Omat tiedot")}></PageHeader>
        </div>
        <div>
        <Button onClick={handleChangePassword}>{t('Vaihda salasana')}</Button>
        </div>
      </div>
      <MyAccountFormik history={history} t={t} getMyUser={getMyUser} preSetValues={preSetValues} notify={notify} />
    </>
  )
  

}
  

export default MyAccountView;

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import {
  httpGetPurchaseOrderProduct,
  httpCreatePurchaseOrderProductDelivery,
} from "services/purchase_orders"

const defaultValues = {
  id: null,
  amount: 0,
}
const PurchaseOrderProductDelivery = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      t,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Saapuneet tuotteet")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("amount")}
                label={t("Saapunut määrä (kpl)")}
                type="number"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Lisätiedot")}
                type="textarea"
              />
            </Col>
          </Row>
          
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <>
            <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Kuittaa saapuneeksi")}</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </>
        </Col>
      </Row>
        
    </>
  );
}

const PurchaseOrderProductDeliveryFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        amount: Yup.number().integer().test(
          'Is positive?',
          t('VIRHE: Määrän on oltava suurempi kuin 0'), 
          (value) => value > 0
        ).required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        purchase_order_delivery: props.purchaseOrderDeliveryId,
        purchase_order_product: props.purchaseOrderProductId,
      };
      
      if (values?.id) {
        httpCreatePurchaseOrderProductDelivery(props.purchaseOrderId, props.purchaseOrderDeliveryId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tallennettu"), message:t("Merkitty saapuneeksi")})
          props.handleResolve()
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PurchaseOrderProductDelivery)
    
    
const PurchaseOrderProductDeliveryView = ({history, purchaseOrderId, purchaseOrderProductId, purchaseOrderDeliveryId, modalOpen, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { t } = useTranslation();
  
  const getPurchaseOrderProduct = (pId, ppId) => {
    setLoading(true)
    httpGetPurchaseOrderProduct(pId, ppId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > -1 && purchaseOrderProductId && parseInt(purchaseOrderProductId) > -1) {
      getPurchaseOrderProduct(purchaseOrderId, purchaseOrderProductId)
    }
  }, [purchaseOrderId, purchaseOrderProductId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderProductDeliveryFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} purchaseOrderProductId={purchaseOrderProductId} purchaseOrderDeliveryId={purchaseOrderDeliveryId} {...rest} />
  )
  

}
  

export default PurchaseOrderProductDeliveryView;

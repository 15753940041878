import React, {useEffect, useState, useCallback} from "react";
import moment from "moment";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router'

import {
  UncontrolledTooltip,
} from "reactstrap";

import { getDate } from "services/datetime"
import { useNotification } from "stories/components/Notification"
import { Container, Row, Col } from "stories/layout";
import { Typography, Button, Link, IconButton, Modal, Spinner, Breadcrumb, BreadcrumbItem, Badge } from "stories/components";
import { Input } from "stories/forms";
import { PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { 
  httpGetProducts,
  httpCreateProductPriceChange
} from "services/products"
import {
  httpGetPurchaseOrders,
  httpGetPurchaseOrder, 
  httpGetPurchaseOrderProducts, 
} from "services/purchase_orders"

import { 
  httpGetProductPurchasePrice,
  httpCalculateProductPurchasePrice,
} from "services/purchase_price_calculator"
import { httpGetPurchaseOrderErpReceipt, httpCreatePurchaseOrderErpReceipt } from "services/erp_receipts"

import PurchaseOrderProductEdit from "views/dashboard/PurchaseOrderProductEdit/PurchaseOrderProductEdit"
import { round } from "services/helpers"
import { useAppContext } from "contexts/AppContext"

const PurchasePriceCalculator = (props) => {
  
  const {
     values,
     touched,
     setTouched,
     errors,
     setErrors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
   } = props;
   
  const {
      t,
      history,
      notify,
      orderId,
      orderData,
      setOrderData,
      pricesData,
      setPricesData,
      getPurchaseOrderOptions,
      handlePurchaseOrderChange,
      unsavedPurchasePrices,
      setUnsavedPurchasePrices,
      unsavedSellPrices,
      setUnsavedSellPrices,
      loadingPurchaseOrder,
  } = props;

  const [loadingProducts, setLoadingProducts] = useState(0)
  const [loadingPurchaseOrderProducts, setLoadingPurchaseOrderProducts] = useState(0)
  const [saveProductPricesLoading, setSaveProductPricesLoading] = useState(false)
  
  const [sorting, setSorting] = useState({
    sortingKey: "erp_id_sort",
    sortingReverse: false,
    sortingType: "float", // int, float, string
  })
  
  const pattern="[0-9]+([.,][0-9]+)?"
  const regExp = React.useRef(new RegExp(pattern));
  const { enums } = useAppContext()
  
  // ERP receipt (if exists)
  const [loadingErpReceipt, setLoadingErpReceipt] = useState(false)
  const [erpReceipt, setErpReceipt] = useState()
  
  const getPurchaseOrderErpReceipt = () => {
    if (orderId === null) {
        return;
    }
    if (loadingErpReceipt === true) {
        return;
    }
    setLoadingErpReceipt(true);
    httpGetPurchaseOrderErpReceipt(orderId).then(res => {
      setErpReceipt(res?.data)
    }).finally(() => {
        setLoadingErpReceipt(false);
    })
  }
  
  useEffect(() => {
    if (orderId) {
      getPurchaseOrderErpReceipt()
    }
  }, [orderId])
  
  const [pricesCalculated, setPricesCalculated] = useState(false)
  
  const handleOpenPurchaseOrderMcfReceipt = () => {
    history.push(`/dashboard/purchase_order_mcf_receipt/${values?.id}/`)
  }
  
  // Currencies / Valuutat
  const getCurrencyOptions = useCallback(() => {
    return enums?.currencies?.currency_data?.available_currencies && enums?.currencies?.currency_data?.available_currencies.map(obj => {
      return (
      <>
        <option value={obj}>{t(obj)}</option> {/* i18next-extract-disable-line */}
      </>
      )
    })
  }, [enums])
  
  // Duty / Tulli
  const getDutyOptions = useCallback(() => {
    return enums?.purchase_price_calculator?.duty_percentage?.duty_percentages && enums?.purchase_price_calculator?.duty_percentage?.duty_percentages.map(obj => {
      return (
        <option value={obj?.key}>{obj?.value}</option>
      )
    })
  }, [enums])
  
  const getDutyValue = useCallback((key) => {
    const findDuty = enums?.purchase_price_calculator?.duty_percentage?.duty_percentages && enums?.purchase_price_calculator?.duty_percentage?.duty_percentages.find(obj => {
      if (obj?.key == key) {
        return true
      }
    })
    return findDuty?.value
  }, [enums])
  
  const [productFilters, setProductFilters] = useState({
    ordering: "-total_count",
    has_customer_orders_or_amount: true,
  })
  const [filterShowProducts, setFilterShowProducts] = useState("only_ordered")
  
  useEffect(() => {
    if (filterShowProducts == "only_ordered") {
      setProductFilters(s => ({
        ...s,
        has_customer_orders_or_amount: true,
      }))
    }
    else {
      setProductFilters(s => ({
        ...s,
        has_customer_orders_or_amount: undefined,
      }))
    }
  }, [filterShowProducts])
  
  const [rows, setRows] = useState([])
  
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
   
  const getTabKey = useCallback(() => {
    const state = values?.state;
    if (state) {
      if (state === 1) {
        return "draft"
      }
      else if (state === 2) {
        return "sent"
      }
      else if (state === 3) {
        return "confirmed"
      }
      else if (state === 4) {
        return "paid"
      }
      else if (state === 5) {
        return "ondelivery"
      }
      else if (state === 6) {
        return "inprogress"
      }
      else if (state === 7) {
        return "archived"
      }
    }
    return "";
   
  }, [values?.state])
  
  const getTabName = useCallback(() => {
    const state = values?.state;
    if (state) {
      if (state === 1) {
        return t("Luonnokset")
      }
      else if (state === 2) {
        return t("Avoimet tilaukset")
      }
      else if (state === 3) {
        return t("Vahvistetut tilaukset")
      }
      else if (state === 4) {
        return t("Maksetut tilaukset")
      }
      else if (state === 5) {
        return t("Lähetetyt tilaukset")
      }
      else if (state === 6) {
        return t("Käsittelyssä olevat tilaukset")
      }
      else if (state === 7) {
        return t("Arkistoidut tilaukset")
      }
    }
    return "";
   
  }, [values?.state])
  
  // Purchase order products
  
  const getOrderProductValue = (field, productId, productVariationId) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = orderData[orderProductKey];
    if (obj) {
      return obj[field];
    }
    return 0
  }
  
  const updateOrderProduct = (productId, productVariationId, key, value) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = orderData[orderProductKey];
    if (!obj) {
      obj = {}
    }
    
    obj = {
      ...obj,
      product: productId,
      product_variation: productVariationId,
      [key]:value
    }
    
    setOrderData(s => ({
      ...s,
      [orderProductKey]: obj
    }))
    
  }
  
  const handleSellPriceChange = ({target}, productId, productVariationId) => {
    try {
      let value = target?.value ? target?.value : 0
      setUnsavedSellPrices(true)
      updateOrderProduct(productId, productVariationId, target.name, value)
    }
    catch(e) {
      console.log("catch", e);
    }
  }
  
  const handleSellPriceValidate = ({target}, productId, productVariationId) => {
    try {
      let value = target?.value ? parseFloat(target?.value.toString().replace(",",".")) : 0;
      if (regExp.current.test(value)) {
        updateOrderProduct(productId, productVariationId, target.name, value)
      }
    }
    catch(e) {
      console.log("catch", e);
    }
  }
  
  // Prices
  
  const getPricesDataValue = (productId, productVariationId, key) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = pricesData[orderProductKey];
    if (obj) {
      return obj[key];
    }
    return 0
  }
  
  const setPricesDataKeyValue = (productId, productVariationId, key, value) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = pricesData[orderProductKey];
    if (!obj) {
      obj = {}
    }
    
    obj = {
      ...obj,
      product: productId,
      product_variation: productVariationId,
      [key]:value
    }
    
    setPricesData(s => ({
      ...s,
      [orderProductKey]: obj
    }))
    
  }
  
  const setPricesDataObject = (productId, productVariationId, newObject) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = pricesData[orderProductKey];
    if (!obj) {
      obj = {}
    }
    
    obj = {
      ...obj,
      product: productId,
      product_variation: productVariationId,
      ...newObject,
    }
    
    setPricesData(s => ({
      ...s,
      [orderProductKey]: obj
    }))
    
  }
  
  // Rows
  
  const updateRow = (id, key, value) => {
    let _rows = [
      ...rows
    ]
    
    const index = _rows && _rows.findIndex(obj => { return obj?.id === id });
    
    _rows[index] = {
      ..._rows[index],
      [key]:value
    }
    
    setRows(s => ([
      ..._rows,
    ]))
    
  }
  
  const handleRowChange = ({target}, id) => {
    updateRow(id, target.name, target.value)
  }
  
  const getRow = (productId, productVariationId) => {
    if (productVariationId && productVariationId > 0) {
      const index = rows && rows.findIndex(obj => { 
        return obj?.product?.id === productId && obj?.product_variation?.id === productVariationId 
      });
      
      if (index > -1) {
        return rows[index]
      }
    }
    else {
      const index = rows && rows.findIndex(obj => { 
        return obj?.product?.id === productId
      });
      
      if (index > -1) {
        return rows[index]
      }
    }
    
    return null;
  }
  
  // Set default value for rows duty if main duty is selected
  useEffect(() => {
    if (values?.duty && rows && rows.length > 0) {
      let _rows = [
        ...rows
      ]
      
      _rows && _rows.map((row, index) => {
        if (!row?.duty || row?.duty === "") {
          _rows[index].duty = values?.duty.toString()
        }
      })
      
      setRows(s => ([
        ..._rows,
      ]))
      
    }
  }, [values?.duty])
  
  // When currency selected, set default value for all currency selects if empty
  useEffect(() => {
    if (values?.purchase_order_currency ) {
      if (!values?.cargo_currency || values?.cargo_currency === "") {
        setFieldValue("cargo_currency", values?.purchase_order_currency)
      }
      if (!values?.other_cargo_currency || values?.other_cargo_currency === "") {
        setFieldValue("other_cargo_currency", values?.purchase_order_currency)
      }
      if (!values?.payment_fee_currency || values?.payment_fee_currency === "") {
        setFieldValue("payment_fee_currency", values?.purchase_order_currency)
      }
      if (!values?.other_payment_fee_currency || values?.other_payment_fee_currency === "") {
        setFieldValue("other_payment_fee_currency", values?.purchase_order_currency)
      }
      
    }
  }, [values?.purchase_order_currency])
  
  // Existing Purchange Order Products
  
  useEffect(() => {
    if (productFilters?.amount__gte == 1) {
      getPurchaseOrderProducts()
    }
    else {
      getProducts()
    }
  }, [productFilters])
  
  const getProducts = () => {
	if (values?.supplier == null) {
		return;
	}
	if (loadingProducts > 0) {
		// Query already ongoing
		return;
	}
    const params = {
      supplier: values?.supplier
    }
	
    setLoadingProducts(s => s+1);
    httpGetProducts(params).then(res => {
      setRows(res?.data)
      getPurchaseOrderProducts()
    }).finally(() => {
      setLoadingProducts(s => s-1);
    })
  }
  
  const getPurchaseOrderProducts = () => {
    let params = {
      ...productFilters,
    }
    
    if (values?.id) {
      setLoadingPurchaseOrderProducts(s => s+1)
      httpGetPurchaseOrderProducts(values?.id, params).then(res => {
        if (productFilters?.has_customer_orders_or_amount == true) {
          setRows(res?.data)
        }
        else {
          // rows already set in getProducts()
        }
        
        let order_data = {
          ...orderData,
        };
        res?.data && res?.data.map(po_product => {
          const orderProductKey = `p${po_product?.product?.id}v${po_product?.product_variation ? po_product?.product_variation?.id : "0"}`;
          
          // Get already calculated price
          
          httpGetProductPurchasePrice(po_product?.id).then(pppResponse => {
            const responseData = pppResponse?.data
            if (responseData && responseData?.product_fk) {
              setPricesDataObject(responseData?.product_fk, responseData?.product_variation_fk, pppResponse?.data)
            }
          }, error => {
            console.log("httpGetProductPurchasePrice.error", error)
          })
          
          const previous_sell_price = po_product?.previous_sell_price ? po_product?.previous_sell_price : (po_product?.product_variation ? po_product?.product_variation?.price : po_product?.product?.price)
          const new_sell_price = po_product?.new_sell_price ? po_product?.new_sell_price : previous_sell_price
          
          order_data[orderProductKey] = {
            ...po_product,
            product:po_product?.product?.id,
            product_variation:po_product?.product_variation ? po_product?.product_variation.id : 0,
            // sell_price: po_product?.product_variation ? po_product?.product_variation?.price : po_product?.product?.price,
            previous_sell_price: previous_sell_price,
            sell_price: new_sell_price,
          }
          
        })
        setOrderData(order_data);
      }).finally(() => {
        setLoadingPurchaseOrderProducts(s => s-1)
      })
    }
  }
  
  // Purchase price calculation
  
  const handleCalculateProductPrice = (productId, productVariationId) => {
    
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    const purchaseOrderProductId = orderData[orderProductKey]?.id
    
    const row = getRow(productId, productVariationId)
    const id = `${row?.id}-${productId}-${productVariationId}`
    
    // Skip quantity 0 products
    if (row?.total_order_count === 0) {
      console.log("skip row, total_order_count=0", row?.id);
      return;
    }
    
    if (!row?.duty) {
      notify({ type: "danger", title:t("Tulli puuttuu"), message:id})
      return;
    }
    
    const data = {
      price: row?.price,
      duty: row?.duty ? parseInt(row?.duty) : undefined, //jk
      cargo: values?.cargo,
      cargo_currency: values?.cargo_currency,
      cargo_currency_date: values?.cargo_currency_date,
      other_cargo: values?.other_cargo,
      other_cargo_currency: values?.other_cargo_currency,
      other_cargo_currency_date: values?.other_cargo_currency_date,
      payment_fee: values?.payment_fee,
      payment_fee_currency: values?.payment_fee_currency,
      payment_fee_currency_date: values?.payment_fee_currency_date,
      purchase_order_currency: values?.purchase_order_currency,
      purchase_order_currency_date: values?.purchase_order_currency_date,
      other_payment_fee: values?.other_payment_fee,
      other_payment_fee_currency: values?.other_payment_fee_currency,
      other_payment_fee_currency_date: values?.other_payment_fee_currency_date,
    }
    
    setPricesDataKeyValue(productId, productVariationId,"loading",true)
    return httpCalculateProductPurchasePrice(purchaseOrderProductId, data).then(res => {
      
      setPricesDataObject(
        productId,
        productVariationId,
        {
          ...res?.data,
          loading: false,
        }
      )
    })
  }
  
  const handleCalculateProductPrices = () => {
    let promises = [];
    rows && rows.map(row => {
      promises.push(
        handleCalculateProductPrice(row?.product?.id, row?.product_variation?.id)
      )
      
    })
    
    Promise.all(promises).then((response) => {
      setPricesCalculated(true)
      setUnsavedPurchasePrices(true)
      notify({ type: "info", title:t("Ostohinnat"), message:t("Laskettu")})
    }, (errors) => {
      notify({ type: "danger", title:t("Ostohinnat"), message:t("Virhe laskennassa")})
    });
  }
  
  // Purchase price save
  
  const handleSaveProductPrices = () => {
    setSaveProductPricesLoading(true)
    httpCreatePurchaseOrderErpReceipt(orderId).then(res => {
      notify({ type: "success", title:t("Ostohinnat"), message:t("Tallennettu")})
      setUnsavedPurchasePrices(false)
      getPurchaseOrderErpReceipt()
    }, (errors) => {
      notify({ type: "danger", title:t("Ostohinnat"), message:t("Virhe tallennuksessa") + errors?.data?.message})
    }).finally(() => {
      setSaveProductPricesLoading(false)
    })
  }
  
  // Sell price save
  
  const handleSaveProductSellPrice = (productId, productVariationId, sellPrice) => {
    const data = {
      erp_receipt: erpReceipt?.id,
      product_fk: productId,
      product_variation_fk: productVariationId,
      price: sellPrice,
    }
    
    setPricesDataKeyValue(productId, productVariationId,"loading",true)
    return httpCreateProductPriceChange(data).then(res => {
      setPricesDataObject(
        productId,
        productVariationId,
        {
          ...res?.data,
          loading: false,
        }
      )
    })
  }
  
  const handleSaveProductSellPrices = () => {
    let promises = [];
    rows && rows.map(row => {
      const productId = row?.product?.extra?.is_variation ? row?.product?.product_fk?.id : row?.product?.id;
      const productVariationId = row?.product_variation ? row?.product_variation?.id : 0;
      const sell_price = getOrderProductValue("sell_price", productId, productVariationId)
      if (sell_price && sell_price > 0) {
        promises.push(
          handleSaveProductSellPrice(row?.product?.id, row?.product_variation?.id, sell_price)
        )
      }
    })
    
    Promise.all(promises).then((response) => {
      setUnsavedSellPrices(false)
      notify({ type: "success", title:t("Myyntihinnat"), message:t("Tallennettu")})
    }, (errors) => {
      notify({ type: "danger", title:t("Myyntihinnat"), message:t("Virhe tallennuksessa")})
    });
    
  }
  
  // Table: Full order (Kokonaistilaus)

  const [headers, setHeaders] = useState([
    { label: t("ID MCF"), key: "id_mcf", sortingKey: "mcf_id_sort", sortable: true, sortingType: "float" }, 
    { label: t("Toiminnot"), key: "actions"},
    { label: t("Nimi"), key: "name", sortingKey: "name_sort", sortable: true, sortingType: "string" },
    { label: t("Koodi"), key: "code"},
    { label: t("purchase_price_calculator_product_supplier_code","Toimittajan tuo.nro"), key: "product_supplier_code", visible: true, sortingKey: "product_supplier_code_sort", sortable: true, sortingType: "int"},
    { label: t("Määrä"), key: "total_order_count", sortingKey: "total_order_count_sort", sortable: true, sortingType: "float"}, // including amount + customer orders amounts
    { label: t("Batch"), key: "batch", sortable: false},
    { label: t("Hinta kuitissa"), key: "price_receipt", sortable: false},
    { label: t("Tulli"), key: "duty", sortingKey: "duty_sort", sortable: true, sortingType: "string"},
    { label: t("Vanha ostohinta"), key: "purchase_price_weight_old", sortable: false},
    { label: t("Uusi ostohinta"), key: "price_new", sortable: false},
    { label: t("Muutos"), key: "price_change", sortingKey: "price_change_sort", sortable: true, sortingType: "float" },
    { label: t("purchase_price_calculator_previous_sell_price","Vanha myyntihinta"), key: "previous_sell_price", sortable: false},
    { label: t("purchase_price_calculator_margin_pct","Kate-%"), key: "sales_margin_pct_previous", sortingKey: "sales_margin_pct_previous_sort", sortable: true, sortingType: "float" },
    { label: t("purchase_price_calculator_sell_price","Uusi myyntihinta"), key: "sell_price", sortable: false},
    { label: t("purchase_price_calculator_margin_pct","Kate-%"), key: "sales_margin_pct", sortingKey: "sales_margin_pct_sort", sortable: true, sortingType: "float" },
    { label: t("purchase_price_calculator_vat_rate","ALV-%"), key: "vat_rate", sortable: false},
    // { label: t("SortValue"), key: "sortValue" },
  ]);
  
  const getPriceChangePercent = (price_change, price_old) => {
    return round(price_change/price_old*100.0, 1)
  }
  
  const getPriceChangeCol = (price_change, price_old) => {
    if (price_change && price_old) {
      return (
        <Typography>
          { price_change >= 0 ? (<i className="fas fa-arrow-up text-danger mr-1"></i>) : (<i className="fas fa-arrow-down text-success mr-1"></i>)}
          {getPriceChangePercent(price_change, price_old)}%&nbsp;/&nbsp;
          {round(price_change, 2)}&nbsp;€
        </Typography>
      )
    }
    else {
      return (
        <Typography>-</Typography>
      )
    }
    
  }
  
  const getTableRows = useCallback(() => {
    
    const sortingType = sorting?.sortingType;
    const sortingKey = sorting?.sortingKey;
    const sortingReverse = sorting?.sortingReverse; // (true/false)
    
    let rowsDict = {};
    
    /*
    let sortedRows = [].concat(rows)
    .map((row, i) => {
    */
    rows && rows.map((row, i) => {
    
      const getSupplierCodeName = (id) => {
        if (row?.product_variation) {
          return row?.product_variation?.product_supplier_codes && row?.product_variation?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
        else {
          return row?.product?.product_supplier_codes && row?.product?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
      }
      
      const productId = row?.product?.id;
      const productVariationId = row?.product_variation ? row?.product_variation?.id : 0;
      
      const getOrderProductFieldValue = (fieldName) => {
        return getOrderProductValue(fieldName, productId, productVariationId)
      }
      
      const mcfProductId = row?.product?.id_mcf;
      const mcfProductVariationId = row?.product_variation ? row?.product_variation?.id_mcf : "0";
      
      let purchase_price_weight_old = getOrderProductFieldValue("purchase_price_weight_old")
      if (!purchase_price_weight_old) {
        purchase_price_weight_old = row?.product_variation ? row?.product_variation?.purchase_price : row?.product?.purchase_price
      }
      
      const price_new = getPricesDataValue(productId, productVariationId, "purchase_price") // Uusi ostohinta
      
      // Previous sell price / Vanha myyntihinta
      let previous_sell_price = "-";
      previous_sell_price = row?.previous_sell_price ? row?.previous_sell_price : "-";

      // New sell price
      let sell_price = getOrderProductFieldValue("sell_price") // Uusi myyntihinta
      let sell_price_input_value = sell_price
      if (sell_price != undefined) {
        sell_price = parseFloat(sell_price);
      }
      else {
        sell_price = previous_sell_price ? previous_sell_price : 0;
        sell_price_input_value = sell_price;
      }
      
      // Ostohinnan muutos ja muutos %
      const price_change = purchase_price_weight_old && price_new ? price_new - purchase_price_weight_old : null;
      
      // Kate-% laskenta
      const vat_rate = row?.product?.vat_rate ? parseFloat(row?.product?.vat_rate) : 0.0
      
      // Kate-% with previous sell price
      let sales_margin_pct_previous = (previous_sell_price / (1.0 + vat_rate / 100.0) - purchase_price_weight_old) / (previous_sell_price / (1.0 + vat_rate / 100.0)) * 100.0
      sales_margin_pct_previous = round(sales_margin_pct_previous, 2)
      
      // Kate-% with new sell price
      let sales_margin_pct = (sell_price / (1.0 + vat_rate / 100.0) - price_new) / (sell_price / (1.0 + vat_rate / 100.0)) * 100.0
      sales_margin_pct = round(sales_margin_pct, 2)
      
      // Kate-% kaava näkyviin
      const sales_margin_pct_expression = `(${sell_price} / (1.0 + ${vat_rate} / 100.0) - ${price_new}) / (${sell_price} / (1.0 + ${vat_rate} / 100.0)) * 100.0`
      
      const supplier_code_name = getSupplierCodeName(row?.product_supplier_code_id);
      
      let rowObj = {
          className: getPricesDataValue(row?.product?.id, row?.product_variation?.id, "loading") === true ? "bg-info" : (row?.product?.is_mcf_product === true ? "" : "bg-light"),
          id: `${row?.id}-${productId}-${productVariationId}`,
          id_mcf: (<>
          { mcfProductId ?
            `${mcfProductId}-${mcfProductVariationId}`
            : (
            <>
              <Badge id={`id_mcf_${productId}_${productVariationId}`} color="warning" type="floating"><i className="fas fa-exclamation"></i></Badge>
              <UncontrolledTooltip flip target={`id_mcf_${productId}_${productVariationId}`}>{t('purchase_price_calculator_not_mcf_product','Tuote pitää viedä ensin MCFään')}</UncontrolledTooltip>
            </>
            )
          }
          </>),
          mcf_id_sort: `${mcfProductId}.${mcfProductVariationId}`,
          erp_id_sort: `${productId}.${productVariationId}`,
          name: <>
            <Typography bold className="sk_name_field sk_font_text sk_text_wrap">
            {row?.product?.name}
            </Typography>
            { row?.product_variation && (
              <Typography className="sk_name_field sk_font_text sk_text_wrap">
              { row?.product_variation?.name }
              </Typography>
            )}
          </>,
          name_sort: `${row?.product?.name}.${row?.product_variation?.name}`,
          code: (
            <Typography className="sk_code_field sk_font_text">
              { row?.product_variation?.product_code && row?.product_variation?.product_code.length > 0 ? row?.product_variation?.product_code : row?.product?.product_code }
            </Typography>
          ),
          product_supplier_code: (
            <Typography className="sk_code_field sk_font_text">
              {supplier_code_name}
            </Typography>
          ),
          product_supplier_code_sort: supplier_code_name,
          total_order_count: row?.total_order_count,
          total_order_count_sort: row?.total_order_count,
          batch: row?.batch,
          price_receipt: <Input min={0} type="number" name="price" value={row?.price} onChange={(e) => handleRowChange(e, row?.id)} />,
          duty: (
            <>
              <Input style={{minWidth:"96px"}} name="duty" type="select" onChange={(e) => handleRowChange(e, row?.id)} value={row?.duty}>
                <option value=""></option>
                {getDutyOptions()}
              </Input>
            </>
          ),
          duty_sort: getDutyValue(row?.duty),
          purchase_price_weight_old: <Typography>{purchase_price_weight_old}&nbsp;€</Typography>,
          price_new: <Typography>{price_new}&nbsp;€</Typography>,
          price_change: getPriceChangeCol(price_change, purchase_price_weight_old),
          price_change_sort: price_change ? parseFloat(price_change) : -1,
          previous_sell_price: previous_sell_price,
          sales_margin_pct_previous: sales_margin_pct_previous,
          sales_margin_pct_previous_sort: sales_margin_pct_previous,
          sell_price: (
            <Input
              id={`sell_price_${productId}_${productVariationId}`}
              name="sell_price"
              value={sell_price_input_value}
              onBlur={(e) => handleSellPriceValidate(e, productId, productVariationId)}
              onChange={(e) => handleSellPriceChange(e, productId, productVariationId)}
              type="text"
              pattern={pattern}
            />
          ),
          sales_margin_pct: sales_margin_pct,
          sales_margin_pct_sort: sales_margin_pct,
          vat_rate: vat_rate,
          actions: (
            <>
              <IconButton size="lg" onClick={() => handleOpenProduct(row?.id)} iconName={"pen"} badgeColor="danger" badgeValue={row?.confirmed_by_orderer ? null : "!"} />
              <IconButton size="lg" onClick={() => handleCalculateProductPrice(productId, productVariationId)} iconName={"search-dollar"} />
            </>
          ),
      }
      
      // SortingBEGIN
      
      // Put the value here that we want to sort by
      if (sortingKey) {
        rowObj["sortValue"] = rowObj[sortingKey]
      }
      if (productVariationId > 0) {
          // Children order by always fixed sort object sort -value
          rowObj["sortFixed"] = row?.product_variation?.sort ? row?.product_variation?.sort : 0;
      }
      
      if (sortingType === "float" || sortingType === "int" ) {
        if (rowObj["sortValue"] !== rowObj["sortValue"]) {
          console.log('The variable is NaN');
          rowObj["sortValue"] = -9007199254740;
        } else {
          console.log('The variable is a number or can be converted to a number');
        }
      }
      
      // Lets make hierarcy structure
      
      
      const getSortValue = (a, b) => {
        if (a == undefined || a == null) {
          return b;
        }
        if (b == undefined || b == null) {
          return a;
        }
        
        // Sort by main product always when sorting one of these columns
        if (["status_sort","name_sort","erp_id_sort","mcf_product_id","brand_id_sort"].includes(sortingKey)) {
          return a
        }
        else {
          // Put the value from child into the parent if bigger or smaller than current parent value (depending on asc/desc)
          let result = 0;
          if (sortingType === "string") {
            result = a.localeCompare(b)
          }
          else if (sortingType === "float") {
            result = parseFloat(a) - parseFloat(b)
          }
          else {
            result = parseInt(a) - parseInt(b)
          }
          
          if (result < 0) {
            return b
          }
          else {
            return a
          }
        }
      }
      
      
      
      const mainProductKey = `p${productId}v0`;
      
      // Group products to parent and children
      if (rowsDict.hasOwnProperty(mainProductKey) === false) {
        if (productVariationId === 0) {
          rowsDict[mainProductKey] = {
            ...rowObj,
            children: [],
            sortValue: rowObj?.sortValue,
          }
        }
        else {
          rowsDict[mainProductKey] = {
            ...row?.product,
            previous_sell_price: row?.product?.price != null ? row?.product?.price : "-",
            children: [rowObj],
            sortValue: rowObj?.sortValue,
          }
        }
      }
      else {
        if (productVariationId === 0) {
          rowsDict[mainProductKey] = {
            ...rowsDict[mainProductKey],
            ...rowObj,
            previous_sell_price: row?.product?.price != null ? row?.product?.price : "-",
            sortValue: getSortValue(rowsDict[mainProductKey].sortValue, rowObj.sortValue),
          }
        }
        else {
          rowsDict[mainProductKey].children.push(rowObj);
          rowsDict[mainProductKey].sortValue = getSortValue(rowsDict[mainProductKey].sortValue, rowObj.sortValue)
        }
      }
      
      // SortingEND
      
    })
    
    // SortingBEGIN
    // Make list of parent products, to do sorting with them
    let sortedParentRows = []
    for (const [key, value] of Object.entries(rowsDict)) {
      sortedParentRows.push(rowsDict[key])
    }
    
    // Client sorting
    const sortRowsChildren = (_rows) => {
      return _rows.sort((a, b) => {
          return parseInt(a["sortFixed"]) > parseInt(b["sortFixed"]) ? 1 : -1
      })
    }
    
    sortedParentRows = sortedParentRows.sort((a, b) => {
      try {
        if (sorting?.sortingType === "string") {
          return a["sortValue"].localeCompare(b["sortValue"])
        }
        else if (sorting?.sortingType === "float") {
          return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
        }
        else {
          return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
        }
      }
      catch(error) {
        console.log("sortedParentRows.error", error)
        return -1
      }
    })
    
    if (sortingReverse) {
      sortedParentRows = sortedParentRows.reverse()
    }
    
    // Add children (variations) to the table rows
    let sortedRows = []
    sortedParentRows.map(row => {
      sortedRows.push(row)
      
      // Sort children
      if (row?.children && row.children?.length > 0) {
          const children = sortRowsChildren(row.children)
          children.map(rowChild => {
            sortedRows.push(rowChild)
          })
      }
      
    })
    
    // SortingEND
    
    return sortedRows;
    
  }, [rows, orderData, pricesData, values, sorting])
  
  // Form
  
  const handleShowProductFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilterShowProducts(value)
  }
  
  // Modal: Product
  const [modalProductOpen, setModalProductOpen] = useState(false)
  const [purchaseOrderProductId, setPurchaseOrderProductId] = useState()
  
  const toggleModalProduct = () => {
    setModalProductOpen(s => !s);
  }
  
  const handleOpenProduct = (id) => {
    setPurchaseOrderProductId(id)
  }
  
  const handleProductResolve = () => {
    setPurchaseOrderProductId(null)
    props.refresh()
  }
  
  const handleProductReject = () => {
    setPurchaseOrderProductId(null)
    props.refresh()
  }
  
  useEffect(() => {
    if (purchaseOrderProductId) {
      setModalProductOpen(true)
    }
    else {
      setModalProductOpen(false)
    }
  },[purchaseOrderProductId])
  
  useEffect(() => {
    if (modalProductOpen === false) {
      setPurchaseOrderProductId(null)
    }
    
  },[modalProductOpen])
  
  const validateFormBeforeSubmit = (submitFunction) => {
    if (!submitFunction) {
      return;
    }
    validateForm().then((res) => { 
      if (Object.keys(res).length === 0) { 
        submitFunction();
      } else { 
        setTouched(res); 
        setErrors(res);
      } 
    })
  }
  
  if (values?.id && values?.id > 0) {
  
    return (
      <>
        <Prompt
          when={unsavedPurchasePrices || unsavedSellPrices}
          message={t("purchase_price_calculator_unsaved_changes", "Sinulla on tallentamattomia tietoja, poistutaanko silti?")}
        />
        <Row>
          <Col>
            <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
              <BreadcrumbItem>
                <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
                  {t("Ostotilaukset")}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
              { getTabKey() === "draft" ? (
                <Link to={`/dashboard/purchase_orders/edit/${values?.id}`}>
                  {t("Tilaus")} {values?.id}
                </Link>
              ) : (
                <Link to={`/dashboard/purchase_orders/review/${values?.id}`}>
                  {t("Tilaus")} {values?.id}
                </Link>
              )}
              </BreadcrumbItem>
              <BreadcrumbItem>
                {t("Ostohintalaskuri")}
              </BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        
        <Row>
          <Col>
            <PageHeader title={`${t("Ostohintalaskuri")} ${values?.id ? `#${values?.id}` : ""}`}></PageHeader>
          </Col>
          <Col className="text-right">
            <div className="d-flex flex-row-reverse">
              <div className="p-2">
                <Button className="btn-block btn-primary" onClick={handleOpenPurchaseOrderMcfReceipt}>{t("Avaa MCF vienti")}</Button>
              </div>
            </div>
          </Col>
        </Row>
        
        <Row className="d-flex justify-content-between mb-5">
          <Col className="col-auto">
            <Row>
              <Col>
                <Typography bold inline>{t("Tilauksen arvo")}:&nbsp;</Typography>
                <Typography inline>{values?.total_order_value} {values?.purchase_order_currency}</Typography>
              </Col>
            </Row>
            <Row>
              <Col>
                <Typography bold inline>{t("Tilaustuotteet")}:&nbsp;</Typography>
                <Typography inline>{values?.total_order_count}kpl</Typography>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Typography bold inline>{t("Tilauksen tila")}:&nbsp;</Typography>
                <Typography inline>{getTabName()}</Typography>
              </Col>
            </Row>
          </Col>
          
          <Col className="col-auto" style={{maxWidth:"256px"}}>
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography variant="small">
                {values?.description}
                </Typography>
                
                { values?.attachment && (
                  <div>
                    <i className="fas fa-paperclip"></i> <a href={values?.attachment} target="_blank">{t("Lataa liite")}</a>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        
        <Row>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <Row>
              <Col className="col-12">
                <Input name="purchase_order" label={t("Valitse tilaus")} type="select" onChange={(e) => handlePurchaseOrderChange(e)} value={values?.id}>
                  <option value=""></option>
                  {getPurchaseOrderOptions()}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <div className="d-flex justify-content-between">
                  <div className="px-1 flex-fill">
                    <Input required label={t("Valuutta")} type="select" {...getFormikInputProps("purchase_order_currency")}>
                      <option value=""></option>
                      {getCurrencyOptions()}
                    </Input>
                  </div>
                  <div className="px-1">
                    <Input required label="Pvm" type="date" {...getFormikInputProps("purchase_order_currency_date")} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          
          <Col className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <Row>
              <Col className="col-12">
                <Input label={t("Tulli")} type="select" {...getFormikInputProps("duty")}>
                  <option value=""></option>
                  {getDutyOptions()}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-start">
                  <div className="px-1">
                    <Input label={t("Rahtikulut")} type="number" {...getFormikInputProps("cargo")} />
                  </div>
                  <div className="px-1">
                    <Input label={t("Valuutta")} type="select" {...getFormikInputProps("cargo_currency")}>
                      <option value=""></option>
                      {getCurrencyOptions()}
                    </Input>
                  </div>
                  <div className="px-1">
                    <Input label="Pvm" type="date" {...getFormikInputProps("cargo_currency_date")} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-start">
                  <div className="px-1">
                    <Input label={t("Muut rahtikulut")} type="number" {...getFormikInputProps("other_cargo")} />
                  </div>
                  <div className="px-1">
                    <Input label={t("Valuutta")} type="select" {...getFormikInputProps("other_cargo_currency")}>
                      <option value=""></option>
                      {getCurrencyOptions()}
                    </Input>
                  </div>
                  <div className="px-1">
                    <Input label={t("Pvm")} type="date" {...getFormikInputProps("other_cargo_currency_date")} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          
          <Col className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-start">
                  <div className="px-1">
                    <Input label={t("Maksu kulut")} type="number" {...getFormikInputProps("payment_fee")} />
                  </div>
                  <div className="px-1">
                    <Input label={t("Valuutta")} type="select" {...getFormikInputProps("payment_fee_currency")}>
                      <option value=""></option>
                      {getCurrencyOptions()}
                    </Input>
                  </div>
                  <div className="px-1">
                    <Input label={t("Pvm")} type="date" {...getFormikInputProps("payment_fee_currency_date")} />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <div className="d-flex align-items-start">
                  <div className="px-1">
                    <Input label={t("Muut kulut")} type="number" {...getFormikInputProps("other_payment_fee")} />
                  </div>
                  <div className="px-1">
                    <Input label={t("Valuutta")} type="select" {...getFormikInputProps("other_payment_fee_currency")}>
                      <option value=""></option>
                      {getCurrencyOptions()}
                    </Input>
                  </div>
                  <div className="px-1">
                    <Input label={t("Pvm")} type="date" {...getFormikInputProps("other_payment_fee_currency_date")} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          
        </Row>
        
        <div className="d-flex flex-row bd-highlight">
          <div className="py-2 pl-2 bd-highlight">
            <Button onClick={() => validateFormBeforeSubmit(handleCalculateProductPrices)}>{t("Laske uudet hinnat")}</Button>
          </div>
          <div className="p-2 bd-highlight">
            <Button loading={saveProductPricesLoading} disabled={pricesCalculated === false} onClick={handleSaveProductPrices}>{t("Tallenna lasketut ostohinnat")}</Button>
          </div>
          <div className="p-2 bd-highlight">
            <Button disabled={!erpReceipt} onClick={handleSaveProductSellPrices}>{t("Tallenna myyntihinnat")}</Button>
          </div>
        </div>
    
        <Row className="mb-3">
          <Col className="col-12 col-sm-12 col-md-auto">
            <Input label={t("Näytä")} name="show" type="select" onChange={handleShowProductFilterChange} value={filterShowProducts}>
              {/* <option value="">{t("purchase_price_calculator_show_all_option", "Kaikki")}</option> */}
              <option value="only_ordered">{t("purchase_price_calculator_show_only_ordered_option","Vain tilatut")}</option>
            </Input>
          </Col>
        </Row>
        
        { values.supplier && (
          <TableAuto
            color="dark"
            showId={true}
            checkboxes={false}
            headers={headers}
            rows={getTableRows()}
            loading={loadingPurchaseOrderProducts || loadingPurchaseOrder || loadingProducts}
            filters={productFilters}
            setFilters={setProductFilters}
            tableStickyHeader={true}
            sortBackend={false}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
        
        <Row className="mt-5">
          <Col></Col>
        </Row>
        
        <Modal
          size="modal-xl"
          title={t("Muokkaa tilaustuotetta")}
          isOpen={modalProductOpen}
          toggleModal={toggleModalProduct}
          closeModal={handleProductReject}
        >
          <PurchaseOrderProductEdit
            purchaseOrderId={values?.id}
            purchaseOrderProductId={purchaseOrderProductId}
            handleResolve={handleProductResolve}
            handleReject={handleProductReject}
            readOnly={values?.state >= 3}
          />
        </Modal>
        
      </>
    );
  }
  else {
    return (
      <>
        <Row>
          <Col>
            <PageHeader title={t("Ostohintalaskuri")}></PageHeader>
          </Col>
        </Row>
        <Row>
          <Col>
            <Input name="purchase_order" label={t("Valitse tilaus")} type="select" onChange={(e) => handlePurchaseOrderChange(e)} value={values?.id}>
              <option value=""></option>
              {getPurchaseOrderOptions()}
            </Input>
          </Col>
        </Row>
      </>
    )
  }
  
}

const defaultValues = {
  id: null,
  supplier: null,
  state: 0,
  cargo_currency_date: moment().format("YYYY-MM-DD"),
  other_cargo_currency_date: moment().format("YYYY-MM-DD"),
  payment_fee_currency_date: moment().format("YYYY-MM-DD"),
  other_payment_fee_currency_date: moment().format("YYYY-MM-DD"),
  purchase_order_currency_date: moment().format("YYYY-MM-DD"),
}

 const PurchasePriceCalculatorFormik = withFormik({
    abortEarly: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        purchase_order_currency: Yup.string().required(required).nullable(),
        purchase_order_currency_date: Yup.date().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      
      props.setUnsavedPurchasePrices(false)
      props.setUnsavedSellPrices(false)
    },
    displayName: "BasicForm"
  
  })(PurchasePriceCalculator)
    
    

const PurchasePriceCalculatorView = ({match, history}) => {
  const [loadingPurchaseOrders, setLoadingPurchaseOrders] = useState(0);
  const [loadingPurchaseOrder, setLoadingPurchaseOrder] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { t } = useTranslation();
  
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [orderId, setOrderId] = useState()
  const [orderData, setOrderData] = useState({})
  const [pricesData, setPricesData] = useState({})
  
  const [unsavedPurchasePrices, setUnsavedPurchasePrices] = useState(false)
  const [unsavedSellPrices, setUnsavedSellPrices] = useState(false)
  
  // PurchaseOrders
  const getPurchaseOrders = () => {
    if (loadingPurchaseOrders > 0) {
        return;
    }
    const params = {
      state__in: "1,2,3,4,5,6",
    }
	setLoadingPurchaseOrders(s => s+1)
    httpGetPurchaseOrders(params).then(res => {
      setPurchaseOrders(res?.data)
    }).finally(() => {
        setLoadingPurchaseOrders(s => s-1)
	})
  }
  
  const getPurchaseOrderOptions = useCallback(() => {
    return purchaseOrders && purchaseOrders.map(obj => {
      return (
        <option value={obj?.id}>{obj?.supplier?.name} ({getDate(obj?.state_updated)})</option>
      )
    })
  }, [purchaseOrders])
  
  const handlePurchaseOrderChange = ({target}) => {
    setOrderId(target.value)
  }
  
  useEffect(() => {
    getPurchaseOrders()
  }, [orderId])
  
  const getFormattedDate = (dt) => {
    if (dt && dt.length > 0) {
      return moment(dt).format("YYYY-MM-DD")
    }
    return moment().format("YYYY-MM-DD")
    
  }
  
  const getPurchaseOrder = (id) => {
    if (id === null) {
        return;
    }
    if (loadingPurchaseOrder === true) {
        return;
    }
    setLoadingPurchaseOrder(true)
    httpGetPurchaseOrder(id).then(res => {
      const data = res?.data;
      
      let previousCalculationData = {
        purchase_order_currency: data?.erp_purchase_prices_status ? data?.temp_purchase_order_currency : data?.temp_purchase_order_currency, // both temp
        purchase_order_currency_date: data?.erp_purchase_prices_status ? getFormattedDate(data?.temp_purchase_order_currency_date) : getFormattedDate(data?.temp_purchase_order_currency_date), // both temp
        cargo: data?.erp_purchase_prices_status ? data?.cargo : data?.temp_cargo,
        cargo_currency: data?.erp_purchase_prices_status ? data?.cargo_currency : data?.temp_cargo_currency,
        cargo_currency_date: data?.erp_purchase_prices_status ? getFormattedDate(data?.cargo_currency_date) : getFormattedDate(data?.temp_cargo_currency_date),
        other_cargo: data?.erp_purchase_prices_status ? data?.other_cargo : data?.temp_other_cargo,
        other_cargo_currency: data?.erp_purchase_prices_status ? data?.other_cargo_currency : data?.temp_other_cargo_currency,
        other_cargo_currency_date: data?.erp_purchase_prices_status ? getFormattedDate(data?.other_cargo_currency_date) : getFormattedDate(data?.temp_other_cargo_currency_date),
        payment_fee: data?.erp_purchase_prices_status ? data?.payment_fee : data?.temp_payment_fee,
        payment_fee_currency: data?.erp_purchase_prices_status ? data?.payment_fee_currency : data?.temp_payment_fee_currency,
        payment_fee_currency_date: data?.erp_purchase_prices_status ? getFormattedDate(data?.payment_fee_currency_date) : getFormattedDate(data?.temp_payment_fee_currency_date),
        other_payment_fee: data?.erp_purchase_prices_status ? data?.other_payment_fee : data?.temp_other_payment_fee,
        other_payment_fee_currency: data?.erp_purchase_prices_status ? data?.other_payment_fee_currency : data?.temp_other_payment_fee_currency,
        other_payment_fee_currency_date: data?.erp_purchase_prices_status ? getFormattedDate(data?.other_payment_fee_currency_date) : getFormattedDate(data?.temp_other_payment_fee_currency_date),
      }
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        ...previousCalculationData,
        supplier: res.data?.supplier?.id,
      });
    }, errors => {
      notify({ title:t("Ostotilaus"), message:t("Ei löytynyt")})
      history.push(`/dashboard/purchase_orders/list/draft`);
    }).finally(response => {
      setLoadingPurchaseOrder(false);
    })
  }
  
  const refresh = () => {
    if (orderId) {
      getPurchaseOrder(orderId)
    }
  }
  
  useEffect(() => {
    refresh()
  }, [orderId])
  
  
  useEffect(() => {
    if (match && match?.params?.purchaseOrderId && parseInt(match?.params?.purchaseOrderId) > 0) {
      setOrderId(match?.params?.purchaseOrderId)
    }
  }, [match])
  
  if (loadingPurchaseOrder) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchasePriceCalculatorFormik
      t={t}
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      orderId={orderId}
      orderData={orderData} 
      setOrderData={setOrderData} 
      refresh={refresh}
      pricesData={pricesData}
      setPricesData={setPricesData}
      getPurchaseOrderOptions={getPurchaseOrderOptions}
      handlePurchaseOrderChange={handlePurchaseOrderChange}
      unsavedPurchasePrices={unsavedPurchasePrices}
      setUnsavedPurchasePrices={setUnsavedPurchasePrices}
      unsavedSellPrices={unsavedSellPrices}
      setUnsavedSellPrices={setUnsavedSellPrices}
      loadingPurchaseOrder={loadingPurchaseOrder}
    />
  )
}

export default PurchasePriceCalculatorView;

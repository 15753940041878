import http from './api'

export const httpLogin = (username, password) => {
  console.log("httpLogin");
  return http({
    url: '/token/',
    method: 'POST',
    data: {
      username,
      password,
    },
  })
}


export const httpActivatePin = (data) => {
  console.log("httpActivatePin");
  return http({
    url: '/activate/',
    method: 'POST',
    data
  })
}


import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { generatePath } from "react-router";
import { Row, Col } from "stories/layout";
import {
  Button,
  IconButton,
  Link,
  Typography,
} from "stories/components";
import { useAppContext } from "contexts/AppContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetSuppliers } from "services/suppliers"
import { httpGetPurchaseOrders } from "services/purchase_orders"
import { getDate, getDateAndTime } from "services/datetime"
import { Input } from "stories/forms";

const ReceiptState = ({color, content, icon }) => {
  
  return (
    <div className={`d-flex flex-row text-${color}`}>
      <div className="p-2">
        <i className={`fa ${icon}`} />
      </div>
      <div className="py-2 pb-2 pr-2 pl-0">{content}</div>
    </div>
  )
}

const PurchaseOrders = ({match, history}) => {
  
  const { enums, getTabNameById } = useAppContext()
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState({})
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  
  // Filters
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-id",
  })
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  // Filter: State
  const getStateOptions = useCallback(() => {
    return enums?.purchase_orders?.purchase_order?.state && enums?.purchase_orders?.purchase_order?.state.map(state => {
      return (
        <option value={state.key}>{state.value}</option>
      )
    })
  }, [enums])
  
  // Filter: Suppliers
  const [suppliers, setSuppliers] = useState([])
  
  const getSuppliers = () => {
    httpGetSuppliers().then(res => {
      setSuppliers(res.data)
    })
  }
   
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option value={supplier.id}>{supplier.name}</option>
      )
    })
  }, [suppliers])
  
  useEffect(() => {
    getSuppliers()
  }, [])
  
  // END
  
  const getPurchaseOrders = () => {
    
    let params = {
      ...filters
    }
    if (params.hasOwnProperty("state__in") === true) {
      params.state = undefined
    }
    
    setLoading(true)
    httpGetPurchaseOrders(params).then(res => {
      setRows(res?.data)
      setLoading(false)
    }, errorResponse => {
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getPurchaseOrders();
  }, [filters])
  
  const handleEdit = (id) => {
    history.push(`/dashboard/purchase_orders/edit/${id}/`);
  }
  
  const handlePurchaseOrderPriceCalculator = (id) => {
    history.push(`/dashboard/purchase_price_calculator/${id}/`);
  }
  
  const handlePurchaseOrderMcfReceipt = (id) => {
    history.push(`/dashboard/purchase_order_mcf_receipt/${id}/`);
  }
  
  const headers = [
    { label: "ID", key: "id"},
    { label: t("Ostotilaus"), key: "supplier"},
    { label: t("Saapunut"), key: "received"},
    { label: t("Tila"), key: "state"},
    { label: t("Kuitin tila"), key: "receipt_state"},
    { label: t("Toiminnot"), key: "actions"},
  ];
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        id: row?.id,
        supplier: (
          <Typography bold>
            <Link to={`/dashboard/purchase_price_calculator/${row?.id}`}>
            {row?.supplier?.name}
            </Link>
          </Typography>
        ),
        state: (
          <>
          {getTabNameById(row?.state)}
          </>
        ),
        receipt_state: (
          <div className="d-flex flex-row">
            <div className="p-0">
              { row?.erp_purchase_prices_status === true ? (
                <ReceiptState color="success" icon="fa-check" content={t("Ostohinnat")} />
              ) : (
                <ReceiptState color="danger" icon="fa-exclamation-circle" content={t("Ostohinnat")} />
              )}
            </div>
            <div className="p-0">
              { row?.erp_stock_amounts_status === true ? (
                <ReceiptState color="success" icon="fa-check" content={t("Varastosaldo")} />
              ) : (
                <ReceiptState color="danger" icon="fa-exclamation-circle" content={t("Varastosaldo")} />
              )}
            </div>
          </div>
        ),
        actions: (
          <>
          {/*
            <Button iconName="calculator" onClick={() => handlePurchaseOrderPriceCalculator(row?.id)}>
            {t("Ostohintalaskuri")}
            </Button>
            <Button iconName="calculator" onClick={() => handlePurchaseOrderMcfReceipt(row?.id)}>
            {t("MCF-kuitti")}
            </Button>
          */}
          </>
        )
      }
    })
  }, [rows])
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  return (
    <>
      <PageHeader title={t("Ostohintalaskuri / ostotilaukset")}></PageHeader>
      
      <div className="mb-3">
      
      <Row>
        <Col className="col-sm-6 col-md-4 col-lg-3">
          <Input label={t("Tila")} type="select" name="state" onChange={handleFilterChange} value={filters?.state}>
            <option value="">{t("Kaikki")}</option>
            {getStateOptions()}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-6 col-md-4 col-lg-3">
          <Input label={t("Tavarantoimittaja")} type="select" name="supplier" onChange={handleFilterChange} value={filters?.supplier}>
            <option value="">{t("Kaikki")}</option>
            {getSupplierOptions()}
          </Input>
        </Col>
      </Row>
      
      </div>
          
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
    </>
  );
}

export default PurchaseOrders;

import React from "react";

const TextCutter = ({text, limit=16}) => {
  
  if (!text || text.length === 0) {
    return "";
  }
  
  return (
    <span title={text}>{text.slice(0,limit)}</span>
  )
}

export default TextCutter;
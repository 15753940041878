import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import DashboardLayout from "layouts/Dashboard.js";
import AuthLayout from "layouts/Auth.js";
import { useAuthContext } from "contexts/AuthContext";
import { useAppContext } from "contexts/AppContext"

const App = () => {
  const { isAuthenticated } = useAuthContext();
  const { getEnums  } = useAppContext()
  
  useEffect(() => {
    getEnums();
  }, []);
  
  return (
    <>
      { isAuthenticated === true ? (
        <Switch>
          <Route path="/auth/invitation" render={(props) => <AuthLayout {...props} />} />
          <Route path="/dashboard" render={(props) => <DashboardLayout {...props} />} />
          <Redirect from="*" to="/dashboard/frontpage" />
        </Switch>
      ) : (
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="*" to="/auth/login" />
        </Switch>
      )}
      
    </>
  )
}

export default App;
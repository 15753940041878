console.log("ENV.REACT_APP_ENV", process?.env?.REACT_APP_ENV);

let config = {
  // apiUrl: 'http://172.26.108.84:8080/api'
  // apiUrl: 'http://localhost:8000/api'
  apiUrl: 'https://erpdev.sarkain.fi/api'
  // apiUrl: 'https://erp.flowm8.fi/api'
  // apiUrl: 'https://tradesoft.flowm8.fi/api'
};

if (process?.env?.REACT_APP_ENV === "prod") {
  config = {
    // apiUrl: 'https://erpdev.sarkain.fi/api'
    apiUrl: 'https://tradesoft.flowm8.fi/api'
  }
}

if (process?.env?.REACT_APP_ENV === "dev") {
  config = {
    // apiUrl: 'https://erpdev.sarkain.fi/api'
    apiUrl: 'https://dev.flowm8.fi/api'
  }
}

if (process?.env?.REACT_APP_ENV === "erpdev") {
  config = {
    apiUrl: 'https://erpdev.sarkain.fi/api'
  }
}
// Automatically select all product complaints as part of the order
config.purchase_order_complaints_auto_add = false
config.purchase_order_name_characters = 128
config.DEFAULT_LANGUAGE = 'en'

export default config;
import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from "./Badge"

import "./Pad.css"

export const Pad = ({ onClick, color, size, className, icon, badgeColor, badgeValue, children, ...rest }) => {
  
  let sizeClass = "";
  if (size && size === "small") {
    sizeClass = "pad-sm"
  }
  else if (size && size === "medium") {
    sizeClass = "pad-md"
  }
  else if (size && size === "large") {
    sizeClass = "pad-lg"
  }
  
  let classNames = `${className ? className : ""} ${sizeClass} pad-${color}`;
  
  return (
    <>
    
    <button
      className={`pad ${classNames}`}
      disabled={onClick == undefined}
      onClick={onClick}
      {...rest}
    >
      <div className="d-flex align-items-center">
        <div className="mx-2">
          <i 
            className={`s-5 fas ${icon}`}
          />
        </div>
        <div className="pad_content text-center">
          {children}
        </div>
      </div>
      { badgeValue != undefined && (<Badge type="pad" color={badgeColor}>{badgeValue}</Badge>)}
    </button>
    
    </>
  );
};

Pad.propTypes = {
  size: PropTypes.oneOf(['default', 'medium', 'large']),
  color: PropTypes.oneOf(['primary','secondary','default','info','success','danger','warning']),
};

Pad.defaultProps = {
  badgeColor: "default",
  badgeValue: null,
  color: 'primary',
  size: 'normal',
};

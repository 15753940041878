import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, IconButton } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input, SelectMulti } from "stories/forms";
import { Table, THead } from "stories/tables";

import ProductStock from "components/Products/ProductStock"

import { useNotification } from "stories/components/Notification"
import { httpGetSuppliers } from "services/suppliers"
import { 
  httpGetProductVariation, 
  httpCreateProductVariation, 
  httpUpdateProductVariation, 
  httpArchiveProductVariation, 
  httpDeleteProductVariation,
  httpExportProductVariationToMCF,
} from "services/products" 
import { getDateAndTime } from "services/datetime"
import { useAppContext } from "contexts/AppContext"

const ProductVariationEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isSubmitting,
     submitCount,
     validateForm,
     setErrors
   } = props;
   
   const {
      t,
      history,
      productId,
      productVariationId,
      enums,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const isTouched = useCallback(() => {
     if (Object.keys(touched).length > 0) {
       return true;
     }
     return false;
  }, [touched])
  
  const handleCancel = () => {
    history.push(`/dashboard/products/edit/${productId}/variations`);
  }
  
  // Suppliers
  
  const [suppliers, setSuppliers] = useState();
   
   useEffect(() => {
     getSuppliers();
   }, [])
  
  const getSuppliers = () => {
    
    httpGetSuppliers({ordering:"name"}).then(res => {
      setSuppliers(res?.data)
    })
  }
  
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return {value: supplier.id, label: supplier.name}
    })
  }, [suppliers])
  
  const getSelectedSuppliers = useCallback(() => {
    return values?.suppliers && values?.suppliers.map(supplier => {
      return {value: supplier.id, label: supplier.name}
    })
  }, [values?.suppliers])
  
  const getSupplier = (id) => {
    return suppliers && suppliers.reduce((result, item) => {
      if (parseInt(item.id) === parseInt(id)) { result = item }
      return result
    }, null)
  }
  
  const getSupplierName = useCallback((id) => {
    const supplier = getSupplier(id)
    return supplier ? supplier?.name : ""
  }, [suppliers])
  
  const getSupplierInputOptionsFiltered = useCallback(() => {
    // List only Suppliers which are selected to the Product
    
    const _suppliers = suppliers; // suppliers=all suppliers, values?.suppliers=only suppliers in product (not yet implemented)
    
    return _suppliers && _suppliers.map(supplier => {
      return (
        <option value={supplier?.id}>{supplier?.name}</option>
      )
    })
  }, [suppliers, values?.suppliers])
  
  // Product supplier codes
  
  const [codesModalOpen, setCodesModalOpen] = useState(false);
  const newCodeDefault = {
    batch: 1,
    formOpen: false,
    id: -1,
    minimum_order_quantity: 1,
    name: "",
    supplier_fk: null,
  }
  const [newCode, setNewCode] = useState(newCodeDefault);
  
  const handleOpenCodesModal = () => {
    setCodesModalOpen(true)
  }
  
  const toggleCodesModal = () => {
    setCodesModalOpen(s => !s)
  }
  
  const getCodesList = useCallback(() => {
    if (values?.product_supplier_codes && values?.product_supplier_codes.length > 0) {
      return values?.product_supplier_codes && values?.product_supplier_codes.map((code, index) => {
        
        return (
          <tr>
              <td>{getSupplierName(code?.supplier_fk)}</td>
              <td>{code?.name}</td>
              <td>{code?.batch}</td>
              <td>{code?.minimum_order_quantity}</td>
              <td><IconButton size="lg" onClick={() => removeCode(index)} iconName="trash" /></td>
          </tr>
        )
      })
    }
    else {
      return (
        <tr><td colspan="5">{t("No product supplier codes")}</td></tr>
      )
    }
  }, [suppliers, values?.product_supplier_codes])
  
  const removeCode = (index) => {
    let codes = [
      ...values?.product_supplier_codes
      ]
    codes.splice(index, 1)
    setFieldValue("product_supplier_codes", codes)
  }
  
  const handleOpenNewCode = () => {
    setNewCode({
      ...newCodeDefault,
      formOpen: true,
    })
  }
  
  const handleNewCodeChange = ({target}) => {
    setNewCode(s => ({
      ...s,
      formOpen: true,
      [target?.name]: target?.value,
    }))
  }
  
  const handleAddCode = () => {
    let codes = [
      ...values?.product_supplier_codes,
      newCode,
    ]
    setFieldValue("product_supplier_codes",codes)
    setNewCode({
      ...newCodeDefault,
      formOpen: false,
    })
  }
  
  const getStatusOptions = useCallback(() => {
    return enums?.products?.product_variation?.status && enums?.products?.product_variation?.status.map(obj => {
      return (
        <>
          {/* i18next-extract-disable-next-line */}
          <option value={obj.key}>{t(obj.value)}</option>
        </>
      )
    })
  }, [enums])
  
  // END
  
  const handleSave = () => {
    validateForm().then(() => handleSubmit())
  }
  
  const handleArchive = () => {
    const result = window.confirm(t("Haluatko varmasti arkistoida tuotteen?"));
    if (result) {
      httpArchiveProductVariation(productId, productVariationId).then(res => {
        history.push(`/dashboard/products/edit/${productId}/variations`);
      }, (error) => {
        props.notify({ 
          type: "danger", 
          title:t("Virhe arkistoinnissa"), 
        })
        setErrors({
          general: JSON.stringify(error)
        })
        if (error?.data?.errorCode === "UNABLE_TO_REMOVE_PRODUCT_VARIATIONS_MCF") {
          // It is ok if MCF remove failed for product with no mcf id
          history.push(`/dashboard/products/edit/${productId}/variations`);
        }
      })
    }
  }
  
  const handleProductStockResolve = () => {
    //jk
  }
  
  return (
    <>
      <div className="d-flex flex-row-reverse p-2">
        <div>
          { values?.id && (
          <Typography variant="small">{t("product_edit_mcf_updated","Päivitetty MCF")}: {values?.updated_mcf ? getDateAndTime(values?.updated_mcf) : ""}</Typography>
          )}
        </div>
      </div>
      <Row>
        {/* First column START */}
        <Col className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Variaation perustiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Typography bold inline>{t("ID (MCF)")}</Typography>: <Typography inline>{values?.id_mcf}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name_en")}
                label={t("Nimi (en)")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name_fi")}
                label={t("Nimi (fi)")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("price")}
                label={t("Oma hinta")}
                type="number"
              />
              {t("Aseta hinta, jos eri kuin tuotteen hinta")}
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("purchase_price")}
                label={t("Ostohinta")}
                type="number"
              />
              {t("Hinta, jolla tuote ostetaan toimittajalta. Syötä aina verottomana.")}
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("weight")}
                label={t("Paino")}
                type="text"
                pattern="[0-9]+([.,][0-9]+)?"
              />
              kg
            </Col>
          </Row>
          
          { enums ? (
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("status")} required={true} label={`${t("Tila")}`} type="select">
                <option value=""></option>
                {getStatusOptions()}
              </Input>
              { values?.status == 3 ? (
                <Typography variant="small">{t("product_status_orderable_helptext","Tilattavissa-tila estää tuotteen päivitykset ERPistä MCF:ään. Tuotteelle ei myöskään luoda MCF-ID:tä ennen kuin tilan vaihtaa.")}</Typography>
              ) : null}
            </Col>
          </Row>
          ) : null}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("sort")}
                label={t("variation_sort","Järjestys")}
                type="number"
                min={0}
              />
            </Col>
          </Row>
        
        </Col>
        
        {/* Second column START */}
        
        <Col className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
          
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Tuotekoodit")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("product_code")}
                label={t("Tuotenumero")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              
                <div className="mb-2">
                  <Table>
                    <THead>
                    <tr>
                      <th>{t("Toimittaja")}</th>
                      <th>{t("Tuotenumero")}</th>
                      <th>{t("Batch")}</th>
                      <th title={t("Minimum order quantity")}>{t("MOQ")}</th>
                      <th>&nbsp;</th>
                    </tr>
                    </THead>
                    <tbody>
                      {getCodesList()}
                    </tbody>
                  </Table>
                </div>
                
                { newCode?.formOpen ? (
                  <Table>
                    <THead>
                    <tr>
                      <th>{t("Toimittaja")}</th>
                      <th>{t("Tuotenumero")}</th>
                      <th>{t("Batch")}</th>
                      <th title={t("Minimum order quantity")}>{t("MOQ")}</th>
                      <th>&nbsp;</th>
                    </tr>
                    </THead>
                    <tbody>
                      <tr>
                        <td>
                          <Input required name="supplier_fk" type="select" onChange={handleNewCodeChange}>
                            <option value=""></option>
                            {getSupplierInputOptionsFiltered()}
                          </Input>
                        </td>
                        <td><Input required name="name" value={newCode?.name} onChange={handleNewCodeChange} /></td>
                        <td><Input required name="batch" min={1} type="number" value={newCode?.batch} onChange={handleNewCodeChange} /></td>
                        <td><Input required name="minimum_order_quantity" min={0} type="number" value={newCode?.minimum_order_quantity} onChange={handleNewCodeChange} /></td>
                        <td><Button color="success" size="sm" onClick={handleAddCode}>{t("Lisää")}</Button></td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Button variant="link" size="sm" onClick={handleOpenNewCode}>{t("Lisää uusi koodi")}</Button>
                )}
                

            </Col>
          </Row>
          
        </Col>
        
        {/* Third column START */}
        
        { values?.id && (
        <Col className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Varasto")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <ProductStock productId={productId} productVariationId={productVariationId} is_mcf_product={values?.is_mcf_product} resolve={handleProductStockResolve} />
            </Col>
          </Row>
          
        </Col>
        )}
          
          
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-center">
          { values?.id ? (
            <Button disabled={!values?.id} onClick={handleArchive} variant="danger">{t("Poista")}</Button>
          ) : null }
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => handleSave()}>{t("Tallenna")}</Button>
          { isTouched() && isValid == false && <Typography className="text-danger">{t("Virheitä lomakkeella")}</Typography> }
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
      
    </>
  );
}

const defaultValues = {
  codes: [],
  id: null,
  name: '',
  product_supplier_codes: [],
  suppliers: [],
}

 const ProductVariationEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        product_code: Yup.string().required(required),
        name_fi: Yup.string().required(required).notOneOf(
          ["0"],
        ),
        name_en: Yup.string().required(required).notOneOf(
          ["0"],
        ),
        status: Yup.string().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const weight = values?.weight ? parseFloat(values?.weight.toString().replace(",",".")) : 0;
      let data = {
        product_fk: parseInt(props.productId),
        ...values,
        name: "a",
        weight: weight,
      };
      
      if (values?.id) {
        httpUpdateProductVariation(props.productId, values.id, data).then(res => {
          props.notify({ 
            type: 'success',
            title:t("Tuote"), 
            message:t("Tallennettu")
          })
          
          httpExportProductVariationToMCF(props.productId, values.id).then(McfResponse => {
            setSubmitting(false);
            props.notify({ 
              type: 'success', 
              title:t("Tuote"), 
              message:t("Muutokset viety MyCashFlowiin")
            })
            props.getProductVariation(values.id);
            // props.history.push(`/dashboard/products/edit/${props.productId}/variations`);
          }, error => {
            
            if (error?.data?.errorCode == "UNABLE_TO_EXPORT_PRODUCT_VARIATIONS_MCF") {
              props.notify({ 
                type: "info", 
                title:t("Ei viety MyCashFlowiin"), 
                message: JSON.stringify(error?.data?.message)
              })
              props.history.push(`/dashboard/products/edit/${props.productId}/variations`);
              
            }
            else if (error?.data?.detail) {
              props.notify({ 
                type: "danger", 
                title:t("Virhe MyCashFlow viennissä"), 
                message: JSON.stringify(error?.data?.detail)
              })
              setErrors({
                general: error?.data?.detail,
              })
            }
            else {
              props.notify({ 
                type: "danger", 
                title:t("MyCashFlow viennissä virhe"), 
              })
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          })
          .finally(() => {
            setSubmitting(false);
          })
          
        }, error => {
          setSubmitting(false);
          props.notify({ 
            type: "danger", 
            title:t("Virhe tuotteen tallennuksessa"), 
          })
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            if (error?.status === 400) {
              setErrors({
                ...error?.data,
                general: t("backend_form_400_error","Tarkista lomakkeen virheet")
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
        })
      }
      else {
        httpCreateProductVariation(props.productId, data).then(res => {
          
          httpExportProductVariationToMCF(props.productId, res?.data?.id).then(res => {
            setSubmitting(false);
            
            props.notify({ 
              type: 'success',
              title:t("Tuote"), 
              message:t("Tallennettu")
            })
            
            props.notify({ 
              type: 'success', 
              title:t("Tuote"), 
              message:t("Muutokset viety MyCashFlowiin")
            })
            props.history.push(`/dashboard/products/edit/${props.productId}/variations`);
          }, error => {
            
            if (error?.data?.errorCode == "UNABLE_TO_EXPORT_PRODUCT_VARIATIONS_MCF") {
              
              props.notify({ 
                type: 'success',
                title:t("Tuote"), 
                message:t("Tallennettu")
              })
              
              props.notify({ 
                type: "info", 
                title:t("Ei viety MyCashFlowiin"), 
                message: JSON.stringify(error?.data?.message)
              })
              
              // When errors, delete created product
              // Do not delete here, this is fine if the status is 3
              props.history.push(`/dashboard/products/edit/${props.productId}/variations`);
            }
            else if (error?.data?.detail) {
              props.notify({ 
                type: "danger", 
                title:t("Virhe MyCashFlow viennissä"), 
                message: JSON.stringify(error?.data?.detail)
              })
              setErrors({
                general: error?.data?.detail,
              })
              
              // When errors, delete created product
              httpDeleteProductVariation(props.productId, res?.data?.id)
            }
            else {
              props.notify({ 
                type: "danger", 
                title:t("MyCashFlow viennissä virhe"), 
              })
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
              
              // When errors, delete created product
              httpDeleteProductVariation(props.productId, res?.data?.id)
            }
          })
          .finally(() => {
            setSubmitting(false);
          })
        }, error => {
          setSubmitting(false);
          props.notify({ 
            type: "danger", 
            title:t("Virhe tuotteen tallennuksessa"), 
          })
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            if (error?.status === 400) {
              setErrors({
                ...error?.data,
                general: t("backend_form_400_error","Tarkista lomakkeen virheet")
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
        })
      }
      
      
      
    },
    displayName: "BasicForm"
  
  })(ProductVariationEdit)
    
    
const ProductVariationEditView = ({match, history, productId, productVariationId}) => {
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification();
  const { t } = useTranslation();
  const { enums } = useAppContext()
  
  const getProductVariation = (id) => {
    setLoading(true)
    httpGetProductVariation(productId, id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (productVariationId && parseInt(productVariationId) > -1) {
      getProductVariation(productVariationId)
    }
  }, [productVariationId])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <ProductVariationEditFormik 
        t={t} 
        enums={enums} 
        history={history} 
        preSetValues={preSetValues} 
        notify={notify} 
        productId={productId} 
        productVariationId={productVariationId}
        getProductVariation={getProductVariation}
    />
  )
  

}
  

export default ProductVariationEditView;

import http from './api'

const url = '/products'

export const httpGetProducts = (params, config={}) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
    ...config,
  })
}

export const httpGetProduct = (id) => {
  return http({
    url: `${url}/${id}/`,
    method: 'GET',
  })
}

export const httpGetProductStatistics = (id) => {
  return http({
    url: `${url}/${id}/statistics/`,
    method: 'GET',
  })
}


export const httpCreateProduct = (data) => {
  return http({
    url: `${url}/`,
    method: 'POST',
    data,
  })
}

export const httpUpdateProduct = (id, data) => {
  return http({
    url: `${url}/${id}/`,
    method: 'PATCH',
    data,
  })
}

export const httpDeleteProduct = (id) => {
  return http({
    url: `${url}/${id}/`,
    method: 'DELETE',
  })
}

export const httpArchiveProduct = (id) => {
  return http({
    url: `${url}/${id}/archive/`,
    method: 'POST',
  })
}

export const httpGetProductVariations = (productId, params) => {
  return http({
    url: `${url}/${productId}/variations/`,
    method: 'GET',
    params,
  })
}

export const httpGetProductVariation = (productId, productVariationId) => {
  return http({
    url: `${url}/${productId}/variations/${productVariationId}/`,
    method: 'GET',
  })
}

export const httpCreateProductVariation = (productId, data) => {
  return http({
    url: `${url}/${productId}/variations/`,
    method: 'POST',
    data
  })
}

export const httpUpdateProductVariation = (productId, productVariationId, data) => {
  return http({
    url: `${url}/${productId}/variations/${productVariationId}/`,
    method: 'PATCH',
    data
  })
}

export const httpArchiveProductVariation = (productId, productVariationId) => {
  return http({
    url: `${url}/${productId}/variations/${productVariationId}/archive/`,
    method: 'POST',
  })
}

export const httpDeleteProductVariation = (productId, productVariationId) => {
  return http({
    url: `${url}/${productId}/variations/${productVariationId}/`,
    method: 'DELETE',
  })
}

// Variations read only API
// without parent product id requirement
export const httpGetVariations = (params) => {
  return http({
    url: `${url}/variations/read_only`,
    method: 'GET',
    params,
  })
}

// MyCashFlow

export const httpImportProductsFromMCF = () => {
  return http({
    url: `${url}/import_products_from_mcf`,
    method: 'GET',
  })
}

export const httpExportProductsToMCF = () => {
  return http({
    url: `${url}/export_products_to_mcf/`,
    method: 'POST',
  })
}

export const httpImportProductVariationsFromMCF = () => {
  return http({
    url: `${url}/import_product_variations_from_mcf`,
    method: 'GET',
  })
}

export const httpExportProductVariationsToMCF = () => {
  return http({
    url: `${url}/export_product_variations_to_mcf/`,
    method: 'POST',
  })
}

// MyCashFlow Single product
export const httpExportProductToMCF = (id) => {
  return http({
    url: `${url}/${id}/export_to_mcf/`,
    method: 'POST',
  })
}

export const httpExportProductVariationToMCF = (productId, productVariationId) => {
  return http({
    url: `${url}/${productId}/variations/${productVariationId}/export_to_mcf/`,
    method: 'POST',
  })
}

// ERP Receipt related

export const httpCreateProductPriceChange = (data) => {
  return http({
    url: `/products/product_price_changes/`,
    method: 'POST',
    data,
  })
}
import Register from "views/auth/Register"
import Login from "views/auth/Login"
import PasswordForgot from "views/auth/PasswordForgot"
import PasswordReset from "views/auth/PasswordReset"
import Invitation from "views/auth/Invitation"

const routes = [
  {
    path: "/register",
    name: "Rekisteröidy",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/login",
    name: "Kirjaudu",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/password/forgot",
    name: "Unohtunut salasana",
    component: PasswordForgot,
    layout: "/auth",
  },
  {
    path: "/reset_password",
    name: "Resetoi salasana",
    component: PasswordReset,
    layout: "/auth",
  },
  {
    path: "/invitation",
    name: "Kutsu",
    component: Invitation,
    layout: "/auth",
  },
];

export default routes;
import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Modal, Typography, Button, Link, IconButton } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetPurchaseOrderProductDeliveries } from "services/purchase_orders"
import PurchaseOrderProductDeliveryEdit from "views/dashboard/PurchaseOrderProductDeliveryEdit/PurchaseOrderProductDeliveryEdit"

const PurchaseOrderDeliveredProductsList = ({history, purchaseOrderId, purchaseOrderDeliveryId}) => {
  
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [sorting, setSorting] = useState({
    sortingKey: "erp_id_sort",
    sortingReverse: false,
    sortingType: "float",
  })
  const [rows, setRows] = useState([]);
  
  /*
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  */
  const [filters, setFilters] = useState({
    //limit: 30,
    //offset:0,
  })
  const getPurchaseOrderProductDeliveries = () => {
    if (!purchaseOrderId || !purchaseOrderDeliveryId) {
      return;
    }
    setLoading(true)
    httpGetPurchaseOrderProductDeliveries(purchaseOrderId, purchaseOrderDeliveryId, filters).then(res => {
      let resRows = res?.data;
      let resRowsSort = []
      let dummyParents = []
      
      // Give each row own sort id
      resRows.map(resRow => {
        const productId = resRow?.purchase_order_product?.product?.id;
        const productVariationId = resRow?.purchase_order_product?.product_variation ? resRow?.purchase_order_product?.product_variation?.id : 0
        
        // Create dummy parent when children
        if (productVariationId > 0 && dummyParents.includes(productId) === false) {
          resRowsSort.push({
            purchase_order_product: {
              product: resRow?.purchase_order_product?.product,
            },
            sortId: parseFloat(`${productId}.${0}`),
            isDummy: true,
          })
          dummyParents.push(productId);
        }
        
        resRow.sortId = parseFloat(`${productId}.${productVariationId}`)
        resRowsSort.push(resRow)
        
        
      })
      
      // Sort by sortId, we want that rows are orderered by main products ids
      let sortedRows = resRowsSort.sort((a, b) => { 
        return parseFloat(a.sortId) - parseFloat(b.sortId)
      })
      
      setRows(sortedRows)
      setLoading(false)
    })
  }
  
  useEffect(() => {
    setFilters(s => ({
      ...s,
      purchaseOrderId: purchaseOrderId,
      purchaseOrderDeliveryId: purchaseOrderDeliveryId,
    }))
  }, [purchaseOrderId, purchaseOrderDeliveryId])
  
  useEffect(() => {
    getPurchaseOrderProductDeliveries();
  }, [filters])
  
  const headers = [
    { label: "", key: "actions"},
    { label: t("ERP ID"), key: "id", visible: true, sortingKey: "erp_id_sort", sortable: true, sortingType: "float"},
    { label: t("MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: t("Nimi"), key: "name"},
    { label: t("delivered_products_list_code","Tuotenumero"), key: "product_code"},
    { label: t("delivered_products_list_product_supplier_code","Toimittajan tuo.nro"), key: "product_supplier_code", visible: true},
    { label: t("Kirjattu määrä"), key: "amount"},
  ];
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    const sortingType = sorting?.sortingType;
    const sortingKey = sorting?.sortingKey;
    const sortingReverse = sorting?.sortingReverse; // (true/false)
    
    let rowsDict = {};
    
    rows && rows.map((row, i) => {
      
      const isVariation = row?.purchase_order_product?.product_variation ? true : false
      
      const product = row?.purchase_order_product?.product
      const productId = product?.id
      const productVariation = row?.purchase_order_product?.product_variation
      const productVariationId = isVariation ? productVariation?.id : 0
      
      const getSupplierCodeName = (id) => {
        if (isVariation) {
          return productVariation?.product_supplier_codes && productVariation?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
        else {
          return product?.product_supplier_codes && product?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
      }
      
      const variationSortIndex = productVariation?.sort ? productVariation?.sort : 0;
      
      let rowObj = {
        // product_variation
        // id: row?.id,
        id: `${productId}-${productVariationId ? productVariationId : "0"}`,
        erp_id_sort: parseFloat(`${productId}.${variationSortIndex}`),
        mcf_product_id: product?.id_mcf,
        mcf_variation_id: isVariation ? productVariation?.id_mcf : "",
        name: (
          <>
            <Typography bold>{row?.purchase_order_product?.product?.name}</Typography>
            { row?.purchase_order_product?.product_variation && (
              <Typography>{ row?.purchase_order_product?.product_variation?.name }</Typography>
            )}
          </>
        ),
        product_code: isVariation ? productVariation?.product_code : product?.product_code,
        product_supplier_code: (
          <Typography className="sk_code_field">
            {getSupplierCodeName(row?.purchase_order_product?.product_supplier_code_id)}
          </Typography>
        ),
        amount: (
          <>
            <Typography>{row?.amount}</Typography>
          </>
        ),
        actions: (
          <>
          {row?.isDummy ? "" : (
            <IconButton size="lg" onClick={() => handleOpenModalPurchaseOrderDeliveredProduct(row?.id)} iconName="pen" />
          )}
          </>
        ),
        test: JSON.stringify(row),
      }
      
      // Put the value here that we want to sort by
      if (sortingKey) {
        rowObj["sortValue"] = rowObj[sortingKey]
      }
      if (productVariationId > 0) {
          // rowObj["sortValue"] = row?.product_variation?.sort;
          rowObj["sortFixed"] = row?.product_variation?.sort;
      }
      
      // Lets make hierarcy structure
      
      const getSortValue = (a, b) => {
        // Sort by main product always when sorting one of these columns
        if (["status_sort","name_sort","erp_id_sort","mcf_product_id","brand_id_sort"].includes(sortingKey)) {
          return a
        }
        else {
          // Put the value from child into the parent if bigger or smaller than current parent value (depending on asc/desc)
          let result = 0;
          if (sortingType === "string") {
            result = a.localeCompare(b)
          }
          else if (sortingType === "float") {
            result = parseFloat(a) - parseFloat(b)
          }
          else {
            result = parseInt(a) - parseInt(b)
          }
          
          if (result < 0) {
            return b
          }
          else {
            return a
          }
        }
      }
      
      const mainProductKey = `p${productId}v0`;
      const fullProductKey = `p${productId}v${productVariationId}`;
      
      // Product / Päätuote
      if (productVariationId === 0) {
        rowsDict[mainProductKey] = {
          ...rowObj,
          children: [],
        }
      }
      else { // Variation
        if (rowsDict.hasOwnProperty(mainProductKey)) {
          rowsDict[mainProductKey] = {
            ...rowsDict[mainProductKey],
            children: [
              ...rowsDict[mainProductKey].children,
              rowObj
            ],
            sortValue: getSortValue(rowsDict[mainProductKey].sortValue, rowObj.sortValue),
          }
        }
        else {
          console.log("ERROR WRONG ROW ORDER, PARENT PRODUCT MUST COME FIRST IN HERE")
        }
      }
      
    }) // END rows map
    
    // Make list of parent products, to do sorting with them
    let sortedParentRows = []
    for (const [key, value] of Object.entries(rowsDict)) {
      sortedParentRows.push(rowsDict[key])
    }
    
    // Client sorting
    
    const sortRows = (_rows) => {
      return _rows.sort((a, b) => {
        try {
          if (sorting?.sortingType === "string") {
            return a["sortValue"].localeCompare(b["sortValue"])
          }
          else if (sorting?.sortingType === "float") {
            return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
          }
          else {
            return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
          }
        }
        catch {
          return -1
        }
      })
    }
    const sortRowsChildren = (_rows) => {
      return _rows.sort((a, b) => {
          return parseInt(a["sortFixed"]) > parseInt(b["sortFixed"]) ? 1 : -1
      })
    }
    
    sortedParentRows = sortRows(sortedParentRows);
    
    if (sortingReverse) {
      sortedParentRows = sortedParentRows.reverse()
    }
    
    // Add children (variations) to the table rows
    let sortedRows = []
    sortedParentRows.map(row => {
      sortedRows.push(row)
      const children = sortRowsChildren(row.children)
      children.map(rowChild => {
        sortedRows.push(rowChild)
      })
    })
    
    return sortedRows;
    
    
  }, [rows, sorting])
  
  // Modal: PurchaseOrderDeliveredProduct
  
  const [modalPurchaseOrderDeliveredProductOpen, setModalPurchaseOrderDeliveredProductOpen] = useState(false)
  const [modalPurchaseOrderProductDeliveryId, setModalPurchaseOrderProductDeliveryId] = useState()
  
  const togglePurchaseOrderDeliveredProduct = () => {
    setModalPurchaseOrderDeliveredProductOpen(s => !s);
  }
  
  const handleOpenModalPurchaseOrderDeliveredProduct = (id) => {
    setModalPurchaseOrderDeliveredProductOpen(true)
    setModalPurchaseOrderProductDeliveryId(id);
  }
  
  const handleModalPurchaseOrderDeliveredProductResolve = () => {
    setModalPurchaseOrderDeliveredProductOpen(false);
    setModalPurchaseOrderProductDeliveryId(null);
    // getPurchaseOrderProductDeliveries();
    window.location.reload();
  }
  
  const handleModalPurchaseOrderDeliveredProductReject = () => {
    setModalPurchaseOrderDeliveredProductOpen(false);
    setModalPurchaseOrderProductDeliveryId(null);
  }
  
  return (
    <>
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        showPaginationLimitSelect={false}
        pagination={false}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        sortBackend={false}
        sorting={sorting}
        setSorting={setSorting}
      />
      
      <Modal
        title={t("Muokkaa osatoimitustuotetta")}
        isOpen={modalPurchaseOrderDeliveredProductOpen}
        toggleModal={togglePurchaseOrderDeliveredProduct}
      >
        <PurchaseOrderProductDeliveryEdit
          purchaseOrderId={purchaseOrderId}
          purchaseOrderDeliveryId={purchaseOrderDeliveryId}
          purchaseOrderProductDeliveryId={modalPurchaseOrderProductDeliveryId}
          handleResolve={handleModalPurchaseOrderDeliveredProductResolve}
          handleReject={handleModalPurchaseOrderDeliveredProductReject}
        />
      </Modal>
      
    </>
  );
}

export default PurchaseOrderDeliveredProductsList;

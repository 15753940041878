import http from './api'

const url = '/complaints'

export const httpGetComplaints = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

export const httpGetComplaint = (id, params) => {
  return http({
    url: `${url}/${id}/`,
    method: 'GET',
    params,
  })
}

export const httpCreateComplaint = (data) => {
  return http({
    url: `${url}/`,
    method: 'POST',
    data,
  })
}

export const httpUpdateComplaint = (id, data) => {
  return http({
    url: `${url}/${id}/`,
    method: 'PATCH',
    data,
  })
}

export const httpDeleteComplaint = (id) => {
  return http({
    url: `${url}/${id}/`,
    method: 'DELETE',
  })
}

export const uploadComplaintImage = (product_complaint_id, file) => {
  return new Promise((resolve, reject) => {
    if (file == null)
    {
      return resolve(null);
    }
    
    let formData = new FormData();
    formData.append("image",file)
    formData.append("product_complaint",product_complaint_id)
    
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      },
    }
    
    http({
      url: `${url}/${product_complaint_id}/images/`,
      method: 'POST',
      data: formData,
      config,
    }).then((response) => {
      return resolve(true);
    }, (error) => {
      return reject(error);
    });
    
  })
  
}

export const httpConfirmComplaint = (id) => {
  return http({
    url: `${url}/${id}/confirm/`,
    method: 'POST',
  })
}
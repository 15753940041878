import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import {
  httpGetPurchaseOrderShipping,
  httpUpdatePurchaseOrderShipping,
  httpConfirmPurchaseOrderShipping,
} from "services/purchase_orders"

const defaultValues = {
  id: null,
}

const SupplierOrderShippingEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      t,
      history,
      notify
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    httpConfirmPurchaseOrderShipping(values?.id).then(res => {
      handleSubmit()
    }, (error) => {
      if (error?.status === 400) {
        if (error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
          props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
          handleSubmit()
        }
        else {
          props.notify({ title:t("ERROR_400","Bad Request"), message: error?.data?.message, type:"warning" })
        }
      }
      else {
        props.notify({ title:t("ERROR_UNKNOWN","Error"), message: error?.data?.message, type:"danger" })
      }
    })
  }
  
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("shipping_identifier")}
                label={t("Lisää seurantatunnus")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("shipping_transport_company")}
                label={t("Lisää kuljetusyhtiö")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("shipping_details")}
                label={t("Muut huomiot")}
                type="textarea"
              />
            </Col>
          </Row>
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Sulje")}</Button>
        </Col>
        <Col className="mb-3 text-right">

          <Button variant="success" disabled={isSubmitting || Object.keys(errors).length > 0} onClick={() => validateForm().then((errors) => { 
            if(Object.keys(errors).length > 0){
              
            }
            else {
              handleConfirm()
            }
          })}>
            { values?.state < 4 ? t("Siirrä tilaus vaiheeseen matkalla") : t("Tallenna") }
          </Button>
          
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const SupplierOrderShippingEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        shipping_identifier: Yup.string().required(required).nullable(),
        shipping_transport_company: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
      };
      
      if (values?.id) {
        httpUpdatePurchaseOrderShipping(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tuote"), message:t("Tallennettu onnistuneesti"), type:"success" })
          props.handleResolve()
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
    },
    displayName: "BasicForm"
  
})(SupplierOrderShippingEdit)
    
    
const SupplierOrderShippingEditView = ({history, purchaseOrderId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const getPurchaseOrderShipping = (pId) => {
    setLoading(true)
    httpGetPurchaseOrderShipping(pId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > -1) {
      getPurchaseOrderShipping(purchaseOrderId)
    }
  }, [purchaseOrderId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <SupplierOrderShippingEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} {...rest} />
  )
  

}
  

export default SupplierOrderShippingEditView;

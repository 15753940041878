import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Collapse } from 'reactstrap';
import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton, Modal, Pad } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import {
  httpGetComplaints,
} from "services/complaints"
import { getDate } from "services/datetime"
import { useAppContext } from "contexts/AppContext"
import ProductComplaintView from "views/dashboard/ProductComplaintView/ProductComplaintView"
import ProductComplaintEdit from "views/dashboard/ProductComplaintEdit/ProductComplaintEdit"
import TextCutter from "components/Helpers/TextCutter"

const PurchaseOrderNewComplaintsList = ({
  history,
  purchaseOrderId,
  updateIndex=0,
  showEdit=false,
  isSupplier=false,
}) => {
  const { enums } = useAppContext()
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({
    
  })
  
  const getTypeValue = (key) => {
    if (!key) {
      return ""
    }
    const value = enums && enums?.complaints?.product_complaint?.type.filter((item) => {
      return item.key === key
    })[0].value
    return t(value) /* i18next-extract-disable-line */
  }
  
  const [complaints, setComplaints] = useState([])
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > 0) {
      getComplaints()
    }
  }, [purchaseOrderId, updateIndex])
  
  const getComplaints = () => {
    setLoading(true)
    const params = {
      purchase_order: purchaseOrderId,
      serializer: "ProductComplaintListSerializer",
      ordering: "-id",
    }
    httpGetComplaints(params).then(res => {
      setComplaints(res?.data)
    }, error => {
      console.log("httpGetComplaints error", error)
    }).finally(() => {
      setLoading(false)
    })
  }
  
  const complaintHeaders = [
    { label: t("purchase_order_new_complaints_list_header_id","Reklamaatio ERP-ID"), key: "id_complaint"},
    { label: "", key: "actions"},
    { label: t("purchase_order_new_complaints_list_header_mcf_product_id","MCF Päätuotteen ID"), key: "mcf_product_id"},
    { label: t("purchase_order_new_complaints_list_header_mcf_variation_id","MCF variaatio tuotteen ID"), key: "mcf_variation_id"},
    { label: t("purchase_order_new_complaints_list_header_name","Nimi"), key: "name"},
    { label: t("purchase_order_new_complaints_list_header_code","Tuotenumero"), key: "code", visible: !isSupplier},
    { label: t("purchase_order_new_complaints_list_header_barcode","Viivakoodi"), key: "barcode"},
    { label: t("purchase_order_new_complaints_list_header_product_supplier_code","Toimittajakoodi"), key: "product_supplier_code"},
    { label: t("purchase_order_new_complaints_list_header_amount","Tilattava määrä"), key: "amount"},
    { label: t("purchase_order_new_complaints_list_header_type","Syy"), key: "type"},
  ];
  
  const getComplaintTableRows = useCallback(() => {
    return complaints && complaints.map(row => {
      const isVariation = row?.purchase_order_product?.product_variation ? true : false;
      
      let barcode = isVariation ? row?.purchase_order_product?.product_variation?.stock_item?.barcode : row?.purchase_order_product?.product?.stock_item?.barcode
      barcode = barcode && barcode.length > 0 ? barcode : "-";
      
      let product_supplier_code_name = "";
      if (row?.product_supplier_code) {
        product_supplier_code_name = row?.product_supplier_code?.name
      } else if (row?.product_variation_supplier_code) {
        product_supplier_code_name = row?.product_variation_supplier_code?.name
      }
      
      return {
        id_complaint: row?.id,
        mcf_product_id: row?.product?.id_mcf,
        mcf_variation_id: row?.product_variation?.id_mcf,
        name: <>
          <Typography bold>
          {row?.product?.name}
          </Typography>
          { row?.product_variation && (
            <Typography>
            { row?.product_variation?.name }
            </Typography>
          )}
        </>,
        code: (
          <Typography>
            <TextCutter text={ row?.product_variation?.product_code && row?.product_variation?.product_code.length > 0 ? row?.product_variation?.product_code : row?.product?.product_code} />
          </Typography>
        ),
        product_supplier_code: <Typography>{product_supplier_code_name}</Typography>,
        barcode: <Typography>{barcode}</Typography>,
        amount: <Typography>{row?.amount}</Typography>,
        type: <Typography>{getTypeValue(row?.type)}</Typography>,
        actions: !row?.has_variations && (
          <>
            <IconButton size="lg" onClick={() => handleOpenModalComplaint(row?.id)} iconName="eye" badgeColor="default" badgeValue={row?.comments_count > 0 ? row?.comments_count : null} />
            { showEdit ? (<IconButton size="lg" onClick={() => handleOpenModalEditComplaint(row?.id)} iconName="pen" />) : null}
          </>
        ),
      }
    })
  }, [complaints])
  
  // Modal: View Complaint
  
  const [selectedComplaintId, setSelectedComplaintId] = useState()
  const [modalComplaintOpen, setModalComplaintOpen] = useState(false)
  
  const toggleModalComplaint = () => {
    setModalComplaintOpen(s => !s);
  }
  
  const resolveModalComplaint = () => {
    setSelectedComplaintId(null)
    getComplaints();
  }
  const rejectModalComplaint = () => {
    console.log("rejectModalComplaint");
    setSelectedComplaintId(null)
    getComplaints();
  }
  
  const handleOpenModalComplaint = (complaintId) => {
    setSelectedComplaintId(complaintId)
  }

  useEffect(() => {
    if (selectedComplaintId) {
      setModalComplaintOpen(true)
    }
    else {
      setModalComplaintOpen(false)
    }
  }, [selectedComplaintId])
  
  // Edit complaint BEGIN
  
  const [editComplaintProductId, setEditComplaintProductId] = useState()
  const [modalEditComplaintOpen, setEditModalComplaintOpen] = useState(false)
  
  const toggleModalEditComplaint = () => {
    setEditModalComplaintOpen(s => !s);
  }
  
  const resolveModalEditComplaint = () => {
    setEditComplaintProductId(null)
    getComplaints();
  }
  const rejectModalEditComplaint = () => {
    setEditComplaintProductId(null)
  }
  
  const handleOpenModalEditComplaint = (productComplaintId) => {
    setEditComplaintProductId(productComplaintId)
  }
  
  useEffect(() => {
    if (editComplaintProductId) {
      setEditModalComplaintOpen(true)
    }
    else {
      setEditModalComplaintOpen(false)
    }
  }, [editComplaintProductId])
  
  // Edit complaint END
  
  if (!complaints || complaints.length === 0) {
    return (
      <></>
    )
  }
  
  return (
    <>
      { complaints && complaints.length > 0 && (
        <Row className="d-flex justify-content-between mb-2">
          <Col className="col-auto">
            <Typography variant="h2">{t("puchase_order_new_complaints_list_title", "Toimituksessa lisätyt reklamaatiot")}</Typography>
            <Typography>{t("puchase_order_new_complaints_list_subtitle", "Käsitellään luomalla uusi tilaus")}</Typography>
          </Col>
          <Col className="col-auto">
            &nbsp;
          </Col>
        </Row>
      )}
      
       <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={complaintHeaders}
        rows={getComplaintTableRows()}
        loading={false}
        setFilters={setFilters}
        showPaginationLimitSelect={false}
      />
      
      <Modal
        title={" "}
        isOpen={modalComplaintOpen}
        closeModal={rejectModalComplaint}
      >
        <ProductComplaintView
          productComplaintId={selectedComplaintId}
          modalOpen={modalComplaintOpen}
          handleResolve={resolveModalComplaint}
          handleReject={rejectModalComplaint}
        />
      </Modal>
      
      <Modal
        title={" "}
        isOpen={modalEditComplaintOpen}
        closeModal={rejectModalEditComplaint}
      >
        <ProductComplaintEdit
          productComplaintId={editComplaintProductId}
          modalOpen={modalEditComplaintOpen}
          handleResolve={resolveModalEditComplaint}
          handleReject={rejectModalEditComplaint}
        />
      </Modal>
      
    </>
  );
}

export default PurchaseOrderNewComplaintsList;

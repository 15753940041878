import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetProduct, httpGetProductVariations } from "services/products"
// import history from "services/history"

const ProductVariationsList = ({history, productId}) => {
  
  const { t } = useTranslation();
  const [product, setProduct] = useState()
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "sort",
  })
  
  const getProduct = (id) => {
    httpGetProduct(id).then(res => {
      setProduct(res?.data)
    })
  }
  
  useEffect(() => {
    if (productId && productId > 0) {
      getProduct(productId)
    }
  }, [productId])
  
  const getProductVariations = () => {
    setLoading(true)
    httpGetProductVariations(productId, filters).then(res => {
      setRows(res?.data)
    }).finally(() => {
      setLoading(false)
    })
  }
  
  useEffect(() => {
    setFilters(s => ({
      ...s,
      productId: productId,
    }))
  }, [productId])
  
  useEffect(() => {
    getProductVariations();
  }, [filters])
  
  const headers = [
    { label: t("product_variations_list_sort","Järjestys"), key: "sort", sortable:false, visible: true},
    { label: t("product_variations_list_erp_id","ERP-id"), key: "id", sortable:false, visible: true},
    { label: t("product_variations_list_mcf_id","MCF-id"), key: "id_mcf", sortable:false, visible: true},
    { label: t("product_variations_list_name","Nimi"), key: "name"},
    { label: t("product_variations_list_product_code","Tuotenumero"), key: "product_code", visible: true},
    { label: t("product_variations_list_stock_item_balance","Varastossa vapaana"), key: "stock_item_balance", sortable:false, visible: true},
    { label: t("product_variations_list_stock_item_balance_limit","Hälytysraja"), key: "stock_item_balance_limit", sortable:false, visible: true},
    
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/products/edit/${productId}/variation/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/products/edit/${productId}/variation/${id}/`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      // If no stock_item or stock_item.enabled is false (limitless/rajoittamaton) show empty ""
      const stock_item_balance = row?.stock_item && row?.stock_item?.enabled === true ? row?.stock_item?.balance : ""
      
      return {
        sort: row?.sort,
        id: row?.id,
        name: (
          <>
            <Typography bold>
              <Link to={`/dashboard/products/edit/${productId}/variation/${row?.id}/`}>
              {row?.name ? row?.name : t("noname")}
              </Link>
            </Typography>
          </>
        ),
        product_code: (
          <>
            <Typography>
            {row?.product_code}
            </Typography>
          </>
        ),
        stock_item_balance: <Typography>{stock_item_balance}</Typography>,
        stock_item_balance_limit: <Typography>{row?.stock_item?.balance_limit}</Typography>,
        id_mcf: <Typography>{row?.id_mcf}</Typography>,
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            {/*
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="exclamation" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
            */}
          </>
        ),
      }
    })
  }, [rows])
  
  const getParentProductHasStockItem = () => {
    if (product?.stock_item && product?.stock_item.product_fk) {
      return true;
    }
    return false;
  }
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Tuotevariaatiot")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2"></div>
          </div>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
          <Button disabled={getParentProductHasStockItem()} color="success" onClick={handleNew}>{t("Lisää tuotevariaatio")}</Button>
          { getParentProductHasStockItem() && (
            <Typography variant="small"><i className="fa fa-info text-info"></i> {t("Ei voi luoda variaatioita. Päätuote on varastotuote.")}</Typography>
          )}
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
      />
    </>
  );
}

export default ProductVariationsList;

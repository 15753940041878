import React, { useEffect, useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import YupPassword from 'yup-password';
import queryString from 'query-string'
import { useTranslation } from 'react-i18next';

// core components
import { Input } from "stories/forms";
import { Button, Typography, Spinner } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { useAuthContext } from "contexts/AuthContext";
import { httpActivatePin } from "services/auth";
import { httpChangePassword } from "services/users";

YupPassword(Yup);

const defaultValues = {
  id: null,
}


const SetPin = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('invitation_activate','Tilin aktivointi')}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t('Email','Sähköposti')}
                      type="email"
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("pin_code")}
                      label={t('invitation_pin_code',"PIN code")}
                      type="number"
                    />
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t('sk_invitation_activate_account','Aktivoi tili')}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

 const SetPinFormik = withFormik({
    
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const { t } = props;
      const required = t("Kenttä on pakollinen");
      
      const requiredField = () => Yup.string().required(required); 
    
      return Yup.object().shape({
        email: requiredField(),
        pin_code: requiredField(),
      });
    },
    validate: (values, props) => {
      const {t} = props;
      const errors = {};
      
      return errors;
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t, setPinValidated} = props;
      let data = {
        email: values?.email,
        pin_code: values?.pin_code,
      };
      
      httpActivatePin(data).then(res => {
        setSubmitting(false);
        localStorage.setItem("accessToken", res?.data?.access);
        localStorage.setItem("refreshToken", res?.data?.refresh);
        setPinValidated(true);
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(SetPin)

const SetPassword = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('Aseta uusi salasana')}</Typography>
                  <Typography variant="p"></Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("new_password")}
                      label={t('Uusi salasana')}
                      type="password"
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("check_password")}
                      label={t('Salasana uudelleen')}
                      type="password"
                    />
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t('Tallenna')}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

 const SetPasswordFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const { t } = props;
      const required = t("Kenttä on pakollinen");
      
      const requiredField = () => Yup.string().required(required); 
      const passwordField = () => requiredField()
          .min(
            8,
            'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
          )
          .minLowercase(1, 'password must contain at least 1 lower case letter')
          .minUppercase(1, 'password must contain at least 1 upper case letter')
          .minNumbers(1, 'password must contain at least 1 number')
          .minSymbols(1, 'password must contain at least 1 special character')
          .matches(/^[a-zA-Z0-9!"#¤%&\/()=?@£${[\]}\+]+$/, 'Password can only contain latin characters a-z, A-Z, 0-9, !"#¤%&/()=?@£${[]}\+')
      
      return Yup.object().shape({
        new_password: passwordField(),
        check_password: passwordField(),
      });
    },
    validate: (values, props) => {
      const {t} = props;
      const errors = {};
      
      if (values?.new_password && values?.check_password && values?.new_password != values?.check_password) {
        errors.new_password = t("Passwords does not match")
      }
     
       return errors;
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        data: {
          new_password: values?.new_password,
          check_password: values?.check_password,
        }
      };
      
      httpChangePassword("me", data).then(res => {
        setSubmitting(false);
        // localStorage.removeItem("refreshToken");
        // localStorage.removeItem("accessToken");
        props.notify({ title:t("Salasana"), message:t("Päivitetty onnistuneesti")})
        // window.location.href = "/auth/login"
        props.history.push("/dashboard/frontpage")
        
        
      }, error => {
        setSubmitting(false);
        if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(SetPassword)
    
    
const InvitationView = ({location, match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [pinCode, setPinCode] = useState();
  const [email, setEmail] = useState();
  const [preSetValues, setPreSetValues] = useState();
  const [pinValidated, setPinValidated] = useState(false);
  
  useEffect(() => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
    
    const parsed = queryString.parse(location.search);
    
    if (parsed?.pin_code && parsed?.email) {
      const data = {
        email: parsed?.email,
        pin_code: parsed?.pin_code,
      };
      httpActivatePin(data).then(res => {
        localStorage.setItem("accessToken", res?.data?.access);
        localStorage.setItem("refreshToken", res?.data?.refresh);
        setPinValidated(true);
      }, error => {
        alert(JSON.stringify(error));
      })
    }
    
    /*
    setPreSetValues({
      pin_code: parsed?.pin_code,
      email: parsed?.email
    })
    */
    
  }, [location])
  
  /*
  useEffect(() => {
    
    const parsed = queryString.parse(location.search);
    if (parsed?.token) {
      localStorage.removeItem("refreshToken");
      localStorage.setItem("accessToken", parsed?.token);
    }
  },[location])
  */
  
  {/*
      <SetPinFormik preSetValues={preSetValues} setPinValidated={setPinValidated} history={history} t={t} notify={notify} />
    */}
  
  return (
    <>
    { pinValidated ? (
      <SetPasswordFormik history={history} t={t} notify={notify} />
    ) : (
      <Spinner />
    )}
    </>
  )
}

export default InvitationView;

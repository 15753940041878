import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Progress } from "reactstrap";
import { generatePath } from "react-router";
import { Row, Col } from "stories/layout";
import {
  Card,
  Typography,
  Button,
  Link,
  IconButton,
  Badge,
  Modal,
  Breadcrumb,
  BreadcrumbItem,
  Popover,
  Pad,
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetPurchaseOrders, httpGetPurchaseOrdersStatuses } from "services/purchase_orders"
import { getDateAndTime } from "services/datetime"
import { round } from "services/helpers"
import PurchaseOrderPaymentEdit from "views/dashboard/PurchaseOrderPaymentEdit/PurchaseOrderPaymentEdit"
import SupplierOrderShippingEdit from "views/dashboard/SupplierOrderShippingEdit/SupplierOrderShippingEdit"
import { useAppContext } from "contexts/AppContext"

const SupplierPurchaseOrders = ({match, history}) => {
  
  const { myUser } = useAuthContext()
  const [statuses, setStatuses] = useState({})
  const { enums, getTabNameByKeySupplier, getTabIdByKeySupplier } = useAppContext()
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-id",
  })
  
  const getPurchaseOrdersStatuses = () => {
    httpGetPurchaseOrdersStatuses().then(res => {
      setStatuses(res?.data);
    })
  }
  
  const getPurchaseOrders = () => {
    setLoading(true);
    getPurchaseOrdersStatuses();
    
    httpGetPurchaseOrders(filters).then(res => {
      setRows(res?.data);
    }).finally(() => {
      setLoading(false);
    });
  }
  
  const getPurchaseOrderStateName = (stateKey) => {
    const value = enums && enums?.purchase_orders?.purchase_order?.state.filter((item) => {
        return item.key === stateKey
      })[0].value
    return t(value) /* i18next-extract-disable-line */
  }
  
  useEffect(() => {
    const tab = match?.params?.tab;
    if (tab) {
      setFilters(s => ({
        ...s,
        state: getTabIdByKeySupplier(tab),
      }))
    }
  }, [match])
  
  useEffect(() => {
    if (filters?.state) {
      getPurchaseOrders();
    }
  }, [filters])
  
  const getTabName = useCallback(() => {
    const tab = match?.params?.tab;
    return getTabNameByKeySupplier(tab)
  }, [match])
  
  const handleEdit = (id) => {
    history.push(`/dashboard/supplier_sales_orders/edit/${id}/`);
  }
  
  const headers = [
    { label: "ID", key: "id"},
    { label: t("Toimenpiteet"), key: "action_badges"},
    { label: "", key: "actions"},
    { label: t("Tilaus"), key: "order"},
    { label: t("Tuotteet"), key: "products"},
    { label: t("Tapahtumat"), key: "events"},
  ];
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      
      // Unprocessed rows or unread comments
      const unprocessed_product_rows = row?.purchase_order_product_rows_count - row?.products_confirmed_by_supplier_count + row?.products_comments_supplier_unread_count;
      
      return {
        id: row?.id,
        order: (
          <>
            <Typography bold>
              <Link to={`/dashboard/supplier_sales_orders/edit/${row?.id}/`}>
                #{row?.id} / {row?.company?.name}
              </Link>
            </Typography>
            <Typography bold>{row?.creator?.full_name}</Typography>
            <Typography>{t("Viimeksi paikalla")}: <span className="text-success">{t("eilen")}</span></Typography>
          </>
        ),
        products: (
          <>
            <div><Typography bold className="d-inline-block">{t("Tuotenimikkeitä")}:</Typography> <Typography className="d-inline-block">{row?.total_order_count}{t("kpl")}</Typography></div>
            <div><Typography bold className="d-inline-block">{t("Reklamaatioita")}:</Typography> <Typography className="d-inline-block">{row?.products_complaints_amount_total}{t("kpl")}</Typography></div>
            <div><Typography bold className="d-inline-block">{t("Tilauksen arvo")}:</Typography> <Typography className="d-inline-block">{row?.total_order_value}{row?.purchase_order_currency}</Typography></div>
          </>
        ),
        events: (
          <>
            <div><Typography bold className="d-inline-block">{t("Tila")}:</Typography> <Typography className="d-inline-block">{getPurchaseOrderStateName(row?.state)} {row?.state_updated && getDateAndTime(row?.state_updated)}</Typography></div>
            <Link isExternal={true} id={`popover${row?.id}`}>{t("näytä tapahtumat")}</Link>
            <Popover
                placement="top"
                target={`popover${row?.id}`}
            >
              <div><Typography bold className="d-inline-block">{t("Saapunut")}:</Typography> <Typography className="d-inline-block">{getDateAndTime(row?.state_sent)}</Typography></div>
              <div><Typography bold className="d-inline-block">{t("Avattu")}:</Typography> <Typography className="d-inline-block">{row?.read_last_supplier ? getDateAndTime(row?.read_last_supplier) : (<Badge color="danger">{t("Ei avattu")}</Badge>)}</Typography></div>
              { row?.state < 3 && (
                <>
                <div><Typography bold className="d-inline-block">{t("Käsitelty")}:</Typography> <Typography className="d-inline-block">{row?.products_confirmed_by_supplier_count}/{row?.product_variations_count}</Typography></div>
                <div>{row?.product_variations_count > 0 ? round(row?.products_confirmed_by_supplier_count / row?.product_variations_count, 2) * 100 : 0}% <Progress max={row?.product_variations_count} value={row?.products_confirmed_by_supplier_count} color={row?.product_variations_count-row?.products_confirmed_by_supplier_count > 0 ? "danger" : "success"} /></div>
                <div><Typography bold className="d-inline-block">{t("Tavarantoimittaja käsitellyt")}:</Typography> <Typography className="d-inline-block">{row?.confirmed_by_supplier_dt ? getDateAndTime(row?.confirmed_by_supplier_dt) : <Badge color="danger">{t("Odottaa hyväksyntääsi")}</Badge>}</Typography></div>
                <div><Typography bold className="d-inline-block">{t("Tilaaja käsitellyt")}:</Typography> <Typography className="d-inline-block">{row?.confirmed_by_orderer_dt ? getDateAndTime(row?.confirmed_by_orderer_dt) : <Badge color="danger">{t("Odottaa hyväksyntää")}</Badge>}</Typography></div>
                </>
              )}
              { row?.state > 2 && (
                <div><Typography bold className="d-inline-block">{t("Vahvistettu")}:</Typography> <Typography className="d-inline-block">{row?.state_delivery ? getDateAndTime(row?.state_delivery) : "-"}</Typography></div>
              )}
              { row?.state > 2 && (
                <div><Typography bold className="d-inline-block">{t("Maksettu")}:</Typography> <Typography className="d-inline-block">{row?.payment_paid_state === 3 ? getDateAndTime(row?.payment_paid_state_dt) : row?.payment_paid_state === 2 ? (<Badge color="warning">{t("Maksettu osittain")}</Badge>) : (<Badge color="danger">{t("Odottaa maksua")}</Badge>)}</Typography></div>
              )}
              { row?.state > 3 && (
                <div><Typography bold className="d-inline-block">{t("Matkalla")}:</Typography> <Typography className="d-inline-block">{row?.state_delivery ? getDateAndTime(row?.state_delivery) : (<Badge color="danger">{t("Odottaa tuotteiden lähetystä")}</Badge>)}</Typography></div>
              )}
            </Popover>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" badgeColor="danger" badgeValue={!row?.confirmed_by_supplier && "!"} />
            { row?.state > 2 && (
            <IconButton disabled={row?.confirmed_by_orderer === false || row?.confirmed_by_supplier === false} size="lg" onClick={() => handleOpenModalPayment(row?.id)} iconName="shopping-cart" badgeColor="danger" badgeValue={row?.state > 2 && !row?.state_paid && "!"} />
            )}
            { row?.state > 2 && (
            <IconButton disabled={row?.confirmed_by_orderer === false || row?.confirmed_by_supplier === false} size="lg" onClick={() => handleOpenModalShipping(row?.id)} iconName="truck" badgeColor="danger" badgeValue={row?.state > 3 && !row?.state_delivery && "!"} />
            )}
          </>
        ),
        action_badges: (
        <>
          { row.state === 1 && (
            <>
              <Pad icon="fa-exclamation-circle" color="warning">{t("Luonnos")}</Pad>
              <br />
            </>
          )}
          
          { row.state > 1 && (
          <>
            { !row?.confirmed_by_supplier || unprocessed_product_rows > 0 ? (
              <>
                <Pad 
                  onClick={() => handleEdit(row?.id)}
                  icon="fa-exclamation-circle" 
                  color="danger" 
                  badgeColor="danger" 
                  badgeValue={unprocessed_product_rows > 0 ? unprocessed_product_rows : null}
                  >
                    { unprocessed_product_rows > 0 ? t("Käsittelemättömiä tuoterivejä") : t("Käsittele tilaus") }
                  </Pad>
                <br />
              </>
            ) : (
              <>
              { !row?.confirmed_by_orderer && (
                <>
                  <Pad 
                    onClick={() => handleEdit(row?.id)}
                    icon="fa-exclamation-circle" 
                    color="warning"
                  >
                    {t("Odottaa")}
                    <br />
                    {t("hyväksyntää")}
                  </Pad>
                  <br />
                </>
              )}
              </>
            )}
            
            { row.state > 2 && (
            <>
              { row?.confirmed_by_orderer && row?.confirmed_by_supplier && (
                <>
                { row.state < 4 ? (
                  <>
                    <Pad 
                      icon="fa-exclamation-circle"
                      color="warning"
                      onClick={() => handleOpenModalShipping(row?.id)}
                    >
                        {t("Odottaa")}<br />{t("seurantatietoja")}
                    </Pad>
                    <br />
                  </>
                ) : (
                  <>
                  { row?.deliveries_count === 0 && (
                    <>
                      <Pad 
                        onClick={() => handleEdit(row?.id)}
                        icon="fa-exclamation-circle" 
                        color="warning"
                      >
                        {t("Matkalla")}
                      </Pad>
                      <br />
                    </>
                  )}
                  </>
                )}
                { row?.payment_paid_state < 3 && (
                  <>
                    <Pad 
                      icon="fa-exclamation-circle"
                      color="warning"
                      onClick={() => handleOpenModalPayment(row?.id)}
                    >
                        {t("Odottaa maksua")}
                    </Pad>
                    <br />
                  </>
                )}
                </>
              )}
            </>
            )}
            
          </>
          )}
        </>
        ),
      }
    })
  }, [rows])
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  // Modal: Payment
  
  const [modalOrderPaymentOpen, setModalOrderPaymentOpen] = useState(false)
  const [modalOrderPaymentId, setModalOrderPaymentId] = useState()
  
  const toggleModalOrderPayment = () => {
    setModalOrderPaymentOpen(s => !s);
  }
  
  const handleOpenModalPayment = (id) => {
    setModalOrderPaymentOpen(true)
    setModalOrderPaymentId(id);
  }
  
  const handleOrderPaymentResolve = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
    getPurchaseOrders();
  }
  
  const handleOrderPaymentReject = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
  }
  
  // Modal: Shipping
  
  const [modalOrderShippingOpen, setModalOrderShippingOpen] = useState(false)
  const [modalOrderShippingId, setModalOrderShippingId] = useState()
  
  const toggleModalOrderShipping = () => {
    setModalOrderShippingOpen(s => !s);
  }
  
  const handleOpenModalShipping = (id) => {
    setModalOrderShippingOpen(true)
    setModalOrderShippingId(id);
  }
  
  const handleOrderShippingResolve = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
    getPurchaseOrders();
  }
  
  const handleOrderShippingReject = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
  }
  
  
  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          <a aria-current="page" className="active">
          {t("supplier_sales_orders_breadcrumb_sales_orders","Myyntitilaukset")}
          </a>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <a aria-current="page" className="active">
            {getTabName()}
          </a>
        </BreadcrumbItem>
      </Breadcrumb>
      
      <PageHeader title={t("supplier_sales_orders_page_header","Myyntitilaukset")}></PageHeader>
      
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "received" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "received"
            })}
          ><i className="fas fa-briefcase"></i> {t("Saapuneet")} ({statuses?.status_counts?.state_2})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "inprogress" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "inprogress"
            })}
          ><i className="far fa-calendar-check"></i> {t("Käsittelyssä")} ({statuses?.status_counts?.state_3})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "ondelivery" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "ondelivery"
            })}
          ><i className="fas fa-truck"></i> {t("Matkalla")} ({statuses?.status_counts?.state_4})</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "completed" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              tab: "completed"
            })}
          ><i className="fas fa-shopping-basket"></i> {t("Valmiit")} ({statuses?.status_counts?.state_5})</Link>
        </li>

      </ul>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
      />
      
      <Modal
        title={t("Maksutiedot")}
        isOpen={modalOrderPaymentOpen}
        toggleModal={toggleModalOrderPayment}
      >
        <PurchaseOrderPaymentEdit
          purchaseOrderId={modalOrderPaymentId}
          handleResolve={handleOrderPaymentResolve}
          handleReject={handleOrderPaymentReject}
        />
      </Modal>
      
      <Modal
        title={t("Kuljetustiedot")}
        isOpen={modalOrderShippingOpen}
        toggleModal={toggleModalOrderShipping}
      >
        <SupplierOrderShippingEdit
          purchaseOrderId={modalOrderShippingId}
          handleResolve={handleOrderShippingResolve}
          handleReject={handleOrderShippingReject}
        />
      </Modal>
      
      
      
    </>
  );
}

export default SupplierPurchaseOrders;

import React, {useEffect, useState, useCallback} from "react";
import moment from "moment";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';

import { useAppContext } from "contexts/AppContext"
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";
import { useNotification } from "stories/components/Notification"
import { Container, FormContainer, Row, Col, Card, CardBody } from "stories/layout";
import { Dropdown } from "stories/forms";
import { Typography, Button, Link, IconButton, Modal, Spinner, Breadcrumb, BreadcrumbItem, Badge, Pad } from "stories/components";
import { Input } from "stories/forms";
import { useAuthContext } from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import helmets from "assets/images/helmets.png"
import { 
  httpGetPurchaseOrder, 
  httpCreatePurchaseOrder, 
  httpUpdatePurchaseOrder, 
  httpGetPurchaseOrderProducts, 
  httpPublishPurchaseOrder, 
  httpConfirmPurchaseOrder,
  httpConfirmPurchaseOrderBoth,
  httpArchivePurchaseOrder,
  httpReturnEditPurchaseOrder,
} from "services/purchase_orders"

import PurchaseOrderProductEdit from "views/dashboard/PurchaseOrderProductEdit/PurchaseOrderProductEdit"
import PurchaseOrderDeliveriesList from "views/dashboard/PurchaseOrderDeliveriesList/PurchaseOrderDeliveriesList"
import PurchaseOrderDeliverySide from "views/dashboard/PurchaseOrderDeliverySide/PurchaseOrderDeliverySide"
import ProductComplaintEdit from "views/dashboard/ProductComplaintEdit/ProductComplaintEdit"
import PurchaseOrderComplaintsList from "views/dashboard/PurchaseOrderComplaintsList/PurchaseOrderComplaintsList"
import PurchaseOrderNewComplaintsList from "views/dashboard/PurchaseOrderNewComplaintsList/PurchaseOrderNewComplaintsList"
import PurchaseOrderPaymentEdit from "views/dashboard/PurchaseOrderPaymentEdit/PurchaseOrderPaymentEdit"
import PurchaseOrderProductDelivery from "views/dashboard/PurchaseOrderProductDelivery/PurchaseOrderProductDelivery"
import SupplierOrderShippingEdit from "views/dashboard/SupplierOrderShippingEdit/SupplierOrderShippingEdit"
import config from "services/config"

import TextCutter from "components/Helpers/TextCutter"
import { listToString } from "services/helpers"

const PurchaseOrderReview = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
  const {
      t,
      history,
      orderData,
      setOrderData,
      refresh,
      updateIndex,
      setUpdateIndex,
      deliveryMade,
      setDeliveryMade,
      modalArchiveOpen,
      setModalArchiveOpen,
  } = props;
  
  const { getTabKeyById, getTabNameById } = useAppContext()
  
  const [activePurchaseOrderDeliveryId, setActivePurchaseOrderDeliveryId] = useState()
  const [deliveredProductsData, setDeliveredProductsData] = useState({})
  
  const clearActivePurchaseOrderDelivery = () => {
    setActivePurchaseOrderDeliveryId(null)
    setDeliveredProductsData({})
  }
  
  const [productFilters, setProductFilters] = useState({
    ordering: "id",
  })
  const [sorting, setSorting] = useState({
    sortingKey: "erp_id_sort",
    sortingReverse: false,
    sortingType: "float",
  })
  const [rows, setRows] = useState([])
  
  // Form
   
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
   
  const [filters, setFilters] = useState({
    is_stock_product: true,
  })
  
  const getTabKey = useCallback(() => {
    const state = values?.state;
    return getTabKeyById(state);
  }, [values?.state])
  
  const getTabName = useCallback(() => {
    const state = values?.state;
    return getTabNameById(state);
  }, [values?.state])
  
  const productsInStock = (product) => {
    if (product?.stock_item?.quantity && product?.stock_item?.quantity > 0) {
      return product?.stock_item?.quantity
    }
    return 0
  }
  const variationsInStock = (product) => {
    let max_stock = 0;
    if (product.variations && product.variations.length > 0) {
      product.variations.map(variation => {
        if (productsInStock(variation) > max_stock && variation?.stock_item?.enabled === true) {
          max_stock = productsInStock(variation);
        }
      })
    }
    return max_stock
  }
  
  // Modal: Add Complaint
  const [selectedComplaintProduct, setSelectedComplaintProduct] = useState()
  const [modalComplaintOpen, setModalComplaintOpen] = useState(false)
  
  const toggleModalComplaint = () => {
    setModalComplaintOpen(s => !s);
  }
  
  const resolveModalComplaint = () => {
    setSelectedComplaintProduct(null)
    setUpdateIndex(s => s+1)
  }
  const rejectModalComplaint = () => {
    setSelectedComplaintProduct(null)
  }
  
  const handleOpenModalComplaint = (productId, productVariationId, name, productSupplierCodeId) => {
    setSelectedComplaintProduct({
      name: name,
      productId:productId,
      productVariationId:productVariationId,
      productSupplierCodeId: productSupplierCodeId,
    })
  }

  useEffect(() => {
    if (selectedComplaintProduct) {
      setModalComplaintOpen(true)
    }
    else {
      setModalComplaintOpen(false)
    }
  }, [selectedComplaintProduct])
  
  // Purchase order products
  
  useEffect(() => {
    if (!values?.id) {
      setOrderData({})
    }
  }, [values?.supplier])
  
  const updateOrderProduct = (productId, productVariationId, key, value) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = orderData[orderProductKey];
    if (!obj) {
      obj = {}
    }
    
    obj = {
      ...obj,
      product: productId,
      product_variation: productVariationId,
      [key]:value
    }
    
    setOrderData(s => ({
      ...s,
      [orderProductKey]: obj
    }))
    
  }
  
  const handleOrderProductChange = ({target}, productId, productVariationId) => {
    updateOrderProduct(productId, productVariationId, target.name, target.value)
  }
  
  const getOrderProductValue = (field, productId, productVariationId) => {
    const orderProductKey = `p${productId}v${productVariationId ? productVariationId : "0"}`;
    let obj = orderData[orderProductKey];
    if (obj) {
      return obj[field];
    }
    return 0
  }
  
  // PurchaseOrder Products
  
  useEffect(() => {
    getPurchaseOrderProducts()
  }, [values, productFilters])
  
  const getPurchaseOrderProducts = () => {
    let params = {
      // amount__gte: values?.state > 1 ? 1 : undefined, // we needed to filter with total_count not amount
      ...productFilters,
    }
    
    if (values?.id) {
      setLoading(true)
      httpGetPurchaseOrderProducts(values?.id, params).then(res => {
        
        let resRows = res?.data;
        let resRowsSort = []
        let dummyParents = []
        
        // Give each row own sort id
        resRows.map(resRow => {
          const productId = resRow.product.id;
          const productVariationId = resRow.product_variation ? resRow.product_variation.id : 0
          resRow.sortId = parseFloat(`${productId}.${productVariationId}`)
          resRowsSort.push(resRow)
          
          // Create dummy parent when children
          if (productVariationId > 0 && dummyParents.includes(productId) === false) {
            resRowsSort.push({
              product: resRow.product,
              sortId: parseFloat(`${productId}.${0}`),
              isDummy: true,
            })
            dummyParents.push(productId);
          }
        })
        
        // Sort by sortId, we want that rows are orderered by main products ids
        let sortedRows = resRowsSort.sort((a, b) => { 
          return parseFloat(a.sortId) - parseFloat(b.sortId)
        })
        
        setRows(sortedRows)
        
        let _delivery = {}
        res?.data && res.data.map(pupr => {
          let suggestedAmount = pupr?.total_count - pupr?.delivered_count;
          if (suggestedAmount <= 0) {
            suggestedAmount = 0;
          }
          _delivery[pupr.id] = {
            purchase_order_product: pupr?.id,
            amount: suggestedAmount,
          }
        })
        
        setDeliveredProductsData(_delivery)
      }).finally(() => {
        setLoading(false)
      })
    }
  }
  
  // Table: Full order (Kokonaistilaus)
  const [loading, setLoading] = useState(false)
  
  const [headers, setHeaders] = useState([
    // { label: t("purchase_order_published_sortvalue","sortValue"), key: "sortValue"},
    // values?.state < 4
    { label: t("purchase_order_published_state","Tila"), key: "actions_pads", visible: true, sortingKey: "actions_pads_sort", sortable: true, sortingType: "int",},
    { label: t("purchase_order_published_actions","Toiminnot"), key: "actions"},
    { label: t("purchase_order_published_erp_id","ERP ID"), key: "erp_product_id", visible: true, sortingKey: "erp_id_sort", sortable: true, sortingType: "float",},
    { label: t("purchase_order_published_mcf_product_id","MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("purchase_order_published_mcf_variation_id","MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: t("purchase_order_published_name","Nimi"), key: "name", sortable: true, sortingKey: "name_sort", sortable: true, sortingType: "string",},
    { label: t("purchase_order_published_code","Tuotenumero"), key: "code"},
    { label: t("purchase_order_published_product_supplier_code","Toimittajan tuo.nro"), key: "product_supplier_code", visible: true, sortingKey: "product_supplier_code_sort", sortable: true, sortingType: "string"},
    { label: t("purchase_order_published_barcode","Viivakoodi"), key: "barcode", visible: true},
    { label: t("purchase_order_published_weight","Paino"), key: "weight", visible: true, sortingKey: "weight_sort", sortable: true, sortingType: "float",},
    { label: t("purchase_order_published_batch","Batch"), key: "batch", sortable: false},
    { label: t("purchase_order_published_total_order_count","Tilattu määrä"), key: "total_order_count", sortingKey: "order_total_count_sort", sortable: true, sortingType: "int",},
    { label: t("purchase_order_published_complaints_count","Reklamaatiot"), key: "complaints_count", sortingKey: "complaints_count_sort", sortable: true, sortingType: "int",},
    { label: t("purchase_order_published_customer_orders_count","Ennakkotilaukset"), key: "customer_orders_count"},
    { label: t("purchase_order_published_delivered","Saapunut"), key: "delivered"},
    { label: t("purchase_order_published_price","Ostohinta tilauksella"), key: "price"},
    { label: t("purchase_order_published_total_value","Tilattavan arvo yhteensä"), key: "order_total_value"},
    { label: t("purchase_order_published_confirmed","Vahvistettu"), key: "confirmed", visible: values?.state < 4},
    
  ]);
  
  const getStockBalanceColor = (stock_item) => {
    if (stock_item) {
      if ((stock_item?.quantity && stock_item?.balance_limit && stock_item?.quantity <= stock_item?.balance_limit) || (stock_item?.quantity == 0)) {
        return "text-danger"
      }
      else {
        return "text-success"
      }
    }
    return ""
  }
  
  const getTableRows = useCallback(() => {
    
    const sortingType = sorting?.sortingType;
    const sortingKey = sorting?.sortingKey;
    const sortingReverse = sorting?.sortingReverse; // (true/false)
    
    let rowsDict = {};
    
    rows && rows.map((row, i) => {
      
      const productId = row?.product?.id
      const productVariationId = row?.product_variation ? row?.product_variation?.id : 0;
      
      const getOrderProductFieldValue = (fieldName) => {
        return getOrderProductValue(fieldName, productId, productVariationId);
      }
      
      const stock_item = row?.product_variation?.stock_item ? row?.product_variation?.stock_item : row?.product?.stock_item
      
      const getSupplierCodeName = (id) => {
        if (row?.product_variation) {
          return row?.product_variation?.product_supplier_codes && row?.product_variation?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
        else {
          return row?.product?.product_supplier_codes && row?.product?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
      }
      
      // Status / Action button state
      let row_status_key = 0;
      if (row?.confirmed_by_orderer === false) {
        row_status_key = 1;
      }
      else if (row?.confirmed_by_supplier === false) {
        row_status_key = 2;
      }
      else {
        row_status_key = 3;
      }
      
      // Weight
      let weight = "-";
      let weight_sort = -1;
      if (row?.product_variation && row?.product_variation?.weight && row?.product_variation?.weight > 0) {
        weight = row?.product_variation?.weight;
        weight_sort = weight;
      }
      else if (row?.product?.weight && row?.product?.weight > 0) {
        weight = row?.product?.weight;
        weight_sort = weight;
      }
      
      const barcode = stock_item?.barcode && stock_item?.barcode.length > 0 ? stock_item?.barcode : "-";
      
      const variationSortIndex = row?.product_variation?.sort ? row?.product_variation?.sort : 0;
      
      const unreadComments = row?.comments_orderer_unread_count
      
      let rowObj = {
        barcode: <Typography className="sk_font_text">{barcode}</Typography>,
        customer_orders_count: <Typography>{row?.customer_orders_amount_total}</Typography>,
        erp_product_id: `${productId}-${variationSortIndex}`,
        erp_id_sort: parseFloat(`${productId}.${variationSortIndex}`),
        mcf_product_id: row?.product?.id_mcf,
        mcf_variation_id: row?.product_variation ? row?.product_variation?.id_mcf : "",
        name: <>
          <Typography bold className="sk_name_field sk_font_text">
            <Link onClick={() => handleOpenProduct(row?.id)}>
              {row?.product?.name}
            </Link>
          </Typography>
          { row?.product_variation && (
            <Typography className="sk_font_text">
            {row?.product_variation?.name}
            </Typography>
          )}
        </>,
        name_sort: `${row?.product?.name}_${row?.product_variation?.name}`,
        code: (
          <Typography className="sk_code_field sk_font_text">
            <TextCutter text={ row?.product_variation?.product_code && row?.product_variation?.product_code.length > 0 ? row?.product_variation?.product_code : row?.product?.product_code} />
          </Typography>
        ),
        product_supplier_code: (
          <Typography className="sk_code_field sk_font_text">
            {getSupplierCodeName(row?.product_supplier_code_id)}
          </Typography>
        ),
        product_supplier_code_sort: !row?.isDummy ? getSupplierCodeName(row?.product_supplier_code_id) : "",
        status_sort: row?.status,
        batch: <Typography>{row?.batch}</Typography>,
        amount: <Typography>{row?.amount}</Typography>,
        total_order_count: <Typography>{row?.total_order_count}</Typography>,
        total_order_count_sort: row?.total_order_count,
        complaints_count: <Typography>{row?.complaints_amount_total}</Typography>,
        complaints_count_sort: row?.complaints_amount_total,
        delivered_amount: <Input name="amount" type="number" value={deliveredProductsData[row?.id]?.amount} onChange={(e) => handleProductDelivery(e, row?.id)} />,
        price: <Typography>{row?.price}{values?.purchase_order_currency}</Typography>,
        order_total_value: <Typography>{row?.total_order_value}{values?.purchase_order_currency}</Typography>,
        confirmed: row?.isDummy ? "" : (
          <>
            <Row className="mb-1">
              <Col>
              {t("Ostaja (minä)")}:
              { row?.confirmed_by_orderer ? (
                <Badge color="success">{t("Käsitelty")}</Badge>
              ) : (
                <Badge color="danger">{t("Ei käsitelty")}</Badge>
              )}
              </Col>
            </Row>
            
            <Row>
              <Col>
              {t("Tavarantoimittaja")}:
              { row?.confirmed_by_supplier ? (
                <Badge color="success">{t("Käsitelty")}</Badge>
              ) : (
                <Badge color="danger">{t("Ei käsitelty")}</Badge>
              )}
              </Col>
            </Row>
          </>
        ),
        delivered: row?.isDummy ? "" : (
          <>
            { row?.delivered_count > 0 ? (
              <>
                <Badge color={row?.delivered_count >= row?.total_count ? "success" : "info"}>{t("Saapunut")}</Badge>
                {row?.delivered_count}/{row?.total_count}
              </>
            ) : (
              <Badge color="danger">{t("Saapumatta")}</Badge>
            )}
          </>
        ),
        actions: row?.isDummy ? "" : (
          <>
            <IconButton size="lg" onClick={() => handleOpenProduct(row?.id)} iconName={values?.state < 4 ? "pen" : "eye"} badgeColor="danger" badgeValue={row?.confirmed_by_orderer && row?.comments_orderer_unread_count === 0 ? null : "!"} />
            <IconButton size="lg" onClick={() => handleOpenModalComplaint(row?.product?.id, row?.product_variation?.id, `${row?.product?.name} ${row?.product_variation ? row?.product_variation.name : ''}`, row?.product_supplier_code_id)} iconName="exclamation" />
          </>
        ),
        actions_pads: row?.isDummy ? "" : (
          <>
          { row_status_key === 1 ? (
            <Pad
              badgeColor={unreadComments && unreadComments > 0 ? "danger" : undefined}
              badgeValue={unreadComments && unreadComments > 0 ? unreadComments : undefined}
              color="danger"
              icon="fa-exclamation-circle" 
              onClick={() => handleOpenProduct(row?.id)} 
            >{t("Käsittele tuoterivi")}</Pad>
          ) : null }
          { row_status_key === 2 ? (
            <Pad 
              badgeColor={unreadComments && unreadComments > 0 ? "danger" : undefined}
              badgeValue={unreadComments && unreadComments > 0 ? unreadComments : undefined}
              color="warning"
              icon="fa-exclamation-circle" 
              onClick={() => handleOpenProduct(row?.id)} 
            >{t("Odottaa käsittelyä")}</Pad>
          ) : null }
          { row_status_key === 3 ? (
            <Pad 
              badgeColor={unreadComments && unreadComments > 0 ? "danger" : undefined}
              badgeValue={unreadComments && unreadComments > 0 ? unreadComments : undefined}
              color="success"
              icon="fa-check" 
              onClick={() => handleOpenProduct(row?.id)} 
            >{t("Käsitelty")}</Pad>
          ) : null }
          </>
        ),
        actions_pads_sort: row_status_key,
        weight: (
          <Typography>{weight}</Typography>
        ),
        weight_sort: weight_sort,
      }

    
      // Put the value here that we want to sort by
      if (sortingKey) {
        rowObj["sortValue"] = rowObj[sortingKey]
      }
      if (productVariationId > 0) {
          // rowObj["sortValue"] = row?.product_variation?.sort;
          rowObj["sortFixed"] = row?.product_variation?.sort;
      }
      
      // Lets make hierarcy structure
      
      const getSortValue = (a, b) => {
        // Sort by main product always when sorting one of these columns
        if (["status_sort","name_sort","erp_id_sort","mcf_product_id","brand_id_sort"].includes(sortingKey)) {
          return a
        }
        else {
          // Put the value from child into the parent if bigger or smaller than current parent value (depending on asc/desc)
          let result = 0;
          if (sortingType === "string") {
            result = a.localeCompare(b)
          }
          else if (sortingType === "float") {
            result = parseFloat(a) - parseFloat(b)
          }
          else {
            result = parseInt(a) - parseInt(b)
          }
          
          if (result < 0) {
            return b
          }
          else {
            return a
          }
        }
      }
      
      const mainProductKey = `p${productId}v0`;
      const fullProductKey = `p${productId}v${productVariationId}`;
      
      // Product / Päätuote
      if (productVariationId === 0) {
        rowsDict[mainProductKey] = {
          ...rowObj,
          children: [],
        }
      }
      else { // Variation
        if (rowsDict.hasOwnProperty(mainProductKey)) {
          rowsDict[mainProductKey] = {
            ...rowsDict[mainProductKey],
            children: [
              ...rowsDict[mainProductKey].children,
              rowObj
            ],
            sortValue: getSortValue(rowsDict[mainProductKey].sortValue, rowObj.sortValue),
          }
        }
        else {
          console.log("ERROR WRONG ROW ORDER, PARENT PRODUCT MUST COME FIRST IN HERE")
        }
      }
      
    })
  
    // Make list of parent products, to do sorting with them
    let sortedParentRows = []
    for (const [key, value] of Object.entries(rowsDict)) {
      sortedParentRows.push(rowsDict[key])
    }
    
    // Client sorting
    
    const sortRows = (_rows) => {
      return _rows.sort((a, b) => {
        try {
          if (sorting?.sortingType === "string") {
            return a["sortValue"].localeCompare(b["sortValue"])
          }
          else if (sorting?.sortingType === "float") {
            return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
          }
          else {
            return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
          }
        }
        catch {
          return -1
        }
      })
    }
    const sortRowsChildren = (_rows) => {
      return _rows.sort((a, b) => {
          return parseInt(a["sortFixed"]) > parseInt(b["sortFixed"]) ? 1 : -1
      })
    }
    
    sortedParentRows = sortRows(sortedParentRows);
    
    /*
    sortedParentRows = sortedParentRows.sort((a, b) => {
      try {
        if (sorting?.sortingType === "string") {
          return a["sortValue"].localeCompare(b["sortValue"])
        }
        else if (sorting?.sortingType === "float") {
          return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
        }
        else {
          return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
        }
      }
      catch {
        return -1
      }
    })
    */
    
    if (sortingReverse) {
      sortedParentRows = sortedParentRows.reverse()
    }
    
    // Add children (variations) to the table rows
    let sortedRows = []
    sortedParentRows.map(row => {
      sortedRows.push(row)
      const children = sortRowsChildren(row.children)
      children.map(rowChild => {
        sortedRows.push(rowChild)
      })
    })
    
    return sortedRows;
    
  }, [rows, orderData, sorting, activePurchaseOrderDeliveryId, deliveredProductsData])
  
  // Form
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  const [confirmLoading, setConfirmLoading] = useState(false)
  const handleConfirmPurchaseOrder = () => {
    setConfirmLoading(true)
    httpConfirmPurchaseOrder(values.id).then(res => {
      history.go(0)
    })
  }
  
  const handleConfirmPurchaseOrderBoth = () => {
    setConfirmLoading(true)
    httpConfirmPurchaseOrderBoth(values.id).then(res => {
      history.go(0)
    })
  }
  
  const [archiveLoading, setArchiveLoading] = useState(false)
  const handleArchivePurchaseOrder = () => {
    setArchiveLoading(true)
    httpArchivePurchaseOrder(values.id).then(res => {
      history.go(0)
    })
  }
  
  // END
  
  const handleReturnEdit = () => {
    // Return Purchase Order to edit mode = state 1
    httpReturnEditPurchaseOrder(values.id).then(res => {
      history.push(`/dashboard/purchase_orders/edit/${values?.id}`);
    })
  }
  
  // Modal: Product
  const [modalProductOpen, setModalProductOpen] = useState(false)
  const [purchaseOrderProductId, setPurchaseOrderProductId] = useState()
  
  const toggleModalProduct = () => {
    setModalProductOpen(s => !s);
  }
  
  const handleOpenProduct = (id) => {
    setPurchaseOrderProductId(id)
  }
  
  const handleProductResolve = () => {
    setPurchaseOrderProductId(null)
    props.refresh()
  }
  
  const handleProductReject = () => {
    setPurchaseOrderProductId(null)
    props.refresh()
  }
  
  useEffect(() => {
    if (purchaseOrderProductId) {
      setModalProductOpen(true)
    }
    else {
      setModalProductOpen(false)
    }
  },[purchaseOrderProductId])
  
  useEffect(() => {
    if (modalProductOpen === false) {
      setPurchaseOrderProductId(null)
    }
    
  },[modalProductOpen])
  
  // Modal: Product Delivery
  
  const [modalProductDeliveryOpen, setModalProductDeliveryOpen] = useState(false)
  const [modalProductDeliveryId, setModalProductDeliveryId] = useState()
  
  
  const toggleModalProductDelivery = () => {
    setModalProductDeliveryOpen(s => !s);
  }
  
  const handleOpenModalProductDelivery = (id) => {
    setModalProductDeliveryOpen(true)
    setModalProductDeliveryId(id);
  }
  
  const handleModalProductDeliveryResolve = () => {
    setModalProductDeliveryOpen(false);
    setModalProductDeliveryId(null);
    getPurchaseOrderProducts();
  }
  
  const handleModalProductDeliveryReject = () => {
    setModalProductDeliveryOpen(false);
    setModalProductDeliveryId(null);
  }
  
  // Modal: Purchase Order Delivery
  const [modalPurchaseOrderDeliveryOpen, setModalPurchaseOrderDeliveryOpen] = useState(false)
  
  const toggleModalPurchaseOrderDelivery = () => {
    setModalPurchaseOrderDeliveryOpen(s => !s);
  }
  
  const handleOpenModalPurchaseOrderDelivery = () => {
    setModalPurchaseOrderDeliveryOpen(true)
  }
  
  const handleModalPurchaseOrderDeliveryResolve = (purchaseOrderDeliveryId) => {
    setModalPurchaseOrderDeliveryOpen(false);
    setActivePurchaseOrderDeliveryId(null);
    setDeliveryMade(true)
  }
  
  useEffect(() => {
    if (deliveryMade === true) {
      refresh()
    }
    
  }, [deliveryMade])
  
  useEffect(() => {
    if (deliveryMade === false) {
      return;
    }
    
    if (values?.products_delivered_count >= values?.total_order_count) {
      handleOpenModalArchive()
    }
    setDeliveryMade(false)
    
  }, [values.products_delivered_count])
  
  const handleModalPurchaseOrderDeliveryReject = () => {
    setModalPurchaseOrderDeliveryOpen(false);
  }
  
  const updateProductDelivery = (purchaseOrderProductId, key, value) => {
    let obj = deliveredProductsData[purchaseOrderProductId];
    if (!obj) {
      obj = {}
    }
    
    obj = {
      ...obj,
      [key]:value
    }
    
    setDeliveredProductsData(s => ({
      ...s,
      [purchaseOrderProductId]: obj
    }))
  }
  
  const handleProductDelivery = ({target}, purchaseOrderProductId) => {
    updateProductDelivery(purchaseOrderProductId, target.name, target.value)
  }
  
  // Modal: Archive order
  
  
  const toggleModalArchive = () => {
    setModalArchiveOpen(s => !s);
  }
  
  const handleOpenModalArchive = () => {
    setModalArchiveOpen(true)
  }
  const resolveArchive = () => {
    setModalArchiveOpen(false)
    handleArchivePurchaseOrder();
  }
  const rejectArchive = () => {
    setModalArchiveOpen(false)
  }
  
  // Modal: Payment
  
  const [modalOrderPaymentOpen, setModalOrderPaymentOpen] = useState(false)
  const [modalOrderPaymentId, setModalOrderPaymentId] = useState()
  
  const toggleModalOrderPayment = () => {
    setModalOrderPaymentOpen(s => !s);
  }
  
  const handleOpenModalPayment = () => {
    setModalOrderPaymentOpen(true)
    setModalOrderPaymentId(values?.id);
  }
  
  const handleOrderPaymentResolve = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
    props.refresh()
  }
  
  const handleOrderPaymentReject = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
  }
  
  // Modal: Shipping
  
  const [modalOrderShippingOpen, setModalOrderShippingOpen] = useState(false)
  const [modalOrderShippingId, setModalOrderShippingId] = useState()
  
  const toggleModalOrderShipping = () => {
    setModalOrderShippingOpen(s => !s);
  }
  
  const handleOpenModalShipping = () => {
    setModalOrderShippingOpen(true)
    setModalOrderShippingId(values?.id);
  }
  
  const handleOrderShippingResolve = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
    props.refresh()
  }
  
  const handleOrderShippingReject = () => {
    setModalOrderShippingOpen(false);
    setModalOrderShippingId(null);
  }
  
  const handleGoPurchasePriceCalculator = () => {
    history.push(`/dashboard/purchase_price_calculator/${values?.id}`);
  }
  
  const getActionOptions = () => {
    let options = [];
    
    if (!values?.confirmed_by_supplier) {
      options.push({
          value: 10, 
          label: t("Vahvista ostotilaus tavarantoimittajan puolesta"),
          props: {
            onClick: () => validateForm().then(() => handleConfirmPurchaseOrderBoth())
          },
      });
    }
    if (values?.state < 5) {
      options.push({
          value: 20, 
          label: t("Palauta luonnostilaan"),
          props: {
            onClick: () => handleReturnEdit()
          }
      });
    }
    
    options.push({
        value: 30, 
        label: t("Siirrä tilaus valmiiksi"),
        props: {
          onClick: () => handleOpenModalArchive()
        }
    });
    
    return options;
  }
  
  return (
    <>
      <Row>
        <Col>
          <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
            <BreadcrumbItem>
              <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
                {t("Ostotilaukset")}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/dashboard/purchase_orders/list/${getTabKey()}`}>
                {getTabName()}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              {t("Tilaus")} {values?.id}
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      
      <Row>
        <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          {/*
            Purchase order basic details
          */}
          <Row>
            <Col>
              <PageHeader title={`${t("Ostotilaus")} #${values?.id}`}></PageHeader>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography bold inline>{t("Tavarantoimittaja")}:&nbsp;</Typography>
              <Typography inline>{values?.supplier_name}</Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography bold inline>{t("Tilauksen arvo")}:&nbsp;</Typography>
              <Typography inline>{values?.total_order_value}{values?.purchase_order_currency}</Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography bold inline>{t("Tilaustuotteet")}:&nbsp;</Typography>
              <Typography inline>{values?.total_order_count}kpl</Typography>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Typography bold inline>{t("Tilauksen tila")}:&nbsp;</Typography>
              <Typography inline>{getTabName()}</Typography>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <div style={{maxWidth:"256px"}}>
                <Typography variant="small">{values?.description}</Typography>
              </div>
            </Col>
          </Row>
          { values?.attachment && (
          <Row className="mb-2">
            <Col>
              <i className="fas fa-paperclip"></i> <a href={values?.attachment} target="_blank">{t("Lataa liite")}</a>
            </Col>
          </Row>
          )}
          
          { values?.confirmed_by_supplier === false ? (
          <Row className="mb-2">
            <Col>
              <Pad color="warning"> 
              {t("Odottaa tavarantoimittajan vahvistusta")}
              </Pad>
            </Col>
          </Row>
          ) : null}
        
        </Col>
        <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
          {/*
            Buttons
          */}
          <div className="d-flex flex-row flex-row-reverse flex-wrap">
            
            <div className="p-2">
              <Dropdown
                color="primary"
                items={getActionOptions()}
                label={t("Toiminto -> Tee")}
              />
            </div>
            
            {/*
              <div className="p-2">
                <Button className="text-nowrap" disabled={values?.state === 5} onClick={handleOpenModalArchive}>{ values?.state === 5 ? t("Tilaus on valmis") : t("Siirrä valmiiksi")}</Button>
              </div>
            */}
            
            { values?.confirmed_by_orderer === false ? (
              <div className="p-2">
                <Button className="text-nowrap" loading={isSubmitting || confirmLoading} disabled={values?.confirmed_by_orderer || isSubmitting} onClick={() => validateForm().then(() => handleConfirmPurchaseOrder())} variant="success">
                  {t("Vahvista ostotilaus")}
                  <Badge color="danger" type="floating">!</Badge>
                </Button>
              </div>
            ) : null }
            
            <div className="p-2">
              <Button className="btn-primary text-nowrap" onClick={handleGoPurchasePriceCalculator}>{t("Ostohintalaskuri")}</Button>
            </div>
            { values?.confirmed_by_orderer === true && values?.confirmed_by_supplier === true && (
            <div className="p-2">
              <Button className="btn-primary text-nowrap" onClick={handleOpenModalShipping} badgeValue={!values?.shipping_identifier || values?.shipping_identifier.length === 0 ? "!" : null} badgeColor="danger">{t("Kuljetustiedot")}</Button>
            </div>
            )}
            
            { /* values?.confirmed_by_orderer === true && values?.confirmed_by_supplier === true && ( */ }
            <div className="p-2">
              <Button className="btn-primary text-nowrap" onClick={handleOpenModalPayment} badgeValue={values?.payment_paid_state === 3 ? null : "!"} badgeColor="danger">{t("Maksutiedot")}</Button>
            </div>
            
          </div>
        </Col>
      </Row>
      
      { values?.state > 3 && (
      <div className="p-2">
        <Button className="btn-success" onClick={handleOpenModalPurchaseOrderDelivery}>{t("purchase_order_review_create_delivery","Kirjaa uusi toimitus")}</Button>
      </div>
      )}
      
      {/* Deliveries */}
      
      <Row className="mt-1">
        <Col>
          <PurchaseOrderDeliveriesList
            purchaseOrderId={values?.id}
            activePurchaseOrderDeliveryId={activePurchaseOrderDeliveryId}
            setActivePurchaseOrderDeliveryId={setActivePurchaseOrderDeliveryId}
            clearActivePurchaseOrderDelivery={clearActivePurchaseOrderDelivery}
            updateIndex={updateIndex}
          />
        </Col>
      </Row>
      
      <Row className="mt-1">
        <Col>
        
          <Row className="d-flex justify-content-between mb-2">
            <Col className="col-auto">
              <Typography variant="h2">{t("Kokonaistilaus")}</Typography>
            </Col>
            <Col className="col-auto">
              &nbsp;
            </Col>
          </Row>
          
          { values.supplier && (
            <TableAuto
              color="dark"
              showId={false}
              checkboxes={false}
              headers={headers}
              rows={getTableRows()}
              loading={loading}
              filters={productFilters}
              setFilters={setProductFilters}
              showPaginationLimitSelect={false}
              sortBackend={false}
              sorting={sorting}
              setSorting={setSorting}
            />
          )}
        
        </Col>
      </Row>
      
      {/* Complaints: In the order */}
      
      <Row className="mt-5">
        <Col>
          <PurchaseOrderComplaintsList
            purchaseOrderId={values?.id}
          />
        </Col>
      </Row>
      
      {/* Complaints: New */}
      
      <Row className="mt-5">
        <Col>
          <PurchaseOrderNewComplaintsList
            purchaseOrderId={values?.id}
            updateIndex={updateIndex}
            showEdit={true}
          />
        </Col>
      </Row>
      
      <Row className="mt-5">
        <Col></Col>
      </Row>
      
      <Modal
        title={t("Tavaran saapumiskuittaus")}
        isOpen={modalProductDeliveryOpen}
        toggleModal={toggleModalProductDelivery}
      >
        <PurchaseOrderProductDelivery
          purchaseOrderId={values?.id}
          purchaseOrderDeliveryId={activePurchaseOrderDeliveryId}
          purchaseOrderProductId={modalProductDeliveryId}
          handleResolve={handleModalProductDeliveryResolve}
          handleReject={handleModalProductDeliveryReject}
        />
      </Modal>
      
      <div className={`${modalPurchaseOrderDeliveryOpen ? "" : "d-none"}`} style={{overflow: "auto",zIndex: 20, position: "fixed", left:0, top:0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.5)" }}>
      
        { modalPurchaseOrderDeliveryOpen === true && (
          <OffCanvas
            /* width={window.innerWidth} */
            transitionDuration={300}
            isMenuOpened={modalPurchaseOrderDeliveryOpen}
            position={"left"}
            effect={"overlay"}
          >
            <OffCanvasMenu className={"my-menu-class px-5 py-3"} style={{ width: "auto", position: "absolute", zIndex: 30, fontWeight: "bold", backgroundColor: "#FFF" }}>
              <Typography variant="h2" className="mb-">{t("purchase_order_delivery_title","Kirjaa toimitus")}</Typography>
              <PurchaseOrderDeliverySide
                purchaseOrderId={values?.id}
                purchaseOrderDeliveryId={activePurchaseOrderDeliveryId}
                supplierId={values?.supplier}
                handleResolve={handleModalPurchaseOrderDeliveryResolve}
                handleReject={handleModalPurchaseOrderDeliveryReject}
                setUpdateIndex={setUpdateIndex}
              />
            </OffCanvasMenu>
          </OffCanvas>
        )}
      
      </div>
      
      <Modal
        size="modal-xl"
        title={t("Muokkaa tilaustuotetta")}
        isOpen={modalProductOpen}
        toggleModal={toggleModalProduct}
        closeModal={handleProductReject}
      >
        <PurchaseOrderProductEdit
          purchaseOrderId={values?.id}
          purchaseOrderProductId={purchaseOrderProductId}
          handleResolve={handleProductResolve}
          handleReject={handleProductReject}
          readOnly={values?.state >= 4}
        />
      </Modal>
      
      <Modal
        title={t("Haluatko varmasti siirtää tilauksen valmiiksi?")}
        isOpen={modalArchiveOpen}
        toggleModal={toggleModalArchive}
        closeModal={rejectArchive}
        buttons={[
          <Button onClick={rejectArchive}>{t("Sulje")}</Button>,
          <Button onClick={resolveArchive} variant="success">{t("Vahvista")}</Button>
        ]}
      >
        <Typography>{t("Huomioi, että tilaus merkitään nyt valmiiksi, vaikka tuotteita olisi vielä saapumatta varastoon.")}</Typography>
      </Modal>
      
      <Modal
        title={selectedComplaintProduct?.name}
        isOpen={modalComplaintOpen}
        toggleModal={toggleModalComplaint}
      >
        <ProductComplaintEdit
          productId={selectedComplaintProduct?.productId}
          productVariationId={selectedComplaintProduct?.productVariationId}
          productComplaintId={selectedComplaintProduct?.productComplaintId}
          productSupplierCodeId={!selectedComplaintProduct?.productVariationId ? selectedComplaintProduct?.productSupplierCodeId : null}
          productVariationSupplierCodeId={selectedComplaintProduct?.productVariationId ? selectedComplaintProduct?.productSupplierCodeId : null}
          purchaseOrderId={values?.id}
          supplierId={values?.supplier}
          modalOpen={modalComplaintOpen}
          handleResolve={resolveModalComplaint}
          handleReject={rejectModalComplaint}
        />
      </Modal>
      
      <Modal
        title={t("Maksutiedot")}
        isOpen={modalOrderPaymentOpen}
        toggleModal={toggleModalOrderPayment}
      >
        <PurchaseOrderPaymentEdit
          purchaseOrderId={modalOrderPaymentId}
          handleResolve={handleOrderPaymentResolve}
          handleReject={handleOrderPaymentReject}
        />
      </Modal>
      
      <Modal
        title={t("Kuljetustiedot")}
        isOpen={modalOrderShippingOpen}
        toggleModal={toggleModalOrderShipping}
      >
        <SupplierOrderShippingEdit
          purchaseOrderId={modalOrderShippingId}
          handleResolve={handleOrderShippingResolve}
          handleReject={handleOrderShippingReject}
        />
      </Modal>
      
    </>
  );
}

const defaultValues = {
  id: null,
  supplier: null,
  state: 0,
}

 const PurchaseOrderReviewFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        // supplier: Yup.string().nullable(),
        // email: Yup.string().email('Tarkista sähköpostiosoitteen muoto').required(required),
        // phone: Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required).nullable(),
        // activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        supplier: {id: values?.supplier, name: "aaa"},
        order_data: props.orderData,
      };
      
      if (values?.id) {
        
        httpUpdatePurchaseOrder(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Ostotilaus"), message:t("Tallennettu onnistuneesti")})
          props.history.go(0)
          
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
    },
    displayName: "BasicForm"
  
  })(PurchaseOrderReview)
    

const PurchaseOrderReviewView = ({match, history}) => {
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { t } = useTranslation();
  
  const [orderId, setOrderId] = useState()
  const [orderData, setOrderData] = useState({})
  const [updateIndex, setUpdateIndex] = useState(0)
  const [deliveryMade, setDeliveryMade] = useState(false)
  const [modalArchiveOpen, setModalArchiveOpen] = useState(false)
  
  const getCompanyBadges = useStoreActions((actions) => actions.badges.getCompanyBadges);
 
  useEffect(() => {
    getCompanyBadges()
  }, [])
  
  const getPurchaseOrder = (id) => {
    setLoading(true)
    httpGetPurchaseOrder(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        supplier: res.data?.supplier?.id,
        supplier_name: res.data?.supplier?.name,
      });
      
      // setUpdateIndex(s => s+1)
    }, errors => {
      notify({ title:t("Ostotilaus"), message:t("Ei löytynyt")})
      history.push(`/dashboard/purchase_orders/list/draft`);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  const refresh = () => {
    if (orderId) {
      getPurchaseOrder(orderId)
      getCompanyBadges()
    }
  }
  
  useEffect(() => {
    refresh()
  }, [orderId])
  
  
  useEffect(() => {
    if (match && match?.params?.purchaseOrderId) {
      setOrderId(match?.params?.purchaseOrderId)
    }
  }, [match])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderReviewFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      notify={notify} 
      orderData={orderData} 
      setOrderData={setOrderData} 
      refresh={refresh}
      updateIndex={updateIndex}
      setUpdateIndex={setUpdateIndex}
      deliveryMade={deliveryMade}
      setDeliveryMade={setDeliveryMade}
      modalArchiveOpen={modalArchiveOpen}
      setModalArchiveOpen={setModalArchiveOpen}
      getCompanyBadges={getCompanyBadges}
    />
  )
  

}
  

export default PurchaseOrderReviewView;

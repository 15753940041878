import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetComplaints } from "services/complaints"
import { httpGetCustomerOrders } from "services/customer_orders"

const defaultValues = {
  id: null,
  amount: 0,
  complaints: [],
  customer_orders: [],
}

const PurchaseOrderProductOrder = (props) => {
  
  const {
     dirty,
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      t,
      history,
      purchaseOrderProduct,
      supplierId,
      complaints,
      customerOrders
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  // Complaints
  
  const getComplaintLabel = (complaintObj) => {
    return `${complaintObj?.name}`
  }
  
  const getComplaintDetailed = (id) => {
    let foundObject = complaints && complaints.find(object => object.id === id);
    return foundObject;
  }
  
  const getComplaintOptions = useCallback(() => {
    return complaints && complaints.map(complaint => {
      return {
        value: complaint.id, 
        label: getComplaintLabel(complaint),
      }
    })
  }, [complaints])
  
  const getSelectedComplaints = useCallback(() => {
    return values?.complaints && values?.complaints.map(complaint => {
      const complaintObj = getComplaintDetailed(complaint?.id);
      if (complaintObj) {
        return {
          value: complaint.id, 
          label: getComplaintLabel(complaintObj),
        }
      }
    }).sort((a,b) => {
      return a["label"].localeCompare(b["label"])
    })
  }, [values?.complaints])

  // Customer orders
  
  const getCustomerOrderLabel = (customerOrderObj) => {
    return `${customerOrderObj?.name}`
  }
  
  const getCustomerOrderDetailed = (id) => {
    let foundObject = customerOrders && customerOrders.find(object => object.id === id);
    return foundObject;
  }
  
  const getCustomerOrderOptions = useCallback(() => {
    return customerOrders && customerOrders.map(customerOrder => {
      return {
        value: customerOrder?.id, 
        label: getCustomerOrderLabel(customerOrder),
      }
    })
    .sort((a,b) => {
      return a["label"].localeCompare(b["label"])
    })
  }, [customerOrders])
  
  const getSelectedCustomerOrders = useCallback(() => {
    return values?.customer_orders && values?.customer_orders.map(valuesCustomerOrder => {
      const customerOrder = getCustomerOrderDetailed(valuesCustomerOrder?.id);
      if (customerOrder) {
        return {
          value: customerOrder?.id, 
          label: getCustomerOrderLabel(customerOrder),
        }
      }
    })
  }, [customerOrders, values?.customer_orders])
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  return (
    <>
      <Row>
        
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{purchaseOrderProduct?.name}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              { customerOrders && customerOrders.length > 0 ? (
                <SelectMulti label={t("Asiakastilaustuotteita")} name="customer_orders" values={values} setFieldValue={setFieldValue} options={getCustomerOrderOptions()} defaultValues={getSelectedCustomerOrders()}  />
              ) : (
                <Typography>{t("Asiakastilaustuotteita")}: {t("purchase_order_product_order_no_results_with_supplier_code","Ei tuloksia valitulla toimittajakoodilla")}</Typography>
              )}
              
              
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              { complaints && complaints.length > 0 ? (
                <SelectMulti label={t("Reklamaatioita")} name="complaints" values={values} setFieldValue={setFieldValue} defaultValues={getSelectedComplaints()} options={getComplaintOptions()} />
              ) : (
                <Typography>{t("Reklamaatioita")}: {t("purchase_order_product_order_no_results_with_supplier_code","Ei tuloksia valitulla toimittajakoodilla")}</Typography>
              )}
            </Col>
          </Row>
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Sulje")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting || !dirty} onClick={() => validateForm().then(() => handleSubmit())}>{t("Lisää määrät")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const PurchaseOrderProductOrderFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const data = {
        ...values,
        ...props.getPurchaseOrderProductCounts(values),
      }
      props.updateOrderProduct(props.purchaseOrderProduct.product, props.purchaseOrderProduct.product_variation, data)
      props.handleResolve()
    },
    displayName: "BasicForm"
  
})(PurchaseOrderProductOrder)
    
    
const PurchaseOrderProductOrderView = ({history, purchaseOrderProduct, purchaseOrderId, supplierId, modalOpen, ...rest}) => {
  
  const { t } = useTranslation();
  const [loadingCustomerOrders, setLoadingCustomerOrders] = useState(true);
  const [loadingComplaints, setLoadingComplaints] = useState(true);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const getPurchaseOrderProduct = () => {
    
    setPreSetValues({
      ...defaultValues,
      ...purchaseOrderProduct,
    });
  }
  
  useEffect(() => {
    if (!purchaseOrderProduct) {
      return;
    }
    getPurchaseOrderProduct()
  }, [purchaseOrderId, purchaseOrderProduct, modalOpen])
  
  // Complaints
  
  const [complaints, setComplaints] = useState();
   
   useEffect(() => {
     getComplaints();
   }, [purchaseOrderProduct, supplierId])
   
  const getComplaints = () => {
    if (!purchaseOrderProduct || !supplierId || !purchaseOrderProduct?.product_supplier_code_id) {
      setLoadingComplaints(false);
      return;
    }
    
    const params = {
      product:  purchaseOrderProduct?.product,
      product_variation: purchaseOrderProduct?.product_variation && purchaseOrderProduct.product_variation > 0 ? purchaseOrderProduct.product_variation : undefined,
      addable: purchaseOrderId ? purchaseOrderId : -1,
      supplier: supplierId,
      serializer: "ProductComplaintWritableSerializer",
      product_supplier_code_id: purchaseOrderProduct?.product_supplier_code_id,
    }
    
    httpGetComplaints(params).then(res => {
      setComplaints(res?.data)
    })
    .finally(() => {
      setLoadingComplaints(false);
    })
  }
  
  // Customer orders
  
  const [customerOrders, setCustomerOrders] = useState();
   
   useEffect(() => {
     getCustomerOrders();
   }, [purchaseOrderProduct, supplierId])
   
  const getCustomerOrders = () => {
    if (!purchaseOrderProduct || !supplierId || !purchaseOrderProduct?.product_supplier_code_id) {
      setLoadingCustomerOrders(false);
      return;
    }
    
    const params = {
      product:  purchaseOrderProduct?.product,
      product_variation: purchaseOrderProduct?.product_variation && purchaseOrderProduct.product_variation > 0 ? purchaseOrderProduct.product_variation : undefined,
      addable: purchaseOrderId ? purchaseOrderId : -1,
      product_supplier_code_id: purchaseOrderProduct?.product_supplier_code_id,
    }
    
    httpGetCustomerOrders(params).then(res => {
      setCustomerOrders(res?.data)
    })
    .finally(() => {
      setLoadingCustomerOrders(false);
    })
  }
  
  // Helper
  
  const getPurchaseOrderProductCounts = (purchaseOrderProduct) => {
    const amount = purchaseOrderProduct?.amount ? parseInt(purchaseOrderProduct?.amount) : 0;
    let complaints_count = 0;
    let complaints_list_length = 0;
    let customer_orders_count = 0;
    let customer_orders_list_length = 0;
    
    let complaints_list = []
    let customer_orders_list = []
    
    // Complaints
    if (purchaseOrderProduct?.complaints && purchaseOrderProduct?.complaints.length > 0) {
      purchaseOrderProduct?.complaints.map(complaint => {
        const id = complaint?.id ? complaint?.id : complaint;
        let complaintFound = complaints.reduce((result, item) => {
          if (item.id === id) { result = item }
          return result
        }, null)
        complaints_count += parseInt(complaintFound?.amount);
        complaints_list_length += 1;
        complaints_list.push(complaintFound);
      })
    }
    
    // Customer orders
    if (purchaseOrderProduct?.customer_orders && purchaseOrderProduct?.customer_orders.length > 0) {
      purchaseOrderProduct?.customer_orders.map(customerOrder => {
        const id = customerOrder?.id ? customerOrder?.id : customerOrder;
        let customerOrderFound = customerOrders.reduce((result, item) => {
          if (item.id === id) { result = item }
          return result
        }, null)
        customer_orders_count += parseInt(customerOrderFound?.amount);
        customer_orders_list_length += 1;
        customer_orders_list.push(customerOrderFound);
      })
    }
    
    const total_count = amount + complaints_count + customer_orders_count;
    
    return {
      complaints_amount_total: complaints_count,
      customer_orders_amount_total: customer_orders_count,
      total_count: total_count,
      complaints_list_length: complaints_list_length,
      customer_orders_list_length: customer_orders_list_length,
      complaints: complaints_list,
      customer_orders: customer_orders_list,
    }
  }
  
  if (loadingComplaints || loadingCustomerOrders) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderProductOrderFormik 
      t={t}
      history={history}
      preSetValues={preSetValues}
      notify={notify}
      complaints={complaints}
      customerOrders={customerOrders}
      purchaseOrderProduct={purchaseOrderProduct}
      purchaseOrderId={purchaseOrderId}
      getPurchaseOrderProduct={getPurchaseOrderProduct}
      supplierId={supplierId}
      getPurchaseOrderProductCounts={getPurchaseOrderProductCounts}
      {...rest}
    />
  )
  
}
  

export default PurchaseOrderProductOrderView;

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Modal, Typography, Spinner, Badge } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetPurchaseOrderProductDelivery, httpUpdatePurchaseOrderProductDelivery } from "services/purchase_orders"

const defaultValues = {
  id: null,
}


const PurchaseOrderProductDeliveryEdit = (props) => {
  
  const {
     dirty,
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      t,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Saapuneet tuotteet")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("amount")}
                label={t("Saapunut määrä (kpl)")}
                type="number"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Lisätiedot")}
                type="textarea"
              />
            </Col>
          </Row>
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <>
            <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Tallenna")}</Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </>
        </Col>
      </Row>
        
    </>
  );
}

const PurchaseOrderProductDeliveryEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        // name: Yup.string().nullable(),
        // email: Yup.string().email('Tarkista sähköpostiosoitteen muoto').required(required),
        // phone: Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required).nullable(),
        // activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
      };
      
      if (values?.id) {
        httpUpdatePurchaseOrderProductDelivery(props.purchaseOrderId, props.purchaseOrderDeliveryId, props.purchaseOrderProductDeliveryId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tuote"), message:t("Tallennettu onnistuneesti")})
          props.handleResolve()
        }, error => {
          setSubmitting(false);
          
          if (error?.data?.errorCode === "CANNOT_UPDATE_PURCHASE_ORDER_DELIVERY_PRODUCT") {
            props.notify({ 
              type:"danger", 
              title:t("Tallennus epäonnistui"), 
              message: `${error?.data?.message} ${t("Tarkista ERP kuitilta onko tietoja viety jo MCF.")}`,
            })
          }
          else if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
            props.handleResolve()
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PurchaseOrderProductDeliveryEdit)
    
    
const PurchaseOrderProductDeliveryEditView = ({history, purchaseOrderId, purchaseOrderDeliveryId, purchaseOrderProductDeliveryId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [purchaseOrderProduct, setPurchaseOrderProduct] = useState({})
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const getPurchaseOrderProductDelivery = () => {
    if (!purchaseOrderId || !purchaseOrderDeliveryId || !purchaseOrderProductDeliveryId || modalOpen === false) {
      return;
    }
    setLoading(true)
    httpGetPurchaseOrderProductDelivery(purchaseOrderId, purchaseOrderDeliveryId, purchaseOrderProductDeliveryId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getPurchaseOrderProductDelivery()
  }, [purchaseOrderId, purchaseOrderDeliveryId, purchaseOrderProductDeliveryId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderProductDeliveryEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} purchaseOrderDeliveryId={purchaseOrderDeliveryId} purchaseOrderProductDeliveryId={purchaseOrderProductDeliveryId} {...rest} />
  )
  
}
  

export default PurchaseOrderProductDeliveryEditView;

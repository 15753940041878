import Frontpage from "views/dashboard/frontpage/Frontpage";
import Help from "views/dashboard/Help/Help";
import PurchaseOrders from "views/dashboard/purchaseOrders/PurchaseOrders";
import PurchaseOrderEdit from "views/dashboard/purchaseOrderEdit/PurchaseOrderEdit";
import PurchaseOrderReview from "views/dashboard/PurchaseOrderReview/PurchaseOrderReview";
import ProductsList from "views/dashboard/ProductsList/ProductsList";
import ProductEditView from "views/dashboard/ProductEditView/ProductEditView";
import ProductEdit from "views/dashboard/ProductEdit/ProductEdit";
import BrandsList from "views/dashboard/BrandsList/BrandsList";
import CategoriesList from "views/dashboard/CategoriesList/CategoriesList";
import CategoryEdit from "views/dashboard/CategoryEdit/CategoryEdit";
import MyAccount from "views/dashboard/MyAccount/MyAccount";
import MyAccountPasswordChange from "views/dashboard/MyAccountPasswordChange/MyAccountPasswordChange";
import ProductComplaintsList from "views/dashboard/ProductComplaintsList/ProductComplaintsList";
import PurchaseOrderMcfReceipt from "views/dashboard/PurchaseOrderMcfReceipt/PurchaseOrderMcfReceipt";
import PurchasePriceCalculator from "views/dashboard/PurchasePriceCalculator/PurchasePriceCalculator";
import PurchasePriceCalculatorList from "views/dashboard/PurchasePriceCalculatorList/PurchasePriceCalculatorList";
import Settings from "views/dashboard/Settings/Settings";
import SupplierEdit from "views/dashboard/SupplierEdit/SupplierEdit";
import SupplierUsersList from "views/dashboard/SupplierUsersList/SupplierUsersList";
import SuppliersList from "views/dashboard/SuppliersList/SuppliersList";
import SupplierUserCreate from "views/dashboard/SupplierUserCreate/SupplierUserCreate";
import SupplierPurchaseOrders from "views/dashboard/SupplierPurchaseOrders/SupplierPurchaseOrders";
import SupplierPurchaseOrderEdit from "views/dashboard/SupplierPurchaseOrderEdit/SupplierPurchaseOrderEdit";
import UsersList from "views/dashboard/UsersList/UsersList";
import UserEdit from "views/dashboard/UserEdit/UserEdit";
import Receipts from "views/dashboard/Receipts/Receipts";
import Reports from "views/dashboard/Reports/Reports";

const Routes = (t, badges) => {
  
  return [
    {
      path: "/frontpage",
      name: t('nav_frontpage'),
      icon: "fas fa-home text-primary",
      component: Frontpage,
      layout: "/dashboard",
    },
    {
      collapse: true,
      name: t("nav_purchase_orders"),
      icon: "fas fa-store text-primary",
      state: "ordersCollapse",
      layout: "/dashboard",
      badge: badges?.nav_purchase_orders_main,
      views: [
        {
          path: "/purchase_orders/new",
          name: t("nav_create_purchase_order","Luo tilaus"),
          miniName: "LT",
          component: PurchaseOrderEdit,
          layout: "/dashboard",
        },
        {
          path: "/purchase_orders/list/draft",
          name: t("nav_purchase_orders","Ostotilaukset"),
          miniName: "OT",
          // component: PurchaseOrders, // see hidden routes
          layout: "/dashboard",
          badge: badges?.nav_purchase_orders_child,
        },
        {
          path: "/purchase_price_calculator_list",
          name: t("nav_purchase_price_calulator","Ostohintalaskuri"),
          miniName: "OH",
          component: PurchasePriceCalculatorList,
          layout: "/dashboard",
        },
      ],
    },
    {
      collapse: true,
      name: t("nav_products","Tuotteet"),
      icon: "fas fa-box-open text-primary",
      state: "productsCollapse",
      layout: "/dashboard",
      views: [
        {
          path: "/products/new",
          name: t("nav_create_product","Luo tuote"),
          miniName: "PN",
          component: ProductEdit,
          layout: "/dashboard",
        },
        {
          path: "/products/list",
          name: t("nav_products","Tuotteet"),
          miniName: "A",
          component: ProductsList,
          layout: "/dashboard",
        },
        {
          path: "/brands/list",
          name: t("nav_brands","Tuotemerkit"),
          miniName: "A",
          component: BrandsList,
          layout: "/dashboard",
        },
        {
          path: "/categories/list",
          name: t("nav_categories","Tuoteryhmät"),
          miniName: "A",
          component: CategoriesList,
          layout: "/dashboard",
        },
      ],
    },
    /*
    {
      path: "/reports",
      name: t("nav_reports","Raportit"),
      icon: "far fa-file-alt text-primary",
      component: Reports,
      layout: "/dashboard",
    },
    {
      path: "/receipts",
      name: t("nav_receipts","Kuitit"),
      icon: "fas fa-receipt text-primary",
      component: Receipts,
      layout: "/dashboard",
    },
    */
    {
      path: "/users/list",
      name: t("nav_users","Käyttäjähallinta"),
      icon: "ni ni-shop text-primary",
      component: UsersList,
      layout: "/dashboard",
    },
    {
      path: "/users/new",
      name: t("nav_new_user","Uusi käyttäjä"),
      icon: "ni ni-single-02 text-primary",
      component: UserEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/users/edit/:id/",
      name: t("nav_edit_user","Muokkaa käyttäjää"),
      icon: "ni ni-single-02 text-primary",
      component: UserEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/list",
      name: t("nav_suppliers","Tavarantoimittajat"),
      icon: "fas fa-people-carry text-primary",
      component: SuppliersList,
      layout: "/dashboard",
    },
    {
      path: "/complaints/list",
      name: t("nav_complaints","Reklamaatiot"),
      icon: "fas fa-expand text-primary",
      component: ProductComplaintsList,
      layout: "/dashboard",
    },
    {
      hr: true,
    },
    {
      path: "/myaccount",
      name: t("nav_myaccount","Omat tiedot"),
      icon: "fas fa-user text-primary",
      component: MyAccount,
      layout: "/dashboard",
      exact: true,
    },
    {
      path: "/myaccount/password",
      name: t("nav_myaccount_password_change","Vaihda salasanaa"),
      icon: "fas fa-user text-primary",
      component: MyAccountPasswordChange,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/products/edit/:productId/:tab/:productVariationId?",
      name: t("nav_product_edit","Muokkaa tuotetta"),
      icon: "ni ni-single-02 text-primary",
      component: ProductEditView,
      layout: "/dashboard",
      invisible: true,
      exact: false,
      // permissions: 3,
    },
    {
      path: "/purchase_orders/list/:tab",
      name: t("nav_purchase_orders","Ostotilaukset"),
      miniName: "A",
      component: PurchaseOrders,
      invisible: true,
      exact: false,
      layout: "/dashboard",
    },
    {
      path: "/purchase_orders/edit/:purchaseOrderId?",
      name: t("nav_purchase_order_edit","Muokkaa ostotilausta"),
      miniName: "A",
      component: PurchaseOrderEdit,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/purchase_orders/review/:purchaseOrderId?",
      name: t("nav_purchase_order_edit","Muokkaa ostotilausta"),
      miniName: "R",
      component: PurchaseOrderReview,
      layout: "/dashboard",
      invisible: true,
      exact: false,
    },
    {
      path: "/purchase_price_calculator/:purchaseOrderId?",
      name: t("nav_purchase_price_calulator","Ostohintalaskuri"),
      miniName: "OH",
      component: PurchasePriceCalculator,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/purchase_order_mcf_receipt/:purchaseOrderId?",
      name: t("nav_purchase_order_mcf_receipt","Vie tiedot MCF"),
      miniName: "OH",
      component: PurchaseOrderMcfReceipt,
      layout: "/dashboard",
      invisible: true,
    },
    {
      path: "/categories/new",
      name: t("nav_category_new","Uusi kategoria"),
      icon: "ni ni-single-02 text-primary",
      component: CategoryEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/categories/edit/:id/",
      name: t("nav_category_edit","Muokkaa kategoriaa"),
      icon: "ni ni-single-02 text-primary",
      component: CategoryEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/new",
      name: t("nav_supplier_new","Uusi tavarantoimittaja"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/edit/:id/",
      name: t("nav_supplier_edit","Muokkaa tavarantoimittajaa"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierEdit,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/users/list/:id/",
      name: t("nav_supplier_users","Tavarantoimittajan käyttäjälista"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierUsersList,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
    {
      path: "/suppliers/users/create/:id/",
      name: t("nav_supplier_user_create","Luo tavarantoimittajakäyttäjä"),
      icon: "ni ni-single-02 text-primary",
      component: SupplierUserCreate,
      layout: "/dashboard",
      invisible: true,
      // permissions: 3,
    },
  ];
}

export default Routes;

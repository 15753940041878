import http from './api'

const url = '/stock'

export const httpGetStockItems = (params) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
  })
}

export const httpGetStockItem = (id) => {
  return http({
    url: `${url}/${id}/`,
    method: 'GET',
  })
}

export const httpCreateStockItem = (data) => {
  return http({
    url: `${url}/`,
    method: 'POST',
    data,
  })
}

export const httpUpdateStockItem = (id, data) => {
  return http({
    url: `${url}/${id}/`,
    method: 'PATCH',
    data,
  })
}

export const httpDeleteStockItem = (id) => {
  return http({
    url: `${url}/${id}/`,
    method: 'DELETE',
  })
}

//MyCashFlow API

export const httpExportStockItemToMCF = (id) => {
  return http({
    url: `${url}/${id}/export_to_mcf/`,
    method: 'POST',
  })
}
import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useNotification } from "stories/components/Notification"

import { Collapse } from 'reactstrap';
import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton, Modal } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import {
  httpGetPurchaseOrderDeliveries,
  httpDeletePurchaseOrderDelivery,
} from "services/purchase_orders"
import PurchaseOrderDelivery from "views/dashboard/PurchaseOrderDelivery/PurchaseOrderDelivery"
import PurchaseOrderDeliveredProductsList from "views/dashboard/PurchaseOrderDeliveredProductsList/PurchaseOrderDeliveredProductsList"
import { getDate } from "services/datetime"

const PurchaseOrderDeliveriesList = ({
  history,
  purchaseOrderId,
  activePurchaseOrderDeliveryId,
  setActivePurchaseOrderDeliveryId,
  clearActivePurchaseOrderDelivery,
  updateIndex,
}) => {
  
  const { t } = useTranslation();
  const { notify } = useNotification();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 100,
    offset:0,
    ordering: "-delivery_date",
  })
  const [collapsesOpen, setCollapsesOpen] = useState({})
  
  const getPurchaseOrderDeliveries = () => {
    if (!purchaseOrderId) {
      return;
    }
    
    httpGetPurchaseOrderDeliveries(purchaseOrderId, filters).then(res => {
      // Set collapses
      let collapses = {}
      res?.data?.results && res?.data?.results.map(pod => {
        collapses[pod.id] = true
      })
      setCollapsesOpen(collapses)
      
      // Purchase order delivery rows
      setRows(res?.data)
      
    })
  }
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > 0) {
      setFilters(s => ({
        ...s,
        purchaseOrderId: purchaseOrderId,
      }))
    }
  }, [purchaseOrderId])
  
  useEffect(() => {
    getPurchaseOrderDeliveries();
  }, [filters])
  
  const headers = [
    { label: "", key: "actions"},
    { label: t("Nimi"), key: "name"},
    { label: t("Lisätiedot"), key: "description"},
    { label: t("Toimitettu"), key: "products_delivered_count"},
  ];
  
  const handleNew = () => {
    setActivePurchaseOrderDeliveryId(null)
    handleOpenModalPurchaseOrderDelivery();
  }
  
  const handleView = (id) => {
    setActivePurchaseOrderDeliveryId(id);
    handleOpenModalPurchaseOrderDelivery();
  }
  
  const toggleActivePurchaseOrderDelivery = (id) => {
    if (activePurchaseOrderDeliveryId === id) {
      setActivePurchaseOrderDeliveryId(null);
    }
    else {
      setActivePurchaseOrderDeliveryId(id);
    }
    
  }
  
  const togglePurchaseOrderDeliveryCollapse = (id) => {
    setCollapsesOpen(s => ({
      ...s,
      [id]: !s[id],
    }))
  }
  
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        id: row?.id,
        name: (
          <>
            <Typography bold>{getDate(row?.delivery_date)}</Typography>
          </>
        ),
        description: (
          <>
            <Typography>{row?.description}</Typography>
          </>
        ),
        products_delivered_count: (
          <>
            <Typography>{row?.products_delivered_count}kpl</Typography>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleView(row?.id)} iconName="eye" />
          </>
        ),
      }
    })
  }, [rows])
  
  const handleDeletePurchaseOrderDelivery = (purchaseOrderDeliveryId) => {
    const result = window.confirm(t("Haluatko varmasti poistaa osatoimituksen?"));
    if (result) {
      httpDeletePurchaseOrderDelivery(purchaseOrderId, purchaseOrderDeliveryId).then(res => {
        notify({ 
          type:"danger", 
          title:t("Osatoimitus poistettu"), 
        })
        // getPurchaseOrderDeliveries();
        window.location.reload();
      }, error => {
        if (error?.data?.errorCode === "CANNOT_DELETE_PURCHASE_ORDER_DELIVERY") {
          notify({ 
            type:"danger", 
            title:t("Poisto epäonnistui"), 
            message: `${error?.data?.message}`,
          })
        }
        else if (error?.data?.detail) {
          notify({ 
            type:"danger", 
            title:t("Poisto epäonnistui"), 
            message: JSON.stringify(error?.data?.detail),
          })
        }
        else {
          notify({ 
            type:"danger", 
            title:t("Poisto epäonnistui"), 
            message: JSON.stringify(error?.data),
          })
        }
      })
    }
  }
  
  const getRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return (
        <>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className="d-flex">
                  <div className="p-0" style={{flexGrow: 1}}>
                    <Button className="btn-block text-left" onClick={() => togglePurchaseOrderDeliveryCollapse(row?.id)}>
                      <Typography bold>{t("OSATOIMITUS")} {getDate(row?.delivery_date)} ({row?.products_delivered_count}) <i className="fas fa-angle-down"></i></Typography>
                    </Button>
                  </div>
                  <div className="ml-auto p-0">
                    <Button className="btn-danger text-left" onClick={() => handleDeletePurchaseOrderDelivery(row?.id)}>
                      <Typography>{t("Poista osatoimitus")}</Typography>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            { row?.description && row?.description.length > 0 ? (
            <Row>
              <Col className="my-2">
                {row?.description}
              </Col>
            </Row>
            ) : null}
            <Row>
              <Col>
                {/*activePurchaseOrderDeliveryId === row?.id*/}
                <Collapse isOpen={collapsesOpen[row.id]}>
                  <PurchaseOrderDeliveredProductsList history={history} purchaseOrderId={purchaseOrderId} purchaseOrderDeliveryId={row?.id} />
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
        
        </>
      )
    })
  }, [rows, collapsesOpen])
  
  // Modal: Purchase Order Delivery
  const [modalPurchaseOrderDeliveryOpen, setModalPurchaseOrderDeliveryOpen] = useState(false)
  
  const toggleModalPurchaseOrderDelivery = () => {
    setModalPurchaseOrderDeliveryOpen(s => !s);
  }
  
  const handleOpenModalPurchaseOrderDelivery = () => {
    setModalPurchaseOrderDeliveryOpen(true)
  }
  
  const handleModalPurchaseOrderDeliveryResolve = (purchaseOrderDeliveryId) => {
    setModalPurchaseOrderDeliveryOpen(false);
    setActivePurchaseOrderDeliveryId(purchaseOrderDeliveryId);
    // getPurchaseOrderDeliveries();
    window.location.reload();
  }
  
  const handleModalPurchaseOrderDeliveryReject = () => {
    setModalPurchaseOrderDeliveryOpen(false);
  }
  
  return (
    <>
      { rows?.count > 0 && (
        <Row className="d-flex justify-content-between mb-2">
          <Col className="col-auto">
            <Typography variant="h2">{t("Kirjatut toimitukset")}</Typography>
          </Col>
          <Col className="col-auto">
            &nbsp;
          </Col>
        </Row>
      )}
      
      {getRows()}
      
      <Modal
        title={activePurchaseOrderDeliveryId ? t("Osatoimitus") : t("Luo osatoimitus")}
        isOpen={modalPurchaseOrderDeliveryOpen}
        toggleModal={toggleModalPurchaseOrderDelivery}
      >
        <PurchaseOrderDelivery
          purchaseOrderId={purchaseOrderId}
          purchaseOrderDeliveryId={activePurchaseOrderDeliveryId}
          handleResolve={handleModalPurchaseOrderDeliveryResolve}
          handleReject={handleModalPurchaseOrderDeliveryReject}
        />
      </Modal>
    </>
  );
}

export default PurchaseOrderDeliveriesList;

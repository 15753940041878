import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import {
  AlertModal,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Link,
  Spinner,
  Typography,
} from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { PageHeader } from "components/Page"
import { Checkbox, Input } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetUser, httpCreateUser, httpUpdateUser, httpInviteUser } from "services/users" 
import { useAppContext } from "contexts/AppContext"
import { isObject } from "services/helpers"

require('yup-phone');

const UserEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      t,
      history,
   } = props;
   
   const { enums } = useAppContext()
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/users/list");
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("Haluatko varmasti poistaa?"));
    if (result) {
      /*
      httpDeleteUser(values?.id).then(res => {
        if (isStaff) {
          history.push("/dashboard/users");
        }
        else {
          history.push("/dashboard/myuser");
        }
      })
      */
    }
  }
  
  
  const getBankExpensesOptions = useCallback(() => {
    const bank_expenses = enums?.suppliers?.supplier?.bank_expenses;
    return bank_expenses && bank_expenses.map(bankExpense => {
      return (
        <option value={bankExpense.key}>{bankExpense.value}</option>
      )
    })
  }, [enums])
  
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("user_edit_basic_title","Perustiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("first_name")}
                label={t("user_edit_first_name_label","Etunimi")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("last_name")}
                label={t("user_edit_last_name_label","Sukunimi")}
                required
              />
            </Col>
          </Row>
          
          {/*
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("username")}
                label={t("user_edit_username_label","Käyttäjätunnus")}
                required
              />
            </Col>
          </Row>
          */}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("email")}
                label={t("user_edit_email_label","Sähköposti/Käyttäjätunnus")}
                type="email"
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("phone")}
                label={t("user_edit_phone_label","Puhelin")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Checkbox
                {...getFormikInputProps("is_superuser")}
                label={t("user_edit_is_superuser_label","Pääkäyttäjä")}
                checked={values?.is_superuser}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("activated")}
                label={t("user_edit_activated_label","Voimassa saakka")}
                defaultValue={values.activatedDefaultValue}
                type="date"
              />
            </Col>
          </Row>
        </Col>
        
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("cancel_button_text","Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-center">
        {/* <Button onClick={handleDelete} variant="danger">{t("Poista")}</Button> */}
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("save_button_text","Tallenna")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </>
  );
}

const defaultValues = {
  id: null,
  name: '',
  activated: moment().add(1, 'years').format("YYYY-MM-DD"),
}

 const UserEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        first_name: Yup.string().required(required).nullable(),
        last_name: Yup.string().required(required).nullable(),
        // username: Yup.string().required(required).nullable(),
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        phone: Yup.string().phone("FI", false,t('Puhelinnumero tulee olla muodossa esim. +358501234567')).required(required).nullable(),
        activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        username: values?.email,
        activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      };
      
      if (values?.id) {
        httpUpdateUser(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Käyttäjä"), message:t("Päivitetty onnistuneesti")})
          props.history.push("/dashboard/users/list");
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data?.errorCode && error?.data?.errorCode === "EMAIL_EXISTS") {
            setErrors({
              email: t("Sähköpostiosoite on jo käytössä")
            })
          }
          else {
            if (error?.status === 400 && isObject(error?.data)) {
              setErrors({
                general: t("check_form_errors","Tarkista lomakkeen virheet"),
                ...error?.data
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
        })
      }
      else {
        data["password"] = Math.random().toString(36).slice(-8);
        
        httpCreateUser(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Käyttäjä"), message:t("Luotu onnistuneesti")})
          props.history.push("/dashboard/users/list");
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else if (error?.data?.errorCode && error?.data?.errorCode === "EMAIL_EXISTS") {
            setErrors({
              email: t("Sähköpostiosoite on jo käytössä")
            })
          }
          else {
            if (error?.status === 400 && isObject(error?.data)) {
              setErrors({
                general: t("check_form_errors","Tarkista lomakkeen virheet"),
                ...error?.data
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
        })
      }
    },
    displayName: "BasicForm"
  
  })(UserEdit)
    
    
const UserEditView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    setLoading(true)
    httpInviteUser(preSetValues?.id).then(res => {
      notify({ title:t("Kutsu"), message:t("Lähetetty onnistuneesti"), type:"default"})
    }, errors => {
      notify({ title:t("Kutsu"), message:t("Lähetys epäonnistui"), type:"error"})
    }).finally(res => {
      setLoading(false)
    })
  }
  const handleModalCancel = () => {
    setInfoAlert(null);
  }
  
  const handleInvite = () => {
    setInfoAlert(
      <AlertModal
        title={t("Lähetä kutsu")}
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalCancel()}
        showCancel={true}
        confirmBtnText={t("Lähetä")}
        cancelBtnText={t("Peruuta")}
      >
        {t("Lähetetäänkö kutsu käyttäjän sähköpostiin?")}
      </AlertModal>
    )
  }
  
  const getUser = (id) => {
    setLoading(true)
    httpGetUser(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        activated:res?.data?.activated ? moment(res?.data?.activated).format("YYYY-MM-DD") + "" : null,
        activatedDefaultValue:res?.data?.activated ? moment(res?.data?.activated).format("YYYY-MM-DD") + "" : null,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (match && match?.params?.id) {
      getUser(match?.params?.id)
    }
  }, [match])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          <Link to={`/dashboard/users/list`}>
            {t("Käyttäjät")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
          { preSetValues?.email || t("Uusi käyttäjä") }
        </BreadcrumbItem>
      </Breadcrumb>
      
      <Row>
        <Col>
          <PageHeader title={t("Käyttäjätili")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            { preSetValues?.id && preSetValues?.id > 0 && (
              <div className="p-2">
                <Button onClick={handleInvite} variant="secondary">{t("Kutsu käyttäjä")}</Button>
              </div>
            )}
            {/*
              <div className="p-2"><Typography variant="small"></Typography></div>
            */}
          </div>
        </Col>
      </Row>
      
      <UserEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} />
      {infoAlert}
    </>
  )
  

}
  

export default UserEditView;

import React, { useCallback, useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';

import { List } from "stories/layout";
import { Typography, Button, Link } from "stories/components";
import { PageHeader } from "components/Page"
import TextCutter from "components/Helpers/TextCutter"


import {
  httpGetComplaints
} from "services/complaints"
import {
  httpGetPurchaseOrdersActionsRequired,
  httpGetPurchaseOrdersWaitingProcess,
} from "services/purchase_orders"
import {
  httpGetStockItems,
} from "services/stock"

const FrontPage = () => {
  
  const { t } = useTranslation();
  const [purchaseOrdersActions, setPurchaseOrdersActions] = useState([])
  const getCompanyBadges = useStoreActions((actions) => actions.badges.getCompanyBadges);
  
  // Required Actions
  
  const getPurchaseOrdersActions = () => {
    const params = {
      state__in: "1,2,3,4,5,6",
    }
    httpGetPurchaseOrdersActionsRequired(params).then(res => {
      setPurchaseOrdersActions(res?.data?.results)
    })
  }
  
  useEffect(() => {
    getPurchaseOrdersActions()
    getCompanyBadges()
  }, [])
  
  const getRowsActions = useCallback(() => {
    return purchaseOrdersActions && purchaseOrdersActions.map(row => {
      return {
        icon: row?.action_type === 1 ? "shopping-cart" : "bell",
        iconColor: 'danger',
        content: (
          <>
            <Typography>{row?.title}</Typography>
            <Typography bold>
              <Link to={`/dashboard/purchase_orders/review/${row?.id}/`}>
                #{row?.id} {row?.name}
              </Link>
            </Typography>
          </>
        ),
      }
    })
    
  }, [purchaseOrdersActions])
  
  
  // Events
  /*
  // dummy data
  const rowsEvents = [
    {
      icon: "bell",
      iconColor: 'danger',
      content: (
        <>
          <Typography>{t('Siirtyi tilaan maksettu')}</Typography>
          <Typography bold>{t('Tilaukseen abc takki')}</Typography>
        </>
      ),
    },
    {
      icon: "exclamation",
      iconColor: 'info',
      content: (
        <>
          <Typography>{t('Siirtyi tilaan lähetetty')}</Typography>
          <Typography bold>{t('Tilaukseen abc takki')}</Typography>
        </>
      ),
    },
    {
      icon: "thumbs-up",
      iconColor: 'success',
      content: (
        <>
          <Typography>{t('Siirtyi tilaan vahvistettu')}</Typography>
          <Typography bold>{t('Tilaukseen abc takki')}</Typography>
        </>
      ),
    }
  ]
  */
  
  // Complaints
  
  const [complaints, setComplaints] = useState([])
  
  const getComplaints = () => {
    const params = {
      status: 1,
      offset: 0,
      limit: 10,
      serializer: "ProductComplaintListSerializer",
    }
    httpGetComplaints(params).then(res => {
      setComplaints(res?.data?.results)
    })
  }
  
  useEffect(() => {
    getComplaints()
  }, [])
  
  const getComplaintsRows = useCallback(() => {
    return complaints && complaints.map(row => {
      return {
        icon: "expand",
        iconColor: 'warning',
        content: (
          <>
            <Typography><TextCutter text={`${row?.product ? row?.product?.name : ""} ${row?.product_variation ? row?.product_variation?.name : ""}`} /></Typography>
            <Typography bold>
              <Link to={`/dashboard/complaints/list`}>{row?.name}</Link>
            </Typography>
          </>
        ),
      }
    })
    
  }, [complaints])
  
  // Stock alert
  
  const [stock, setStock] = useState([])
  
  const getStock = () => {
    const params = {
      low_quantity: 1,
      offset: 0,
      limit: 10,
      serializer: "StockItemListSerializer",
    }
    httpGetStockItems(params).then(res => {
      setStock(res?.data?.results)
    })
  }
  
  useEffect(() => {
    getStock()
  }, [])
  
  const getStockRows = useCallback(() => {
    return stock && stock.map(row => {
      return {
        icon: "cubes",
        iconColor: 'warning',
        content: (
          <>
            <Typography><TextCutter text={`${row?.product_fk ? row?.product_fk?.name : ""} ${row?.product_variation_fk ? row?.product_variation_fk?.name : ""}`} /></Typography>
            <Typography bold>
              <Link to={`/dashboard/purchase_orders/new`}>{t("Tee tilaus")}</Link>
            </Typography>
          </>
        ),
      }
    })
    
  }, [stock])
  
  // PurchaseOrder waiting process
  
  const [waitingProcess, setWaitingProcess] = useState([])
  const [waitingProcessRows, setWaitingProcessRows] = useState([])
  
  const getWaitingProcess = () => {
    const params = {
      
    }
    httpGetPurchaseOrdersWaitingProcess(params).then(res => {
      setWaitingProcess(res?.data?.results)
    })
  }
  
  useEffect(() => {
    getWaitingProcess()
  }, [])
  
  const getWaitingProcessRows = useCallback(() => {
    return waitingProcess && waitingProcess.map(row => {
      return {
        icon: "exclamation",
        iconColor: 'danger',
        content: (
          <>
            <Typography>{t("Tilaus")} {row?.id} - {row?.supplier_name}</Typography>
            <Typography bold>
              <Link to={`/dashboard/purchase_orders/review/${row?.id}/`}>{t("Käsittele")}</Link>
            </Typography>
          </>
        ),
      }
    })
    
  }, [waitingProcess])
  
  return (
    <>
      <PageHeader title={t("Etusivu")}></PageHeader>
      
      <div className="d-flex justify-content-start flex-wrap align-items-stretch mb-3">

          <List 
            className="p-2 mr-3"
            title={t("Odottaa käsittelyä")}
            rows={getWaitingProcessRows()}
          />
          
          <List 
            className="p-2 mr-3"
            title={t("Avoimet reklamaatiot")}
            rows={getComplaintsRows()}
          />

          <List 
            className="p-2 mr-3"
            title={t("Varasto vähissä")}
            rows={getStockRows()}
          />

          <List 
            className="p-2 mr-3"
            title={t("Vaadittavat toimenpiteet")}
            rows={getRowsActions()}
          />
          
      </div>
      
    </>
  );
}

export default FrontPage;

import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ListRow } from './ListRow'
import { Card } from './Card'
import { CardHeader } from './CardHeader'
import { CardBody } from './CardBody'

export const List = ({ title, rows, className, children, ...rest }) => {
  const { t } = useTranslation()
  
  return (
    <Card className={className}>
      <CardHeader>{title}</CardHeader>
      <CardBody>
        { rows && rows.map((row, i) => (
          <ListRow key={i} icon={row?.icon} iconColor={row?.iconColor}>
          {row?.content}
          </ListRow>
        ))}
        { rows && rows.length === 0 && (
           <ListRow>{t('list_no_results','Ei tuloksia')}</ListRow>
        )}
      </CardBody>
    </Card>
  )
}

List.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array
}

List.defaultProps = {
  rows: []
};

// i18next-extract-disable

/*
EXAMPLE USAGE

const rows = [
    {
      icon: "bell",
      iconColor: 'danger',
      content: (
        <>
          <Typography>Ostohinnat laskematta</Typography>
          <Typography bold>Tilaukseen abc takki</Typography>
        </>
      ),
    },
    {
      icon: "bell",
      iconColor: 'danger',
      content: (
        <>
          <Typography>Ostohinnat laskematta</Typography>
          <Typography bold>Tilaukseen abc takki</Typography>
        </>
      ),
    },
    {
      icon: "bell",
      iconColor: 'danger',
      content: (
        <>
          <Typography>Ostohinnat laskematta</Typography>
          <Typography bold>Tilaukseen abc takki</Typography>
        </>
      ),
    }
  ]
  
<List 
  title="Vaadittavat toimenpiteet"
  rows={rows}
/>
*/
// i18next-extract-enable
import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { 
  AlertModal, 
  Button, 
  Card, 
  IconButton,
  Link, 
  Typography, 
} from "stories/components";
import { useAuthContext } from "contexts/AuthContext"
import { TableAuto } from "stories/tables"
import { Input } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import { httpExportProductsToFile, httpExportProductVariationsToFile } from "services/products_export"
import { httpGetProducts, httpGetVariations } from "services/products"

const SELECT_ALL_DEFAULT = false;

const ProductsExport = (props) => {
  const { 
    brands,
    categories,
    // filters, setFilters,
    handleReject,
    // loading, setLoading,
    // rows,
    // searchField, setSearchField,
    suppliers,
  } = props;

  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const { notify } = useNotification();

  const [loading, setLoading] = useState(0)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "name",
  })
  const [searchField, setSearchField] = useState()

  const [params, setParams] = useState({
    all_ids: true,
    ids: [],
    fields: [],
    file_type: "xlsx",
    delimiter: ";",
    separator: "|",
    mark: ",",
  });

  const handleSelectChange = ({ target }) => {
    const { name, value } = target;
    setParams(p => ({
      ...p,
      [name]: value,
    }))
  }
  
  const handleParamsCheckbox = ({ target }) => {
    const { name, checked } = target;
    setParams(s => ({
      ...s,
      [name]: checked,
    }))
  }

  const [formErrors, setFormErrors] = useState({});
  const [loadingExport, setLoadingExport] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [selectedTab, setSelectedTab] = useState("products"); // products, variations, versions
  const [showPreview, setShowPreview] = useState(false);
  const [variationRows, setVariationRows] = useState({
    count: 0,
    results: [],
    ids: [],
  });
  // const [selectAll, setSelectAll] = useState(SELECT_ALL_DEFAULT);
  const handleTogglePreview = () => {
    setShowPreview(!showPreview)
  }

  const handleSelectAll = () => {
    if (getIsAnySelectedHeaders() === false) {
      setHeaders(headers.map(header => {
        return {
          ...header,
          visible: true,
        }
      }))
    }
    else {
      setHeaders(headers.map(header => {
        return {
          ...header,
          visible: false,
        }
      }))
    }
    // setSelectAll(s => !s)
    
  }

  const getIsAnySelectedHeaders = useCallback(() => {
    return headers.filter(header => header.visible).length > 0;
  }, [headers])

  const fileTypes = ["csv", "xls", "xlsx", "json"];
  const delimiters = ["|","~",";",","];
  const decimalMarks = [",","."];
  
  const productKeys = [
      "product_fk_erp",
      "product_id_mcf",
      "product_archived",
      "product_status",
      "product_created_at_erp",
      "product_updated_at_erp",
      "product_updated_at_mcf",
      "product_brand_id_erp",
      "product_brand_name_erp",
      "product_code",
      "product_name_fi",
      "product_name_en",
      "product_selling_price",
      "product_tax_rate",
      "product_purchase_price",
      "product_msrp",
      "product_weight",
      "product_parcel_type",
      "product_warranty_period",
      "product_description",
      "product_information",
      "product_keywords",
      "product_categories_id_erp",
      "product_categories_name_erp",
      "product_versions_visibility",
      "product_featured",
      "product_availability_start",
      "product_availability_end",
      "product_visible_from",
      "product_purchasable_from",
      "product_order_moq",
      "product_order_limit",
      "product_supplier_id_mcf",
      "product_supplier_name_mcf",
      "product_supplier_product_code_mcf", // old: product_supplier_code_mcf
      "product_url",
      "product_image_url",
      "product_notes",
      "product_sale_1_month",
      "product_sale_3_month",
      "product_sale_6_month",
      "product_sale_9_month",
      "product_sale_1_year",
      "product_sale_2_year",
      "product_sale_3_year",
      "product_sale_5_year",
      "product_sale_updated_at",
      "product_stock_id_erp",
      "product_stock_barcode",
      "product_stock_place",
      "product_stock_tariff_code",
      "product_stock_origin_country",
      "product_stock_enabled",
      "product_stock_balance",
      "product_stock_reserved",
      "product_stock_available",
      "product_stock_alarm_level",
      "product_stock_backorder_enabled",
      "product_stock_backorder_delivery_time",
      "product_supplier_product_codes_id_erp", // product_ prefix added
      "product_supplier_product_codes_active_enabled_erp", // product_ prefix added
      "product_supplier_product_codes_product_id_erp", // product_ prefix added
      "product_supplier_product_codes_supplier_id_erp", // product_ prefix added
      "product_supplier_product_codes_name_erp", // product_ prefix added
      "product_supplier_product_codes_batch_size_erp", // product_ prefix added
      "product_supplier_product_codes_moq_erp", // product_ prefix added
      "product_supplier_product_codes_supplier_purchase_price_erp" // product_ prefix added
  ];

  const productVariationKeys = [
    "product_fk_erp",
    "variation_id_erp",
    "product_id_mcf",
    "variation_id_mcf",
    "product_archived",
    "variation_archived",
    "product_status",
    "variation_status",
    "variation_created_at_erp",
    "variation_updated_at_erp",
    "variation_updated_at_mcf",
    "product_brand_id_erp",
    "product_brand_name_erp",
    "product_code",
    "variation_code",
    "variation_sort",
    "product_name_fi",
    "product_name_en",
    "variation_name_fi",
    "variation_name_en",
    "product_selling_price",
    "variation_selling_price",
    "product_tax_rate",
    "product_purchase_price",
    "variation_purchase_price",
    "product_msrp",
    "variation_msrp",
    "product_weight",
    "variation_weight",
    "product_parcel_type",
    "product_warranty_period",
    "product_supplier_id_mcf",
    "product_supplier_name_mcf",
    "product_supplier_product_code_mcf",
    "product_supplier_product_codes_product_id_erp",
    "product_url",
    "variation_image_url",
    "product_notes",
    "variation_sale_1_month",
    "variation_sale_3_month",
    "variation_sale_6_month",
    "variation_sale_9_month",
    "variation_sale_1_year",
    "variation_sale_2_year",
    "variation_sale_3_year",
    "variation_sale_5_year",
    "variation_sale_updated_at",
    "variation_stock_id_erp",
    "variation_stock_barcode", // stock_item_barcode
    "variation_stock_place",
    "variation_stock_tariff_code", // product_stock_tariff_code
    "variation_stock_origin_country",
    "variation_stock_enabled",
    "variation_stock_balance", // stock_item_balance
    "variation_stock_reserved", // stock_item_reserved
    "variation_stock_available",
    "variation_stock_alarm_level",
    "variation_stock_backorder_enabled",
    "variation_stock_backorder_delivery_time",
    "variation_supplier_product_codes_id_erp",
    "variation_supplier_product_codes_active_enabled_erp",
    "variation_supplier_product_codes_product_id_erp",
    "variation_supplier_product_codes_supplier_id_erp",
    "variation_supplier_product_codes_name_erp",
    "variation_supplier_product_codes_batch_size_erp",
    "variation_supplier_product_codes_moq_erp",
    "variation_supplier_product_codes_supplier_purchase_price_erp"
  ];

  // Get variations API call
  useEffect(() => {
    if (selectedTab === "products") {
      setFormErrors({}) // Clear
      setLoading(s => s+1)
      httpGetProducts(filters)
        .then(response => {
          setRows(response.data)
        }).finally(() => {
          setLoading(s => s-1)
        })
    }
    else if (selectedTab === "variations") {
      setFormErrors({}) // Clear
      setLoading(s => s+1)
      httpGetVariations(filters)
        .then(response => {
          setVariationRows(response.data)
        }).finally(() => {
          setLoading(s => s-1)
        })
    }
  }, [filters, selectedTab])
  
  // Brands
  
  const getBrandOptions = useCallback(() => {
    return brands && brands.map(brand => {
      return (
        <option value={brand.id}>{brand.name}</option>
      )
    })
  }, [brands])
  
  // Categories
  
  const getCategoryOptions = useCallback(() => {
    return categories && categories.map(category => {
      return (
        <option value={category.id}>{category.name}</option>
      )
    })
  }, [categories])
  
  // Suppliers
  
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option value={supplier.id}>{supplier.name}</option>
      )
    })
  }, [suppliers])

  // Table

  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  const handleSearchChange = ({ target }) => {
    const { value } = target;
    setSearchField(value)
  }
  const handleSearchBlur = () => {
    setFilters(s => ({
      ...s,
      search: searchField,
    }))
  }
  const handleSearchKeyUp = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      setFilters(s => ({
        ...s,
        search: searchField,
      }))
    }
  }

  useEffect(() => {

    if (selectedTab === "products") {
      let _headers = [];
      productKeys.map((key, index) => {
        _headers.push({
          label: key,
          // key: key ? key.replace("product_", "") : key,
          key: key,
          visible: SELECT_ALL_DEFAULT,
        })
      })
      setHeaders(_headers)
    }
    else if (selectedTab === "variations") {
      let _headers = [];
      productVariationKeys.map((key, index) => {
        _headers.push({
          label: key,
          // key: key ? key.replace("product_", "") : key,
          key: key,
          visible: SELECT_ALL_DEFAULT,
        })
      })
      setHeaders(_headers)
    }
  }, [selectedTab])

  const getHeaderVisibility = (key) => {
    const header = headers.find(h => h.key === key);
    if (header) {
      return header.visible;
    }
    return false;
  }

  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const handleCheckbox = ({ target }) => {
    const { name, checked } = target;
    
    setHeaders(headers.map(header => {
      if (header.key === name) {
        return {
          ...header,
          visible: checked,
        }
      }
      return header;
    }))
  }

  const getTableRows = useCallback(() => {
    let results = [];
    if (selectedTab === "products") {
      results = rows?.results;
    }
    else if (selectedTab === "variations") {
      results = variationRows?.results;
    }

    if (results == null || results.length === 0) {
      return [];
    }
    
    return results.map(row => {
      
      // If no stock_item or stock_item.enabled is false (limitless/rajoittamaton) show empty ""
      // const stock_item_balance = row?.stock_item && row?.stock_item?.enabled === true ? row?.stock_item?.balance : ""
      
      return {
        ...row,
        product_fk_erp: selectedTab === "products" ? row?.id : row?.product_fk?.id,
        variation_id_erp: selectedTab === "products" ? "" : row?.id,
        product_id_mcf: selectedTab === "products" ? row?.id_mcf : row?.product_fk?.id_mcf,
        variation_id_mcf: selectedTab === "products" ? "" : row?.id_mcf,
        product_name_fi: row?.name,
        product_name_en: row?.name_en,
        /*
        name: (
          <>
            <Typography bold>
              <Link to={`/dashboard/products/edit/${row?.id}/basic`}>
              {row?.name ? row?.name : t("noname")}
              </Link>
            </Typography>
            <Typography variant="small">{row?.variations && row?.variations.length > 0 ? `${t("Variaatioita")}: ${row?.variations.length}` : ""}</Typography>
          </>
        ),
        product_code: (
          <>
            <Typography>{row?.product_code}</Typography>
          </>
        ),
        */
      }
    })
  }, [rows, variationRows, selectedTab])


  const validateForm = () => {
    let errors = {};
    if (params?.separator == params?.delimiter) {
      errors.separator = t("products_export_separator_delimiter_same_error", "Ei voi olla sama kuin erotinmerkki, solujen välillä")
    }
    setFormErrors(errors)
    console.log("validateForm params?.separator", params?.separator);
    console.log("validateForm params?.delimiter", params?.delimiter);
    console.log("validateForm errors", errors);
    return errors;
  }

  const handleExport = () => {
    // Validate filters
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (selectedTab === "products") {
      console.log("Exporting products", rows?.ids)

      // Validate products count
      if (params.all_ids === false) {
        if (rows === undefined || rows?.ids === undefined || rows?.ids.length === 0) {
          setFormErrors({
            general: t("products_export_no_products_selected","Ei yhtään tuotetta valittuna")
          })
          return;
        }
        else if (rows && rows?.ids && rows?.ids.length > 1000) {
          setFormErrors({
            general: t("products_export_too_many_products","Liian monta tuotetta valittuna. Valitse kaikki tuotteet tai suodata niin, että tuotteita on vähemmän kuin 1000.")
          })
          return;
        }
      }

      let ids = "all_ids";
      if (params?.all_ids === false) {
        ids = rows?.ids.map(id => id).join(","); // ids as list
      }
      const dataParams = {
        ...params,
        // fields: headers.filter(header => header.visible).map(header => header.key), // fields as list
        fields: headers.filter(header => header.visible).map(header => header.key).join(","), // fields separated by comma
        // ids: rows?.results.map(row => row.id).join(","), // ids separated by comma
        ids: ids,
      }
      setLoadingExport(true)
      httpExportProductsToFile(dataParams)
        .then(response => {
          const filename = selectedTab;
          const blob = new Blob([response.data], { type: 'application/octet-stream' }); // application/pdf
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}-${+new Date()}.${params.file_type}`;
          link.click();

          notify({
            type: "success",
            message: t("products_export_successful","Tuotteet viety onnistuneesti"),
          })
        })
        .catch(error => {
          notify({
            type: "error",
            message: t("products_export_failed","Tuotteiden vienti epäonnistui"),
          })
        }).finally(() => {
          setLoadingExport(false)
        })
    }
    else if (selectedTab === "variations") {
      // Validate products count
      if (params.all_ids === false) {
        if (variationRows === undefined || variationRows?.ids === undefined || variationRows?.ids.length === 0) {
          setFormErrors({
            general: t("products_export_no_products_selected","Ei yhtään tuotetta valittuna")
          })
          return;
        }
        else if (variationRows && variationRows?.ids && variationRows?.ids.length > 1000) {
          setFormErrors({
            general: t("products_export_too_many_products","Liian monta tuotetta valittuna. Valitse kaikki tuotteet tai suodata niin, että tuotteita on vähemmän kuin 1000.")
          })
          return;
        }
      }
      
      /*
      let ids = "all_ids";
      if (filters?.params === false) {
        ids = variationRows?.ids.map(id => id).join(","); // ids as list
      }
      */
      let ids = "all_ids";
      if (params?.all_ids === false) {
        ids = variationRows?.ids.map(id => id).join(","); // ids as list
      }
      const dataParams = {
        ...params,
        fields: headers.filter(header => header.visible).map(header => header.key).join(","), // fields separated by comma
        ids: ids, 
      }
      setLoadingExport(true)
      httpExportProductVariationsToFile(dataParams)
        .then(response => {
          
          const filename = selectedTab;
          const blob = new Blob([response.data], { type: 'application/octet-stream' }); // application/pdf
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `${filename}-${+new Date()}.${params.file_type}`;
          link.click();

          notify({
            type: "success",
            message: t("products_export_successful","Tuotteet viety onnistuneesti"),
          })
        })
        .catch(error => {
          notify({
            type: "error",
            message: t("products_export_failed","Tuotteiden vienti epäonnistui"),
          })
        }).finally(() => {
          setLoadingExport(false)
        })
    }
  }
  
  return (
    <>
      <Row className="mb-3">
          <Col>
            <input type="checkbox" name="all_ids" onClick={handleParamsCheckbox} checked={params?.all_ids} /> <Typography inline>{t("products_export_all_products_label","Kaikki tuotteet")}</Typography>
          </Col>
          <Col>
            <Input
              disabled={params?.all_ids}
              label={t("products_export_input_name","Nimi")} 
              type="text" 
              name="search" 
              onChange={handleSearchChange} 
              value={searchField} 
              onBlur={handleSearchBlur}
              onKeyUp={handleSearchKeyUp}
            />
          </Col>

          <Col>
            <Input disabled={params?.all_ids} label={t("Toimittaja")} type="select" name="supplier" onChange={handleFilterChange} value={filters?.supplier}>
              <option value="">{t("Kaikki")}</option>
              {getSupplierOptions()}
            </Input>
          </Col>
          
          <Col>
            <Input disabled={params?.all_ids} label={t("Tuotekategoria")} type="select" name="category" onChange={handleFilterChange} value={filters?.category}>
              <option value="">{t("Kaikki")}</option>
              {getCategoryOptions()}
            </Input>
          </Col>

          <Col>
            <Input disabled={params?.all_ids} label={t("Tuotemerkki")} type="select" name="brand_id" onChange={handleFilterChange} value={filters?.brand_id}>
              <option value="">{t("Kaikki")}</option>
              {getBrandOptions()}
            </Input>
          </Col>

      </Row>

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link className={`nav-link ${selectedTab === "products" ? "active" : ""} py-3`}
            onClick={() => setSelectedTab("products")}
          ><i className="fas fa-exclamation-circle"></i> {t("products_export_sub_nav_products","Päätuotteet")}</Link>
        </li>
        <li className="nav-item">
          <Link disabled className={`nav-link ${selectedTab === "variations" ? "active" : ""} py-3`}
            onClick={() => setSelectedTab("variations")}
          ><i className="fas fa-exclamation-circle"></i> {t("products_export_sub_nav_variations","Variaatiot")}</Link>
        </li>
        <li className="nav-item">
          <Link disabled className={`nav-link ${selectedTab === "versions" ? "active" : ""} py-3`}
            onClick={() => setSelectedTab("products")}
          ><i className="fas fa-exclamation-circle"></i> {t("products_export_sub_nav_versions","Versionäkyvyydet")}</Link>
        </li>
      </ul>

      {selectedTab === "products" && (
        <Row>
        {productKeys.map((key, index) => {
          return (
            <Col className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <input type="checkbox" name={key} onClick={handleCheckbox} checked={getHeaderVisibility(key)} /> <Typography inline>{key}</Typography>
            </Col>
          )
        })}
        </Row>
      )}

      {selectedTab === "variations" && (
        <Row>
        {productVariationKeys.map((key, index) => {
          return (
            <Col className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
              <input type="checkbox" name={key} onClick={handleCheckbox} checked={getHeaderVisibility(key)} /> <Typography inline>{key}</Typography>
            </Col>
          )
        })}
        </Row>
      )}

      <div className="mt-3"></div>

      <Row>
        <Col className="col-sm-3 mb-2">
            {/* t("select_unselect_all", "Valitse kaikki/Poista kaikki") */}
            {/*
            <Button onClick={handleSelectAll} type="button" variant="link">{getIsAnySelectedHeaders() === true ? t("products_export_unselect_all", "Poista kaikki") : t("products_export_select_all", "Valitse kaikki")}</Button>
            */}
            <a onClick={handleSelectAll} className="btn-link finger">
              {getIsAnySelectedHeaders() === true ? t("products_export_unselect_all", "Poista kaikki") : t("products_export_select_all", "Valitse kaikki")}
            </a>
          </Col>
      </Row>

      <Row>
        <Col className="col-sm-3">
          <Typography inline bold>{t("products_export_filetype","Tiedostotyyppi")}</Typography>
        </Col>
        <Col className="col-md-auto">
          <select name="file_type" onChange={handleSelectChange}>
            {fileTypes.map(fileType => {
              return (
                <option value={fileType} selected={params.file_type === fileType}>{fileType}</option>
              ) 
            })}
          </select>
        </Col>
      </Row>

      <Row>
        <Col className="col-sm-3">
          <Typography inline bold>{t("products_export_delimiter_field","Erotinmerkki, solujen välillä")}</Typography>
        </Col>
        <Col className="col-md-auto">
          <select name="delimiter" onChange={handleSelectChange}>
            {delimiters.map(delim => {
              return (
                <option value={delim} selected={params.delimiter === delim}>{delim}</option>
              ) 
            })}
          </select>
        </Col>
      </Row>

      <Row>
        <Col className="col-sm-3">
          <Typography inline bold>{t("products_export_delimiter_inner","Erotinmerkki, solujen sisällä")}</Typography>
        </Col>
        <Col className="col-md-auto">
          <select name="separator" onChange={handleSelectChange}>
            {delimiters.map(delim => {
              return (
                <option value={delim} selected={params.separator === delim}>{delim}</option>
              ) 
            })}
          </select>
        </Col>
      </Row>
      
      { formErrors && formErrors?.separator && (
        <Row>
          <Col className="col-sm-3">
            <Typography className="text-danger" bold>{formErrors.separator}</Typography>
          </Col>
        </Row>
      )}
      
      <Row>
        <Col className="col-sm-3">
          <Typography inline bold>{t("products_export_mark_point_dot","Pilkku/Piste")}</Typography>
        </Col>
        <Col className="col-md-auto">
          <select name="mark" onChange={handleSelectChange}>
            {decimalMarks.map(decimalMark => {
              return (
                <option value={decimalMark} selected={params.mark === decimalMark}>{decimalMark}</option>
              ) 
            })}
          </select>
        </Col>
      </Row>
      
      { formErrors && formErrors?.mark && (
        <Row>
          <Col className="col-sm-3">
            <Typography className="text-danger" bold>{formErrors.mark}</Typography>
          </Col>
        </Row>
      )}

      <div className="mt-3"></div>

      <Row className="d-flex justify-content-between mb-3">
        <Col>
          <Button color="secondary" onClick={handleReject}>{t("Cancel","Peruuta")}</Button>
        </Col>
        <Col className="text-right">
          <Button disabled={loading || loadingExport} color="primary" onClick={handleTogglePreview}>{t("Preview","Esikatsele")}</Button>
          <Button loading={loadingExport} disabled={loading || loadingExport || getIsAnySelectedHeaders() === false} color="success" onClick={handleExport}>{t("products_export_excel","Vie Excel")}</Button>
          { loading ? (
            <Typography className="text-primary">{t("products_export_loading_products","Ladataan tuotteita...")}</Typography>
          ) : null}
          { loadingExport ? (
            <Typography className="text-primary">{t("products_export_exporting_products","Viedään tuotteita...")}</Typography>
          ): null}
          { formErrors && Object.keys(formErrors).length > 0 ? (
            <Typography className="text-danger" bold>{t("Form_errors","Virheitä lomakkeella")}. {formErrors?.general}</Typography>
          ) : null}
        </Col>
      </Row>

      {showPreview && (
        <div className="table-responsive">
          <TableAuto
            color="dark"
            showId={false}
            checkboxes={false}
            headers={headers}
            rows={getTableRows()}
            loading={loading}
            pagination={true}
            paginationPrevious={paginationPrevious}
            paginationNext={paginationNext}
            paginationOffset={filters?.offset}
            paginationLimit={filters?.limit}
            paginationCount={rows?.count}
            filters={filters}
            setFilters={setFilters}
            tableStickyHeader={true}
          />
        </div>
      )}
    </>
  );
}

export default ProductsExport;

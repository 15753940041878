import React, { createContext, useContext, useEffect, useState } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { httpGetMyUser } from "services/users";
import i18n from "services/i18n"
import appConfig from "services/config"
import { useAppContext } from "contexts/AppContext"

export const AuthContext = createContext({});

const AuthProviderComponent = ({ history, location, children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [myUser, setMyUser] = useState({});
  const { getMySupplier } = useAppContext()
  
  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    window.location.reload();
  }
  
  const getMyUser = (params) => {
    return new Promise((resolve, reject) => {
      if (location?.pathname.startsWith("/auth/invitation")) {
        setLoading(false);
        return;
        // return reject()
      }
      
      if (localStorage.getItem("accessToken") === null) {
        setIsAuthenticated(false);
        setLoading(false);
        return;
      }
      else {
        setLoading(true);
        httpGetMyUser().then(response => {
          if (response && response.status === 200) {
            setMyUser(response?.data);
            setIsAuthenticated(true);
            
            // Set client language
            const myUserLanguage = response?.data?.language ? response?.data?.language : appConfig.DEFAULT_LANGUAGE;
            i18n.changeLanguage(myUserLanguage)
            localStorage.setItem("language", myUserLanguage);
            
            if (response?.data?.is_supplier === true) {
              getMySupplier()
            }
            
            resolve(response?.data)
          }
          else {
            return;
          }
        }, error => {
          setIsAuthenticated(false);
          return;
        }).finally(res => {
          setLoading(false);
        })
      }
    })
  }
  
  useEffect(() => {
    getMyUser()
  }, [])
  
  if (loading) {
    return (
      <>Loading...</>
    )
  }
  
  return (
      <AuthContext.Provider
        value={{
          getMyUser,
          isAuthenticated,
          loading,
          logout,
          myUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
};
export const AuthProvider = withRouter(AuthProviderComponent);

AuthContext.defaultProps = {};
AuthContext.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuthContext = () =>
  useContext(AuthContext);
  
/*
# USAGE

import { useAuthContext } from "contexts/AuthContext"
const MyComponent = (props) => {
  const { myUser } = useAuthContext()
}
*/

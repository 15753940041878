import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetProducts } from "services/products" 
import { httpCreatePurchaseOrderProduct } from "services/purchase_orders" 

const PurchaseOrderAddProduct = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
  const {
    purchaseOrderId,
    supplierId,
    t,
  } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.reject()
  }
  
  const [products, setProducts] = useState([])
   
  useEffect(() => {
    getProducts()
  }, [purchaseOrderId])
   
  const getProducts = () => {
    const params = {
      purchase_order_exclude: purchaseOrderId,
    }
    httpGetProducts(params).then(res => {
      setProducts(res.data)
    })
  }
   
  const getProductOptions = useCallback(() => {
    return products && products.map(product => {
      return (
        <option value={product.id}>{product.name}</option>
      )
    })
  }, [products])
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input label={t("Tuote")} type="select" {...getFormikInputProps("product")}>
                <option value=""></option>
                {getProductOptions()}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("price")}
                label={t("Ostohinta")}
                type="number"
                step="0.01"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("amount")}
                label={t("Määrä")}
                type="number"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Lisää")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </>
  );
}

const defaultValues = {
  id: null,
  product: null,
  price: 0.0,
  amount: 0,
}

 const PurchaseOrderAddProductFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        purchase_order: props.purchaseOrderId,
      };
      
      if (values?.id) {
        
      }
      else {
        httpCreatePurchaseOrderProduct(props.purchaseOrderId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tuote"), message:t("Lisätty onnistuneesti")})
          props.resolve();
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
    },
    displayName: "BasicForm"
  
  })(PurchaseOrderAddProduct)
    
    
const PurchaseOrderAddProductView = ({match, purchaseOrderId, resolve, reject}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderAddProductFormik t={t} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} resolve={resolve} reject={reject} />
  )
  

}
  

export default PurchaseOrderAddProductView;

import http from './api'

const url = '/product_attributes'

export const httpGetProductAttributes = (params) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
  })
}


import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Pagination as BootstrapPagination, CardFooter, PaginationItem, PaginationLink} from "reactstrap";
import { Input } from "stories/forms"
import './Table.css';

export const Pagination = ({ 
    className, 
    paginationPrevious, 
    paginationNext, 
    paginationGoPage, 
    paginationLimit, 
    paginationOffset,
    paginationCount,
    setFilters,
    showPaginationLimitSelect
  }) => {
    
  const { t } = useTranslation();
    
  const getCurrentPageNum = () => {
    if (parseInt(paginationOffset) === 0) {
      return 1;
    }
    return (parseInt(paginationOffset) / parseInt(paginationLimit)) + 1
  }
  
  const getMaxPageNum = () => {
    const max_page_num = Math.ceil(parseInt(paginationCount) / parseInt(paginationLimit));
    if (max_page_num < 1) {
      return 1;
    }
    return max_page_num;
  }
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      offset: 0,
      [name]: parseInt(value)
    }))
  }

  return (
    <CardFooter className="py-4">
      <nav aria-label="...">
        <BootstrapPagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <div class="d-flex flex-row">
            
            { showPaginationLimitSelect && (
            <div className="align-self-center mr-1">
              <Input type="select" name="limit" onChange={handleFilterChange} value={paginationLimit}>
                <option value="10000">{t("pagination_all_option", "Kaikki")}</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="300">300</option>
              </Input>
            </div>
            )}
            
            <div className="align-self-center">
            <PaginationItem className={paginationOffset === 0 ? "disabled" : ""}>
              <PaginationLink
                onClick={paginationPrevious}
                tabIndex="-1"
              >
                <i className="fas fa-angle-left" />
                <span className="sr-only">{t("Previous")}</span>
              </PaginationLink>
            </PaginationItem>
            </div>
            {/*
            <PaginationItem className="active">
              <PaginationLink
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                1
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                2 <span className="sr-only">(current)</span>
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                3
              </PaginationLink>
            </PaginationItem>
            */}
            <div className="align-self-center">
            <PaginationItem className={(paginationOffset > paginationCount - paginationLimit) ? "disabled" : ""}>
              <PaginationLink
                onClick={paginationNext}
              >
                <i className="fas fa-angle-right" />
                <span className="sr-only">{t("Next")}</span>
              </PaginationLink>
            </PaginationItem>
            </div>
            <div className="align-self-center pl-2">
            <PaginationItem>
              {getCurrentPageNum()} / {getMaxPageNum()}
            </PaginationItem>
            </div>
          </div>
        </BootstrapPagination>
      </nav>
    </CardFooter>
  )
    
}

Pagination.defaultProps = {
  paginationLimit: 1,
  paginationOffset: 0,
  paginationCount: 0,
  showPaginationLimitSelect: true,
};

// i18next-extract-disable

/* USAGE EXAMPLE

const paginationPrevious = () => {
  let offset = filters?.offset - filters?.limit;
  if (offset < 0) {
    offset = 0;
  }
  setFilters(f => ({
    ...f,
    offset: offset,
  }))
}

const paginationNext = () => {
  let offset = filters?.offset + filters?.limit;
  if (offset > filters?.count) {
    return
  }
  setFilters(f => ({
    ...f,
    offset: offset,
  }))
}

<TableAuto
  headers={headers}
  rows={getRows()}
  loading={loading}
  pagination={true}
  paginationPrevious={paginationPrevious}
  paginationNext={paginationNext}
/>


*/
// i18next-extract-enable
import React, { useState, useCallback, useEffect, useRef } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge, IconButton, Link } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetProduct, httpGetProductVariation } from "services/products"
import { httpCreateComplaint, httpGetComplaint, httpUpdateComplaint, uploadComplaintImage, httpConfirmComplaint } from "services/complaints"
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import ProductComplaintComments from "views/dashboard/ProductComplaintComments/ProductComplaintComments"

import helmets from "assets/images/helmets.png"

const defaultValues = {
  id: null,
  amount: 1,
  // type: 1,
}

const ProductComplaintView = (props) => {
  
  const {
     dirty,
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      t,
      history,
      newImages,
      setNewImages,
      newImageFiles,
      setNewImageFiles,
   } = props;
   
  const { enums } = useAppContext()
  const { myUser } = useAuthContext()
  const [saving, setSaving] = useState(false);
  
  const getTypeValue = (key) => {
    if (!key) {
      return ""
    }
    const value = enums && enums?.complaints?.product_complaint?.type && enums?.complaints?.product_complaint?.type.filter((item) => {
      return item.key === key
    })[0].value
    return t(value) /* i18next-extract-disable-line */
  }
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const getPurchaseOrderLink = (purchaseOrderId) => {
    if (myUser?.is_supplier) {
      return `/dashboard/supplier_sales_orders/edit/${purchaseOrderId}/`
    }
    return `/dashboard/purchase_orders/review/${purchaseOrderId}/`
    
  }
  
  const handleConfirm = () => {
    setSaving(true);
    httpConfirmComplaint(values?.id).then(res => {
      props.handleResolve()
    }).finally(() => {
      setSaving(false);
    })
  }
  
  return (
    <>
      <Row>
        <Col>
          
          <Row className="mb-3">
            <Col>
               <Typography bold variant="h2">
                <div className="avatar rounded-circle bg-danger w-32 h-32 mr-2"><i className="fas fa-wrench"></i></div>
                {t("product_complaint_view_complaint","Reklamaatio")}
              </Typography>
            </Col>
          </Row>
          
           <Row>
            <Col>
              <Typography bold inline className="pr-1">
              {values?.product?.name}
              </Typography>
              { values?.product_variation && (
                <Typography inline>
                { values?.product_variation?.name }
                </Typography>
              )}
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Typography bold inline className="pr-1">{t("product_complaint_view_amount","Määrä")}</Typography>
              <Typography inline>{values?.amount}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Typography bold inline className="pr-1">{t("product_complaint_view_type","Tyyppi")}</Typography>
              <Typography inline>{getTypeValue(values?.type)}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Typography bold inline className="pr-1">{t("product_complaint_view_description","Kuvaus")}</Typography>
              <Typography inline>{values?.description}</Typography>
            </Col>
          </Row>
          
          { values?.purchase_order && (
            <Row>
              <Col>
                <Typography bold inline className="pr-1">{t("product_complaint_view_purchase_order","Ostotilaus")}</Typography>
                <Link target="_blank" to={getPurchaseOrderLink(values?.purchase_order)}>
                  <Typography inline bold>{values?.purchase_order}</Typography>
                </Link>
              </Col>
            </Row>
          )}
          
          { values?.images && values?.images.length > 0 && (
          <>
            <Row className="mb-3">
              <Col>
                <Typography bold>{t("product_complaint_view_images","Kuvat")}</Typography>
              </Col>
              <Col className="text-right">
                
              </Col>
            </Row>
            
            <Row className="my-2">
              <Col>
                <div className="d-flex flex-row mb-3">
                { values?.images && values?.images.length > 0 && values?.images.map(existingImage => (
                    <a href={existingImage?.image} target="_blank" rel="noopener noreferrer">
                    <div
                      className="p-2 mr-2 mb-2 avatar avatar-xl"
                    >
                        <img border="0" alt={"complaint_image_"+existingImage?.id} src={existingImage?.image} />
                    </div>
                    </a>
                ))}
                </div>
              </Col>
            </Row>
          </>
          )}
          
        </Col>

      </Row>
      
      <Row className="mt-3 mb-3">
        <Col>
          <ProductComplaintComments productComplaintId={values?.id} />
        </Col>
      </Row>
      
      <Row className="mt-3 mb-3">
        <Col>
          <Button onClick={handleCancel} variant="secondary">{t("product_complaint_view_cancel","Sulje")}</Button>
        </Col>
        <Col className="text-right">
        { myUser?.is_supplier === true && values?.confirmed_by_supplier === false ? (
          <Button disabled={saving} onClick={handleConfirm} variant="success">{t("product_complaint_view_confirm","Käsittele")}</Button>
        ) : null }
        { myUser?.is_supplier === false && values?.confirmed_by_orderer === false ? (
          <Button disabled={saving} onClick={handleConfirm} variant="success">{t("product_complaint_view_confirm","Käsittele")}</Button>
        ) : null }
        </Col>
      </Row>
        
    </>
  );
}

const ProductComplaintViewFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("validation_required_field","Kenttä on pakollinen");
      return Yup.object().shape({
        amount: Yup.string().nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      console.log("handleSubmit");
    },
    displayName: "BasicForm"
  
})(ProductComplaintView)
    
    
const ProductComplaintViewView = ({history, productId, productVariationId, productComplaintId, purchaseOrderId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({})
  const [productComplaint, setProductComplaint] = useState({})
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const [newImages, setNewImages] = useState([])
  const [newImageFiles, setNewImageFiles] = useState([])
  
  const getProductComplaint = () => {
    if (!productComplaintId || modalOpen === false) {
      return;
    }
    const params = {
      serializer: "ProductComplaintReadSerializer"
    }
    setLoading(true)
    httpGetComplaint(productComplaintId, params).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getProductComplaint()
  }, [productComplaintId, modalOpen])
  
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <ProductComplaintViewFormik
        t={t}
        history={history}
        preSetValues={preSetValues}
        notify={notify}
        productId={productId}
        productVariationId={productVariationId}
        productComplaintId={productComplaintId}
        product={product}
        purchaseOrderId={purchaseOrderId}
        newImages={newImages}
        setNewImages={setNewImages}
        newImageFiles={newImageFiles}
        setNewImageFiles={setNewImageFiles}
        {...rest}
      />
    </>
  )
  
}
  

export default ProductComplaintViewView;

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetCategory, httpCreateCategory, httpUpdateCategory, httpDeleteCategory } from "services/categories" 

const CategoryEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm
   } = props;
   
   const {
      history,
      notify,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/categories/list");
  }
  
  const handleDelete = () => {
    let warningText = t("Haluatko varmasti poistaa?")
    if (values?.products_count > 0) {
      warningText += " Huom! Kategoriassa on tuotteita."
    }
    const result = window.confirm(warningText);
    if (result) {
      httpDeleteCategory(values?.id).then(res => {
        notify({ title:"Kategoria", message:"Poistettu onnistuneesti"})
        history.push("/dashboard/categories/list");
      })
    }
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>Perustiedot</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name")}
                label="Nimi"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">Peruuta</Button>
        </Col>
        <Col className="mb-3 text-center">
          { values?.id && values?.id > 0 && (
            <Button onClick={handleDelete} variant="danger">Poista</Button>
          )}
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>Tallenna</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </>
  );
}

const defaultValues = {
  id: null,
  name: '',
  // activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const CategoryEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().nullable(),
        // email: Yup.string().email('Tarkista sähköpostiosoitteen muoto').required(required),
        // phone: Yup.string().phone("FI", false,'Puhelinnumero tulee olla muodossa esim. +358501234567').required(required).nullable(),
        // activated: Yup.date().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      // activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      let data = {
        ...values,
      };
      
      if (values?.id) {
        httpUpdateCategory(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kategoria"), message:t("Päivitetty onnistuneesti")})
          props.history.push("/dashboard/categories/list");
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      else {
        httpCreateCategory(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Kategoria"), message:t("Luotu onnistuneesti")})
          props.history.push("/dashboard/categories/list");
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }

    },
    displayName: "BasicForm"
  
  })(CategoryEdit)
    
    
const CategoryEditView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  
  const getCategory = (id) => {
    setLoading(true)
    httpGetCategory(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        // activated:res?.activated ? moment(res?.activated).format("YYYY-MM-DD") + "" : null,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (match && match?.params?.id) {
      getCategory(match?.params?.id)
    }
  }, [match])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <CategoryEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} />
  )
  

}
  

export default CategoryEditView;

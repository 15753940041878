import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';

// app components
import { PageHeader } from "components/Page"

// contexts
import { useAppContext } from "contexts/AppContext"

// story components
import { useNotification } from "stories/components/Notification"
import { Row, Col } from "stories/layout";
import {
  Button,
  Link,
  Typography,
} from "stories/components";
import { TableAuto } from "stories/tables"
import { Input, Checkbox } from "stories/forms";

// services
import { httpUpdatePurchaseOrderProductDelivery } from "services/purchase_orders";
import {
  httpGetPurchaseOrderErpReceipt,
  httpExportProductPurchasePriceMcf,
  httpRevertProductPurchasePriceMcf,
  httpExportProductPriceMcf,
  httpRevertProductPriceMcf,
  httpExportStockItemChangeMcf,
  httpRevertStockItemChangeMcf,
} from "services/erp_receipts";

import { getDateAndTime } from "services/datetime"

const PurchaseOrderMcfReceipt = ({match}) => {
  
  const { enums } = useAppContext()
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  // Table
  const [loading, setLoading] = useState(false) // Dont use this, we want to show the table all the time
  const [loadingRow, setLoadingRow] = useState({})
  const [checked, setChecked] = useState([])
  
  const [purchaseOrderId, setPurchaseOrderId] = useState()
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > 0) {
      getRrpReceipt()
    }
  }, [purchaseOrderId])
  
  useEffect(() => {
    if (match && match?.params?.purchaseOrderId && parseInt(match?.params?.purchaseOrderId) > 0) {
      setPurchaseOrderId(parseInt(match?.params?.purchaseOrderId))
    }
  }, [match])
  
  // PurchaseOrder ErpReceipt
  
  const [erpReceipt, setErpReceipt] = useState()
  
  const getRrpReceipt = () => {
    if (!purchaseOrderId) {
      return;
    }
    // setLoading(true)
    httpGetPurchaseOrderErpReceipt(purchaseOrderId).then(res => {
      setErpReceipt(res.data)
    }).finally(fResponse => {
      // setLoading(false);
    })

  }
  
  
  // Filters
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "-id",
  })
  
  const handleFilterChange = ({ target }) => {
    const { value, name } = target;
    setFilters(s => ({
      ...s,
      [name]: value
    }))
  }
  
  // Execute
  
  const [isSaving, setIsSaving] = useState(0)
  const [action, setAction] = useState("stocks");
  
  const handleActionChange = ({ target }) => {
    const { value, name } = target;
    setAction(value)
  }
  
  const getActionOptions = () => {
    return [
      (<option value="stocks">{t("Vie varastosaldot")}</option>),
      (<option value="purchase_prices">{t("Vie ostohinnat")}</option>),
      (<option value="selling_prices">{t("Vie myyntihinnat")}</option>),
    ]
  }
  
  const handleRunAction = () => {
    if (checked.length === 0) {
      notify({ type: "warning", title:t("Valitse ensin rivi(t)")})
      return;
    }
    if (action && checked.length > 0) {
      const rows = getTableRows();
      
      let promises = []
      
      checked && checked.map((checkId, index) => {
        
        const deliveryProduct = rows.reduce((result, row) => {
          if (row.checkId == checkId) {
            result = row
          }
          return result
        }, null)
        
        if (deliveryProduct) {
          if (action === "stocks") {
            if (deliveryProduct?.stock_amount && !deliveryProduct?.stock_amount?.updated_to_mcf && deliveryProduct?.stock_amount?.quantity_change > 0) {
              promises.push(handleExportStockItemChangeMcf(deliveryProduct?.id_stock))
            }
          }
          else if (action === "purchase_prices") {
            if (deliveryProduct?.purchase_price && deliveryProduct?.purchase_price?.purchase_price && !deliveryProduct?.purchase_price?.updated_to_mcf && deliveryProduct?.stock_amount?.quantity_change > 0) {
              promises.push(handleExportProductPurchasePriceMcf(deliveryProduct?.id_purchase_price))
            }
          }
          else if (action === "selling_prices") {
            const old_sell_price = deliveryProduct?.selling_price ? parseFloat(deliveryProduct?.selling_price) : null;
            const new_sell_price = deliveryProduct?.new_sell_price ? parseFloat(deliveryProduct?.new_sell_price) : null;
            
            if (new_sell_price && 
              new_sell_price > 0 && 
              !deliveryProduct?.selling_price_updated_to_mcf && 
              old_sell_price != new_sell_price &&
              deliveryProduct?.stock_amount?.quantity_change > 0
            ) {
              promises.push(handleExportProductPriceMcf(deliveryProduct?.id_sell_price))
            }
            /*
            else {
              console.log("action selling_prices else")
              console.log("deliveryProduct", deliveryProduct)
              console.log("deliveryProduct?.selling_price?.old_price", deliveryProduct?.selling_price?.old_price)
              console.log("deliveryProduct?.selling_price?.price", deliveryProduct?.selling_price?.price)
              console.log("deliveryProduct?.selling_price?.updated_to_mcf", deliveryProduct?.selling_price?.updated_to_mcf)
            }
            */
          }
        }
        
      })
      
      if (promises.length === 0) {
        notify({ type: "warning", title:t("mcf_receipt_export_empty","Ei mitään vietävää"), message:""})
      }
      else {
        setIsSaving(s => s+1)
        Promise.all(promises).then((response) => {
          // notify({ type: "success", title:t("mcf_receipt_export_succeed","Vienti onnistui"), message:""})
        }, (errors) => {
          notify({ type: "danger", title:t("Vienti epäonnistui"), message:JSON.stringify(errors)})
        }).finally(() => {
          setIsSaving(s => s-1)
          getRrpReceipt();
        });
      }
      
      
      
    }
  }
  
  // END
  
  // Export / Revert API calls
  
  // Purchase price
  const handleExportProductPurchasePriceMcf = (id, refresh) => {
    setIsSaving(s => s+1)
    return httpExportProductPurchasePriceMcf(id).then(res => {
      if (res?.status === 200) {
        notify({ 
          type: "success", 
          title: t("mcf_receipt_export_product_purchase_price_mcf_success","Vienti onnistui"), 
          message:""
        })
        if (refresh) {
          getRrpReceipt();
        }
      }
      else {
        notify({ type: "danger", title:t("mcf_receipt_export_product_purchase_price_mcf_error","Vienti epäonnistui"), message:JSON.stringify(res?.data)})
      }
    }, error => {
      notify({ type: "danger", title:t("mcf_receipt_export_product_purchase_price_mcf_error","Vienti epäonnistui"), message:JSON.stringify(error?.data)})
    }).finally(() => {
      setIsSaving(s => s-1)
    })
  }
  
  const handleRevertProductPurchasePriceMcf = (id, refresh) => {
    setIsSaving(s => s+1)
    return httpRevertProductPurchasePriceMcf(id).then(res => {
      if (res?.status === 200) {
        notify({ type: "success", title:t("mcf_receipt_revert_product_purchase_price_mcf_success","Peruutus onnistui"), message:""})
        if (refresh) {
          getRrpReceipt();
        }
      }
      else {
        notify({ type: "danger", title:t("mcf_receipt_revert_product_purchase_price_mcf_error","Peruutus epäonnistui"), message:JSON.stringify(res?.data)})
      }
    }, error => {
      notify({ type: "danger", title:t("mcf_receipt_revert_product_purchase_price_mcf_error","Peruutus epäonnistui"), message:JSON.stringify(error?.data)})
    }).finally(() => {
      setIsSaving(s => s-1)
    })
  }
  
  // Product price
  const handleExportProductPriceMcf = (id, refresh) => {
    setIsSaving(s => s+1)
    return httpExportProductPriceMcf(id).then(res => {
      if (res?.status === 200) {
        notify({ type: "success", title:t("mcf_receipt_export_product_price_mcf_success","Vienti onnistui"), message:""})
        if (refresh) {
          getRrpReceipt();
        }
      }
      else {
        notify({ type: "danger", title:t("mcf_receipt_export_product_price_mcf_error","Vienti epäonnistui"), message:JSON.stringify(res?.data)})
      }
    }, error => {
      notify({ type: "danger", title:t("mcf_receipt_export_product_price_mcf_error","Vienti epäonnistui"), message:JSON.stringify(error?.data)})
    }).finally(() => {
      setIsSaving(s => s-1)
    })
  }
  
  const handleRevertProductPriceMcf = (id, refresh) => {
    setIsSaving(s => s+1)
    return httpRevertProductPriceMcf(id).then(res => {
      if (res?.status === 200) {
        notify({ type: "success", title:t("mcf_receipt_revert_product_price_mcf_success","Peruutus onnistui"), message:""})
        if (refresh) {
          getRrpReceipt();
        }
      }
      else { 
        notify({ type: "danger", title:t("mcf_receipt_revert_product_price_mcf_error","Peruutus epäonnistui"), message:JSON.stringify(res?.data)})
      }
    }, error => {
      notify({ type: "danger", title:t("mcf_receipt_revert_product_price_mcf_error","Peruutus epäonnistui"), message:JSON.stringify(error?.data)})
    }).finally(() => {
      setIsSaving(s => s-1)
    })
  }
  
  // Stock item change
  
  const handleExportStockItemChangeMcf = (id, refresh) => {
    setIsSaving(s => s+1)
    return httpExportStockItemChangeMcf(id).then(res => {
      if (res?.status === 200) {
        notify({ type: "success", title:t("mcf_receipt_export_stock_item_change_mcf_success","Vienti onnistui"), message:""})
      }
      else {
        notify({ type: "danger", title:t("mcf_receipt_export_stock_item_change_mcf_error","Vienti epäonnistui"), message:JSON.stringify(res?.data)})
      }
    }, error => {
      notify({ type: "danger", title:t("mcf_receipt_export_stock_item_change_mcf_error","Vienti epäonnistui"), message:JSON.stringify(error?.data)})
    }).finally(() => {
      setIsSaving(s => s-1)
      if (refresh) {
        getRrpReceipt();
      }
    })
  }
  
  const handleRevertStockItemChangeMcf = (id, refresh) => {
    setIsSaving(s => s+1)
    return httpRevertStockItemChangeMcf(id).then(res => {
      if (res?.status === 200) {
        notify({ type: "success", title:t("mcf_receipt_revert_stock_item_change_mcf_success","Peruutus onnistui"), message:""})
      }
      else {
        notify({ type: "danger", title:t("mcf_receipt_revert_stock_item_change_mcf_error","Peruutus epäonnistui"), message:JSON.stringify(res?.data)})
      }
    }, error => {
      notify({ type: "danger", title:t("mcf_receipt_revert_stock_item_change_mcf_error","Peruutus epäonnistui"), message:JSON.stringify(error?.data)})
    }).finally(() => {
      setIsSaving(s => s-1)
      if (refresh) {
        getRrpReceipt();
      }
    })
  }
  
  
  
  // END
  
  const getStatusName = (key) => {
   try {
     return enums && enums?.products?.product?.status.filter(obj => {
       if (obj.key === key) return true;
       return false;
     })[0].value
   }
   catch {
     return ""
   }
  }
  
  const headers = [
    { label: t("Tila"), key: "status", sortingKey: "status_sort", sortable: false, sortingType: "int", visible: true},
    { label: t("mcf_receipt_header_erp_id","ERP ID"), key: "id"},
    { label: t("mcf_receipt_header_mcf_product_id","MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("mcf_receipt_header_mcf_variation_id","MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: t("mcf_receipt_header_name","Nimi"), key: "name"},
    { label: t("mcf_receipt_header_code","Koodi"), key: "code"},
    { label: t("mcf_receipt_header_code_product_supplier_code","Toimittajan tuo.nro"), key: "product_supplier_code", visible: true, sortingKey: "product_supplier_code_sort", sortable: false, sortingType: "string"},
    { label: t("mcf_receipt_header_amount","Määrä"), key: "amount"},
    { label: t("mcf_receipt_header_batch","Batch kerroin"), key: "batch"},
    { label: t("mcf_receipt_header_amount_pcs","Määrä (yks)"), key: "amount_pcs"},
    { label: t("mcf_receipt_header_receipt_price","Hinta kuitissa"), key: "receipt_price"},
    { label: t("mcf_receipt_header_old_price","Vanha ostohinta"), key: "old_price"},
    { label: t("mcf_receipt_header_new_price","Uusi ostohinta"), key: "new_price"},
    { label: t("mcf_receipt_header_old_selling_price","Vanha myyntihinta"), key: "old_selling_price"},
    { label: t("mcf_receipt_header_new_sell_price","Uusi myyntihinta"), key: "new_sell_price"},
    { label: t("mcf_receipt_header_handled","Käsitelty"), key: "handled"},
    { label: t("mcf_receipt_header_states","Tila"), key: "states"},
    
  ];
  
  const getRowStockButton = (product, checkId) => {
    if (product?.stock_amount?.updated_to_mcf) {
      return (
        <Button disabled={isSaving} size="sm" color="default" onClick={() => handleRevertStockItemChangeMcf(product?.stock_amount?.id, true)}>{t("Peruuta vienti")}</Button>
      )
    }
    else if (product?.stock_amount?.error_on_update_to_mcf) {
      return (
        <Button disabled={isSaving || !product?.stock_amount || checkId === -1 || product?.stock_amount?.quantity_change === 0} size="sm" color={checkId === -1 ? "dark" : "warning"} onClick={() => handleExportStockItemChangeMcf(product?.stock_amount?.id, true)}>{t("mcf_receipt_limitless_stock","Rajaton varasto")}</Button>
      )
    }
    else {
      return (
        <Button disabled={isSaving || !product?.stock_amount || checkId === -1 || product?.stock_amount?.quantity_change === 0} size="sm" color={checkId === -1 ? "dark" : "success"} onClick={() => handleExportStockItemChangeMcf(product?.stock_amount?.id, true)}>{t("Vie MCF varastoon")}</Button>
      )
    }
  }
  
  const getRowPurchasePriceButton = (product, checkId) => {
    if (product?.purchase_price?.updated_to_mcf) {
      return (
        <Button disabled={isSaving} size="sm" color="default" onClick={() => handleRevertProductPurchasePriceMcf(product?.purchase_price?.id, true)}>{t("Peruuta ostohinta")}</Button>
      )
    }
    else if (product?.purchase_price?.error_on_update_to_mcf) {
      return (
        <Button disabled={isSaving || !product?.purchase_price || !product?.purchase_price?.purchase_price || checkId === -1 || product?.stock_amount?.quantity_change === 0} size="sm" color={checkId === -1 ? "warning" : "success"} onClick={() => handleExportProductPurchasePriceMcf(product?.purchase_price?.id, true)}>{t("Vie ostohinta")}</Button>
      )
    }
    else {
      return (
        <Button disabled={isSaving || !product?.purchase_price || !product?.purchase_price?.purchase_price || checkId === -1 || product?.stock_amount?.quantity_change === 0} size="sm" color={checkId === -1 ? "dark" : "success"} onClick={() => handleExportProductPurchasePriceMcf(product?.purchase_price?.id, true)}>{t("Vie ostohinta")}</Button>
      )
    }
  }
  
  const getRowSellingPriceButton = (product, checkId) => {
    if (product?.selling_price?.updated_to_mcf) {
      return (
        <Button disabled={isSaving} size="sm" color="default" onClick={() => handleRevertProductPriceMcf(product?.selling_price?.id, true)}>{t("Peruuta myyntihinta")}</Button>
      )
    }
    else if (product?.selling_price?.error_on_update_to_mcf) {
      return (
        <Button disabled={isSaving || !product?.selling_price || checkId === -1 || product?.selling_price?.old_price == product?.selling_price?.price || product?.stock_amount?.quantity_change === 0} size="sm" color={checkId === -1 ? "warning" : "success"} onClick={() => handleExportProductPriceMcf(product?.selling_price?.id, true)}>{t("Vie myyntihinta")}</Button>
      )
    }
    else {
      return (
        <Button disabled={isSaving || !product?.selling_price || checkId === -1 || product?.selling_price?.old_price == product?.selling_price?.price || product?.stock_amount?.quantity_change === 0} size="sm" color={checkId === -1 ? "dark" : "success"} onClick={() => handleExportProductPriceMcf(product?.selling_price?.id, true)}>{t("Vie myyntihinta")}</Button>
      )
    }  
  }
  
  const getTableRows = useCallback(() => {
    
    let rows = [];
    
    erpReceipt && erpReceipt?.deliveries && erpReceipt.deliveries.map(delivery => {
      
      rows.push({
        isSubtitle: true,
        className: "subtitle",
        name: getDateAndTime(delivery?.delivery_date),
      })
      
      let parentProducts = {};
      let productsTemp = [];
      
      delivery.products && delivery.products.map(product => {
        
        const id = `${product?.id_erp_product}-${product?.id_erp_product_variation ? product?.id_erp_product_variation : 0}`
        const rowId = `${delivery?.id}_${id}`
        const checkId = product?.status === 1 || product?.status === 2 ? `${delivery?.id}_${id}` : -1
        
        const handleCheckbox = (deliveryId, productErpId, productVariationErpId) => {
          let _erpReceipt = erpReceipt;
          if (_erpReceipt && _erpReceipt?.deliveries) {
            for (let i=0; i<_erpReceipt?.deliveries.length; i++) {
              if (_erpReceipt?.deliveries[i].id === deliveryId) {
                for (let j=0; j<_erpReceipt?.deliveries[i].products.length; j++) {
                  if (_erpReceipt?.deliveries[i].products[j].id_erp_product === productErpId && _erpReceipt?.deliveries[i].products[j].id_erp_product_variation === productVariationErpId) {
                    const newHandledValue = _erpReceipt?.deliveries[i].products[j].handled ? false : true;
                    _erpReceipt.deliveries[i].products[j].handled = newHandledValue
                    // Id is reserved for product.id, 
                    // let's use the original product delivery id in PATCH
                    const id_product_delivery = _erpReceipt?.deliveries[i].products[j].id_product_delivery
                    setLoadingRow(s => ({
                      ...s,
                      [rowId]: true
                    }))
                    httpUpdatePurchaseOrderProductDelivery(
                      purchaseOrderId,
                      deliveryId,
                      id_product_delivery,
                      _erpReceipt.deliveries[i].products[j]
                    ).finally(() => {
                        setLoadingRow(s => ({
                        ...s,
                        [rowId]: false
                      }))
                    })
                  }
                }
              }
            }
            setErpReceipt(_erpReceipt)
          }
          
        }
        
        const getLoadingRow = () => {
          try {
            return loadingRow[rowId]
          }
          catch (e) {
            return false;
          }
          
        }
        
        const amount = parseFloat(product?.stock_amount?.quantity_change) / parseFloat(product?.batch)
        
        // Previous sell price / Vanha myyntihinta
        let old_selling_price = "-";
        if (product?.selling_price?.old_price != null) {
          old_selling_price = product?.selling_price?.old_price;
        }
        
        // productsTemp.push({
        const productTemp = {
          key: id,
          parentProductId: product?.variation_parent_product?.id,
          variation_sort: product?.variation_sort,
          rowId: rowId,
          checkId: checkId,
          id: id,
          id_purchase_price: product?.purchase_price?.id,
          id_sell_price: product?.selling_price?.id,
          id_stock: product?.stock_amount?.id,
          mcf_product_id: product?.mcf_product_id,
          mcf_variation_id: product?.mcf_variation_id,
          name: product?.name,
          code: product?.code,
          product_supplier_code: product?.product_supplier_code_name,
          product_supplier_code_sort: product?.product_supplier_code_name,
          amount: amount,
          amount_pcs: product?.stock_amount?.quantity_change,
          batch: product?.batch,
          receipt_price: product?.price,
          old_price: product?.purchase_price?.old_purchase_price,
          new_price: product?.purchase_price?.purchase_price,
          old_selling_price: old_selling_price,
          new_sell_price: product?.selling_price?.old_price == product?.selling_price?.price ? "-" : product?.selling_price?.price,
          stock_amount: product?.stock_amount,
          purchase_price: product?.purchase_price,
          status: <Typography>{getStatusName(product?.status)}</Typography>,
          status_sort: product?.status,
          handled: (
            <Checkbox
              onChange={() => handleCheckbox(delivery?.id, product?.id_erp_product, product?.id_erp_product_variation)}
              id={`handled_${checkId}`}
              name={`handled_${checkId}`}
              value={`handled_${checkId}`}
              label={t("mcf_receipt_row_handled", "")}
              checked={product?.handled}
              disabled={getLoadingRow()}
            />
          ),
          states: (
            <>
              {getRowStockButton(product, checkId)}
              {getRowPurchasePriceButton(product, checkId)}
              {getRowSellingPriceButton(product, checkId)}
            </>
          ),
          selling_price_updated_to_mcf: product?.selling_price?.updated_to_mcf,
        }
        
        // Group products to parent and children
        if (product?.variation_parent_product) {
          // Is child
          
          // Create parent if not exist
          if (parentProducts.hasOwnProperty(product?.variation_parent_product?.id) === false) {
            parentProducts[product?.variation_parent_product?.id] = {
              ...product?.variation_parent_product,
              mcf_product_id: product?.variation_parent_product?.id_mcf,
              code: product?.variation_parent_product?.product_code,
              children: [],
            }
          }
          
          // Add child to list
          productTemp["sortFixed"] = productTemp?.variation_sort ? productTemp?.variation_sort : 0;
          console.log("sortFixed", productTemp["sortFixed"])
          parentProducts[product?.variation_parent_product?.id].children.push(productTemp);
          
        }
        else {
          // Is parent
          parentProducts[productTemp?.id] = {
            ...productTemp,
            children: [],
          }
        }
        
      })
      
      const sortRowsChildren = (_rows) => {
        return _rows.sort((a, b) => {
            return parseInt(a["sortFixed"]) > parseInt(b["sortFixed"]) ? 1 : -1
        })
      }
      
      // Transform parent / chilren products hierarcy back to simple list of products
      for (const [key, value] of Object.entries(parentProducts)) {
        rows.push(parentProducts[key])
        
        const row = parentProducts[key];
        // Sort children
        if (row?.children && row.children.length > 0) {
            const children = sortRowsChildren(row.children)
            children.map(rowChild => {
              rows.push(rowChild)
            })
        }
        
        /*
        parentProducts[key].children.map(childProduct => {
          rows.push(childProduct)
        })
        */
      }
      
    })
    
    
    
    return rows;
    
  }, [erpReceipt, checked, loadingRow, isSaving])
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  return (
    <>
    {/*JSON.stringify(isSaving)*/}
      <Row>
        <Col>
          <PageHeader 
            title={t("Vie tiedot MCF")}
            subtitle={t("Päivitetään MCFn tiedot")}
          />
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <Link to={`/dashboard/purchase_price_calculator/${purchaseOrderId}`}>
                <Button className="btn-primary">{t("Avaa Ostohintalaskuri")}</Button>
              </Link>
            </div>
            <div className="p-2">
              <Link to={`/dashboard/purchase_orders/review/${purchaseOrderId}`}>
                <Button className="btn-primary">{t("Näytä Ostotilaus")}</Button>
              </Link>
            </div>
          </div>
        </Col>
      </Row>
      
        
      <div className="mb-3">
      
        <div className="d-flex flex-row align-items-center">
          <div className="p-2">
            <Input labelInline={true} label={t("Käsittele")} type="select" name="action" onChange={handleActionChange} value={action}>
              {/* <option value="">{t("Kaikki")}</option> */ }
              {getActionOptions()}
            </Input>
          </div>
          <div className="p-2">
            <Button disabled={isSaving} color="success" onClick={handleRunAction}>{t("Vahvista ja vie tiedot")}</Button>
          </div>
        </div>
      
      </div>
          
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={true}
        checked={checked}
        setChecked={setChecked}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={0}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
    </>
  );
}

export default PurchaseOrderMcfReceipt;

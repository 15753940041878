import http from './api'

const url = '/customer_orders'

export const httpGetCustomerOrders = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import {
  Button,
  Typography,
  Spinner,
  Link,
  Breadcrumb,
  BreadcrumbItem
} from "stories/components";
import { Container, Row, Col, FormContainer } from "stories/layout";
import { Checkbox, Input } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetSupplier, httpCreateSupplier, httpUpdateSupplier } from "services/suppliers" 
import { useAppContext } from "contexts/AppContext"

import "./SupplierEdit.css"

require('yup-phone');

const SupplierEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm
  } = props;
   
  const {
    t,
    history,
  } = props;
   
  const { enums } = useAppContext()
   
  const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const handleCancel = () => {
    history.push("/dashboard/suppliers/list");
  }
  
  const handleDelete = () => {
    const result = window.confirm(t("Haluatko varmasti poistaa?"));
    if (result) {
    }
  }
  
  
  const getBankExpensesOptions = useCallback(() => {
    const bank_expenses = enums?.suppliers?.supplier?.bank_expenses;
    return bank_expenses && bank_expenses.map(bankExpense => {
      return (
        <option value={bankExpense.key}>{bankExpense.value}</option>
      )
    })
  }, [enums])
  
  const getCargoOrganizerOptions = useCallback(() => {
    const cargo_organizer = enums?.suppliers?.supplier?.cargo_organizer;
    return cargo_organizer && cargo_organizer.map(cargoOrganization => {
      return (
        <option value={cargoOrganization.key}>{cargoOrganization.value}</option>
      )
    })
  }, [enums])
  
  const getCurrencyOptions = useCallback(() => {
    const currencies = enums?.suppliers?.supplier?.currency;
    return currencies && currencies.map(currency => {
      return (
        <option value={currency.key}>{currency.value}</option>
      )
    })
  }, [enums])
  
  const getTermsOfPaymentOptions = useCallback(() => {
    const terms_of_payment_days = enums?.suppliers?.supplier?.terms_of_payment_days;
    return terms_of_payment_days && terms_of_payment_days.map(termsOfPayment => {
      return (
        <option value={termsOfPayment.key}>{termsOfPayment.value}</option>
      )
    })
  }, [enums])
  
  
  return (
    <>
      <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
        <BreadcrumbItem>
          <Link to={`/dashboard/suppliers/list`}>
            {t("Tavarantoimittajat")}
          </Link>
        </BreadcrumbItem>
        <BreadcrumbItem>
        { values?.id ? t("Muokkaa") : t("Lisää") }
        </BreadcrumbItem>
      </Breadcrumb>
      
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Perustiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name")}
                label={t("Nimi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("code")}
                label={t("Tavarantoimittajakoodi")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("vat_id")}
                label={t("Y-tunnus")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-0">
              <Input
                {...getFormikInputProps("email")}
                label={t("Sähköposti")}
                type="email"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              { values?.id && values?.id > 0 ? (
                <Typography>{t("Käyttäjät on kutsuttava erikseen")} <Link to={`/dashboard/suppliers/users/list/${values?.id}/`}>{t("tästä")}</Link></Typography>
              ) : (
                <Typography>{t("Kun tavarantoimittaja on tallennettu, käyttäjät on kutsuttava erikseen.")}</Typography>
              )}
            </Col>
          </Row>
            
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("phone")}
                label={t("Puhelin")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("currency")} label={t("Valuutta")} type="select">
                {getCurrencyOptions()}
              </Input>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                  {...getFormikInputProps("purchase_order_threshold")}
                  label={t("Ostotilauksen raja-arvo")}
                  type="number"
                />
            </Col>
          </Row>
          
        </Col>
        
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Osoitetiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("street_address")}
                label={t("Katuosoite")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("city")}
                label={t("Kaupunki")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("postal_code")}
                label={t("Postinumero")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("country")}
                label={t("Maa")}
              />
            </Col>
          </Row>
        
        </Col>
        
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Muut tiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("cargo_organizer")} label={t("Rahti")} type="select">
                {getCargoOrganizerOptions()}
              </Input>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("bank_expenses")} label={t("Pankin käsittelykulut")} type="select">
                {getBankExpensesOptions()}
              </Input>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("terms_of_payment_days")} label={t("Maksuaika")} type="select">
                {getTermsOfPaymentOptions()}
              </Input>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("purchase_order_info")}
                label={t("Tilauksen tekotapa")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Lisätiedot")}
                type="textarea"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Checkbox
                {...getFormikInputProps("active")}
                label={t("Aktiivinen")}
                checked={values?.active}
              />
            </Col>
          </Row>
          
        
        </Col>
        
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-center">
        {/* <Button onClick={handleDelete} variant="danger">{t("Poista")}</Button> */}
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t("Tallenna")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
    </>
  );
}

const defaultValues = {
  id: null,
  name: '',
  active: true,
}

 const SupplierEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().required(required).nullable(),
        code: Yup.string().required(required).nullable(),
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
      };
      
      if (values?.id) {
        httpUpdateSupplier(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tavarantoimittaja"), message:t("Päivitetty onnistuneesti")})
          props.history.push("/dashboard/suppliers/list");
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      else {
        httpCreateSupplier(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tavarantoimittaja"), message:t("Luotu onnistuneesti")})
          props.history.push("/dashboard/suppliers/list");
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
      
      
    },
    displayName: "BasicForm"
  
  })(SupplierEdit)
    
    
const SupplierEditView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  
  const getSupplier = (id) => {
    setLoading(true)
    httpGetSupplier(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (match && match?.params?.id) {
      getSupplier(match?.params?.id)
    }
  }, [match])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <SupplierEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} />
    </>
  )
  

}
  

export default SupplierEditView;

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetPurchaseOrderProduct, httpCreatePurchaseOrderProduct, httpUpdatePurchaseOrderProduct, httpConfirmPurchaseOrderProduct } from "services/purchase_orders"
import ProductComments from "views/dashboard/ProductComments/ProductComments"

const defaultValues = {
  id: null,
  product_fk: null,
  product_variation_fk: null,
  id_mcf: null,
  backorder_enabled: false,
  backorder_estimate: "30",
  balance: 0,
  balance_alert: null,
  balance_limit: null,
  barcode: "",
  code: "",
  enabled: true,
  location: "",
  product_id: null,
  quantity: 0,
  reserved: null,
  sku: "-",
  variation_id: null,
}


const SupplierOrderProductEdit = (props) => {
  
  const {
     dirty,
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      t,
      history,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const handleConfirm = () => {
    httpConfirmPurchaseOrderProduct(props?.purchaseOrderId, props?.purchaseOrderProductId).then(res => {
      props.handleResolve(res?.data)
    });
  }
  
  return (
    <>
      <Row>
        
        <Col>
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Tuotetiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("amount")}
                label={t("Tilattu määrä")}
                disabled
                type="number"
                step={1}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("price")}
                label={t("Ostohinta")}
                disabled={props?.readOnly}
                type="number"
                step={0.01}
                pattern="[0-9]+([.,][0-9]+)?"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("time")}
                label={t("Toimitusaika")}
                disabled
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("weight")}
                label={t("Paino")}
                disabled
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("size")}
                label={t("Tilavuus")}
                disabled
              />
            </Col>
          </Row>
          
        </Col>
        
        <Col>
          <ProductComments purchaseOrderProductId={props?.purchaseOrderProductId} />
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          { !props?.readOnly && (
            <>
              <Button variant="success" disabled={isSubmitting || !dirty} onClick={() => validateForm().then(() => handleSubmit())}>{t("Tallenna")}</Button>
              
              <Button variant="success" disabled={isSubmitting || dirty || values?.confirmed_by_supplier} onClick={() => validateForm().then(() => handleConfirm())}>
                { values?.confirmed_by_supplier ? t("Käsitelty") : (
                <>
                  {t("Käsittele")}
                  <Badge color="danger" type="floating">!</Badge>
                </>
                )}
              </Button>
              
              { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
            </>
          )}
          { props?.readOnly && (
            <Badge size="large" color="success">{t("Käsitelty")}</Badge>
          )}
        </Col>
      </Row>
        
    </>
  );
}

const SupplierOrderProductEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let price = values?.price ? parseFloat(values?.price.toString().replace(",",".")) : 0;
      let data = {
        ...values,
        price: price,
      };
      
      if (values?.id) {
        httpUpdatePurchaseOrderProduct(props.purchaseOrderId, values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tuote"), message:t("Tallennettu"), type:"success"})
          props.getPurchaseOrderProduct();
        }, error => {
          setSubmitting(false);
          if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ title:t("Tuote"), message:t("Tallennettu"), type:"success" })
            props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
            props.getPurchaseOrderProduct();
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(SupplierOrderProductEdit)
    
    
const SupplierOrderProductEditView = ({history, purchaseOrderId, purchaseOrderProductId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [purchaseOrderProduct, setPurchaseOrderProduct] = useState({})
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const getPurchaseOrderProduct = () => {
    if (!purchaseOrderId || !purchaseOrderProductId || modalOpen === false) {
      return;
    }
    setLoading(true)
    httpGetPurchaseOrderProduct(purchaseOrderId, purchaseOrderProductId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    getPurchaseOrderProduct()
  }, [purchaseOrderId, purchaseOrderProductId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <SupplierOrderProductEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} purchaseOrderProductId={purchaseOrderProductId} getPurchaseOrderProduct={getPurchaseOrderProduct} {...rest} />
  )
  

}
  

export default SupplierOrderProductEditView;

import React, { useEffect, useCallback, useState} from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import classnames from "classnames";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { useTranslation } from 'react-i18next';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { PageWrapper } from "components/Page"
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import routesAdmin from "routes_dashboard.js";
import routesSupplier from "routes_supplier.js";
import { Container, Row, Col } from "stories/layout";
import { IconButton } from "stories/components";
import { httpGetDashboardTopNavigationButtons } from "services/dashboard"

const Dashboard = ({sidenavAlwaysOpen}) => {
  const { t } = useTranslation();
  const { sidenavOpen, setSidenavOpen } = useAppContext()
  const { myUser } = useAuthContext()
  
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const [router, setRouter] = useState();
  const getCompanyBadges = useStoreActions((actions) => actions.badges.getCompanyBadges);
  const getSupplierBadges = useStoreActions((actions) => actions.badges.getSupplierBadges);
  const badges = useStoreState((state) => state.badges?.badges);
  
  useEffect(() => {
    if (document?.documentElement) {
      document.documentElement.scrollTop = 0;
    }
    if (document?.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    if (mainContentRef?.current) {
      mainContentRef.current.scrollTop = 0;
    }
  }, [location]);
  
  useEffect(() => {
    if (myUser?.is_supplier) {
      getSupplierBadges()
    }
    else {
      getCompanyBadges()
    }
  }, [myUser])
  
  useEffect(() => {
    setRouter(myUser?.is_supplier ? routesSupplier(t, badges) : routesAdmin(t, badges))
  }, [myUser, badges])
  
  // Top navigation
  const [topNavOpen, setTopNavOpen] = useState(true);
  const [topNavButtons, setTopNavButtons] = useState([])
  
  const getDashboardTopNavigationButtons = () => {
    httpGetDashboardTopNavigationButtons().then(res => {
      setTopNavButtons(res?.data)
    })
  }
  
  useEffect(() => {
	if (myUser?.is_supplier === false) {
		getDashboardTopNavigationButtons()
	}
  }, [])
  
  const toggleTopNav = () => {
    setTopNavOpen(s => !s)
  }
  
  //
  
  const getRouter = () => {
    return myUser?.is_supplier ? routesSupplier : routesAdmin;
  }
  
  const getRoutes = (_router) => {
    if (!_router) {
      return;
    }
    
    // Returns only right routes, by user permissions and layout
    return _router.filter((route, key) => {
      if (route?.layout === "/dashboard") {
        if (route?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return true;
          }
        }
        else {
          return true;
        }
      }
      return false;
    })
  }
  
  const listRoutes = (_router) => {
    if (!_router) {
      return;
    }
    // Construct Route components
    const filteredRoutes = getRoutes(_router);
    
    return filteredRoutes.map((prop, key) => {
      if (!prop.component && !prop.collapse) {
        return null;
      }
      if (prop.collapse) {
        return listRoutes(prop.views);
      }
      if (prop.layout === "/dashboard") {
        if (prop?.permissions === 3) {
          if (myUser?.is_staff === true) {
            return (
              <Route
                exact={prop.exact ? prop.exact : undefined}
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        }
        else {
          return (
            <Route
              exact={prop.exact ? prop.exact : undefined}
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    if (!router) {
      return "";
    }
    for (let i = 0; i < router.length; i++) {
      if (location.pathname.indexOf(router[i].layout + router[i].path) !== -1) {
        return router[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (sidenavAlwaysOpen) {
      return;
    }
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return location.pathname.indexOf("admin/alternative-dashboard") === -1
      ? "light"
      : "dark";
  };
  
  if (router) {
    return (
      <>
        <Sidebar
          t={t}
          routes={getRoutes(router)}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          sidenavAlwaysOpen={sidenavAlwaysOpen}
          hoverKeepOpen={false}
          logo={{
            innerLink: "/dashboard/frontpage",
            imgSrc: require("assets/images/logo.png").default,
            imgAlt: "...",
          }}
          rtlActive={false}
        />
        <div className={`main-content ${sidenavOpen ? "dashboard_main_content_sidenav_open" : "dashboard_main_content_sidenav_closed"}`} ref={mainContentRef}>
          <AdminNavbar
            theme={getNavbarTheme()}
            sideNavToggleButtonVisible={false}
            toggleSidenav={toggleSidenav}
            sidenavOpen={sidenavOpen}
            brandText={getBrandText(location.pathname)}
            myUser={myUser}
            topNavOpen={topNavOpen}
            toggleTopNav={toggleTopNav}
            topNavButtons={topNavButtons}
          />
          <div style={{position:"absolute", right:"4px", top: topNavOpen ? "0px" : "-12px"}}>
            <IconButton color="secondary" size="lg" iconName={topNavOpen ? "times" : "angle-down"} onClick={toggleTopNav} />
          </div>
          <div className="mt-3">
            <PageWrapper>
              <Switch>
                {listRoutes(router)}
                <Redirect from="*" to="/dashboard/frontpage" />
              </Switch>
            </PageWrapper>
          </div>
          <AdminFooter />
          
        </div>
        
      </>
    );
  }
  return (
    <></>
  );
}

Dashboard.defaultProps = {
  sidenavAlwaysOpen: false,
}

export default Dashboard;

import http from './api'

const base_url_orders = '/purchase_orders/orders'

export const httpGetPurchaseOrderErpReceipt = (id) => {
  return http({
    url: `${base_url_orders}/${id}/erp_receipt/`,
    method: 'GET',
  })
}

export const httpCreatePurchaseOrderErpReceipt = (id) => {
  return http({
    url: `${base_url_orders}/${id}/create_erp_receipt/`,
    method: 'POST',
  })
}

// Purchase price mcf

export const httpExportProductPurchasePriceMcf = (id) => {
  return http({
    url: `/products/product_purchase_price_changes/${id}/export_to_mcf/`,
    method: 'POST',
  })
}

export const httpRevertProductPurchasePriceMcf = (id) => {
  return http({
    url: `/products/product_purchase_price_changes/${id}/revert_mcf_update/`,
    method: 'POST',
  })
}

// Product price mcf

export const httpExportProductPriceMcf = (id) => {
  return http({
    url: `/products/product_price_changes/${id}/export_to_mcf/`,
    method: 'POST',
  })
}

export const httpRevertProductPriceMcf = (id) => {
  return http({
    url: `/products/product_price_changes/${id}/revert_mcf_update/`,
    method: 'POST',
  })
}

// Stock mcf

export const httpExportStockItemChangeMcf = (id) => {
  return http({
    url: `/stock_item_changes/${id}/export_to_mcf/`,
    method: 'POST',
  })
}

export const httpRevertStockItemChangeMcf = (id) => {
  return http({
    url: `/stock_item_changes/${id}/revert_mcf_update/`,
    method: 'POST',
  })
}
import React from "react";
import { Row, Col, Card, CardBody } from "stories/layout";
import { Typography } from "stories/components";
import { getDateAndTime } from "services/datetime"

const Comment = ({comment}) => {
  
  /*
  const getCompanyName = (comment) => {
    if (comment.sender_type === 1) {
      return "Tradesoft Oy"
    }
    else {
      return comment?.purchase_order_product?.purchase_order?.supplier?.name
    }
  }
  */
  
  return (
    <Card className="mb-1">
      <CardBody className="pt-2 pb-2">
        <Row className="align-items-center">
          <Col className={`border border-top-0 border-bottom-0 border-right-0 border-left-3 ${comment?.extra?.is_mine ? "border-success" : "border-danger"}`}>
            <div className="d-flex justify-content-between">
              <div><Typography variant="small" bold>{/* {getCompanyName(comment)} / */}{comment?.sender?.full_name}</Typography></div>
              <div><Typography variant="small" bold>{getDateAndTime(comment?.sent)}</Typography></div>
            </div>
            
            <Typography variant="small">{comment?.message}</Typography>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Comment;
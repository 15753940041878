import React, { useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import YupPassword from 'yup-password';
import { useTranslation } from 'react-i18next';

// core components
import { Checkbox, Input } from "stories/forms";
import { AlertModal, Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"
import { cleanEmail } from "services/helpers"

import appConfig from "services/config"
import { httpRegisterUser } from "services/users"

YupPassword(Yup);

const Register = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
     setFieldValue,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="7" md="9" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t("Luo tunnukset palveluun")}</Typography>
                  <Typography variant="p">{t("Aloita palvelun käyttö luomalla tunnukset")}</Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("first_name")}
                      label={t("Etunimi")}
                      required
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("last_name")}
                      label={t("Sukunimi")}
                      required
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("Sähköposti")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password")}
                      label={t("Salasana")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password2")}
                      label={t("Salasana uudelleen")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>
                
                <div className="row justify-content-start">
                  <div className="ml-3 mb-3">
                    <Checkbox 
                      name="agree_terms"
                      checked={values?.agree_terms}
                      onChange={(e) => {setFieldValue("agree_terms",e.target.checked)}}
                      value="agree_terms"
                      required
                      errorText={touched["agree_terms"] ? errors["agree_terms"] : ""}
                      label={<>{t('Hyväksy')} <Link>{t('käyttöehdot')}</Link></>}
                    />
                  </div>
                  
                </div>
                
                <div className="text-center text-muted mb-4">
                  <Button disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>{t('Rekisteröidy')}</Button>
                  { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
                </div>
                
                <Row>
                  <Col className="mb-3 text-center">
                    <Typography variant="small">{t('On jo tunnukset?')} <Link to="/auth">{t('Kirjaudu tästä')}</Link></Typography>
                  </Col>
                </Row>
                
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password2: '',
  agree_terms: false,
  language: appConfig.DEFAULT_LANGUAGE,
}

 const RegisterFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      const requiredField = () => Yup.string().required(required); 
      const passwordField = () => requiredField()
          .min(
            8,
            'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special'
          )
          .minLowercase(1, 'password must contain at least 1 lower case letter')
          .minUppercase(1, 'password must contain at least 1 upper case letter')
          .minNumbers(1, 'password must contain at least 1 number')
          .minSymbols(1, 'password must contain at least 1 special character')
          .matches(/^[a-zA-Z0-9!"#¤%&\/()=?@£${[\]}\+]+$/, 'Password can only contain latin characters a-z, A-Z, 0-9, !"#¤%&/()=?@£${[]}\+')
      
      return Yup.object().shape({
        first_name: Yup.string().required(required),
        last_name: Yup.string().required(required),
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        password: passwordField(),
        password2: passwordField(),
        agree_terms: Yup.bool().test(
          'consent',
          t('Kenttä on pakollinen'),
          value => value === true
        )
      });
    },
    validate: (values, props) => {
      const {t} = props;
      const errors = {};
      
      if (values?.password && values?.password2 && values?.password != values?.password2) {
        errors.password = t("Passwords does not match")
      }
     
       return errors;
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const email = cleanEmail(values?.email);
      const data = {
        ...values,
        email: email,
        username: email,
        password: values?.password,
      }
      httpRegisterUser(data).then(res => {
        setSubmitting(false);
        props.handleResolve()
      }, error => {
        setSubmitting(false);
        setErrors({
          general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,
        })
      })
    },
    displayName: "BasicForm"
  
  })(Register)
    
    
const RegisterView = ({match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    history.push("/auth/login")
  }
  
  const handleResolve = () => {
    setInfoAlert(
      <AlertModal
        style={{ display: "block", marginTop: "-100px" }}
        title={t("Tilin aktivointi")}
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
      {t("Viimeistele tunnuksen luonti aktivoimalla sähköpostiosoitteesi. Olemme lähettäneet vahvistuslinkin sähköpostiisi.")}
      </AlertModal>
    )
    
  }
  
  return (
    <>
      <RegisterFormik history={history} handleResolve={handleResolve} />
      {infoAlert}
    </>
  )
}
  

export default RegisterView;

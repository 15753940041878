import React from "react";
// react library for routing
import { useLocation, NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";

import { useAuthContext } from "contexts/AuthContext"
import { useAppContext } from "contexts/AppContext"

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";

import { Badge } from "stories/components/Badge"
import coFoundedEuFi from "assets/images/FI_Co-fundedbytheEU_RGB_NEG.png"
import coFoundedEuEn from "assets/images/EN_Co-fundedbytheEU_RGB_NEG.png"

const INITIAL_OPEN_COLLAPSE_MENUS = true;

function Sidebar({ t, toggleSidenav, sidenavOpen, routes, logo, rtlActive, sidenavAlwaysOpen, hoverKeepOpen=false }) {
  const { logout, myUser } = useAuthContext()
  const { isMobile } = useAppContext()
  const [state, setState] = React.useState({});
  const location = useLocation();
  
  // EU-logo BEGIN
  const divMenuRef = React.useRef(null);
  const divEuRef = React.useRef(null);
  const [isOverlapping, setIsOverlapping] = React.useState(true);

  const checkOverlap = () => {
      if (divMenuRef.current && divEuRef.current) {
          const rect1 = divMenuRef.current.getBoundingClientRect();
          const rect2 = divEuRef.current.getBoundingClientRect();

          const overlap = !(rect1.right < rect2.left ||
                            rect1.left > rect2.right ||
                            rect1.bottom < rect2.top ||
                            rect1.top > rect2.bottom);

          setIsOverlapping(overlap);
      }
  };

  React.useEffect(() => {
    window.addEventListener('resize', checkOverlap);

    return () => {
        window.removeEventListener('resize', checkOverlap);
    };
  }, []);
  
  React.useEffect(() => {
    setTimeout(() => {
      checkOverlap();
    }, 500);
    
  }, []);
  
  // EU-logo END
  
  React.useEffect(() => {
    setState(getCollapseStates(routes));
    // eslint-disable-next-line
  }, []);
  
  React.useEffect(() => {
    if (sidenavOpen) {
      document.body.classList.add("g-sidenav-show");
    }
    else {
      document.body.classList.remove("g-sidenav-show");
    }
  }, [sidenavOpen]);
  
  const handleLogout = () => {
    logout();
  }
  
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    // return location.pathname.indexOf(routeName) > -1 ? "active" : "";
    return location.pathname.startsWith(routeName) ? "active" : "";
  };
  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    /*
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
    */
  };
  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    /*
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
    */
  };
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return INITIAL_OPEN_COLLAPSE_MENUS;
    // return false;
  };
  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (sidenavAlwaysOpen) {
      return;
    }
    /*
    if (window.innerWidth < 1200) {
      toggleSidenav();
    }
    */
  };
  
  const getBadge = (badge) => {
    if (badge && badge > 0) {
      return (
        <>
        &nbsp;
        <Badge color="default" type="floating">{badge}</Badge>
        </>
      )
    }
    return "";
  }
  
  // this function creates the links and collapses that appear in the sidebar (left menu)
  // innerLinks = true if the function is creating the links for the collapse
  const createLinks = (routes, innerLinks) => {
    return routes.map((prop, key) => {
      if(prop.invisible) return null;
      if (prop.redirect) {
        return null;
      }
      if (prop.hr)
      {
        return (
          <hr className="my-3" />
        )
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        return (
          <NavItem key={key}>
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={state[prop.state]}
              className={classnames({
                // active: getCollapseInitialState(prop.views),
              })}
              onClick={(e) => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon ? (
                <>
                  <i className={prop.icon} />
                  <span className="nav-link-text">
                    {prop?.name}
                    {getBadge(prop?.badge)}
                  </span>
                  
                </>
              ) : prop.miniName ? (
                <>
                  <span className="sidenav-mini-icon"> {prop.miniName} </span>
                  <span className="sidenav-normal">
                    {prop?.name}
                    {getBadge(prop?.badge)}
                  </span>
                </>
              ) : null}
            </NavLink>
            <Collapse isOpen={state[prop.state]}>
              <Nav className="nav-sm flex-column">
                {createLinks(prop.views, true)}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }
      return (
        <NavItem className={`${activeRoute(prop.layout + prop.path)}`} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            activeClassName=""
            onClick={closeSidenav}
            tag={NavLinkRRD}
          >
            {prop.icon !== undefined ? (
              <>
                <div className={`${innerLinks ? "sk_nav_inner" : "sk_nav_root"}`}>
                  <i className={prop.icon} />
                  <span className="nav-link-text">
                    {!prop.collapse ? prop?.name : ""}
                    {getBadge(prop?.badge)}
                  </span>
                </div>
              </>
            ) : prop.miniName !== undefined ? (
              <>
                <span className="sidenav-mini-icon"> {prop.miniName} </span>
                <span className="sidenav-normal">
                  {!prop.collapse ? prop?.name : ""}
                  {getBadge(prop?.badge)}
                </span>
              </>
            ) : (
              <>
                {!prop.collapse ? prop?.name : ""}
                {getBadge(prop?.badge)}
              </>
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const scrollBarInner = (
    <div className="scrollbar-inner" >
      <div className="sidenav-header d-flex align-items-center">
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        
        { !sidenavAlwaysOpen && (
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler ", {
                active: sidenavOpen,
              })}
              onClick={toggleSidenav}
            >
              
              <div className="sidenav-toggler-inner">
                { sidenavOpen ? (
                  <i className="fas fa-times text-light"></i>
                ) : (
                  <i className="fas fa-angle-right text-light"></i>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="navbar-inner" ref={divMenuRef}>
        <Collapse navbar isOpen={true}>
          <Nav navbar>
            {createLinks(routes, false)}
            <NavItem>
              <NavLink
                className="nav-link"
                activeClassName=""
                onClick={handleLogout}
              >
                <>
                  <i className="ni ni-button-power text-primary" />
                  <span className="nav-link-text">{t("sidebar_logout", "Kirjaudu ulos")}</span>
                </>
              </NavLink>
            </NavItem>
            
            { isMobile === true && sidenavOpen === true ? (
            <NavItem>
              <NavLink>
                <img src={myUser?.language == "fi" ? coFoundedEuFi : coFoundedEuEn} className="w-100 sk_eu_logo_image" />
              </NavLink>
            </NavItem>
            ) : null }
          </Nav>
          
        </Collapse>
      </div>
      { isMobile === false && sidenavOpen === true ? (
        <div className="navbar-inner sk_eu_logo_container" ref={divEuRef}>
          { !isOverlapping ? (
            <img src={myUser?.language == "fi" ? coFoundedEuFi : coFoundedEuEn} className="w-100" />
          ) : null }
        </div>
      ) : null }
    </div>
  );
  
  /*
  ${sidenavAlwaysOpen ? "navbar-fixed" : "navbar-responsive"}
  */
  
  return (
    <Navbar
      className={`
        sidenav navbar-vertical navbar-expand-xs navbar-dark bg-dark navbar-fixed
        ${rtlActive ? "" : "fixed-left"}
        ${sidenavOpen ? "" : "sidebar_sidenav_open"}
        ${hoverKeepOpen ? "sidenav_hover_keep_open" : ""}
      `}
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
      style={{zIndex: 15}}
    >
      {navigator.platform.indexOf("Win") > -1 ? (
        <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
      ) : (
        scrollBarInner
      )}
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
  sidenavAlwaysOpen: false,
};

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
};

export default Sidebar;

import React, { useState, useCallback, useEffect } from "react";

import { Button, Modal, Typography } from "stories/components"
import ProductStockEdit from "views/dashboard/ProductStockEdit/ProductStockEdit"
import { httpGetStockItems } from "services/stock"
import { useTranslation } from 'react-i18next';

const ProductStock = ({productId, productVariationId, is_mcf_product=false, resolve}) => {
  
  const { t } = useTranslation();
  const [stockItem, setStockItem] = useState()
  
  const getStockItem = () => {
    const params = {
      product_fk: productVariationId && productVariationId > 0 ? undefined : productId,
      product_variation_fk: productVariationId
    }
    setStockItem(null)
    httpGetStockItems(params).then(res => {
      if (res?.data && res?.data.length > 0) {
        res?.data.map(stock => {
          if (productVariationId && productVariationId > 0) {
            // Variation stock
            if (stock.product_variation_fk == productVariationId) {
              setStockItem(stock)
            }
          }
          else {
            // Main product stock
            if (stock.product_fk == productId) {
              setStockItem(stock)
            }
          }
        })
        
      }
    })
  }
  
  useEffect(() => {
    getStockItem();
  }, [productId, productVariationId])
  
  const [modalOpen, setModalOpen] = useState(false)
  
  const toggleModal = () => {
    setModalOpen(s => !s)
  }
  
  const handleResolve = () => {
    getStockItem()
    resolve()
    setModalOpen(false)
  }
  
  const handleReject = () => {
    setModalOpen(false);
  }
  
  const handleCreateStockItem = () => {
    setModalOpen(true);
  }
  
  return (
    <>
      { stockItem && (
        <div>
          { stockItem?.enabled ? (
            <><Typography bold inline>{t("Varastossa")}:</Typography> <Typography inline>{stockItem?.quantity}</Typography></>
          ) : (
            <><Typography bold inline>{t("Varastossa")}:</Typography> <Typography inline>{t("Rajoittamaton")}</Typography></>
          )}
        </div>
      )}
      
      { stockItem ? (
        <Button
          size="sm"
          onClick={handleCreateStockItem}
        >
          {t("Muokkaa varastotuotetta")}
        </Button>
      ) : (
        <Button
          size="sm"
          onClick={handleCreateStockItem}
          disabled={is_mcf_product === false}
        >
          {t("Luo varastotuote")}
        </Button>
      )}
    
      <Modal
        size="modal-lg"
        isOpen={modalOpen}
        toggleModal={toggleModal}
        className="modal-dialog-centered modal-secondary"
      >
        <ProductStockEdit 
          modalOpen={modalOpen}
          stockItemId={stockItem?.id}
          productId={productId}
          productVariationId={productVariationId}
          handleResolve={handleResolve}
          handleReject={handleReject}
        />
      </Modal>
    </>
  )
}

export default ProductStock;
export const cleanEmail = (email) => {
  return email.toLowerCase().replace(" ", "");
}

export const listToString = (list) => {
  if (list == null || list.length == 0) {
    return "";
  }
  const size = list.length;
  return list && list.map((elem, i) => {
    let txt = elem.name;
    if (i < size-1) {
      txt += ","
    }
    return txt;
  })
}

export const round = (value, precision) => {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const limitText = (t_string="", t_char_limit=50) => {
  if (t_string == undefined || t_string == null || t_string === "") {
    return "";
  }
  if (t_string.length > t_char_limit) {
    return t_string.slice(0, t_char_limit) + "...";
  }
  else {
    return t_string;
  }
  
}

export const compare = (a,b ) => {
  if ( a["name_sort"] < b["name_sort"] ){
    return -1;
  }
  if ( a["name_sort"] > b["name_sort"] ){
    return 1;
  }
  return 0;
}

export const isObject = (variable) => {
  return Object.prototype.toString.call(variable) === '[object Object]';
}
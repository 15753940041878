import React, { useCallback } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Button, IconButton, Link } from "stories/components"
import { useAuthContext }from "contexts/AuthContext"
import defaultAvatar from "assets/images/boss-1.png"

function AdminNavbar({ myUser, theme, sidenavOpen, toggleSidenav, sideNavToggleButtonVisible, topNavOpen, toggleTopNav, topNavButtons }) {
  
  // function that on mobile devices makes the search open
  const openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  const closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  
  const renderTopNavButtons = useCallback(() => {
    return topNavButtons && topNavButtons.map((topNavButton, index) => {
      return (
        <div className="mr-2">
          <Link isExternal href={topNavButton?.url}>
            <Button color="secondary">
              {topNavButton?.name} <div className="ml-4 avatar rounded-circle bg-primary w-32 h-32"><i className={`fas ${topNavButton?.icon_font_awesome}`}></i></div>
            </Button>
          </Link>
        </div>
      )
    })
  }, [topNavButtons])
  
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" },
          { "d-none": topNavOpen === false },
        )}
      >
        <Container fluid>
          
          {renderTopNavButtons()}
          
          <Collapse navbar isOpen={true}>
            <Nav className="align-items-center ml-md-auto" navbar>
              
            </Nav>
            
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={myUser?.picture || defaultAvatar}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {myUser?.email}
                    </span>
                  </Media>
                </Media>
              </UncontrolledDropdown>
              
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;

import http from './api'

const url = '/brands'

export const httpGetBrands = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

export const httpImportBrandsFromMCF = () => {
  return http({
    url: `${url}/import_from_mcf` ,
    method: 'GET',
  })
}
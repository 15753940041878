import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import {
  Card,
  Typography,
  Button,
  Link,
  IconButton,
  Breadcrumb,
  BreadcrumbItem,
} from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetSuppliers } from "services/suppliers"
// import history from "services/history"

const SuppliersList = ({history}) => {
  
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
    ordering: "name",
  })
  
  const getSuppliers = () => {
    setLoading(true)
    httpGetSuppliers(filters).then(res => {
      setRows(res?.data)
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getSuppliers();
  }, [])
  
  useEffect(() => {
    getSuppliers();
  }, [filters])
  
  const headers = [
    { label: "", key: "actions"},
    { label: t("Nimi"), key: "name"},
    { label: t("Koodi"), key: "code"},
    { label: t("Paikkakunta"), key: "city"},
    { label: t("Käyttäjät"), key: "users_count"},
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/suppliers/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/suppliers/edit/${id}/basic`);
  }
  
  const handleUsers = (id) => {
    history.push(`/dashboard/suppliers/users/list/${id}/`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        id: row?.id,
        name: (
          <>
            <Link to={`/dashboard/suppliers/edit/${row?.id}/basic`}>
              <Typography bold>{row?.name}</Typography>
            </Link>
          </>
        ),
        code: (
          <>
            <Typography>{row?.code}</Typography>
          </>
        ),
        city: (
          <>
            <Typography>{row?.city}</Typography>
          </>
        ),
        users_count: (
          <>
            <Typography>{row?.users_count}</Typography>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            <IconButton size="lg" onClick={() => handleUsers(row?.id)} iconName="users" badgeColor="warning" badgeValue={row?.users_count === 0 ? "!" : null}  />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
          </>
        ),
      }
    })
  }, [rows])
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Tavarantoimittajat")}></PageHeader>
        </Col>
        {/*
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
              <div className="p-2"><Typography variant="small"></Typography></div>
          </div>
        </Col>
        */}
      </Row>
      
      
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
          <Button color="success" onClick={handleNew}>{t("Lisää tavarantoimittaja")}</Button>
        </Col>
      </Row>
      
      
      <TableAuto
        color="dark"
        showId={true}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
        tableStickyHeader={true}
      />
      
    </>
  );
}

export default SuppliersList;

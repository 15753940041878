import http from './api'

const url = '/products'

export const httpExportProductsToFile = (params) => {
    return http({
      url: `${url}/export_products_to_file/`,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': "blob"
      },
      params,
    })
  }

  export const httpExportProductVariationsToFile = (params) => {
    return http({
      url: `${url}/export_product_variations_to_file/`,
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': "blob"
      },
      params,
    })
  }

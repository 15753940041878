import React from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";

import './Toggle.css';

export const Toggle = ({ defaultChecked, labelOn, labelOff, className, ...rest }) => {
  return (
    <div className={`toggle_container`}>
    <label className="custom-toggle">
      <input 
        defaultChecked={defaultChecked}
        className={`${className ? className : ""}`}
        type="checkbox"
        {...rest}
      />
      <span
        className={`custom-toggle-slider rounded-circle}`}
        data-label-off={labelOff}
        data-label-on={labelOn}
        
      />
    </label>
    </div>
  )
};

Toggle.propTypes = {
  defaultChecked: PropTypes.bool,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
};

Toggle.defaultProps = {
  defaultChecked: false,
  labelOn: "Yes",
  labelOff: "No",
};

/*
Example

<Toggle
  {...getFormikInputProps("enabled")}
  defaultChecked={values?.enabled}
  labelOn="Kyllä"
  labelOff="Ei"
/>
*/
              

import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetBrands, httpImportBrandsFromMCF } from "services/brands"

const BrandsList = ({history}) => {
  
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const getBrands = () => {
    setLoading(true)
    httpGetBrands(filters).then(res => {
      setRows(res?.data)
      setLoading(false)
    }, errorResponse => {
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getBrands();
  }, [])
  
  useEffect(() => {
    getBrands();
  }, [filters])
  
  const handleImportBrandsFromMCF = () => {
    httpImportBrandsFromMCF().then(res => {
      getBrands()
    })
  }
  
  const headers = [
    // { label: "", key: "actions"},
    { label: t("Nimi"), key: "name"},
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/categories/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/categories/edit/${id}/`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        id: row?.id,
        name: (
          <Typography bold>{row?.name}</Typography>
        ),
        /*
        name: (
          <>
            <Typography bold>
              <Link to={`/dashboard/brands/edit/${row?.id}/`}>
              {row?.name}
              </Link>
            </Typography>
          </>
        ),
        */
        actions: (
          <>
            
            {/*
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="eye" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="exclamation" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
            */}
          </>
        ),
      }
    })
  }, [rows])
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Tuotemerkit")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <Typography variant="small">
                <Link variant="link" onClick={handleImportBrandsFromMCF}>
                  {t("Tuo tiedot (MCF)")}
                </Link>
              </Typography>
            </div>
            
          </div>
        </Col>
      </Row>
      
      
      
      
      {/*
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
          <Button color="success" onClick={handleNew}>{t("Lisää tuotemerkki")}</Button>
        </Col>
      </Row>
      */}
      
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
      />
      
    </>
  );
}

export default BrandsList;

import React from 'react';
import PropTypes from 'prop-types';

// import './Radio.css';

export const Radio = ({ choices, checkedChoice, name, className, inline, ...rest }) => {
  return (
    <>
    { choices && choices.map((choice, key) => (
      <div key={key} className={`custom-control custom-radio ${inline ? "custom-control-inline": ""} mb-3`}>
        <input
          {...rest}
          className="custom-control-input"
          id={choice?.id || key}
          name={name}
          type="radio"
          value={choice?.value}
          checked={checkedChoice == choice?.value.toString()}
        />
        <label className="custom-control-label" htmlFor={choice?.id || key}>
          {choice.label}
        </label>
      </div>
    ))}
    
    </>
  )
};

Radio.propTypes = {
  checkedChoice: PropTypes.string,
  choices: PropTypes.array,
  defaultChecked: PropTypes.bool,
  inline: PropTypes.bool,
  labelOn: PropTypes.string,
  labelOff: PropTypes.string,
  name: PropTypes.string,
};

Radio.defaultProps = {
  choices: [
    {id: 1, label: "Example choice A", value: "1"},
    {id: 2, label: "Example choice B", value: "2"},
  ],
  defaultChecked: false,
  inline: false,
  labelOn: "Yes",
  labelOff: "No",
};

/*
Example

<Radio
    {...getFormikInputProps("my_fieldname")}
    checkedChoice={values?.my_fieldname.toString()}
    choices={[
      { id: 1, label: "Option A", value: "false"},
      { id: 2, label: "Option B", value: "true"},
    ]}
/>
*/
              

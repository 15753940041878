import http from './api'

const base_url_calculator = '/purchase_price_calculator'

/*
const base_url_orders = '/product_purchase_prices'

export const httpGetProductPurchasePrice = (purchaseOrderProductId, params) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderProductId}/`,
    method: 'GET',
    params,
  })
}
*/

export const httpGetProductPurchasePrice = (purchaseOrderProductId, params) => {
  return http({
    url: `${base_url_calculator}/purchase_order_product/${purchaseOrderProductId}/purchase_price_change/`,
    method: 'GET',
    params,
  })
}


export const httpCalculateProductPurchasePrice = (purchaseOrderProductId, data) => {
  return http({
    url: `${base_url_calculator}/purchase_order_product/${purchaseOrderProductId}/calculate_purchase_price/`,
    method: 'POST',
    data,
  })
}

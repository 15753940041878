import { createStore, action, thunk, reducer } from 'easy-peasy';
import { httpLogin } from 'services/auth'
import authModel from './auth-model';
import badgesModel from './badges-model';

export const store = createStore({
  auth: authModel,
  badges: badgesModel,
});



/*
export const store = createStore({
  auth: {},
});

const todoReducer = {
  todos: [],
  addTodo: action((state, payload) => {
    state.todos.push(payload);
  }),
  saveTodo: thunk(async (actions, payload) => {
    const { data } = await axios.post('/todos', payload);
    actions.addTodo(data);
  }),
};

*/
import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import PurchaseOrderDeliveredProductsList from "views/dashboard/PurchaseOrderDeliveredProductsList/PurchaseOrderDeliveredProductsList"
import { useNotification } from "stories/components/Notification"
import {
  httpGetPurchaseOrderDelivery,
  httpCreatePurchaseOrderDelivery,
  httpUpdatePurchaseOrderDelivery,
} from "services/purchase_orders"

const defaultValues = {
  id: null,
  delivery_date: moment().format("yyyy-MM-DDTHH:mm"),
}
const PurchaseOrderDelivery = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      history,
      t
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("delivery_date")}
                label={t("Saapumispäivä")}
                type="datetime-local"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Lisätiedot")}
                type="textarea"
              />
            </Col>
          </Row>
          
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <>
            <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>
              { values?.id ? t("Tallenna") : t("Luo") }
            </Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </>
        </Col>
      </Row>
        
    </>
  );
}

const PurchaseOrderDeliveryFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        /*
        amount: Yup.number().integer().test(
          'Is positive?',
          'VIRHE: Määrän on oltava suurempi kuin 0', 
          (value) => value > 0
        ).required(required),
        */
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        purchase_order: props.purchaseOrderId,
        delivered_products_data: props?.deliveredProductsData,
      };
      
      if (values?.id) {
        
        httpUpdatePurchaseOrderDelivery(props.purchaseOrderId, props.purchaseOrderDeliveryId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tallennettu"), message:t("Osatoimitus tallennettu")})
          props.handleResolve(res?.data?.id)
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      else {
        httpCreatePurchaseOrderDelivery(props.purchaseOrderId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Luotu"), message:t("Uusi osatoimitus avattu")})
          props.handleResolve(res?.data?.id)
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PurchaseOrderDelivery)
    
    
const PurchaseOrderDeliveryView = ({history, purchaseOrderId, purchaseOrderDeliveryId, deliveredProductsData, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const getPurchaseOrderDelivery = (purchaseOrderId, purchaseOrderDeliveryId) => {
    setLoading(true)
    httpGetPurchaseOrderDelivery(purchaseOrderId, purchaseOrderDeliveryId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        delivery_date:res?.data?.delivery_date ? moment(res?.data?.delivery_date).format("yyyy-MM-DDTHH:mm") + "" : null,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > -1 && purchaseOrderDeliveryId && parseInt(purchaseOrderDeliveryId) > -1) {
      getPurchaseOrderDelivery(purchaseOrderId, purchaseOrderDeliveryId)
    }
  }, [purchaseOrderId, purchaseOrderDeliveryId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <PurchaseOrderDeliveryFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} purchaseOrderDeliveryId={purchaseOrderDeliveryId} deliveredProductsData={deliveredProductsData} {...rest} />
      { purchaseOrderDeliveryId && purchaseOrderDeliveryId > 0 && (
        <PurchaseOrderDeliveredProductsList history={history} purchaseOrderId={purchaseOrderId} purchaseOrderDeliveryId={purchaseOrderDeliveryId} />
      )}
    </>
  )
  

}
  

export default PurchaseOrderDeliveryView;

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { 
  httpUpdatePurchaseOrder,
  httpPublishPurchaseOrder,
  httpUploadPurchaseOrderFile,
} from "services/purchase_orders"

const defaultValues = {
  id: null,
}

const PurchaseOrderPublish = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t,
      publishing,
      existingAttachment,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Kommentti")}
                placeholder={t("Jätä kommentti tilaukseen")}
                type="textarea"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                label={existingAttachment ? t("Korvaa liite (PDF, Excel, PNG, JPG)") : t("Lisää liite (PDF, Excel, PNG, JPG)")}
                type="file"
                name="file"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
            </Col>
          </Row>
          
          { existingAttachment && (
          <Row>
            <Col className="mb-3">
              <i className="fas fa-paperclip"></i> <a href={existingAttachment} target="_blank">{t("Lataa liite")}</a>
            </Col>
          </Row>
          )}
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Sulje")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" loading={isSubmitting || publishing} disabled={isSubmitting || publishing} onClick={() => validateForm().then(() => handleSubmit())}>{t("Lähetä tilaus")}</Button>
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const PurchaseOrderPublishFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        description: Yup.string().nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
      };
      
      if (props?.purchaseOrderId) {
        httpUpdatePurchaseOrder(props.purchaseOrderId, data).then(res => {
          // Upload attachment
          if (values?.file) {
            let formData = new FormData();
            let file = values?.file;
            formData.append('file_attachment', file);
            
            httpUploadPurchaseOrderFile(props.purchaseOrderId, formData).then(fres => {
              setSubmitting(false);
              props.handlePublish()
            })
          }
          else {
            setSubmitting(false);
            props.handlePublish()
          }
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
    },
    displayName: "BasicForm"
  
})(PurchaseOrderPublish)
    
    
const PurchaseOrderPublishView = ({history, purchaseOrderId, description, existingAttachment, modalOpen, handleResolve, handleReject, ...rest}) => {
  // API requests here
  const [loading, setLoading] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { t } = useTranslation();
  
  useEffect(() => {
    setPreSetValues(s => ({
      ...s,
      description: description,
    }))
      
  }, [description])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  const handlePublish = () => {
    setPublishing(true)
    httpPublishPurchaseOrder(purchaseOrderId).then(res => {
      handleResolve()
    }, error => {
      handleReject()
    });
  }

  return (
    <PurchaseOrderPublishFormik 
      t={t} 
      history={history} 
      publishing={publishing} 
      preSetValues={preSetValues} 
      notify={notify} 
      purchaseOrderId={purchaseOrderId} 
      handlePublish={handlePublish} 
      handleReject={handleReject}
      existingAttachment={existingAttachment}
      {...rest} 
    />
  )
  

}
  

export default PurchaseOrderPublishView;

import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { 
  httpGetPurchaseOrderPayment,
  httpUpdatePurchaseOrderPayment,
  httpUploadPurchaseOrderPaymentFile
} from "services/purchase_orders"

import { useAppContext } from "contexts/AppContext"

const defaultValues = {
  id: null,
  payment_paid_state: 1,
}

const PurchaseOrderPaymentEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm,
     preSetValues
   } = props;
   
   const {
      history,
      t,
   } = props;
   
   const { enums } = useAppContext()
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  const getPaymentStateOptions = useCallback(() => {
    return enums?.purchase_orders?.purchase_order?.payment_paid_state && enums?.purchase_orders?.purchase_order?.payment_paid_state.map(obj => {
      return (
      <>
        <option value={obj.key}>{t(obj.value)}</option> {/* i18next-extract-disable-line */}
      </>
      )
    })
  }, [enums])
  
  const getPaymentAttachments = useCallback(() => {
    if (values?.payment_attachments && values?.payment_attachments.length > 0) {
      
      return values?.payment_attachments.map(payment_attachment => {
        return (
          <Row>
            <Col className="mb-3">
              <i className="fas fa-paperclip"></i> <a href={payment_attachment?.attachment_file} target="_blank">{t("purchase_order_payment_edit_download","Lataa")} {payment_attachment?.attachment_file_name}</a>
            </Col>
          </Row>
        )
      })
    }
    return null;
  }, [values?.payment_attachments])
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("payment_billing_details")}
                label={t("purchase_order_payment_edit_billing_details","Liitä maksutiedot")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              {/*
              label={values?.payment_bill_attachment ? t("purchase_order_payment_edit_replace_attachment","Korvaa lasku (pdf, excel)") : t("purchase_order_payment_edit_add_attachment","Lisää lasku (pdf, excel)")}
              */}
              <Input
                label={t("purchase_order_payment_edit_add_attachment","Lisää lasku (pdf, excel)")}
                type="file"
                name="file"
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
            </Col>
          </Row>
          
          {/* DEPRECATED field but show if there is file */}
          { values?.payment_bill_attachment && (
          <Row>
            <Col className="mb-3">
              <i className="fas fa-paperclip"></i> <a href={values?.payment_bill_attachment} target="_blank">{t("purchase_order_payment_edit_download_bill","Lataa lasku")}</a>
            </Col>
          </Row>
          )}
          
          {getPaymentAttachments()}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("payment_other_details")}
                label={t("purchase_order_payment_edit_other_details","Muut huomiot")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mt-3 mb-3">
              <Input label={t("purchase_order_payment_edit_paid_state","Laskun tila")} type="select" {...getFormikInputProps("payment_paid_state")}>
                <option value=""></option>
                {getPaymentStateOptions()}
              </Input>
            </Col>
          </Row>
          
          { values?.payment_paid_state && values?.payment_paid_state > 1 && (
          <Row>
            <Col className="mt-3 mb-3">
              <Input
                {...getFormikInputProps("payment_paid_state_dt")}
                label={t("purchase_order_payment_edit_payment_datetime","Maksupäivä")}
                type="date"
              />
            </Col>
          </Row>
          )}
        
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Sulje")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          { !preSetValues?.state_paid && (
          <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>
            { !preSetValues?.payment_billing_details ? t("purchase_order_payment_edit_paid_state","Lisää laskutiedot tilaukselle") : t("save_button","Tallenna") }
          </Button>
          )}
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

const PurchaseOrderPaymentEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        name: Yup.string().nullable(),
        payment_paid_state: Yup.number().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        payment_paid_state_dt: values?.payment_paid_state_dt ? moment(values?.payment_paid_state_dt).format() : null,
      };
      
      if (values?.id) {
        httpUpdatePurchaseOrderPayment(values.id, data).then(res => {
          setSubmitting(false);
          
          if (res?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ 
              title:t("Sähköposti"), 
              message:t("Lähettäminen epäonnistui"), 
              type:"warning"
            })
          }
          
          // Upload attachment
          if (values?.file) {
            let formData = new FormData();
            let file = values?.file;
            formData.append('payment_bill_attachment', file);
            
            httpUploadPurchaseOrderPaymentFile(values.id, formData).then(fres => {
              props.notify({ 
                title:t("Maksutiedot"), 
                message:t("Tallennettu onnistuneesti"),
                type: "success",
              })
              props.handleResolve()
            })
          }
          else {
            props.notify({ 
              title:t("Maksutiedot"), 
              message:t("Tallennettu onnistuneesti"),
              type: "success",
            })
            props.handleResolve()
          }
          
        }, error => {
          setSubmitting(false);
          
          if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
          }
          else {
            if (error?.data?.detail) {
              setErrors({
                general: error?.data?.detail,
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
          
        })
      }
      
    },
    displayName: "BasicForm"
  
})(PurchaseOrderPaymentEdit)
    
    
const PurchaseOrderPaymentEditView = ({history, purchaseOrderId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const getPurchaseOrderPayment = (pId) => {
    setLoading(true)
    httpGetPurchaseOrderPayment(pId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        payment_paid_state_dt: res?.data?.payment_paid_state_dt ? moment(res?.data?.payment_paid_state_dt).format('YYYY-MM-DD') : null,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > -1) {
      getPurchaseOrderPayment(purchaseOrderId)
    }
  }, [purchaseOrderId, modalOpen])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderPaymentEditFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} {...rest} />
  )
  

}
  

export default PurchaseOrderPaymentEditView;

import http from './api'

// Suppliers

const url = '/suppliers'

export const httpGetSuppliers = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

export const httpGetSupplier = (id) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'GET',
  })
}

export const httpCreateSupplier = (data) => {
  return http({
    url: `${url}/` ,
    method: 'POST',
    data,
  })
}

export const httpUpdateSupplier = (id, data) => {
  return http({
    url: `${url}/${id}/` ,
    method: 'PATCH',
    data,
  })
}

// SupplierUsers

/*
export const httpCreateSupplierUser = (supplierId, data) => {
  return http({
    url: `${url}/${supplierId}/create_user/` ,
    method: 'POST',
    data
  })
}
*/

export const httpGetSupplierUsers = (supplierId, params) => {
  return http({
    url: `${url}/${supplierId}/user_roles/` ,
    method: 'GET',
    params,
  })
}

export const httpGetSupplierUser = (supplierId, userId) => {
  return http({
    url: `${url}/${supplierId}/user_roles/${userId}/` ,
    method: 'GET',
  })
}

export const httpCreateSupplierUserRole = (supplierId, data) => {
  return http({
    url: `${url}/${supplierId}/user_roles/` ,
    method: 'POST',
    data,
  })
}

export const httpUpdateSupplierUserRole = (supplierId, userId, data) => {
  return http({
    url: `${url}/${supplierId}/user_roles/${userId}/` ,
    method: 'PATCH',
    data
  })
}

// MySupplier

export const httpGetMySupplier = () => {
  return http({
    url: `/mysupplier/me` ,
    method: 'GET',
  })
}



import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import {
  ListGroupItem,
  ListGroup,
} from "reactstrap";

import { 
  Breadcrumb,
  BreadcrumbItem, 
  Button, 
  Link,
  Typography, 
  Spinner, 
  Modal, 
  IconButton 
} from "stories/components";

import { Container, Row, Col, FormContainer } from "stories/layout";
import { Input, SelectMulti, Dropdown } from "stories/forms";
import { Table, THead } from "stories/tables";

import { useNotification } from "stories/components/Notification"
import { httpGetBrands } from "services/brands"
import { httpGetCategories } from "services/categories"
import { httpGetSuppliers } from "services/suppliers"
import { httpGetVersions } from "services/versions"
import { httpGetProductAttributes } from "services/product_attributes"
import { 
  httpGetProduct, 
  httpCreateProduct, 
  httpUpdateProduct, 
  httpDeleteProduct, 
  httpArchiveProduct, 
  httpExportProductToMCF 
} from "services/products" 
import ProductStock from "components/Products/ProductStock"
import { getDateAndTime } from "services/datetime"
import { useAppContext } from "contexts/AppContext"

const defaultValues = {
  categories: [],
  codes: [],
  product_supplier_codes: [],
  id: null,
  name: '',
  suppliers: [],
  versions: [],
  // activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

const ProductEdit = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     // isTouched,
     isValid,
     isSubmitting,
     setFieldTouched,
     submitCount,
     validateForm
   } = props;
   
   const {
      history,
      t,
      enums
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const isTouched = useCallback(() => {
     if (Object.keys(touched).length > 0) {
       return true;
     }
     return false;
  }, [touched])
  
  const getStatusOptions = useCallback(() => {
    return enums?.products?.product?.status && enums?.products?.product?.status.map(obj => {
      return (
        <>
          {/* i18next-extract-disable-next-line */}
          <option value={obj.key}>{t(obj.value)}</option>
        </>
      )
    })
  }, [enums])
  
  const handleCancel = () => {
    history.push("/dashboard/products/list");
  }
  
  const handleArchive = () => {
    const result = window.confirm(t("Haluatko varmasti arkistoida tuotteen?"));
    if (result) {
      httpArchiveProduct(values?.id).then(res => {
        history.push("/dashboard/products/list");
      }, (error) => {
        
      })
    }
  }
  
  // Brands
  
  const [brands, setBrands] = useState();
   
   useEffect(() => {
     getBrands();
   }, [])
   
  const getBrands = () => {
    
    httpGetBrands({ordering:"name"}).then(res => {
      setBrands(res?.data)
    })
  }
  
  const getBrandOptions = useCallback(() => {
    return brands && brands.map(brand => {
      return (
        <option value={brand.id}>{brand.name}</option>
      )
    })
  }, [brands])
  
  // Categories
  
  const [categories, setCategories] = useState();
   
   useEffect(() => {
     getCategories();
   }, [])
   
  const getCategories = () => {
    
    httpGetCategories({ordering:"name"}).then(res => {
      setCategories(res?.data)
    })
  }
  
  const getCategoryOptions = useCallback(() => {
    return categories && categories.map(category => {
      return {value: category.id, label: category.name}
    })
  }, [categories])
  
  const getSelectedCategories = useCallback(() => {
    return values?.categories && values?.categories.map(category => {
      return {value: category.id, label: category.name}
    })
  }, [values?.categories])
  
  // Suppliers
  
  const [suppliers, setSuppliers] = useState();
   
   useEffect(() => {
     getSuppliers();
   }, [])
  
  const getSuppliers = () => {
    
    httpGetSuppliers({ordering:"name"}).then(res => {
      setSuppliers(res?.data)
    })
  }
  
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return {value: supplier.id, label: supplier.name}
    })
  }, [suppliers])
  
  const getSupplierInputOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option value={supplier.id}>{supplier.name}</option>
      )
    })
  }, [suppliers])
  
  const getSupplierInputOptionsFiltered = useCallback(() => {
    // List only Suppliers which are selected to the Product
    return values?.suppliers && values?.suppliers.map(supplier => {
      return (
        <option value={supplier?.id}>{supplier?.name}</option>
      )
    })
  }, [suppliers, values?.suppliers])
  
  const getSelectedSuppliers = useCallback(() => {
    return values?.suppliers && values?.suppliers.map(supplier => {
      return {value: supplier.id, label: supplier.name}
    })
  }, [values?.suppliers])
  
  const getSupplier = (id) => {
    return suppliers && suppliers.reduce((result, item) => {
      if (parseInt(item.id) === parseInt(id)) { result = item }
      return result
    }, null)
  }
  
  const getSupplierName = useCallback((id) => {
    const supplier = getSupplier(id)
    return supplier ? supplier?.name : ""
  }, [suppliers])
  
  // Versions
  
  const [versions, setVersions] = useState();
   
   useEffect(() => {
     getVersions();
   }, [])
  
  const getVersions = () => {
    
    httpGetVersions().then(res => {
      setVersions(res?.data)
    })
  }
  
  const getVersionOptions = useCallback(() => {
    return versions && versions.map(version => {
      return {value: version.id, label: version.name}
    })
  }, [versions])
  
  const getSelectedVersions = useCallback(() => {
    return values?.versions && values?.versions.map(version => {
      return {value: version.id, label: version.name}
    })
  }, [values?.versions])
  
  // Product Codes
  
  const [codesModalOpen, setCodesModalOpen] = useState(false);
  const newCodeDefault = {
    batch: 1,
    formOpen: false,
    id: -1,
    minimum_order_quantity: 1,
    name: "",
    supplier_fk: null,
  }
  const [newCode, setNewCode] = useState(newCodeDefault);
  
  const handleOpenCodesModal = () => {
    setCodesModalOpen(true)
  }
  
  const toggleCodesModal = () => {
    setCodesModalOpen(s => !s)
  }
  
  const getCodesList = useCallback(() => {
    if (values?.product_supplier_codes && values?.product_supplier_codes.length > 0) {
      return values?.product_supplier_codes && values?.product_supplier_codes.map((code, index) => {
        
        return (
          <tr>
              <td>{getSupplierName(code?.supplier_fk)}</td>
              <td>{code?.name}</td>
              <td>{code?.batch}</td>
              <td>{code?.minimum_order_quantity}</td>
              <td><IconButton size="lg" onClick={() => removeCode(index)} iconName="trash" /></td>
          </tr>
        )
      })
    }
    else {
      return (
        <tr><td colspan="5">{t("No product supplier codes")}</td></tr>
      )
    }
  }, [suppliers, values?.product_supplier_codes])
  
  
  const removeCode = (index) => {
    let codes = [
      ...values?.product_supplier_codes
      ]
    codes.splice(index, 1)
    setFieldValue("product_supplier_codes", codes)
  }
  
  const handleOpenNewCode = () => {
    setNewCode({
      ...newCodeDefault,
      formOpen: true,
    })
  }
  
  const handleNewCodeChange = ({target}) => {
    setNewCode(s => ({
      ...s,
      formOpen: true,
      [target?.name]: target?.value,
    }))
  }
  
  const handleAddCode = () => {
    let codes = [
      ...values?.product_supplier_codes,
      newCode,
    ]
    setFieldValue("product_supplier_codes",codes)
    setNewCode({
      ...newCodeDefault,
      formOpen: false,
    })
  }
  
  // Product attributes
  const [productAttributes, setProductAttributes] = useState();
  
   useEffect(() => {
     getProductAttributes();
   }, [])
  
  const getProductAttributes = () => {
    
    httpGetProductAttributes().then(res => {
      setProductAttributes(res?.data)
    })
  }

  const getProductAttributeOptions = useCallback(() => {
    return productAttributes && productAttributes.map(productAttribute => {
      return {value: productAttribute.id, label: productAttribute.code}
    })
  }, [productAttributes])
  
  const handleSave = () => {
    validateForm().then(() => handleSubmit())
  }
  
  const handleProductStockResolve = () => {
    //jk
  }
  
  return (
    <>
      <div className="d-flex flex-row-reverse p-2">
        <div>
          { values?.id && (
          <Typography variant="small">{t("product_edit_mcf_updated","Päivitetty MCF")}: {values?.updated_mcf ? getDateAndTime(values?.updated_mcf) : ""}</Typography>
          )}
        </div>
      </div>
      
      <Row>
        
        {/* First column START */}
        
        <Col className="col-12 col-lg-6 col-xl-4">
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Perustiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Typography bold inline>{t("ID (MCF)")}</Typography>: <Typography inline>{values?.id_mcf}</Typography>
            </Col>
          </Row>
          
          {/*
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name")}
                label={t("Nimi")}
                required
              />
            </Col>
          </Row>
          */}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name_en")}
                label={t("Nimi (en)")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("name_fi")}
                label={t("Nimi (fi)")}
                required
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <SelectMulti 
                disabled
                label={t("Tuotekategoriat")} 
                name="categories" 
                values={values} 
                setFieldValue={setFieldValue} 
                defaultValues={getSelectedCategories()} 
                options={getCategoryOptions()} 
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Tuotekuvaus")}
                type="textarea"
                readonly="readonly"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("information")}
                label={t("Tarkkatuotekuvaus (information)")}
                type="textarea"
                readonly="readonly"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("keywords")}
                label={t("Avainsanat")}
                readonly="readonly"
              />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("brand_id")} label={t("Tuotemerkki")} type="select">
                <option value=""></option>
                {getBrandOptions()}
              </Input>
            </Col>
          </Row>
        </Col>
        
        {/* First column END */}
        
        {/* Second column START */}

        <Col className="col-12 col-lg-6 col-xl-8">
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Myyntitiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("purchase_price")}
                label={t("Ostohinta")}
                type="number"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("price")}
                label={t("Myyntihinta")}
                type="number"
              />
            </Col>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("vat_rate")}
                label={t("Vero")}
                type="number"
                required
              />
            </Col>
          </Row>
          
          {/* use StockItem.barcode
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("barcode")}
                label={t("Viivakoodi (EAN)")}
              />
            </Col>
          </Row>
          */}
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("product_code")}
                label={t("Tuotenumero")}
                required
              />
            </Col>
          </Row>
          
          {/* Supplier product codes BEGIN */}
          
          <Row>
            <Col className="mb-3">
              <SelectMulti label={t("Tavarantoimittajat")} name="suppliers" values={values} setFieldValue={setFieldValue} defaultValues={getSelectedSuppliers()} options={getSupplierOptions()} />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              
                <div className="mb-2">
                  <Table>
                    <THead>
                    <tr>
                      <th>{t("Toimittaja")}</th>
                      <th>{t("Tuotenumero")}</th>
                      <th>{t("Batch")}</th>
                      <th title={t("Minimum order quantity")}>{t("MOQ")}</th>
                      <th>&nbsp;</th>
                    </tr>
                    </THead>
                    <tbody>
                      {getCodesList()}
                    </tbody>
                  </Table>
                </div>
                
                { newCode?.formOpen ? (
                  <Table>
                    <THead>
                    <tr>
                      <th>{t("Toimittaja")}</th>
                      <th>{t("Tuotenumero")}</th>
                      <th>{t("Batch")}</th>
                      <th title={t("Minimum order quantity")}>{t("MOQ")}</th>
                      <th>&nbsp;</th>
                    </tr>
                    </THead>
                    <tbody>
                      <tr>
                        <td>
                          <Input required name="supplier_fk" type="select" onChange={handleNewCodeChange}>
                            <option value=""></option>
                            {getSupplierInputOptionsFiltered()}
                          </Input>
                        </td>
                        <td><Input required name="name" value={newCode?.name} onChange={handleNewCodeChange} /></td>
                        <td><Input required name="batch" min={1} type="number" value={newCode?.batch} onChange={handleNewCodeChange} /></td>
                        <td><Input required name="minimum_order_quantity" min={0} type="number" value={newCode?.minimum_order_quantity} onChange={handleNewCodeChange} style={{minWidth: "48px"}} /></td>
                        <td><Button color="success" size="sm" onClick={handleAddCode}>{t("Lisää")}</Button></td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Button variant="link" size="sm" onClick={handleOpenNewCode}>{t("Lisää uusi koodi")}</Button>
                )}
                

            </Col>
          </Row>
          
          {/* Supplier product codes END */}
          
        </Col>
        
        {/* Second column END */}
        
        {/* Third column START */}

        <Col className="col-12 col-lg-6 col-xl-4">
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Muut tiedot")}</Typography>
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <SelectMulti disabled={true} label={t("Näytä versioissa")} name="versions" values={values} setFieldValue={setFieldValue} defaultValues={getSelectedVersions()} options={getVersionOptions()} />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("visible_from")}
                label={t("Näkyvillä")}
                type="datetime-local"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("weight")}
                label={t("Paino")}
                type="text"
                pattern="[0-9]+([.,][0-9]+)?"
              />
              kg
            </Col>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("template")}
                label={t("Pakettikoko")}
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input {...getFormikInputProps("status")} required={true} label={`${t("Tila")}`} type="select" helpText="Tilattavissa-tila estää tuotteen päivitykset ERPistä MCF:ään. Tuotteelle ei myöskään luoda MCF-ID:tä ennen kuin tilan vaihtaa.">
                <option value=""></option>
                {getStatusOptions()}
              </Input>
              { values?.status == 3 ? (
                <Typography variant="small">{t("product_status_orderable_helptext","Tilattavissa-tila estää tuotteen päivitykset ERPistä MCF:ään. Tuotteelle ei myöskään luoda MCF-ID:tä ennen kuin tilan vaihtaa.")}</Typography>
              ) : null}
            </Col>
          </Row>
          
        </Col>
        
        {/* Third column END */}
        
        {/* Fourth column START */}
        
        { values?.id && (
        <Col className="col-12 col-lg-6 col-xl-4">
          <Row>
            <Col className="mb-3">
              <Typography variant="h2" bold>{t("Varasto")}</Typography>
            </Col>
          </Row>
          
          { values?.variations && values?.variations.length > 0 ? (
            <Row>
              <Col className="mb-3">
              {t("Ei voi olla varastoa, koska tuotteella on variaatioita.")}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="mb-3">
                <ProductStock productId={values?.id} is_mcf_product={values?.is_mcf_product} resolve={handleProductStockResolve} />
              </Col>
            </Row>
          )}
          
        </Col>
        )}
        
        {/* Fourth column END */}
        
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-center">
          { values?.id ? (
          <Button disabled={!values?.id} onClick={handleArchive} variant="danger">{t("Poista")}</Button>
          ) : null }
        </Col>
        <Col className="mb-3 text-right">
          <Button variant="success" disabled={isSubmitting} onClick={() => handleSave()}>{t("Tallenna")}</Button>
          { isTouched() && isValid == false && <Typography className="text-danger">{t("Virheitä lomakkeella")}</Typography> }
          { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
        </Col>
      </Row>
        
    </>
  );
}

 const ProductEditFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        product_code: Yup.string().required(required),
        name_fi: Yup.string().required(required).notOneOf(
          ["0"],
        ),
        name_en: Yup.string().required(required).notOneOf(
          ["0"],
        ),
        status: Yup.string().required(required),
        vat_rate: Yup.number().required(required), //jk
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      // activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      
      //const visible_from = moment(values?.visible_from).format('YYYY-MM-DD').toString()
      const visible_from = values?.visible_from ? moment(values?.visible_from).toISOString() : 0;
      const weight = values?.weight ? parseFloat(values?.weight.toString().replace(",",".")) : 0;
      
      let data = {
        ...values,
        brand_id: values?.brand_id && values?.brand_id.length > 0 ? values.brand_id : undefined,
        name: values?.name_fi ? values?.name_fi : "noname",
        visible_from: visible_from,
        weight: weight,
      };
      
      if (values?.id) {
        httpUpdateProduct(values.id, data).then(res => {
          props.notify({ 
            type: 'success',
            title:t("Tuote"), 
            message:t("Tallennettu")
          })
          
          httpExportProductToMCF(res?.data?.id).then(res2 => {
            setSubmitting(false);
            props.notify({ 
              type: 'success', 
              title:t("Tuote"), 
              message:t("Muutokset viety MyCashFlowiin")
            })
            // Go to list view
            // props.history.push("/dashboard/products/list");
            
            // Get most recent data of product from backend
            props.getProduct(res?.data?.id)
          }, error => {
            if (error?.data?.errorCode == "UNABLE_TO_EXPORT_ORDERS_MCF") {
              props.notify({ 
                type: "info", 
                title:t("Ei viety MyCashFlowiin"), 
                message: JSON.stringify(error?.data?.message)
              })
            }
            else if (error?.data?.detail) {
              props.notify({ 
                type: "danger", 
                title:t("Virhe MyCashFlow viennissä"), 
                message: JSON.stringify(error?.data?.detail)
              })
              setErrors({
                general: error?.data?.detail,
              })
            }
            else {
              props.notify({ 
                type: "danger", 
                title:t("MyCashFlow viennissä virhe"), 
              })
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }).finally(() => {
            setSubmitting(false);
          })
        }, error => {
          setSubmitting(false);
          props.notify({ 
            type: "danger", 
            title:t("Virhe tuotteen tallennuksessa"), 
          })
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            if (error?.status === 400) {
              setErrors({
                ...error?.data,
                general: t("backend_form_400_error","Tarkista lomakkeen virheet")
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
        })
        .finally(() => {
          setSubmitting(false);
        })
      }
      else {
        httpCreateProduct(data).then(res => {
          const id = res?.data?.id;
          
          httpExportProductToMCF(id).then(resMcf => {
            setSubmitting(false);
            
            props.notify({ 
              type: 'success',
              title:t("Tuote"), 
              message:t("Tallennettu")
            })

            props.notify({ 
              type: 'success', 
              title:t("Tuote"), 
              message:t("Muutokset viety MyCashFlowiin")
            })
            
            // Go to edit view
            props.history.push(`/dashboard/products/edit/${id}/basic`);
          }, error => {
            if (error?.data?.errorCode == "UNABLE_TO_EXPORT_ORDERS_MCF") {
              
              props.notify({ 
                type: 'success',
                title:t("Tuote"), 
                message:t("Tallennettu")
              })
              
              props.notify({ 
                type: "info", 
                title:t("Ei viety MyCashFlowiin"), 
                message: JSON.stringify(error?.data?.message)
              })
              
              // When errors, delete created product
              // Do not delete here, this is fine if the status is 3
              // httpDeleteProduct(id)
              
              // Go to edit view
              props.history.push(`/dashboard/products/edit/${id}/basic`);
            }
            else if (error?.data?.detail) {
              props.notify({ 
                type: "danger", 
                title:t("Virhe MyCashFlow viennissä"), 
                message: JSON.stringify(error?.data?.detail)
              })
              setErrors({
                general: error?.data?.detail,
              })
              
              // When errors, delete created product
              httpDeleteProduct(id)
            }
            else {
              props.notify({ 
                type: "danger", 
                title:t("MyCashFlow viennissä virhe"), 
              })
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
              
              // When errors, delete created product
              httpDeleteProduct(id)
            }
          }).finally(() => {
            setSubmitting(false);
          })
          
          // props.history.push(`/dashboard/products/edit/${res?.data?.id}/basic`);
        }, error => {
          setSubmitting(false);
          props.notify({ 
            type: "danger", 
            title:t("Virhe tuotteen tallennuksessa"), 
          })
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            if (error?.status === 400) {
              setErrors({
                ...error?.data,
                general: t("backend_form_400_error","Tarkista lomakkeen virheet")
              })
            }
            else {
              setErrors({
                general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
              })
            }
          }
        })
        .finally(() => {
          setSubmitting(false);
        })
      }
      
      
      
    },
    displayName: "BasicForm"
  
  })(ProductEdit)
    
    
const ProductEditView = ({match, history, productId, tab}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { enums } = useAppContext()
  
  const getProduct = (id) => {
    setLoading(true)
    httpGetProduct(id).then(res => {
      
      const visible_from = res?.data?.visible_from;
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        // visible_from: visible_from ? moment.parseZone(visible_from).format("YYYY-MM-DD") : undefined, // .toDate(),
        visible_from: visible_from ? moment(visible_from).format("yyyy-MM-DDTHH:mm") : undefined,
        // activated:res?.activated ? moment(res?.activated).format("YYYY-MM-DD") + "" : null,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  
  useEffect(() => {
    if (productId && parseInt(productId) > -1) {
      getProduct(productId)
    }
  }, [productId])
  
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
  <div className="sk-product-edit-root">
    <Breadcrumb className="mb-0" listClassName="breadcrumb-links">
      <BreadcrumbItem>
        <Link to={`/dashboard/products/list`}>
          {t("Tuotteet")}
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem>
        { t("Tuote") }
      </BreadcrumbItem>
    </Breadcrumb>
    <ProductEditFormik 
        t={t} history={history} 
        preSetValues={preSetValues} 
        notify={notify} 
        enums={enums}
        getProduct={getProduct}
    />
  </div>
  )
  
}
  

export default ProductEditView;

import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Collapse } from 'reactstrap';
import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton, Modal, Pad } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import {
  httpGetPurchaseOrderProductComplaints,
} from "services/purchase_orders"
import { getDate } from "services/datetime"
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import ProductComplaintView from "views/dashboard/ProductComplaintView/ProductComplaintView"
import TextCutter from "components/Helpers/TextCutter"

const PurchaseOrderComplaintsList = ({
  history,
  purchaseOrderId,
  isSupplier=false,
}) => {
  
  const { enums } = useAppContext()
  const { myUser } = useAuthContext()
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState({})
  
  const getTypeValue = (key) => {
    const value = enums && enums?.complaints?.product_complaint?.type.filter((item) => {
      return item.key === key
    })[0].value
    return t(value) /* i18next-extract-disable-line */
  }
  
  const [complaints, setComplaints] = useState([])
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > 0) {
      getPurchaseOrderProductComplaints()
    }
  }, [purchaseOrderId])
  
  const getPurchaseOrderProductComplaints = () => {
    setLoading(true)
    httpGetPurchaseOrderProductComplaints(purchaseOrderId).then(res => {
      setComplaints(res?.data)
      setLoading(false)
    }, error => {
      setLoading(false)
    })
  }
  
  const complaintHeaders = [
    { label: t("purchase_order_complaints_list_id_complaint","Reklamaatio ERP-ID"), key: "id_complaint"},
    { label: t("purchase_order_complaints_list_state","Tila"), key: "actions_pads"},
    { label: "", key: "actions"},
    { label: t("MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: t("purchase_order_complaints_list_name","Nimi"), key: "name"},
    { label: t("purchase_order_complaints_list_code","Tuotenumero"), key: "code", visible: !isSupplier},
    { label: t("purchase_order_complaints_list_product_supplier_code","Toimittajan tuo.nro"), key: "product_supplier_code", visible: true},
    { label: t("purchase_order_complaints_list_barcode","Viivakoodi"), key: "barcode"},
    { label: t("purchase_order_complaints_list_amount","Tilattava määrä"), key: "amount"},
    { label: t("purchase_order_complaints_list_type","Syy"), key: "type"},
  ];
  
  const getComplaintTableRows = useCallback(() => {
    return complaints && complaints.map(row => {
      
      const isVariation = row?.purchase_order_product?.product_variation ? true : false;
      
      let barcode = isVariation ? row?.purchase_order_product?.product_variation?.stock_item?.barcode : row?.purchase_order_product?.product?.stock_item?.barcode
      barcode = barcode && barcode.length > 0 ? barcode : "-";
      
      let product_supplier_code_name = "";
      if (row?.product_complaint?.product_supplier_code) {
        product_supplier_code_name = row?.product_complaint?.product_supplier_code_serializer?.name
      } else if (row?.product_complaint?.product_variation_supplier_code) {
        product_supplier_code_name = row?.product_complaint?.product_variation_supplier_code_serializer?.name
      }
      
      const code = row?.purchase_order_product?.product_variation?.product_code && row?.purchase_order_product?.product_variation?.product_code.length > 0 ? row?.purchase_order_product?.product_variation?.product_code : row?.purchase_order_product?.product?.product_code
      
      const getProductComplaintStatus = () => {
        if (myUser?.is_supplier) {
          if (row?.product_complaint?.confirmed_by_orderer && row?.product_complaint?.confirmed_by_supplier) {
            return (
              <Pad icon="fa-check" color="success">{t("purchase_order_complaints_list_success","Confirmed")}</Pad>
            )
          }
          else if (row?.product_complaint?.confirmed_by_supplier) {
            return (
              <Pad icon="fa-exclamation-circle" color="warning">{t("purchase_order_complaints_list_waiting_orderer_confirm","Waiting orderer confirm")}</Pad>
            )
          }
          else {
            return (
              <Pad icon="fa-exclamation-circle" color="danger">{t("purchase_order_complaints_list_waiting_for_confirm","Waiting confirm")}</Pad>
            )
          }
        }
        else {
          if (row?.product_complaint?.confirmed_by_orderer && row?.product_complaint?.confirmed_by_supplier) {
            return (
              <Pad icon="fa-check" color="success">{t("purchase_order_complaints_list_success","Confirmed")}</Pad>
            )
          }
          else if (row?.product_complaint?.confirmed_by_orderer) {
            return (
              <Pad icon="fa-exclamation-circle" color="warning">{t("purchase_order_complaints_list_waiting_supplier_confirm","Waiting supplier confirm")}</Pad>
            )
          }
          else {
            return (
              <Pad icon="fa-exclamation-circle" color="danger">{t("purchase_order_complaints_list_waiting_for_confirm","Waiting confirm")}</Pad>
            )
          }
        }
        
        
        
      }
      
      return {
        id: row?.id,
        id_complaint: row?.product_complaint?.id,
        mcf_product_id: row?.purchase_order_product?.product?.id_mcf,
        mcf_variation_id: row?.purchase_order_product?.product_variation ? row?.purchase_order_product?.product_variation?.id_mcf : "",
        name: <>
          <Typography bold>
          {row?.purchase_order_product?.product?.name}
          </Typography>
          { row?.purchase_order_product?.product_variation && (
            <Typography>
            { row?.purchase_order_product?.product_variation?.name }
            </Typography>
          )}
        </>,
        barcode: <Typography>{barcode}</Typography>,
        code: (
          <Typography>
            <TextCutter text={code} />
          </Typography>
        ),
        product_supplier_code: <Typography>{product_supplier_code_name}</Typography>,
        amount: <Typography>{row?.product_complaint?.amount}</Typography>,
        type: <Typography>{getTypeValue(row?.product_complaint?.type)}</Typography>,
        actions: !row?.has_variations && (
          <>
          {/*
          row?.product_complaint?.comments_count
          row?.product_complaint?.unread_comments
          */}
            <IconButton size="lg" onClick={() => handleOpenModalComplaint(row?.product_complaint?.id)} iconName="eye" badgeColor="default" badgeValue={row?.product_complaint?.unread_comments > 0 ? row?.product_complaint?.unread_comments : null} />
          </>
        ),
        actions_pads: (
          <>
            {getProductComplaintStatus()}
          </>
        ),
      }
    })
  }, [complaints])
  
  // Modal: View Complaint
  
  const [selectedComplaintId, setSelectedComplaintId] = useState()
  const [modalComplaintOpen, setModalComplaintOpen] = useState(false)
  
  const toggleModalComplaint = () => {
    setModalComplaintOpen(s => !s);
  }
  
  const resolveModalComplaint = () => {
    setSelectedComplaintId(null)
    getPurchaseOrderProductComplaints();
  }
  const rejectModalComplaint = () => {
    setSelectedComplaintId(null)
    getPurchaseOrderProductComplaints();
  }
  
  const handleOpenModalComplaint = (complaintId) => {
    setSelectedComplaintId(complaintId)
  }

  useEffect(() => {
    if (selectedComplaintId) {
      setModalComplaintOpen(true)
    }
    else {
      setModalComplaintOpen(false)
    }
  }, [selectedComplaintId])
  
  if (!complaints || complaints.length === 0) {
    return (
      <></>
    )
  }
  
  return (
    <>
      { complaints && complaints.length > 0 && (
        <Row className="d-flex justify-content-between mb-2">
          <Col className="col-auto">
            <Typography variant="h2">{t("puchase_order_complaints_list_title", "Reklamaatiot")}</Typography>
          </Col>
          <Col className="col-auto">
            &nbsp;
          </Col>
        </Row>
      )}
      
       <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={complaintHeaders}
        rows={getComplaintTableRows()}
        loading={false}
        setFilters={setFilters}
        showPaginationLimitSelect={false}
      />
      
      <Modal
        title={" "}
        isOpen={modalComplaintOpen}
        toggleModal={toggleModalComplaint}
        closeModal={rejectModalComplaint}
      >
        <ProductComplaintView
          productComplaintId={selectedComplaintId}
          modalOpen={modalComplaintOpen}
          handleResolve={resolveModalComplaint}
          handleReject={rejectModalComplaint}
        />
      </Modal>
      
    </>
  );
}

export default PurchaseOrderComplaintsList;

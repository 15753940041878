import { action, thunk, persist } from 'easy-peasy';
import {
  httpGetPurchaseOrdersWaitingProcess,
  httpGetPurchaseOrdersWaitingProcessSupplier,
} from "services/purchase_orders"

const model = {
  badges: {},
  setBadges: action((state, payload) => {
    state.badges = {
      ...payload,
    }
  }),
  updateBadges: action((state, payload) => {
    state.badges = {
      ...state,
      ...payload,
    }
  }),
  getCompanyBadges: thunk(async (actions, payload) => {
    const params = {
      offset:0,
      limit:1,
    }
    httpGetPurchaseOrdersWaitingProcess(params).then(res => {
      actions.setBadges({
        nav_purchase_orders_main: res?.data?.badge_count,
        nav_purchase_orders_child: res?.data?.badge_count,
      })
    })
  }),
  getSupplierBadges: thunk(async (actions, payload) => {
    const params = {
      offset:0,
      limit:1,
    }
    httpGetPurchaseOrdersWaitingProcessSupplier(params).then(res => {
      actions.setBadges({
        nav_supplier_sales_orders: res?.data?.badge_count
      })
    })
  }),
};

export default model;
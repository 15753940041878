import React, {useEffect, useState, useCallback} from "react";
import moment from "moment";
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';

import { useNotification } from "stories/components/Notification"
import { Container, Row, Col } from "stories/layout";
import {
  Typography,
  Button,
  Link,
  Modal,
  Spinner,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Pad,
} from "stories/components";
import { PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { 
  httpGetPurchaseOrder,
  httpGetPurchaseOrderProducts,
  httpConfirmPurchaseOrder,
  httpGetPurchaseOrderPayment,
  httpGetPurchaseOrderShipping,
} from "services/purchase_orders"

import PurchaseOrderAddProduct from "views/dashboard/PurchaseOrderAddProduct/PurchaseOrderAddProduct"
import SupplierOrderProductEdit from "views/dashboard/SupplierOrderProductEdit/SupplierOrderProductEdit"
import { getDateAndTime } from "services/datetime"
import { useAppContext } from "contexts/AppContext"
import ProductComplaintEdit from "views/dashboard/ProductComplaintEdit/ProductComplaintEdit"
import PurchaseOrderComplaintsList from "views/dashboard/PurchaseOrderComplaintsList/PurchaseOrderComplaintsList"
import PurchaseOrderPaymentEdit from "views/dashboard/PurchaseOrderPaymentEdit/PurchaseOrderPaymentEdit"
import PurchaseOrderNewComplaintsList from "views/dashboard/PurchaseOrderNewComplaintsList/PurchaseOrderNewComplaintsList"
import TextCutter from "components/Helpers/TextCutter"
import config from "services/config"

const SupplierPurchaseOrderEdit = (props) => {
  
  const {
      t,
      values,
      orderData,
      setOrderData,
      loading,
      purchaseOrderPayment,
      purchaseOrderShipping,
  } = props;
  
  const [loadingProducts, setLoadingProducts] = useState(false);
  const { getTabKeyByIdSupplier, getTabNameByIdSupplier } = useAppContext()
  
  const [filters, setFilters] = useState({
    is_stock_product: true,
  })
  
  const getTabKey = useCallback(() => {
    const state = values?.state;
    return getTabKeyByIdSupplier(state);
  }, [values?.state])
  
  const getTabName = useCallback(() => {
    const state = values?.state;
    return getTabNameByIdSupplier(state);
  }, [values?.state])
  
  // Purchase order products
  
  useEffect(() => {
    if (!values?.id) {
      setOrderData({})
    }
  }, [values?.supplier])
  
  // Existing Purchange Order Products
  
  useEffect(() => {
    getPurchaseOrderProducts()
  }, [values])
  
  const getPurchaseOrderProducts = () => {
    let params = {
      // amount__gte: values?.state > 1 ? 1 : undefined,
      ordering: "name",
    }
    
    if (values?.id) {
      setLoadingProducts(true);
      httpGetPurchaseOrderProducts(values?.id, params).then(res => {
        setRows(res?.data)
      }).finally(() => {
        setLoadingProducts(false);
      })
    }
  }
  
  // Table
  const [rows, setRows] = useState([])
  
  const headers = [
    // { label: "ID", key: "id", sortable: true},
    { label: t("Tila"), key: "actions_pads"},
    { label: t("MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true},
    { label: t("Nimi"), key: "name", sortable: true},
    // { label: t("Tuotenumero"), key: "code"},
    { label: t("Toimittajan tuo.nro"), key: "product_supplier_code", visible: true},
    { label: t("Viivakoodi"), key: "barcode", visible: true},
    { label: t("Paino"), key: "weight", visible: true},
    // { label: t("Batch"), key: "batch", sortable: false},
    { label: t("Tilattava määrä"), key: "total_order_count"},
    { label: t("supplier_sales_order_edit_sale_price_label", "Myyntihinta"), key: "buy_price"},
    { label: t("Yhteensä"), key: "total_value"},
    { label: t("supplier_purchase_order_delivered_count","Toimittu perille"), key: "delivered_count"},
    // { label: t("Tila"), key: "confirmed"},
  ];
  
  const getTableRows = useCallback(() => {
    return rows && rows.map(row => {
      
      // const productId = row?.extra?.is_variation ? row?.product_fk?.id : row?.id;
      // const productVariationId = row?.extra?.is_variation ? row?.id : 0;
      
      const stock_item = row?.product_variation?.stock_item ? row?.product_variation?.stock_item : row?.product?.stock_item
      
      const getSupplierCodeName = (id) => {
        if (row?.product_variation) {
          return row?.product_variation?.product_supplier_codes && row?.product_variation?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
        else {
          return row?.product?.product_supplier_codes && row?.product?.product_supplier_codes.reduce((result, item) => {
            if (parseInt(item.id) === parseInt(id)) { result = item?.name }
            return result
          }, null)
        }
      }
      
      const barcode = stock_item?.barcode && stock_item?.barcode.length > 0 ? stock_item?.barcode : "-";
      
      const unreadComments = row?.comments_supplier_unread_count
      
      return {
        actions_pads: (
          <>
          { row?.confirmed_by_supplier == false ? (
            <Pad
              onClick={() => handleOpenProduct(row?.id)}
              icon="fa-exclamation-circle" 
              color="danger"
              badgeValue={unreadComments && unreadComments > 0 ? unreadComments : undefined}
              badgeColor={unreadComments && unreadComments > 0 ? "danger" : undefined}
            >
              {t("Käsittele tuoterivi")}
            </Pad>
          ) : (
            <>
              { row?.confirmed_by_orderer == false ? (
                <Pad 
                  icon="fa-exclamation-circle" 
                  color="warning"
                  badgeValue={unreadComments && unreadComments > 0 ? unreadComments : undefined}
                  badgeColor={unreadComments && unreadComments > 0 ? "danger" : undefined}
                  onClick={() => handleOpenProduct(row?.id)}
                >{t("Odottaa käsittelyä")}</Pad>
              ) : (
                <Pad 
                  icon="fa-check" 
                  color="success"
                  badgeValue={unreadComments && unreadComments > 0 ? unreadComments : undefined}
                  badgeColor={unreadComments && unreadComments > 0 ? "danger" : undefined}
                  onClick={() => handleOpenProduct(row?.id)}
                >{t("Käsitelty")}</Pad>
              )}
            </>
          )}
          </>
        ),
        barcode: <Typography>{barcode}</Typography>,
        batch: <Typography>{row?.batch}</Typography>,
        buy_price: <Typography>{row?.price}{values?.purchase_order_currency}</Typography>,
        code: (
          <Typography className="sk_code_field">
            <TextCutter text={ row?.product_variation?.product_code && row?.product_variation?.product_code.length > 0 ? row?.product_variation?.product_code : row?.product?.product_code} />
          </Typography>
        ),
        complaints_count: <Typography>{row?.complaints_count}</Typography>,
        confirmed: (
          <>
            { row?.confirmed_by_supplier ? (
              <Badge color="success">{t("Käsitelty")}</Badge>
            ) : (
              <Badge color="danger">{t("Ei käsitelty")}</Badge>
            )}
          </>
        ),
        mcf_product_id: row?.product?.id_mcf,
        mcf_variation_id: row?.product_variation ? row?.product_variation?.id_mcf : "",
        name: <>
          <Typography bold className="sk_name_field">
            <Link onClick={() => handleOpenProduct(row?.id)}>
              <TextCutter text={row?.product?.name} limit={config?.purchase_order_name_characters} />
            </Link>
          </Typography>
          { row?.product_variation && (
            <Typography className="sk_name_field">
              <TextCutter text={row?.product_variation?.name} limit={config?.purchase_order_name_characters} />
            </Typography>
          )}
        </>,
        product_supplier_code: (
          <Typography className="sk_code_field">{getSupplierCodeName(row?.product_supplier_code_id)}</Typography>
        ),
        total_order_count: <Typography>{row?.total_order_count}</Typography>,
        total_value: <Typography>{row?.total_order_value}{values?.purchase_order_currency}</Typography>,
        weight: row?.product_variation ? row?.product_variation?.weight : row?.product?.weight,
        delivered_count: <Typography>{row?.delivered_count}</Typography>,
      }
    })
  }, [rows, orderData])
  
  // Modal: Product
  const [modalProductOpen, setModalProductOpen] = useState(false)
  const [purchaseOrderProductId, setPurchaseOrderProductId] = useState()
  
  const toggleModalProduct = () => {
    setModalProductOpen(s => !s);
  }
  
  const handleOpenProduct = (id) => {
    setPurchaseOrderProductId(id)
  }
  
  const handleProductResolve = (response) => {
    setPurchaseOrderProductId(null)
    props.refresh() // because we need to refresh also Purchase Order (status)
    
    // All products confirmed by supplier => open confirm modal
    if (response && response?.all_products_confirmed_by_supplier === true) {
      handleOpenModalConfirm(true)
    }
  }
  
  const handleProductReject = () => {
    setPurchaseOrderProductId(null)
    props.refresh() // because we need to refresh also Purchase Order (status)
    // getPurchaseOrderProducts()
  }
  
  useEffect(() => {
    if (purchaseOrderProductId) {
      setModalProductOpen(true)
    }
    else {
      setModalProductOpen(false)
    }
  },[purchaseOrderProductId])
  
  useEffect(() => {
    if (modalProductOpen === false) {
      setPurchaseOrderProductId(null)
    }
    
  },[modalProductOpen])
  
  // Modal: Add Product
  const [modalAddProductOpen, setModalAddProductOpen] = useState(false)
  
  const modalToggleAddProduct = () => {
    setModalAddProductOpen(s => !s);
  }
  
  const resolveAddProduct = () => {
    setModalAddProductOpen(false)
  }
  
  const rejectAddProduct = () => {
    setModalAddProductOpen(false)
  }
  
  // Statuses
  
  const handleAcceptOrder = () => {
    httpConfirmPurchaseOrder(values?.id).then(res => {
      props.refresh()
      getPurchaseOrderProducts()
      handleOpenModalPayment(values?.id)
    })
  }
  
  // Modal: Confirm
  
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false)
  
  const handleOpenModalConfirm = () => {
    setModalConfirmOpen(true)
  }
  
  const toggleModalConfirm = () => {
    setModalConfirmOpen(s => !s);
  }
  
  const resolveConfirm = () => {
    setModalConfirmOpen(false)
    handleAcceptOrder()
  }
  
  const rejectConfirm = () => {
    setModalConfirmOpen(false)
    props.refresh();
  }
  
  // Modal: View Complaint
  
  const [selectedComplaintId, setSelectedComplaintId] = useState()
  const [modalComplaintOpen, setModalComplaintOpen] = useState(false)
  
  const toggleModalComplaint = () => {
    setModalComplaintOpen(s => !s);
  }
  
  const resolveModalComplaint = () => {
    setSelectedComplaintId(null)
  }
  const rejectModalComplaint = () => {
    setSelectedComplaintId(null)
  }
  
  const handleOpenModalComplaint = (complaintId) => {
    setSelectedComplaintId(complaintId)
  }

  useEffect(() => {
    if (selectedComplaintId) {
      setModalComplaintOpen(true)
    }
    else {
      setModalComplaintOpen(false)
    }
  }, [selectedComplaintId])
  
  // Modal: Payment
  
  const [modalOrderPaymentOpen, setModalOrderPaymentOpen] = useState(false)
  const [modalOrderPaymentId, setModalOrderPaymentId] = useState()
  
  const toggleModalOrderPayment = () => {
    setModalOrderPaymentOpen(s => !s);
  }
  
  const handleOpenModalPayment = (id) => {
    setModalOrderPaymentOpen(true)
    setModalOrderPaymentId(id);
  }
  
  const handleOrderPaymentResolve = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
    props.refresh()
  }
  
  const handleOrderPaymentReject = () => {
    setModalOrderPaymentOpen(false);
    setModalOrderPaymentId(null);
  }
  
  return (
    <>
      <Row>
        <Col>
          <Breadcrumb className="mb-3" listClassName="breadcrumb-links">
            <BreadcrumbItem>
              <Link to={`/dashboard/supplier_sales_orders/list/${getTabKey()}`}>
                {t("supplier_sales_order_edit_breadcrumb_sales_orders","Myyntitilaukset")}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={`/dashboard/supplier_sales_orders/list/${getTabKey()}`}>
                {getTabName()}
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <a aria-current="page" className="active">
              {t("supplier_sales_order_edit_breadcrumb_order","Tilaus")} {values?.id}
              </a>
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      
      <Row>
        <Col>
          <PageHeader title={t("supplier_sales_order_edit_page_header","Myyntitilaukset")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2"></div>
          </div>
        </Col>
      </Row>
      
      <Row className="d-flex justify-content-between mb-5">
        <Col className="col-auto">
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography variant="h2" bold className="mb-2">{t("supplier_sales_order_edit_order","Tilaus")} #{values?.id}</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography bold inline>{t("Tilaus saapunut")}:</Typography>&nbsp;
                <Typography inline>{getDateAndTime(values?.state_sent)}</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography bold inline>{t("Tilauksen paino")}:</Typography>&nbsp;
                <Typography inline>-</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography bold inline>{t("Tilauksen arvo")}:</Typography>&nbsp;
                <Typography inline>{values?.total_order_value}{values?.purchase_order_currency}</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography bold inline>{t("Toimitusehto")}:</Typography>&nbsp;
                <Typography inline>-</Typography>
              </Col>
            </Row>
            <Row>
              <Col className="col-12 col-sm-12 col-md-auto">
                <Typography bold inline>{t("Sopimusehdot")}:</Typography>&nbsp;
                <Typography inline>-</Typography>
              </Col>
            </Row>
        </Col>
        
        <Col className="col-auto">
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography variant="h2" bold className="mb-2">{t("Tilaaja")}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Yhteyshenkilö")}:</Typography>&nbsp;
              <Typography inline>{values?.creator?.full_name}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Puhelin")}:</Typography>&nbsp;
              <Typography inline>{values?.company?.phone}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Yritys")}:</Typography>&nbsp;
              <Typography inline>{values?.company?.name}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Sähköposti")}:</Typography>&nbsp;
              <Typography inline>{values?.company?.email}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Osoite")}:</Typography>&nbsp;
              <Typography inline>{values?.company?.full_address}</Typography>
            </Col>
          </Row>
        </Col>
        
        { values?.state > 2 && (
        <Col className="col-auto">
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography variant="h2" bold className="mb-2">{t("Maksu- ja lähetystiedot")}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Lasku (pdf)")}:</Typography>&nbsp;
              <Typography inline>
              { purchaseOrderPayment?.payment_bill_attachment ? (
                <a href={purchaseOrderPayment?.payment_bill_attachment} target="_blank">{t("avaa pdf")}</a>
              ) : "-"}
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Seurantatunnus")}:</Typography>&nbsp;
              <Typography inline>{purchaseOrderShipping?.shipping_identifier}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Kuljetusyhtiö")}:</Typography>&nbsp;
              <Typography inline>{purchaseOrderShipping?.shipping_transport_company}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography bold inline>{t("Muut huomiot")}:</Typography>&nbsp;
              <Typography inline>{purchaseOrderShipping?.shipping_details}</Typography>
            </Col>
          </Row>
        </Col>
        )}
        
        <Col className="col-auto" style={{maxWidth:"256px"}}>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography variant="h2" bold className="mb-2">{t("Tilauksen lisätiedot")}</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-sm-12 col-md-auto">
              <Typography variant="small">
              {values?.description ? values?.description : t("Ei lisätietoja")}
              </Typography>
              
              { values?.attachment && (
                <div>
                  <i className="fas fa-paperclip"></i> <a href={values?.attachment} target="_blank">{t("Lataa liite")}</a>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col className="text-right" style={{maxWidth:"256px"}}>
          <div className="d-flex flex-column">

            <div className="p-2">
              <Button className="btn-success text-nowrap" disabled={values?.confirmed_by_supplier} onClick={handleOpenModalConfirm}>
                { values?.confirmed_by_orderer && values?.confirmed_by_supplier ? t("Tilaus hyväksytty") : values?.confirmed_by_supplier ? (
                  <>
                  {t("Odottaa tilaajan hyväksyntää")}
                  </>
                ) : (
                  <>
                  {t("Hyväksy tilaus")}
                  <Badge color="danger" type="floating">!</Badge>
                  </>
                )}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      
      <Row>
        <Col>
          <Typography variant="h2">{t("Tilauksen sisältö")}</Typography>
        </Col>
      </Row>
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading || loadingProducts}
        setFilters={setFilters}
        showPaginationLimitSelect={false}
        tableStickyHeader={true}
      />
      
      <Row className="mt-5">
        <Col>
          <PurchaseOrderComplaintsList
            purchaseOrderId={values?.id}
            isSupplier={true}
          />
        </Col>
      </Row>
      
      <Row className="mt-5">
        <Col>
          <PurchaseOrderNewComplaintsList
            purchaseOrderId={values?.id}
            updateIndex={0}
            isSupplier={true}
          />
        </Col>
      </Row>
      
      <Modal
        size="modal-xl"
        title={t("Muokkaa tilaustuotetta")}
        isOpen={modalProductOpen}
        toggleModal={toggleModalProduct}
        closeModal={handleProductReject}
      >
        <SupplierOrderProductEdit
          purchaseOrderId={values?.id}
          purchaseOrderProductId={purchaseOrderProductId}
          handleResolve={handleProductResolve}
          handleReject={handleProductReject}
          readOnly={values?.state >= 4}
        />
      </Modal>
      
      <Modal
        title={t("Lisää tuote")}
        isOpen={modalAddProductOpen}
        toggleModal={modalToggleAddProduct}
      >
        <PurchaseOrderAddProduct purchaseOrderId={values?.id} supplierId={values?.supplier} resolve={resolveAddProduct} reject={rejectAddProduct} />
      </Modal>
      
      <Modal
        title={t("Haluatko varmasti hyväksyä tilauksen?")}
        isOpen={modalConfirmOpen}
        toggleModal={toggleModalConfirm}
        buttons={[
          <Button onClick={rejectConfirm}>{t('Sulje')}</Button>,
          <Button onClick={resolveConfirm} variant="success">{t('Hyväksy tilaus')}</Button>
        ]}
      >
        <Typography>{t('Kaikki tuotteet merkitään käsitellyiksi')}</Typography>
      </Modal>
      
      <Modal
        title={t("Näytä reklamaatio")}
        isOpen={modalComplaintOpen}
        toggleModal={toggleModalComplaint}
        closeModal={rejectModalComplaint}
      >
        <ProductComplaintEdit
          productComplaintId={selectedComplaintId}
          modalOpen={modalComplaintOpen}
          handleResolve={resolveModalComplaint}
          handleReject={rejectModalComplaint}
        />
      </Modal>
      
      <Modal
        title={t("Maksutiedot")}
        isOpen={modalOrderPaymentOpen}
        toggleModal={toggleModalOrderPayment}
      >
        <PurchaseOrderPaymentEdit
          purchaseOrderId={modalOrderPaymentId}
          handleResolve={handleOrderPaymentResolve}
          handleReject={handleOrderPaymentReject}
        />
      </Modal>
      
      
      
    </>
  );
}

// export default PurchaseOrderEdit;

const defaultValues = {
  id: null,
  supplier: null,
  state: 0,
  // activated: moment().set({hour:23,minute:59,second:59,millisecond:999}).format("YYYY-MM-DD"),
}

 const SupplierPurchaseOrderEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues;
      }
    },
    /*
    validationSchema: props => {
      
    },
    */
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      console.log("handleSubmit");
    },
    displayName: "BasicForm"
  
  })(SupplierPurchaseOrderEdit)

const SupplierPurchaseOrderEditView = ({match, history}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [preSetValues, setPreSetValues] = useState(null);
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const [purchaseOrderId, setPurchaseOrderId] = useState()
  const [orderData, setOrderData] = useState({})
  const [purchaseOrderPayment, setPurchaseOrderPayment] = useState({})
  const [purchaseOrderShipping, setPurchaseOrderShipping] = useState({})
  
  const getSupplierBadges = useStoreActions((actions) => actions.badges.getSupplierBadges);
 
  useEffect(() => {
    getSupplierBadges()
  }, [])
  
  const getPurchaseOrder = (id) => {
    setLoading(true)
    httpGetPurchaseOrder(id).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        supplier: res.data?.supplier?.id,
      });
    }, errors => {
      notify({ title:t("supplier_sales_order_notify_title","Myyntitilaus"), message:t("Ei löytynyt")})
      history.push(`/dashboard/supplier_sales_orders/list/open`);
    }).finally(response => {
      setLoading(false);
    })
  }
  
  const getPurchaseOrderPayment = (pId) => {
    httpGetPurchaseOrderPayment(pId).then(res => {
      setPurchaseOrderPayment({
        ...res.data,
        payment_paid_state_dt: res?.data?.payment_paid_state_dt ? moment(res?.data?.payment_paid_state_dt).format('YYYY-MM-DD') : null,
      })
    }).finally(response => {
      
    })
  }
  
  const getPurchaseOrderShipping = (pId) => {
    httpGetPurchaseOrderShipping(pId).then(res => {
      setPurchaseOrderShipping(res.data)
    }).finally(response => {
      
    })
  }  
  
  const refresh = () => {
    if (purchaseOrderId && purchaseOrderId > 0) {
      getPurchaseOrder(purchaseOrderId)
      getPurchaseOrderPayment(purchaseOrderId)
      getPurchaseOrderShipping(purchaseOrderId)
      getSupplierBadges()
    }
  }
  
  useEffect(() => {
    if (match && match?.params?.purchaseOrderId) {
      setPurchaseOrderId(match?.params?.purchaseOrderId)
    }
  }, [match])
  
  useEffect(() => {
    refresh()
  }, [purchaseOrderId])
  
  if (!preSetValues) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <SupplierPurchaseOrderEditFormik 
      t={t} 
      history={history} 
      preSetValues={preSetValues} 
      loading={loading} 
      notify={notify} 
      orderData={orderData} 
      setOrderData={setOrderData} 
      refresh={refresh} 
      purchaseOrderPayment={purchaseOrderPayment} 
      purchaseOrderShipping={purchaseOrderShipping}
    />
  )
  
}
  

export default SupplierPurchaseOrderEditView;

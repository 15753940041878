import http from './api'

const url = '/versions'

export const httpGetVersions = (params) => {
  return http({
    url: `${url}/` ,
    method: 'GET',
    params,
  })
}

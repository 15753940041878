import React, { useCallback, useState } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

// core components
import { Input } from "stories/forms";
import { Button, Link, Typography } from "stories/components"
import { Card, CardBody, CardHeader, Col, Container, Row } from "stories/layout"
import { useNotification } from "stories/components/Notification"

import { httpLogin } from "services/auth"
import { useStoreActions } from 'easy-peasy';
import { useAuthContext } from "contexts/AuthContext";

const Login = (props) => {

  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     isValid,
     isSubmitting,
     validateForm,
   } = props;
   
   const {
      history,
      t
   } = props;
   
   
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const handleCancel = () => {
    history.push("/");
  }
  
  const onSubmit = (e) => {
    e.preventDefault();
    validateForm().then(() => handleSubmit())
  }
  
  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="7" className="">
            <Card variant="primary">
              <CardHeader className="bg-transparent pt-5 pb-1">
                
                <div className="text-center text-muted mb-4">
                  <Typography variant="h2" className="mb-2">{t('Kirjaudu sisään')}</Typography>
                  <Typography variant="p">{t('Tervetuloa takaisin')}</Typography>
                </div>
              
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <form onSubmit={onSubmit}>
                
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("email")}
                      label={t("Sähköposti")}
                      type="email"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Input
                      {...getFormikInputProps("password")}
                      label={t("Salasana")}
                      type="password"
                      required
                    />
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mb-3">
                  {/* <Typography variant="small">Ei tunnuksia? <Link to="/auth/register">Luo tästä</Link></Typography> */}
                  </Col>
                  <Col className="mb-3 text-right">
                    <Typography variant="small">{t('Salasana unohtunut?')} <Link to="/auth/password/forgot">{t('Palauta tästä')}</Link></Typography>
                  </Col>
                </Row>
                
                <div className="text-center text-muted mb-4">
                  <Button disabled={isSubmitting} type="submit">{t('Kirjaudu')}</Button>
                </div>
                
                { Boolean(errors["general"]) && (
                  <div className="text-center text-muted mb-4">
                    <Typography className="text-danger">{errors["general"]}</Typography>
                  </div>
                )}
              
              </form>
              </CardBody>
            </Card>

          </Col>
        </Row>
      </Container>
    </>
  );
}

const defaultValues = {
  email: '',
  password: '',
}

 const LoginFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return defaultValues
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      return Yup.object().shape({
        email: Yup.string().email(t('Tarkista sähköpostiosoitteen muoto')).required(required),
        password: Yup.string().required(required),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      httpLogin(values?.email, values?.password).then(res => {
        setSubmitting(false);
        if (res?.data) {
          let { access, refresh } = res.data;
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);
          props.handleResolve()
        }
        else {
          setErrors({
            general: t('Väärä tunnus tai salasana'),
          })
        }
      }, error => {
        setSubmitting(false);
        const details = error?.data?.detail ? error?.data?.detail : t("Väärä tunnus tai salasana");
        
        if (error?.status === 401) {
          if (error?.data?.detail === "No active account found with the given credentials") {
            setErrors({
              general: t("No active account found with the given credentials"),
            })
          }
          else {
            setErrors({
              general: error?.data?.detail,
            })
          }
        }
        else if (error?.status === 400) {
          if (error?.data?.errorCode == "USER_NOT_ACTIVATED") {
            setErrors({
              general: `${t('Käyttäjätunnusta ei ole aktivoitu')}`,
            })
          }
          else {
            setErrors({
              general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error?.data)}`,
            })
          }
        }
        else {
          setErrors({
            general: `${t('Virhe. Ota tarvittaessa yhteys ylläpitoon.')} ${JSON.stringify(error)}`,
          })
        }
      })
    },
    displayName: "BasicForm"
  
  })(Login)
    
    
const LoginView = ({match, history}) => {
  // API requests here
  
  const { t } = useTranslation();
  const { getMyUser } = useAuthContext();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const [infoAlert, setInfoAlert] = useState();
  // const login = useStoreActions((actions) => actions.auth.login);
  
  const handleModalConfirm = () => {
    setInfoAlert(null);
    getMyUser();
    // history.push("/dashboard/frontpage");
  }
  
  const handleResolve = () => {
    notify({ title:t("Käyttäjä"), message:t("Kirjautuminen onnistui")})
    handleModalConfirm();
    /*
    setInfoAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Lähetetty"
        onConfirm={() => handleModalConfirm()}
        onCancel={() => handleModalConfirm()}
        btnSize=""
      >
        Kutsu lähetetty onnistuneesti
      </ReactBSAlert>
    )
    */
  }
  
  return (
    <>
      <LoginFormik history={history} t={t} handleResolve={handleResolve} />
      {infoAlert}
    </>
  )
}
  

export default LoginView;

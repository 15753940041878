import React, { useState, useCallback, useEffect } from "react";
import moment from "moment";
import { generatePath } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import ProductEdit from "views/dashboard/ProductEdit/ProductEdit";
import ProductVariationsList from "views/dashboard/ProductVariationsList/ProductVariationsList";
import ProductVariationEdit from "views/dashboard/ProductVariationEdit/ProductVariationEdit";
import { httpGetProductStatistics } from "services/products"

const ProductEditView = ({match}) => {
  
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [productStatistics, setProductStatistics] = useState({});
  
  const getProductStatistics = () => {
    if (!id) {
      return;
    }
    httpGetProductStatistics(id).then(res => {
      setProductStatistics(res.data)
    })
  }
  
  useEffect(() => {
    getProductStatistics()
  }, [id, match])
  
  useEffect(() => {
    if (match?.params?.productId) {
      setId(match?.params?.productId);
    }
  }, [match])
  
  const handleExportProductToMCF = () => {
    // httpExportProductToMCF(id)
  }
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Tuote")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
          {/*<div className="p-2"><Button size="sm" onClick={handleExportProductToMCF}>Vie tiedot MyCashFlow</Button></div>*/}
          </div>
        </Col>
      </Row>
      
      
      
      <ul className="nav nav-tabs mb-2">
        <li className="nav-item">
          <Link className={`nav-link ${match?.params?.tab === "basic" ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              productId: match?.params?.productId,
              tab: "basic"
            })}
          ><i className="fas fa-exclamation-circle"></i> {t("Perustiedot")}</Link>
        </li>
        <li className="nav-item">
          <Link className={`nav-link ${(match?.params?.tab === "variations" || match?.params?.tab === "variation") ? "active" : ""} py-3`}
            to={generatePath(match.path, {
              productId: match?.params?.productId,
              tab: "variations"
            })}
          ><i className="fas fa-briefcase"></i> {t("Variaatiot")} ({productStatistics?.variations_count})</Link>
        </li>
        {/*
        <li className="nav-item">
          <a className="nav-link py-3" href="#" disabled><i className="far fa-calendar-check"></i> {t("Saatavuus")}</a>
        </li>
        <li className="nav-item">
          <a className="nav-link py-3" href="#"><i className="far fa-credit-card"></i> {t("Toimitus")}</a>
        </li>
        */}
      </ul>
      
      <Switch>
        <Route
          path={
            generatePath(match.path, {
              productId: match?.params?.productId,
              tab: "basic"
            })
          }
          exact
          render={props => <ProductEdit {...props} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path, {
              productId: match?.params?.productId,
              tab: "variations"
            })
          }
          exact
          render={props => <ProductVariationsList {...props} productId={match?.params?.productId} />}
        />
        <Route
          path={
            generatePath(match.path, {
              productId: match?.params?.productId,
              productVariationId: match?.params?.productVariationId,
              tab: "variation"
            })
          }
          exact
          render={props => <ProductVariationEdit {...props} productId={match?.params?.productId} productVariationId={match?.params?.productVariationId} />}
        />
        
      </Switch>
      
    </>
  );
}

export default ProductEditView;

import React, { useState, useCallback, useEffect, useRef } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge, IconButton } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";

import { useNotification } from "stories/components/Notification"
import { httpGetSuppliers } from "services/suppliers"
import { httpGetStockItems } from "services/stock"
import { httpCreateComplaint, httpGetComplaint, httpUpdateComplaint, httpDeleteComplaint, uploadComplaintImage } from "services/complaints"
import { useAppContext } from "contexts/AppContext"
import { useAuthContext } from "contexts/AuthContext"
import ProductComplaintComments from "views/dashboard/ProductComplaintComments/ProductComplaintComments"

import helmets from "assets/images/helmets.png"

const defaultValues = {
  id: null,
  amount: 1,
  customer_type: 2,
}

const ProductComplaintEdit = (props) => {
  
  const {
     dirty,
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm,
     setErrors,
     setTouched,
   } = props;
   
   const {
      t,
      history,
      newImages,
      setNewImages,
      newImageFiles,
      setNewImageFiles,
      productId,
      productVariationId,
      purchaseOrderId,
      myUser,
   } = props;
   
   const { enums } = useAppContext()
   const inputHeroFile = useRef(null)
   const [loadingSuppliers, setLoadingSuppliers] = useState(false);
   const [loadingProducts, setLoadingProducts] = useState(false);
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  const getTypeOptions = useCallback(() => {
    return enums?.complaints?.product_complaint?.type && enums?.complaints?.product_complaint?.type.map(obj => {
      return (
        <>
          {/* i18next-extract-disable-next-line */}
          <option value={obj.key}>{t(obj.value)}</option>
        </>
      )
    })
  }, [enums])
  
  const getStatusOptions = useCallback(() => {
    return enums?.complaints?.product_complaint?.status && enums?.complaints?.product_complaint?.status.map(obj => {
      return (
        <>
          {/* i18next-extract-disable-next-line */}
          <option value={obj.key}>{t(obj.value)}</option>
        </>
      )
    })
  }, [enums])
  
  const getCustomerTypeOptions = useCallback(() => {
    return enums?.complaints?.product_complaint?.customer_type && enums?.complaints?.product_complaint?.customer_type.map(obj => {
      return (
        <>
          {/* i18next-extract-disable-next-line */}
          <option value={obj.key}>{t(obj.value)}</option>
        </>
      )
    })
  }, [enums])
  
  const handleCancel = () => {
    props.handleReject()
  }
  
  // Image handling
  
  const handleHeroImageClick = () => {
    inputHeroFile.current.click()
  }
  
  const handleHeroImageChange = ({target}) => {
    
    const file = target.files[0];
    const convert_base64 = false;
    
    if (!file) return;
    
    if(file.size > 5000000) {
      props.enqueueSnackbar(`${file.name} koko on liian suuri (max 5 Mt)`, { variant: 'error' });
    }
    else {
      setNewImages(s => ([
        ...s,
        URL.createObjectURL(file)
      ]))
      
      setNewImageFiles(s => ([
        ...s,
        file
      ]))
      
    }

  }
  
  // Suppliers
  
  const [suppliers, setSuppliers] = useState([])
   
  useEffect(() => {
    getSuppliers()
  }, [])
   
  const getSuppliers = () => {
    const params = {
      active: true,
      ordering: "name",
    }
    setLoadingSuppliers(true);
    httpGetSuppliers(params).then(res => {
      setSuppliers(res.data)
    }).finally(() => {
      setLoadingSuppliers();
    })
  }
   
  const getSupplierOptions = useCallback(() => {
    return suppliers && suppliers.map(supplier => {
      return (
        <option value={supplier.id}>{supplier.name}</option>
      )
    })
  }, [suppliers])
  
  // Stock items (Products / Product Variations)
  
  const [stock, setStock] = useState([])
  const [productSupplierCodes, setProductSupplierCodes] = useState()
   
  useEffect(() => {
    if (values?.supplier) {
      getStock();
    }
  }, [values?.supplier])
   
  const getStock = () => {
    const params = {
      ordering: "product_name",
      serializer: "StockItemListSerializer",
      supplier: values?.supplier,
    }
    setLoadingProducts(true);
    httpGetStockItems(params).then(res => {
      setStock(res.data)
    }).finally(() => {
      setLoadingProducts(false);
    })
  }
   
  const getStockOptions = useCallback(() => {
    return stock && stock.map(stockProduct => {
      return (
        <option value={stockProduct.id}>{stockProduct?.product_fk?.name} {stockProduct?.product_variation_fk ? ` - ${stockProduct?.product_variation_fk?.name}` : ""} {`(${stockProduct?.product_fk?.id_mcf}-${stockProduct?.product_variation_fk?.id_mcf ? stockProduct?.product_variation_fk?.id_mcf : "0"}) ${stockProduct?.product_variation_fk ? stockProduct?.product_variation_fk?.product_code : stockProduct?.product_fk?.product_code}`}</option>
      )
    })
  }, [stock])
  
  useEffect(() => {
    if (!values?.stockItemId) {
      console.log("!values?.stockItemId");
      return;
    }
    const i = stock.findIndex(si => {
      return si?.id == values?.stockItemId
    })
    const stockItem = stock[i]
    setFieldValue("product", stockItem?.product_fk?.id)
    setFieldValue("product_variation", stockItem?.product_variation_fk?.id)
    
    // Set ProductSupplierCodes (options)
    if (stockItem?.product_variation_fk?.id) {
      setProductSupplierCodes(stockItem?.product_variation_fk?.product_supplier_codes)
    }
    else {
      setProductSupplierCodes(stockItem?.product_fk?.product_supplier_codes)
    }
  }, [values?.stockItemId])
  
  useEffect(() => {
    if (!stock || !values?.product) {
      return;
    }
    
    if (values?.product_variation) {
      const i = stock.findIndex(si => {
        return si?.product_fk?.id == values?.product && si?.product_variation_fk?.id == values?.product_variation
      })
      const stockItem = stock[i]
      setFieldValue("stockItemId", stockItem?.id)
    }
    else {
      const i = stock.findIndex(si => {
        return si?.product_fk?.id == values?.product
      })
      const stockItem = stock[i]
      setFieldValue("stockItemId", stockItem?.id)
    }
  }, [stock, values?.product, values?.product_variation])
  
  // Product supplier codes
  
  const getProductSupplierCodeInputOptions = () => {
      return productSupplierCodes && productSupplierCodes.map(product_supplier_code => {
        if (parseInt(product_supplier_code?.supplier_fk) === parseInt(values?.supplier)) {
          return (
            <option value={product_supplier_code.id}>{product_supplier_code.name}</option>
          )
        }
      })
    }
  
  const showFields = () => {
    return ((props?.supplierId || values?.supplier) && (productId || values?.product))
  }
  
  const handleDelete = () => {
    httpDeleteComplaint(values?.id).then(res => {
      props.handleResolve()
    })
  }
  
  const validateFormBeforeSubmit = (submitFunction) => {
    if (!submitFunction) {
      return;
    }
    validateForm().then((res) => { 
      if (Object.keys(res).length === 0) { 
        submitFunction();
      } else { 
        setTouched(res);
        setErrors(res);
      } 
    })
  }
  
  return (
    <>
      <Row>
        <Col>
          
          {!values?.id || values?.id === 0 && (
          <Row className="mb-3">
            <Col>
               <Typography bold variant="h2"><div className="avatar rounded-circle bg-danger w-32 h-32 mr-2"><i className="fas fa-wrench"></i></div> {t('product_complaint_edit_title','Tee uusi reklamaatio')}</Typography>
            </Col>
          </Row>
          )}
          
          { suppliers && !props?.supplierId ? (
            <Row>
              <Col>
                <Input label={t("Toimittaja")} type="select" {...getFormikInputProps("supplier")} disabled={loadingSuppliers || props?.supplierId || values?.id}>
                  <option value=""></option>
                  {getSupplierOptions()}
                </Input>
              </Col>
            </Row>
          ): null }
          
          { stock && !productId && !productVariationId ? (
          <Row className="mb-3">
            <Col>
              <Input label={t("Tuote")} type="select" {...getFormikInputProps("stockItemId")} disabled={loadingProducts || !values?.supplier || values?.id}>
                <option value=""></option>
                {getStockOptions()}
              </Input>
            </Col>
          </Row>
          ) : null }
          
          { showFields() && (
          <>
            
            { !values?.product_variation && productSupplierCodes ? (
            <Row className="mb-3">
              <Col>
                <Input {...getFormikInputProps("product_supplier_code")} className="sk_input form-control" label={t("Toimittajan tuotenumero") + "*"} name="product_supplier_code" type="select" value={values?.product_supplier_code}>
                  <option value=""></option>
                  {getProductSupplierCodeInputOptions()}
                </Input>
              </Col>
            </Row>
            ) : null }
            
            { values?.product_variation && productSupplierCodes ? (
            <Row className="mb-3">
              <Col>
                <Input {...getFormikInputProps("product_variation_supplier_code")} className="sk_input form-control" label={t("Toimittajan tuotenumero") + "*"} name="product_variation_supplier_code" type="select" value={values?.product_variation_supplier_code}>
                  <option value=""></option>
                  {getProductSupplierCodeInputOptions()}
                </Input>
              </Col>
            </Row>
            ) : null }
            
            <Row className="mb-3">
              <Col>
                <Typography bold>{t('product_complain_edit_pictures_title','Kuvat')}</Typography>
              </Col>
              <Col className="text-right">
                <div onClick={handleHeroImageClick} className="cursor_pointer">
                  <Button color="default">
                    <div className="avatar rounded-circle w-32 h-32 mr-1"><i className="fas fa-camera"></i></div> {t("Lisää kuva")}
                  </Button>
                  <input className="inputFileHidden" ref={inputHeroFile} type="file" name="hero_image" accept="image/*" onChange={handleHeroImageChange} />
                </div>
              </Col>
            </Row>
            
            
            { newImages && newImages.length > 0 && (
            <>
              <Row className="my-2">
                <Col>
                  <Typography size="small">{t('product_complain_edit_new_pictures_title','Uudet kuvat')}</Typography>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <div className="d-flex flex-row mb-3">
                  { newImages && newImages.length > 0 && newImages.map((newImage, index) => (
                      <div
                        className="p-2 mr-2 mb-2 avatar avatar-xl"
                      >
                        <img alt={"complaint_new_image_"+index} src={newImage} />
                      </div>
                  ))}
                  </div>
                </Col>
              </Row>
            </>
            )}
            
            { values?.images && values?.images.length > 0 && (
            <>
              <Row className="my-2">
                <Col>
                  <Typography size="small">{t('product_complain_edit_uploaded_pictures_title','Ladatut kuvat')}</Typography>
                </Col>
              </Row>
              <Row className="my-2">
                <Col>
                  <div className="d-flex flex-row mb-3">
                  { values?.images && values?.images.length > 0 && values?.images.map(existingImage => (
                      <a href={existingImage?.image} target="_blank" rel="noopener noreferrer">
                      <div
                        className="p-2 mr-2 mb-2 avatar avatar-xl"
                      >
                          <img border="0" alt={"complaint_image_"+existingImage?.id} src={existingImage?.image} />
                      </div>
                      </a>
                  ))}
                  </div>
                </Col>
              </Row>
            </>
            )}
            
            <Row>
              <Col className="mb-3">
                <Input
                  {...getFormikInputProps("amount")}
                  label={t('product_complaint_edit_amount','Määrä')}
                />
              </Col>
            </Row>
            
            <Row>
              <Col className="mb-3">
                <Input {...getFormikInputProps("type")} required label={t('product_complaint_edit_type','Tyyppi')} type="select" >
                  <option value=""></option>
                  {getTypeOptions()}
                </Input>
              </Col>
            </Row>
            
            <Row>
              <Col>
                 <Input 
                  {...getFormikInputProps("description")} 
                  label={t('product_complaint_edit_description','Kuvaus')}
                  placeholder={t('product_complaint_edit_description','Kuvaus')}
                  type="textarea" 
                />
              </Col>
            </Row>
            
            <Row>
              <Col className="mb-3">
                <Input {...getFormikInputProps("status")} required label={t('product_complaint_edit_status','Tila')} type="select">
                  <option value=""></option>
                  {getStatusOptions()}
                </Input>
              </Col>
            </Row>
            
            <Row>
              <Col className="mb-3">
                <Input {...getFormikInputProps("customer_type")} required label={t('product_complaint_edit_customer_type','Asiakastyyppi')} type="select">
                  {getCustomerTypeOptions()}
                </Input>
              </Col>
            </Row>
            
            { purchaseOrderId ? null : (
            <Row>
              <Col className="mb-3">
                <Input {...getFormikInputProps("purchase_order")} label={t('product_complaint_edit_purchase_order','Ostotilaus (id)')} type="number" helpText={t("Jossa reklamoitu")} />
              </Col>
            </Row>
            )}
        </>
        )}
        
      </Col>
      </Row>
      
      <Row className="mt-3 mb-3">
        <Col className="text-left">
          <Button onClick={handleCancel} variant="secondary">{t('Cancel','Peruuta')}</Button>
        </Col>
        {values?.id && myUser?.is_supplier === false ? (
        <Col className="text-center">
          <Button onClick={handleDelete} variant="danger">{t('Delete','Poista')}</Button>
        </Col>
        ) : null }
        <Col className="text-right">
          { !props?.readOnly && (
            <>
              <Button variant="success" disabled={isSubmitting || !dirty || !showFields() || isValid === false} onClick={() => validateFormBeforeSubmit(handleSubmit)}>{t('Save','Tallenna')}</Button>
              
              { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
            </>
          )}
        </Col>
      </Row>
        
    </>
  );
}

const ProductComplaintEditFormik = withFormik({
    enableReinitialize: true,
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        amount: Yup.string().nullable(),
        type: Yup.string().required(required).nullable(),
        status: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      
      let data = {
        product: props.productId,
        product_variation: props.productVariationId,
        purchase_order: props?.purchaseOrderId ? props?.purchaseOrderId : undefined,
        product_supplier_code: props?.productSupplierCodeId ? props?.productSupplierCodeId : undefined,
        product_variation_supplier_code: props?.productVariationSupplierCodeId ? props?.productVariationSupplierCodeId : undefined,
        supplier: props?.supplierId,
        ...values,
      };
      
      // Validation: Supplier code is required
      if (!data?.product_supplier_code && !data?.product_variation_supplier_code) {
        setErrors({
          general: t("Virheitä lomakkeella"),
          product_supplier_code: required,
          product_variation_supplier_code: required,
        })
        return;
      }
      
      const uploadNewImageFiles = (complaintId) => {
        
        if (props.newImageFiles && props.newImageFiles.length > 0) {
          props.newImageFiles.map((newImage, index) => {
                
            const imageFile = props.newImageFiles[index]
        
            uploadComplaintImage(complaintId, imageFile).then((response) => {
              // success
            }, (errors) => {
              // errors
              if (errors?.status === 400 && errors?.data?.image) {
                props.notify({ 
                  title:t("product_complaint_edit_notify_file_error", "Tiedosto"), 
                  message: errors?.data?.image,
                  type:"danger"
                })
              }
            }).finally(() => {
              // All images uploaded?
              if (index+1 === props.newImageFiles.length) {
                setSubmitting(false);
                props.handleResolve()
              }
            })
            
          })
        }
        else {
          // No images to upload
          setSubmitting(false);
          props.handleResolve()
        }
          
      }
      
      if (data?.id) {

        httpUpdateComplaint(values.id, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("product_complaint_edit_notify_updated_title", "Reklamaatio"), message:t("product_complaint_edit_notify_updated_message","Tallennettu onnistuneesti")})
          
          // Upload possible images
          uploadNewImageFiles(res.data.id)
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ title:t("product_complaint_edit_notify_updated_title", "Reklamaatio"), message:t("product_complaint_edit_notify_updated_message","Tallennettu onnistuneesti")})
            props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
            props.handleResolve()
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      else {
        setSubmitting(true);
        httpCreateComplaint(data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Reklamaatio"), message:t("Luotu onnistuneesti")})
          
          // Upload possible images
          uploadNewImageFiles(res.data.id)
          
        }, error => {
          setSubmitting(false);
          if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
            props.notify({ title:t("Reklamaatio"), message:t("Luotu onnistuneesti")})
            props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
            props.handleResolve()
          }
          else if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error", "Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
    },
    displayName: "BasicForm"
  
})(ProductComplaintEdit)
    
    
const ProductComplaintEditView = ({history, productId, productVariationId, productComplaintId, purchaseOrderId, productSupplierCodeId, productVariationSupplierCodeId, supplierId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [productComplaint, setProductComplaint] = useState({})
  const [preSetValues, setPreSetValues] = useState({
    product: productId,
    product_variation: productVariationId,
    supplier: supplierId,
    product_supplier_code: productSupplierCodeId,
    product_variation_supplier_code: productVariationSupplierCodeId,
  });
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const { myUser } = useAuthContext()
  
  const [newImages, setNewImages] = useState([])
  const [newImageFiles, setNewImageFiles] = useState([])
  
  const getProductComplaint = () => {
    if (!productComplaintId || modalOpen === false) {
      return;
    }
    setLoading(true)
    httpGetComplaint(productComplaintId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    getProductComplaint()
  }, [productComplaintId, modalOpen])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  return (
    <>
      <ProductComplaintEditFormik
        t={t}
        history={history}
        preSetValues={preSetValues}
        notify={notify}
        productId={productId}
        productVariationId={productVariationId}
        productComplaintId={productComplaintId}
        productSupplierCodeId={productSupplierCodeId}
        productVariationSupplierCodeId={productVariationSupplierCodeId}
        purchaseOrderId={purchaseOrderId}
        newImages={newImages}
        setNewImages={setNewImages}
        newImageFiles={newImageFiles}
        setNewImageFiles={setNewImageFiles}
        myUser={myUser}
        supplierId={supplierId}
        {...rest}
      />
      
      { preSetValues?.id ? (
      <Row className="mt-3 mb-3">
        <Col>
          <ProductComplaintComments productComplaintId={preSetValues?.id} />
        </Col>
      </Row>
      ) : null }
      
    </>
  )
  
}
  

export default ProductComplaintEditView;

import React, { useCallback, useEffect, useState} from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { useStoreActions } from 'easy-peasy';

import { Container, Row, Col, Card, CardHeader, CardBody, List } from "stories/layout";
import { Typography, Button, Link } from "stories/components";
import { useAuthContext }from "contexts/AuthContext"
import { PageWrapper, PageHeader } from "components/Page"

import {
  httpGetPurchaseOrdersWaitingProcessSupplier,
} from "services/purchase_orders"


const FrontpageSupplier = () => {
  
  const { t } = useTranslation();
  const { myUser } = useAuthContext()
  const getSupplierBadges = useStoreActions((actions) => actions.badges.getSupplierBadges);
  
  // PurchaseOrder waiting process
  
  const [waitingProcess, setWaitingProcess] = useState([])
  const [waitingProcessRows, setWaitingProcessRows] = useState([])
  
  const getWaitingProcess = () => {
    const params = {
      
    }
    httpGetPurchaseOrdersWaitingProcessSupplier(params).then(res => {
      setWaitingProcess(res?.data?.results)
    })
  }
  
  useEffect(() => {
    getWaitingProcess()
    getSupplierBadges()
  }, [])
  
  const getWaitingProcessRows = useCallback(() => {
    return waitingProcess && waitingProcess.map(row => {
      return {
        icon: "exclamation",
        iconColor: 'danger',
        content: (
          <>
            <Typography>{t("frontpage_supplier_order","Tilaus")} {row?.id}</Typography>
            <Typography bold>
              <Link to={`/dashboard/supplier_sales_orders/edit/${row?.id}/`}>{t("frontpage_supplier_process","Käsittele")}</Link>
            </Typography>
          </>
        ),
      }
    })
    
  }, [waitingProcess])
  
  
  return (
    <>
      <PageHeader title={t("frontpage_supplier_frontpage","Etusivu")}></PageHeader>
      
      <div className="d-flex justify-content-start flex-wrap align-items-stretch mb-3">
        <List 
          className="p-2 mr-3"
          title={t("frontpage_supplier_waiting_process","Odottaa käsittelyä")}
          rows={getWaitingProcessRows()}
        /> 
      </div>
      
    </>
  );
}

export default FrontpageSupplier;

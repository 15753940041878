import React, { useState, useCallback, useEffect } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import moment from "moment"
import { useTranslation } from 'react-i18next';

import { TableAuto } from "stories/tables"
import { Button, Typography, Spinner, Modal, IconButton } from "stories/components";
import { Container, Row, Col } from "stories/layout";
import { Input } from "stories/forms";
import { useNotification } from "stories/components/Notification"
import {
  httpGetPurchaseOrderDelivery,
  httpCreatePurchaseOrderDelivery,
  httpUpdatePurchaseOrderDelivery,
  httpGetPurchaseOrderProducts
} from "services/purchase_orders"
import {
  httpGetComplaints,
} from "services/complaints"

import ProductComplaintEdit from "views/dashboard/ProductComplaintEdit/ProductComplaintEdit"

const defaultValues = {
  id: null,
  delivery_date: moment().format("yyyy-MM-DDTHH:mm"),
}
const PurchaseOrderDeliverySide = (props) => {
  
  const {
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     validateForm
   } = props;
   
   const {
      t,
      history,
      deliveredProductsData,
      productRows,
      purchaseOrderId,
      setDeliveredProductsData,
      supplierId,
      setUpdateIndex,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean(touched[name] && errors[name]),
      errorText: touched[name] ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange]
  );
  
  const [sorting, setSorting] = useState({
    sortingKey: "erp_id_sort",
    sortingReverse: false,
    sortingType: "float",
  })

  const handleCancel = () => {
    props.handleReject()
  }
  
  const updateProductDelivery = (purchaseOrderProductId, key, value) => {
    let obj = deliveredProductsData[purchaseOrderProductId];
    if (!obj) {
      obj = {}
    }
    
    obj = {
      ...obj,
      [key]:value,
      processed: false,
      
    }
    
    setDeliveredProductsData(s => ({
      ...s,
      [purchaseOrderProductId]: obj
    }))
  }
  
  const handleProductDelivery = ({target}, purchaseOrderProductId) => {
    updateProductDelivery(purchaseOrderProductId, target.name, target.value)
    // setProcessed(purchaseOrderProductId, false)
  }
  
  // Modal: Add Complaint
  
  const [selectedComplaintProduct, setSelectedComplaintProduct] = useState()
  const [modalComplaintOpen, setModalComplaintOpen] = useState(false)
  
  const toggleModalComplaint = () => {
    setModalComplaintOpen(s => !s);
  }
  
  const resolveModalComplaint = () => {
    setSelectedComplaintProduct(null)
    setUpdateIndex(s => s+1)
  }
  const rejectModalComplaint = () => {
    setSelectedComplaintProduct(null)
  }
  
  const handleOpenModalComplaint = (productId, productVariationId, productSupplierCodeId) => {
    setSelectedComplaintProduct({
      productId:productId,
      productVariationId:productVariationId,
      productSupplierCodeId: productSupplierCodeId,
    })
  }

  useEffect(() => {
    if (selectedComplaintProduct) {
      setModalComplaintOpen(true)
    }
    else {
      setModalComplaintOpen(false)
    }
  }, [selectedComplaintProduct])
  
  //
  
  const headers = [
    { label: t("f8_header_mcf_product_id","MCF Päätuotteen ID"), key: "mcf_product_id", visible: true},
    { label: t("f8_header_mcf_variation_id","MCF variaatio tuotteen ID"), key: "mcf_variation_id", visible: true, sortable: false},
    { label: t("f8_header_name","Nimi"), key: "name", sortingKey: "name_sort", sortable: true, sortingType: "string"},
    { label: t("f8_header_barcode","Viivakoodi"), key: "barcode"},
    { label: t("f8_header_code","Tuotenumero"), key: "code"},
    { label: t("f8_header_supplier_product_code","Toimittajan tuotenumero"), key: "supplier_product_code", sortingKey: "supplier_product_code_sort", sortable: true, sortingType: "string"},
    { label: "", key: "copy", sortingKey: "copy_sort", sortable: false, sortingType: "int"},
    { label: t("f8_header_summary_amounts","purchase_order_deliver_side_summary_amounts","J / S"), key: "summary_amounts", sortingKey: "summary_amounts_sort", sortable: false, sortingType: "int"},
    { label: t("f8_header_amount","Määrä"), key: "delivered_amount", sortingKey: "delivered_amount_sort", sortable: true, sortingType: "int"},
    { label: t("f8_header_complaint","Reklamaatio"), key: "complaint_create"},
    { label: t("f8_header_processed","Käsitelty"), key: "processed"},
  ];
  
  const getSupplierProductCodeName = (row, mainProduct) => {
    let supplierProductCode = mainProduct?.product_supplier_codes.reduce((result, item) => {
          if (item.id === row?.product_supplier_code_id) { result = item }
          return result
        }, null)
        
    return supplierProductCode?.name
  }

  const handleCopyAmount = (purchaseOrderProductId) => {
    // Copy expected amount to delivered amount input
    let obj = deliveredProductsData[purchaseOrderProductId];
    if (obj) {
      // Copy value
      setDeliveredProductsData(s => ({
        ...s,
        [purchaseOrderProductId]: {
          ...obj,
          amount: obj.expectedAmount && obj.expectedAmount > 0 ? obj.expectedAmount : 0,
          processed: true,
        }
      }))
    }
  }

  const handleProcessed = (e, purchaseOrderProductId) => {
    // Set row processed or not processed
    let obj = deliveredProductsData[purchaseOrderProductId];
    if (obj) {
      setProcessed(purchaseOrderProductId, e.target.checked)
      /*
      setDeliveredProductsData(s => ({
        ...s,
        [purchaseOrderProductId]: {
          ...obj,
          processed: e.target.checked,
        }
      }))
      */
    }
  }

  const setProcessed = (purchaseOrderProductId, value) => {
    let obj = deliveredProductsData[purchaseOrderProductId];
    if (obj) {
      setDeliveredProductsData(s => ({
        ...s,
        [purchaseOrderProductId]: {
          ...obj,
          processed: value,
        }
      }))
    }
  }


  
  const getTableRows = useCallback(() => {
    
    const sortingType = sorting?.sortingType;
    const sortingKey = sorting?.sortingKey;
    const sortingReverse = sorting?.sortingReverse; // (true/false)
    
    let rowsDict = {};
    
    productRows && productRows.map((row, i) => {

      const productId = row?.product?.id
      const productVariationId = row?.product_variation ? row?.product_variation?.id : 0;
      
      const mainProduct = row?.product_variation ? row?.product_variation : row?.product;
      const supplierProductCodeName = getSupplierProductCodeName(row, mainProduct);
      
      const amount = deliveredProductsData[row?.id]?.amount;
      const expectedAmount = deliveredProductsData[row?.id]?.expectedAmount;
      const totalAmount = deliveredProductsData[row?.id]?.totalAmount;
      
      const processed = deliveredProductsData[row?.id]?.processed;

      // Row coloring
      let className = "";
      if (amount > expectedAmount) {
        className = "sk_color_row_alert";
      }
      else {
        if (deliveredProductsData[row?.id]?.processed) {
          className = "sk_color_row_active";
        }
      }
      
      let rowObj = {
        className: className,
        barcode: mainProduct?.stock_item?.barcode,
        code: mainProduct?.product_code,
        summary_amounts: !row?.isDummy ? `${expectedAmount} / ${totalAmount}` : "",
        copy: !row?.isDummy ? (
          <IconButton size="lg" onClick={() => handleCopyAmount(row?.id)} iconName="check" />
        ) : "",
        delivered_amount: !row?.isDummy ? <Input name="amount" type="number" min={0} value={deliveredProductsData[row?.id]?.amount} onChange={(e) => handleProductDelivery(e, row?.id)} tooltip={t("product_delivery_amount_tooltip","Kirjaa myös ehjät reklamaatiot")} /> : "",
        delivered_amount_sort: deliveredProductsData[row?.id]?.amount,
        mcf_product_id: row?.product?.id_mcf,
        mcf_variation_id: row?.product_variation ? row?.product_variation?.id_mcf : "",
        name: {
          // className: "sk_class",
          content: (
            <>
              <Typography bold>
              {row?.product?.name}
              </Typography>
              { row?.product_variation && (
                <Typography>
                { row?.product_variation?.name }
                </Typography>
              )}
            </>
          )
        },
        name_sort: `${row?.product?.name}_${row?.product_variation?.name}`,
        supplier_product_code: supplierProductCodeName,
        supplier_product_code_sort: !row?.isDummy ? supplierProductCodeName : "",
        complaint_create: !row?.isDummy ? (
          <IconButton size="lg" onClick={() => handleOpenModalComplaint(row?.product?.id, row?.product_variation?.id, row?.product_supplier_code_id)} iconName="exclamation" />
        ) : "",
        processed: !row?.isDummy ? (
          <input type="checkbox" onChange={(e) => handleProcessed(e, row?.id)} checked={processed} />
        ) : "",
        processed_cell_attributes: {
          onClick: (e) => {
            // e.preventDefault();
            // e.stopPropagation();
            setProcessed(row?.id, !processed)
          }
        },

      }
      
      // Sorting single row BEGIN
      // Put the value here that we want to sort by
      if (sortingKey) {
        rowObj["sortValue"] = rowObj[sortingKey]
      }
      if (productVariationId > 0) {
          rowObj["sortFixed"] = row?.product_variation?.sort;
      }
      
      // Lets make hierarcy structure
      
      const getSortValue = (a, b) => {
        // Sort by main product always when sorting one of these columns
        if (["status_sort","name_sort","erp_id_sort","mcf_product_id","brand_id_sort"].includes(sortingKey)) {
          return a
        }
        else {
          // Put the value from child into the parent if bigger or smaller than current parent value (depending on asc/desc)
          let result = 0;
          if (sortingType === "string") {
            result = a.localeCompare(b)
          }
          else if (sortingType === "float") {
            result = parseFloat(a) - parseFloat(b)
          }
          else {
            result = parseInt(a) - parseInt(b)
          }
          
          if (result < 0) {
            return b
          }
          else {
            return a
          }
        }
      }
      
      const mainProductKey = `p${productId}v0`;
      const fullProductKey = `p${productId}v${productVariationId}`;
      
      // Product / Päätuote
      if (productVariationId === 0) {
        rowsDict[mainProductKey] = {
          ...rowObj,
          children: [],
        }
      }
      else { // Variation
        if (rowsDict.hasOwnProperty(mainProductKey)) {
          rowsDict[mainProductKey] = {
            ...rowsDict[mainProductKey],
            children: [
              ...rowsDict[mainProductKey].children,
              rowObj
            ],
            sortValue: getSortValue(rowsDict[mainProductKey].sortValue, rowObj.sortValue),
          }
        }
        else {
          console.log("ERROR WRONG ROW ORDER, PARENT PRODUCT MUST COME FIRST IN HERE")
        }
      }
      // Sorting single row END
      
    })
    
    // Sorting rows BEGIN
    
    // Make list of parent products, to do sorting with them
    let sortedParentRows = []
    for (const [key, value] of Object.entries(rowsDict)) {
      sortedParentRows.push(rowsDict[key])
    }
    
    // Client sorting
    
    const sortRows = (_rows) => {
      return _rows.sort((a, b) => {
        try {
          if (sorting?.sortingType === "string") {
            return a["sortValue"].localeCompare(b["sortValue"])
          }
          else if (sorting?.sortingType === "float") {
            return parseFloat(a["sortValue"]) - parseFloat(b["sortValue"])
          }
          else {
            return parseInt(a["sortValue"]) > parseInt(b["sortValue"]) ? 1 : -1
          }
        }
        catch {
          return -1
        }
      })
    }
    const sortRowsChildren = (_rows) => {
      return _rows.sort((a, b) => {
          return parseInt(a["sortFixed"]) > parseInt(b["sortFixed"]) ? 1 : -1
      })
    }
    
    sortedParentRows = sortRows(sortedParentRows);
    
    if (sortingReverse) {
      sortedParentRows = sortedParentRows.reverse()
    }
    
    // Add children (variations) to the table rows
    let sortedRows = []
    sortedParentRows.map(row => {
      sortedRows.push(row)
      const children = sortRowsChildren(row.children)
      children.map(rowChild => {
        sortedRows.push(rowChild)
      })
    })
    
    return sortedRows;
    
    // Sorting rows END
    
    
  }, [productRows, sorting, deliveredProductsData])
  
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("delivery_date")}
                label={t("Saapumispäivä")}
                type="datetime-local"
              />
            </Col>
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Input
                {...getFormikInputProps("description")}
                label={t("Lisätiedot")}
                type="textarea"
              />
            </Col>
          </Row>
          
          
        </Col>

      </Row>
      
      <Row>
        <Col className="mb-3">
          <Typography bold>{t("Saapuneet tuotteet")}</Typography>
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <TableAuto
            color="dark"
            showId={false}
            checkboxes={false}
            headers={headers}
            rows={getTableRows()}
            loading={false}
            showPaginationLimitSelect={false}
            sortBackend={false}
            sorting={sorting}
            setSorting={setSorting}
          />
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <Button onClick={handleCancel} variant="secondary">{t("Peruuta")}</Button>
        </Col>
        <Col className="mb-3 text-right">
          <>
            <Button variant="success" disabled={isSubmitting} onClick={() => validateForm().then(() => handleSubmit())}>
              { values?.id ? t("Tallenna muutokset") : t("Lisää toimitus") }
            </Button>
            { Boolean(errors["general"]) && <Typography className="text-danger">{errors["general"]}</Typography> }
          </>
        </Col>
      </Row>
      
      <Modal
        title={t("Tee reklamaatio")}
        isOpen={modalComplaintOpen}
        toggleModal={toggleModalComplaint}
      >
        <ProductComplaintEdit
          productId={selectedComplaintProduct?.productId}
          productVariationId={selectedComplaintProduct?.productVariationId}
          productComplaintId={null}
          purchaseOrderId={purchaseOrderId}
          productSupplierCodeId={!selectedComplaintProduct?.productVariationId ? selectedComplaintProduct?.productSupplierCodeId : null}
          productVariationSupplierCodeId={selectedComplaintProduct?.productVariationId ? selectedComplaintProduct?.productSupplierCodeId : null}
          supplierId={supplierId}
          modalOpen={modalComplaintOpen}
          handleResolve={resolveModalComplaint}
          handleReject={rejectModalComplaint}
        />
      </Modal>

    </>
  );
}

const PurchaseOrderDeliverySideFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        /*
        amount: Yup.number().integer().test(
          'Is positive?',
          'VIRHE: Määrän on oltava suurempi kuin 0', 
          (value) => value > 0
        ).required(required),
        */
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      let data = {
        ...values,
        purchase_order: props.purchaseOrderId,
        delivered_products_data: props?.deliveredProductsData,
      };
      
      if (values?.id) {
        
        httpUpdatePurchaseOrderDelivery(props.purchaseOrderId, props.purchaseOrderDeliveryId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Tallennettu"), message:t("Osatoimitus tallennettu")})
          props.handleResolve(res?.data?.id)
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      else {
        httpCreatePurchaseOrderDelivery(props.purchaseOrderId, data).then(res => {
          setSubmitting(false);
          props.notify({ title:t("Luotu"), message:t("Uusi osatoimitus avattu")})
          props.handleResolve(res?.data?.id)
        }, error => {
          setSubmitting(false);
          if (error?.data?.detail) {
            setErrors({
              general: error?.data?.detail,
            })
          }
          else {
            setErrors({
              general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
            })
          }
        })
      }
      
      
    },
    displayName: "BasicForm"
  
})(PurchaseOrderDeliverySide)
    
    
const PurchaseOrderDeliverySideView = ({history, purchaseOrderId, purchaseOrderDeliveryId, supplierId, modalOpen, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [productRows, setProductRows] = useState();
  const [deliveredProductsData, setDeliveredProductsData] = useState();
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  
  const [complaints, setComplaints] = useState()
  const [loadingComplaints, setLoadingComplaints] = useState(false)

  const getComplaints = () => {
    // Return async promise

    return new Promise((resolve, reject) => {
      setLoadingComplaints(true)
      const params = {
        purchase_order: purchaseOrderId,
        serializer: "ProductComplaintListSerializer",
        ordering: "-id",
      }
      httpGetComplaints(params).then(res => {
        setComplaints(res?.data)
      }, error => {
        console.log("httpGetComplaints error", error)
      }).finally(() => {
        setLoadingComplaints(false)
        return resolve(true);
      })
    })
  }

  const getPurchaseOrderDelivery = (purchaseOrderId, purchaseOrderDeliveryId) => {
    setLoading(true)
    httpGetPurchaseOrderDelivery(purchaseOrderId, purchaseOrderDeliveryId).then(res => {
      
      setPreSetValues({
        ...defaultValues,
        ...res.data,
        delivery_date:res?.data?.delivery_date ? moment(res?.data?.delivery_date).format("yyyy-MM-DDTHH:mm") + "" : null,
      });
    }).finally(response => {
      setLoading(false);
    })
  }
  
  useEffect(() => {
    if (purchaseOrderId && parseInt(purchaseOrderId) > -1 && purchaseOrderDeliveryId && parseInt(purchaseOrderDeliveryId) > -1) {
      getPurchaseOrderDelivery(purchaseOrderId, purchaseOrderDeliveryId)
    }
  }, [purchaseOrderId, purchaseOrderDeliveryId, modalOpen])
  
  const getPurchaseOrderProducts = () => {
    let params = {
      total_count__gte: 1,
      // ...productFilters,
    }
    
    if (purchaseOrderId) {
      httpGetPurchaseOrderProducts(purchaseOrderId, params).then(res => {
        //setProductRows(res?.data)
        
        let resRows = res?.data;
        let resRowsSort = []
        let dummyParents = []
        
        // Give each row own sort id
        resRows.map(resRow => {
          const productId = resRow.product.id;
          const productVariationId = resRow.product_variation ? resRow.product_variation.id : 0
          resRow.sortId = parseFloat(`${productId}.${productVariationId}`)
          resRowsSort.push(resRow)
          
          // Create dummy parent when children
          if (productVariationId > 0 && dummyParents.includes(productId) === false) {
            resRowsSort.push({
              product: resRow.product,
              sortId: parseFloat(`${productId}.${0}`),
              isDummy: true,
            })
            dummyParents.push(productId);
          }
        })
        
        // Sort by sortId, we want that rows are orderered by main products ids
        let sortedRows = resRowsSort.sort((a, b) => { 
          return parseFloat(a.sortId) - parseFloat(b.sortId)
        })
        
        setProductRows(sortedRows)
        
        let _delivery = {}
        res?.data && res.data.map(pupr => {
          // Alkuperäinen saapuva määrä = tilattu määrä + tilauksella ilmoitettujen reklamaatiotapausten tuotemäärä yhteensä
          let totalAmount = pupr?.total_count;

          // Oletettu saapumismäärä = alkuperäinen saapuva määrä - (saapunut määrä + uusien nimikkeellä “Ei saapunut” merkittyjen reklamaatiotapausten tuotemäärät yhteensä)
          let expectedAmount = totalAmount - pupr?.delivered_count;

          // Add new complaints amounts where type is 1 (did not arrive)
          
          // let newComplaintsAmountAll = 0;
          let newComplaintsAmountNotArrived = 0; // type = 1

          complaints && complaints.map(complaint => {
            if (complaint?.product?.id === pupr?.product?.id && complaint?.product_variation?.id === pupr?.product_variation?.id) {
              // newComplaintsAmountAll += complaint?.amount;
              if (complaint?.type === 1) {
                newComplaintsAmountNotArrived += complaint?.amount;
              }
            }
          })
          expectedAmount = expectedAmount - newComplaintsAmountNotArrived
          
          /*
          if (expectedAmount <= 0) {
            expectedAmount = 0;
          }
          */

          _delivery[pupr.id] = {
            purchase_order_product: pupr?.id,
            amount: 0, // expectedAmount // changed to 0 in ticket 4-ostotilaukset-kirjaa-uusi-toimitus-merkinnat
            expectedAmount: expectedAmount,
            totalAmount: totalAmount,
          }
        })
        
        setDeliveredProductsData(_delivery)
      })
    }
  }
  
  useEffect(() => {
    getComplaints().then(() => {
      // getPurchaseOrderProducts()
    })
  },[purchaseOrderId, modalOpen])

  useEffect(() => {
    if (complaints != null) {
      getPurchaseOrderProducts()
    }

  }, [complaints])
  
  if (loading || loadingComplaints || !deliveredProductsData) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }

  return (
    <PurchaseOrderDeliverySideFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderId={purchaseOrderId} supplierId={supplierId} purchaseOrderDeliveryId={purchaseOrderDeliveryId} productRows={productRows} deliveredProductsData={deliveredProductsData} setDeliveredProductsData={setDeliveredProductsData} {...rest} />
  )
  

}
  

export default PurchaseOrderDeliverySideView;

import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { useTranslation } from 'react-i18next';

import { Row, Col } from "stories/layout";
import { Card, Typography, Button, Link, IconButton } from "stories/components";
import { PageWrapper, PageHeader } from "components/Page"
import { TableAuto } from "stories/tables"
import { httpGetCategories, httpImportCategoriesFromMCF } from "services/categories"
// import history from "services/history"

const CategoriesList = ({history}) => {
  
  const { t } = useTranslation();
  
  // Table
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState({
    count: 0,
    results: [],
  })
  const [filters, setFilters] = useState({
    limit: 30,
    offset:0,
  })
  
  const getCategories = () => {
    setLoading(true)
    httpGetCategories(filters).then(res => {
      setRows(res?.data)
      setLoading(false)
    }, errorResponse => {
      setLoading(false)
    })
  }
  
  useEffect(() => {
    getCategories();
  }, [])
  
  useEffect(() => {
    getCategories();
  }, [filters])
  
  const handleImportCategoriesFromMCF = () => {
    httpImportCategoriesFromMCF().then(res => {
      getCategories()
    })
  }
  
  const headers = [
    // { label: "", key: "actions"},
    { label: t("Nimi"), key: "name"},
    { label: t("Tuotteita"), key: "products_count"},
  ];
  
  const handleNew = () => {
    history.push(`/dashboard/categories/new`);
  }
  
  const handleEdit = (id) => {
    history.push(`/dashboard/categories/edit/${id}/`);
  }
  
  const paginationPrevious = () => {
    let offset = parseInt(filters?.offset) - parseInt(filters?.limit);
    if (offset < 0) {
      offset = 0;
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const paginationNext = () => {
    let offset = parseInt(filters?.offset) + parseInt(filters?.limit);
    if (offset > filters?.count) {
      return
    }
    setFilters(f => ({
      ...f,
      offset: offset,
    }))
  }
  
  const getTableRows = useCallback(() => {
    return rows && rows.results.map(row => {
      return {
        id: row?.id,
        /*
        name: (
          <>
            <Typography bold>
              <Link to={`/dashboard/categories/edit/${row?.id}/`}>
              {row?.name}
              </Link>
            </Typography>
          </>
        ),
        */
        name: (
          <Typography bold>
            {row?.name}
          </Typography>
        ),
        products_count: (
          <>
            <Typography>{row?.products_count}</Typography>
          </>
        ),
        actions: (
          <>
            <IconButton size="lg" onClick={() => handleEdit(row?.id)} iconName="pen" />
            {/*
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="eye" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="exclamation" />
            <IconButton disabled size="lg" onClick={() => function(){}} iconName="trash" />
            */}
          </>
        ),
      }
    })
  }, [rows])
  
  return (
    <>
      <Row>
        <Col>
          <PageHeader title={t("Tuotekategoriat")}></PageHeader>
        </Col>
        <Col className="text-right">
          <div className="d-flex flex-row-reverse">
            <div className="p-2">
              <Typography variant="small">
                <Link variant="link" onClick={handleImportCategoriesFromMCF}>
                  {t("Tuo tiedot (MCF)")}
                </Link>
              </Typography>
            </div>
            
          </div>
        </Col>
      </Row>
      
      
      
      <Row className="d-flex justify-content-between mb-3">
        <Col>
          
        </Col>
        <Col className="text-right">
        {/*
          <Button color="success" onClick={handleNew}>{t("Lisää tuotekategoria")}</Button>
        */}
        </Col>
      </Row>
      
      
      <TableAuto
        color="dark"
        showId={false}
        checkboxes={false}
        headers={headers}
        rows={getTableRows()}
        loading={loading}
        pagination={true}
        paginationPrevious={paginationPrevious}
        paginationNext={paginationNext}
        paginationOffset={filters?.offset}
        paginationLimit={filters?.limit}
        paginationCount={rows?.count}
        setFilters={setFilters}
      />
      
    </>
  );
}

export default CategoriesList;

import React from "react";
import { Container} from "stories/layout";

const PageWrapper = (props) => {
  const { children } = props;
  
  return (
    <Container fluid>
    {children}
    </Container>
  )
}


export default PageWrapper;
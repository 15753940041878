import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Popover as BootstrapPopover, PopoverBody, UncontrolledPopover } from "reactstrap";

import "./Popover.css"

export const Popover = ({ placement="top", target, trigger="click hover focus", className, children, ...rest }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  let classNames = `${className ? className : ""}`;
  
  return (
    <BootstrapPopover
      className={`${classNames}`}
      placement={placement}
      target={target}
      isOpen={popoverOpen}
      toggle={toggle}
      trigger={trigger}
      {...rest}
    >
      <PopoverBody>
      {children}
      </PopoverBody>
    </BootstrapPopover>
  );
};

Popover.propTypes = {
  placement: PropTypes.oneOf(['top','right','bottom','left']),
};

/**
USAGE

<Button color="default" id="tooltip876279349" type="button">
  Popover on top
</Button>

<Popover
  placement="top"
  target="tooltip876279349"
>
Content
</Popover>
**/
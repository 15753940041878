import http from './api'

const url = '/complaints_comments/comments'

export const httpGetComplaintsComments = (params) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
  })
}

export const httpCreateComplaintComment = (data) => {
  return http({
    url: `${url}/`,
    method: 'POST',
    data,
  })
}

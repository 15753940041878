import http from './api'

const base_url_orders = '/purchase_orders/orders'

export const httpGetPurchaseOrders = (params) => {
  return http({
    url: `${base_url_orders}/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrdersStatuses = (params) => {
  return http({
    url: `${base_url_orders}/statuses/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrder = (id) => {
  return http({
    url: `${base_url_orders}/${id}/`,
    method: 'GET',
  })
}

export const httpCreatePurchaseOrder = (data) => {
  return http({
    url: `${base_url_orders}/`,
    method: 'POST',
    data,
  })
}

export const httpUpdatePurchaseOrder = (id, data) => {
  return http({
    url: `${base_url_orders}/${id}/`,
    method: 'PATCH',
    data,
  })
}

export const httpDeletePurchaseOrder = (id) => {
  return http({
    url: `${base_url_orders}/${id}/`,
    method: 'DELETE',
  })
}

export const httpPublishPurchaseOrder = (id) => {
  return http({
    url: `${base_url_orders}/${id}/publish/`,
    method: 'POST',
  })
}

export const httpGetPurchaseOrderSummary = (id) => {
  return http({
    url: `${base_url_orders}/${id}/summary/`,
    method: 'GET',
  })
}

export const httpUploadPurchaseOrderFile = (id, data) => {
  return http({
    url: `${base_url_orders}/${id}/upload/`,
    method: 'POST',
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data
  })
}

export const httpConfirmPurchaseOrder = (id) => {
  return http({
    url: `${base_url_orders}/${id}/confirm/`,
    method: 'POST',
  })
}

export const httpConfirmPurchaseOrderBoth = (id) => {
  return http({
    url: `${base_url_orders}/${id}/confirm_both/`,
    method: 'POST',
  })
}

export const httpArchivePurchaseOrder = (id) => {
  return http({
    url: `${base_url_orders}/${id}/archive/`,
    method: 'POST',
  })
}

export const httpReturnEditPurchaseOrder = (id) => {
  return http({
    url: `${base_url_orders}/${id}/return_edit/`,
    method: 'POST',
  })
}

// Payments

const base_url_order_payments = '/purchase_orders/order_payments'

export const httpGetPurchaseOrderPayment = (id) => {
  return http({
    url: `${base_url_order_payments}/${id}/`,
    method: 'GET',
  })
}

export const httpUpdatePurchaseOrderPayment = (id, data) => {
  return http({
    url: `${base_url_order_payments}/${id}/`,
    method: 'PATCH',
    data
  })
}

export const httpUploadPurchaseOrderPaymentFile = (id, data) => {
  return http({
    url: `${base_url_order_payments}/${id}/upload/`,
    method: 'POST',
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data
  })
}

// Shipping

const base_url_order_shipping = '/purchase_orders/order_shipping'

export const httpGetPurchaseOrderShipping = (id) => {
  return http({
    url: `${base_url_order_shipping}/${id}/`,
    method: 'GET',
  })
}

export const httpUpdatePurchaseOrderShipping = (id, data) => {
  return http({
    url: `${base_url_order_shipping}/${id}/`,
    method: 'PATCH',
    data
  })
}

export const httpConfirmPurchaseOrderShipping = (id) => {
  return http({
    url: `${base_url_order_shipping}/${id}/confirm/`,
    method: 'POST',
  })
}

// Products

export const httpGetPurchaseOrderProducts = (purchaseOrderId, params) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/products/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrderProduct = (purchaseOrderId, purchaseOrderProductId) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/products/${purchaseOrderProductId}/`,
    method: 'GET',
  })
}

export const httpCreatePurchaseOrderProduct = (purchaseOrderId, data) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/products/`,
    method: 'POST',
    data
  })
}

export const httpUpdatePurchaseOrderProduct = (purchaseOrderId, purchaseOrderProductId, data) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/products/${purchaseOrderProductId}/`,
    method: 'PATCH',
    data
  })
}

export const httpConfirmPurchaseOrderProduct = (purchaseOrderId, purchaseOrderProductId) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/products/${purchaseOrderProductId}/confirm/`,
    method: 'POST',
  })
}

// PurchaseOrder Product Complaints

export const httpGetPurchaseOrderProductComplaints = (purchaseOrderId, params) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/complaints/`,
    method: 'GET',
    params,
  })
}

// PurchaseOrder deliveries

export const httpGetPurchaseOrderDeliveries = (purchaseOrderId, params) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrderDelivery = (purchaseOrderId, purchaseOrderDeliveryId) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/`,
    method: 'GET',
  })
}

export const httpCreatePurchaseOrderDelivery = (purchaseOrderId, data) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/`,
    method: 'POST',
    data,
  })
}

export const httpUpdatePurchaseOrderDelivery = (purchaseOrderId, purchaseOrderDeliveryId, data) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/`,
    method: 'PATCH',
    data,
  })
}

export const httpDeletePurchaseOrderDelivery = (purchaseOrderId, purchaseOrderDeliveryId) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/`,
    method: 'DELETE',
  })
}

// PurchaseOrder product deliveries

export const httpGetPurchaseOrderProductDeliveries = (purchaseOrderId, purchaseOrderDeliveryId, params) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/products/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrderProductDelivery = (purchaseOrderId, purchaseOrderDeliveryId, purchaseOrderProductDeliveryId) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/products/${purchaseOrderProductDeliveryId}/`,
    method: 'GET',
  })
}

export const httpCreatePurchaseOrderProductDelivery = (purchaseOrderId, purchaseOrderDeliveryId, data) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/products/`,
    method: 'POST',
    data,
  })
}

export const httpUpdatePurchaseOrderProductDelivery = (purchaseOrderId, purchaseOrderDeliveryId, purchaseOrderProductDeliveryId, data) => {
  return http({
    url: `${base_url_orders}/${purchaseOrderId}/deliveries/${purchaseOrderDeliveryId}/products/${purchaseOrderProductDeliveryId}/`,
    method: 'PATCH',
    data,
  })
}

// Frontpage status boxes

export const httpGetPurchaseOrdersActionsRequired = (params) => {
  return http({
    url: `${base_url_orders}/actions_required/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrdersWaitingProcess = (params) => {
  return http({
    url: `${base_url_orders}/waiting_process_orderer/`,
    method: 'GET',
    params,
  })
}

export const httpGetPurchaseOrdersWaitingProcessSupplier = (params) => {
  return http({
    url: `${base_url_orders}/waiting_process_supplier/`,
    method: 'GET',
    params,
  })
}

// END

// Helpers

export const httpGetPurchaseOrderProductPricePrevious = (params) => {
  return http({
    url: `/purchase_orders/get_purchase_order_product_price_previous/`,
    method: 'GET',
    params,
  })
}




import React, { useState, useCallback, useEffect, useRef } from "react";
import { withFormik } from 'formik';
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';

import { Button, Typography, Spinner, Badge } from "stories/components";
import { Container, Row, Col, FormContainer, Card, CardBody } from "stories/layout";
import { Input, SelectMulti, Toggle, Radio } from "stories/forms";
import { getDateAndTime } from "services/datetime"
import { useNotification } from "stories/components/Notification"
import { 
  httpGetProductsComments,
  httpCreateProductComment
} from "services/products_comments"
import Comment from "components/Comment/Comment"

const defaultValues = {
  id: null,
}

const ProductComments = (props) => {
  
  const {
     dirty,
     values,
     touched,
     errors,
     handleChange,
     handleBlur,
     handleSubmit,
     setFieldValue,
     isValid,
     isTouched,
     isSubmitting,
     submitCount,
     validateForm
   } = props;
   
   const {
      history,
      t,
   } = props;
   
   const getFormikInputProps = useCallback(
    name => ({
      name,
      value: values[name],
      error: Boolean((touched[name] || submitCount > 0) && errors[name]),
      errorText: (touched[name] || submitCount > 0) ? errors[name] : "",
      onBlur: handleBlur,
      onChange: handleChange
    }),
    [values, errors, touched, handleBlur, handleChange, submitCount]
  );
  
  return (
    <>
      <Row>
        <Col className="mb-1">
          <Input
            {...getFormikInputProps("message")}
            placeholder={t("Jätä kommentti")}
            type="textarea"
          />
        </Col>
      </Row>
      
      <Row>
        <Col className="mb-3">
          <div className="d-flex justify-content-between">
            <div></div>
            <div>
              <Button variant="link" disabled={isSubmitting || !dirty} onClick={() => validateForm().then(() => handleSubmit())}>
                {t("Lisää kommentti")}
              </Button>
            </div>
          </div>
          
        </Col>
      </Row>
      
      { errors?.general ? (
      <Row>
        <Col className="mb-3 text-danger">
        {JSON.stringify(errors?.general)}
        </Col>
      </Row>
      ) : null }
    </>
  );
}

const ProductCommentsFormik = withFormik({
    
    validateOnMount: true,
    mapPropsToValues: props => {
      const { preSetValues } = props;
      if ( preSetValues) {
        return preSetValues;
      } else {
        return {
          ...defaultValues
        }
      }
    },
    validationSchema: props => {
      const {t} = props;
      const required = t("Kenttä on pakollinen");
      return Yup.object().shape({
        message: Yup.string().required(required).nullable(),
      });
    },
    handleSubmit: (values, { setSubmitting, setErrors, props }) => {
      const {t} = props;
      // activated: values.activated ? moment(values.activated).set({hour:23,minute:59,second:59,millisecond:999}) : null,
      let data = {
        ...values,
      };
      
      httpCreateProductComment(data).then(res => {
        setSubmitting(false);
        props.notify({ title:t("Kommentti"), message:t("Lähetetty"), type: "success" })
        props.getProductComments();
      }, error => {
        console.log("httpCreateProductComment error", error);
        setSubmitting(false);
        if (error?.status === 400 && error?.data?.errorCode === "CANNOT_SEND_EMAIL") {
          props.notify({ title:t("Kommentti"), message:t("Tallennettu"), type:"success" })
          props.notify({ title:t("CANNOT_SEND_EMAIL","Email failed"), message: error?.data?.message, type:"warning" })
          props.getProductComments();
        }
        else if (error?.data?.detail) {
          setErrors({
            general: error?.data?.detail,
          })
        }
        else {
          setErrors({
            general: t("unknown_backend_error","Tuntematon virhe. Yritä myöhemmin uudelleen tai ota yhteys tukeen.")
          })
        }
      })
      
    },
    displayName: "BasicForm"
  
})(ProductComments)
    
    
const ProductCommentsView = ({history, purchaseOrderProductId, ...rest}) => {
  // API requests here
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([])
  const [preSetValues, setPreSetValues] = useState();
  const { notify } = useNotification(); // import { useNotification } from "stories/components/Notification"
  const lastCommentRef = useRef(null);
  
  const getProductComments = () => {
    if (!purchaseOrderProductId) {
      return;
    }
    setLoading(true)
    const params = {
      offset: 0,
      limit: 100,
      purchase_order_product: purchaseOrderProductId,
      ordering: "-sent",
    }
    httpGetProductsComments(params).then(res => {
      setComments(res.data.results)
      
      setPreSetValues({
        ...defaultValues,
        purchase_order_product: purchaseOrderProductId,
      });
      
    }).finally(response => {
      setLoading(false);
      
      /*
      setTimeout(() => {
        if (lastCommentRef?.current) {
          lastCommentRef.current.scrollIntoView({behavior: 'smooth'});
        }
      }, 1000);
      */
      
    })
  }
  
  useEffect(() => {
    getProductComments()
  }, [purchaseOrderProductId])
  
  const listComments = useCallback(() => {
    const commentsCount = comments.length;
    return comments && comments.map((comment, i) => (
      <Comment key={i} comment={comment} />
    ))
  }, [comments])
  
  if (loading) {
    return (
      <Container fluid>
        <Spinner />
      </Container>
    )
  }
  
  return (
    <>
      <Row>
        <Col>
           <Typography bold variant="h2">{t('Kommentit')}</Typography>
        </Col>
      </Row>
      <div style={{ maxHeight:"300px", overflowY:"auto"}}>
        {listComments()}
        <div ref={lastCommentRef}></div>
      </div>
      <ProductCommentsFormik t={t} history={history} preSetValues={preSetValues} notify={notify} purchaseOrderProductId={purchaseOrderProductId} getProductComments={getProductComments} {...rest} />
    </>
  )
  
}
  

export default ProductCommentsView;

import http from './api'

const url = '/products_comments/comments'

export const httpGetProductsComments = (params) => {
  return http({
    url: `${url}/`,
    method: 'GET',
    params,
  })
}

export const httpCreateProductComment = (data) => {
  return http({
    url: `${url}/`,
    method: 'POST',
    data,
  })
}
